import VerificationInput from 'react-verification-input'
import styles from './styles.module.scss'
import cx from 'classnames'

interface IProps {
  value: string
  setValue: (value: string) => void
  onComplete?: () => Promise<void>

  inputLength?: number
  validChars?: string
  placeholder?: string

  stylesContainer?: string
  stylesCharacter?: string
  stylesCharacterInactive?: string
  stylesCharacterSelected?: string
  stylesCharacterFilled?: string
  disabled?: boolean
}

export const CodeInput = ({
  value,
  setValue,
  onComplete,
  inputLength = 6,
  validChars = '0-9',
  placeholder = '',
  disabled = false,
  ...props
}: IProps) => {
  const onChange = (data: string) => {
    setValue(data)
    if (data.length >= inputLength) {
      onComplete && onComplete()
    }
  }

  return (
    <VerificationInput
      classNames={{
        container: cx(styles.container, props.stylesContainer),
        character: cx(styles.character, props.stylesCharacter),
        characterInactive: cx(
          styles.characterInactive,
          props.stylesCharacterInactive
        ),
        characterSelected: cx(
          styles.characterSelected,
          props.stylesCharacterSelected
        ),
        characterFilled: cx(
          styles.characterFilled,
          props.stylesCharacterFilled
        ),
      }}
      value={value}
      onChange={onChange}
      length={inputLength}
      placeholder={placeholder}
      autoFocus
      inputProps={{disabled: disabled}}
      {...props}
    />
  )
}
