import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';

interface ImageUrls {
  contentType: string;
  filePath: string;
  id: number;
}


export enum Place {
  Home = 0,
  Reward = 1,
  All = 2
}
export enum TypeUser {
  Regular = 1, //Consumer
  Business = 2, //Main
  NeteviaApplication = 3,
  SubProfile = 4, //SubUser
  Support = 5,
}
export enum Platform {
  All = 0,
  Web = 1,
  Mobile = 2,
}
export interface RewardIntegrationsApi {
  name: string;
  description: string;
  url: string;
  imageUrls: ImageUrls[];
  type: number;
  id: number;
  userType: TypeUser
  place: Place
  platform: Platform
  order: number
}


export interface RewardIntegrationsArg {
  skip?: number;
  count?: number ;
  place?: Place;
  typeUser?: TypeUser;
  platform?: number;
}
export const rewardsIntegrationsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'rewardsIntegrations',
  endpoints: (builder) => ({
    rewardIntegrationsController: builder.query<
      RewardIntegrationsApi[],
      void
      // RewardIntegrationsArg
    >({
      query: (
        //{ skip = 0, count =  20, place, typeUser, platform}
      ) => ({
        url: 'api/RewardIntegrations',
        method: 'GET',
        // params: {
        //   skip,
        //   count,
        //   place,
        //   typeUser,
        //   platform
        // }
      }),
    }),
  }),
});

export const { useRewardIntegrationsControllerQuery } = rewardsIntegrationsApi;
