import { useEffect } from 'react';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAppSelector } from 'shared/models';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { ScheduleTransferType, findFrequencyByTitle } from 'entities/transfers';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import moment from 'moment';
import {
  selectFromFinancialAccount,
  selectToCustomer,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  selectTransferMemo
} from 'features/transfers/transfer-to-netevia-customer/model';
import { usePayeeTransferControllerMutation } from 'api/endpoints/fundsMovement';
import { useAddScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';
import { useFinancialAccounts } from '../../../../../api/hooksApi/useFinancialAccounts';

export const AuntificatorTransfer = () => {
  const { setSnackBar } = useSnackBar();
  const { goNext, goBack } = useDrawerStepperContext();
  const [sendTransfer] =
    usePayeeTransferControllerMutation();
  const [scheduledTransferToPayee] =
    useAddScheduledTransferMutation();
  const { finAccountsRefetch } = useFinancialAccounts();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount);
  const toCustomer = useAppSelector(selectToCustomer);
  const amount = useAppSelector(selectAmount);
  const frequency = useAppSelector(selectFrequency);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const memo = useAppSelector(selectTransferMemo);
  const mfa = useMfa();

  const frequencyValue = findFrequencyByTitle(frequency);

  const isTransferToday = moment(dateTransfer).isSame(moment(), 'day');

  const handlerSubmit = async (secureOperationType: SecureOperationType, code: string) => {
    if (!frequencyValue && isTransferToday) {
      await neteviaTransfer(secureOperationType, code);
    } else {
      await scheduledTransferToExternalPayee(secureOperationType, code);
    }
  };

  const neteviaTransfer = async (secureOperationType: SecureOperationType, code: string) => {
    try {
      if (code && fromFinancialAccount && toCustomer && amount?.floatValue) {
        await sendTransfer({
          fromFinancialAccountId: fromFinancialAccount?.id,
          toFinancialAccountId: toCustomer?.id,
          amount: amount.floatValue,
          currencyCode: CurrencyCode.USD,
          secureOperationType: secureOperationType,
          oneTimeCode: code,
          memo,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        });
        mfa.close();
        goNext('3');
        finAccountsRefetch();
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const scheduledTransferToExternalPayee = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      if (fromFinancialAccount && toCustomer && amount?.floatValue) {
        await scheduledTransferToPayee({
          fromFinancialAccountId: fromFinancialAccount.id,
          toFinancialAccountId: toCustomer.id,
          amount: amount.floatValue,
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          frequency: frequencyValue,
          nextDate: dateTransfer,
          transferType: ScheduleTransferType.InternalTransfer,
          memo,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        });
        mfa.close();
        goNext('3');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await handlerSubmit(secureOperationType, code)
      }
    })
  }, []);

  return (
    <TransitionAnimation>
      <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
