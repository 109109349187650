import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimateWrapper } from 'components/animate-wrapper';
import SimpleContainer from 'shared/components/containers/simpleContainer';
import moment from 'moment';
import MonthPicker from './componetns/monthPicker';
import CategoryList from './componetns/categoryList';
import CategoryProccentList from './componetns/categoryProccnetList';
import DonutChart from './componetns/donutChart';
import { useSpendingReport } from 'api/hooksApi/useSpendingReport';
import helper from 'services/helper';
import styles from './style.module.scss';
import WarningModal from 'components/modals/WarningModal';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { appRoutes } from 'routes';
import { requireNotNull } from 'shared/utils/require-not-null';
import useGetCardSpendingReportEXEL from 'shared/hooks/useGetSpendingReportEXEL';
// Is it used?
const SpendingReport = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const params = useParams();
  const paymentCardId = params.cardId;

  const [dateStart, setDateStart] = useState(
    moment().startOf('month').toDate()
  );
  const [dateEnd, setDateEnd] = useState(moment().endOf('month').toDate());

  const [isShowWarning, setIsShowWarnign] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>('');
  const { spendingReport } = useSpendingReport(
    paymentCardId,
    moment(new Date(dateStart)).format('YYYY-MM-DDTHH:mm:ssZ'),
    moment(new Date(dateEnd)).format('YYYY-MM-DDT23:59:ssZ')
  );

  const { getSpendingReportEXEL, isLoading: getSpendingReportEXELIsLoading } =
    useGetCardSpendingReportEXEL({
      paymentCardId: paymentCardId ?? '',
      dateFrom: moment(new Date(dateStart)).format('YYYY-MM-DDTHH:mm:ssZ'),
      dateTo: moment(new Date(dateEnd)).format('YYYY-MM-DDT23:59:ssZ'),
      onError: (e) => {
        setWarningText(helper.formatErrors(e));
        setIsShowWarnign(true);
        console.error('error', e);
      },
    });

  const onPrevMonth = () => {
    const selectedDate = moment(new Date(dateStart)).subtract(1, 'months');
    const newDateStart = moment(selectedDate).startOf('month').toDate();
    const newDateEnd = moment(selectedDate).endOf('month').toDate();
    setDateStart(newDateStart);
    setDateEnd(newDateEnd);
  };
  const onNextMonth = () => {
    const selectedDate = moment(new Date(dateStart)).subtract(-1, 'months');
    const newDateStart = moment(selectedDate).startOf('month').toDate();
    const newDateEnd = moment(selectedDate).endOf('month').toDate();
    setDateStart(newDateStart);
    setDateEnd(newDateEnd);
  };

  const disableNextMonth =
    moment().format('YYYY-MM') ===
    moment(new Date(dateStart)).format('YYYY-MM');

  return (
    <AnimateWrapper className='fade'>
      <SimpleContainer
        title={t('Settings.Spending report', {
          defaultValue: 'Spending report',
        })}
        onTitleClick={() =>
          navigate(appRoutes.cardTransactions(requireNotNull(paymentCardId)))
        }
      >
        <div className={styles.container}>
          <MonthPicker
            date={moment(dateStart).format('MMMM YYYY')}
            handlePrevMonth={onPrevMonth}
            handleNextMonth={onNextMonth}
            disableNextMonth={disableNextMonth}
          />
          <div className={styles.box}>
            <DonutChart
              categories={spendingReport?.categories}
              total={helper.formatUsdValue(spendingReport?.total)}
            />
            <CategoryProccentList
              categories={spendingReport?.categories}
              handleDownloadRepor={getSpendingReportEXEL}
              isLoading={getSpendingReportEXELIsLoading}
            />
          </div>
        </div>
        {!!spendingReport?.categories?.length && (
          <CategoryList categories={spendingReport?.categories} />
        )}
        <WarningModal
          titleMassage={warningText}
          onClose={() => setIsShowWarnign(false)}
          isOpen={isShowWarning}
        />
      </SimpleContainer>
    </AnimateWrapper>
  );
};

export default SpendingReport;
