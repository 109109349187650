import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { IAddressInfo } from 'shared/lib/hooks/useFormattedHolderData';
import styles from './styles.module.scss';

interface ViewAddressProps {
  title: string;
  addressInfo: IAddressInfo;
}

export const ViewAddress = (props: ViewAddressProps) => {
  const { title, addressInfo } = props;
  return (
    <div className={styles.wrapper}>
      <Typography>{title}</Typography>
      <TextInput
        label='Address line 1'
        value={addressInfo.streetAddress}
        disabled
      />
        <TextInput
        label='Address line 2'
        value={addressInfo.extendedAddress}
        disabled
      />
      <TextInput
        label='City'
        value={addressInfo.locality}
        disabled
      />
      <TextInput
        label='State'
        value={addressInfo.region}
        disabled
      />
      <TextInput
        label='ZIP code'
        value={addressInfo.postalCode}
        disabled
      />
    </div>
  );
};
