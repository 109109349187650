import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { FormFundingApplication } from '../formFundingApplication';
import { ConfirmFundingApplication } from '../confirmFundingApplication';
import { useState } from 'react';

interface Props {
  isShow: boolean;
  onClose: () => void;
  startStep?: string;
}

export const FundingApplicationDrawer = (props: Props) => {
  const { isShow, onClose, startStep } = props;
  const [loanAmount, setLoansAmount] = useState<string>('');
  const [selectAccountId, setSelectAccountId] = useState<string>('');

  const onCloseDrawer = () => {
    setLoansAmount('');
    setSelectAccountId('');
    onClose();
  };

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Submit funding application',
        prevId: null,
        resetStepsOnReach: true,
        Element: (
          <FormFundingApplication
            loanAmount={loanAmount}
            setLoansAmount={setLoansAmount}
            selectAccountId={selectAccountId}
            setSelectAccountId={setSelectAccountId}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Submit funding application',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <ConfirmFundingApplication
            loanAmount={loanAmount}
            financialAccountId={selectAccountId}
          />
        ),
      },
    ],
  ];
  const MapSteps = new Map(steps);
  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep={startStep ? startStep : '0'}
      steps={MapSteps}
      onClose={onCloseDrawer}
    />
  );
};
