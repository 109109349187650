import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import downloadIcon from "assets/svg/download-blue.svg";
import downloadDisabledIcon from "assets/svg/download-gray.svg";
import warningIcon from "assets/svg/warning-blue.svg";
import styles from "./styles.module.scss";
import { triggerBase64Download } from "common-base64-downloader-react";
import moment from "moment";
import { useDownloadRewardsReportControllerMutation } from "api/endpoints/rewards";
import { useRewards } from "pages/reward/provider/useRewards";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import filterIcon from "assets/svg/filter-blue.svg";
import { Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useConfirmDialog } from "providers/confirm.provider";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";
import { offExtFromFilename } from '../../../../../../shared/utils/pipes';
import { RewardMode } from "pages/reward/types";

interface ReportStatement {
  item1: string;
  item2: number;
}
const DownloadStatement = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { t } = useTranslationProvider();
  const confirm = useConfirmDialog();
  const { setSnackBar } = useSnackBar();
  const [, setSearchParams] = useSearchParams();

  const { publishedReports, rewardsHistoryDatePeriod } = useRewards();
  const [downloadRewardsReportMutation, { isLoading }] =
    useDownloadRewardsReportControllerMutation({});

  const [reportStatement, setReportStatement] = useState<
    ReportStatement | undefined
  >(undefined);

  useEffect(() => {
    findReportId();
  }, [rewardsHistoryDatePeriod]);

  const findReportId = () => {
    const reportPeriod = publishedReports?.find((period) => {
      return (
        moment.utc(period.item1).format("MMMM YYYY") ===
        moment(rewardsHistoryDatePeriod.dateFrom).format("MMMM YYYY")
      );
    });

    setReportStatement(reportPeriod ? reportPeriod : undefined);
  };

  const handleDownloadRewardsReport = async () => {
    try {
      if (reportStatement) {
        const resStatement = await downloadRewardsReportMutation({
          reportPeriodId: reportStatement?.item2,
        }).unwrap();

        const base64PDF = `data:application/pdf;base64, ${resStatement.content}`;
        triggerBase64Download(
          offExtFromFilename(base64PDF),
          `Rewards_statement_${moment(rewardsHistoryDatePeriod.dateFrom).format(
            "MM_YYYY"
          )}`
        );
      }
    } catch (e: any) {
      console.log("error", e);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  const handleDownloadReportForMobView = () => {
    const date = moment(rewardsHistoryDatePeriod.dateFrom).format("MMMM YYYY");
    confirm.show({
      applyButtonText: t("common.Download", {
        defaultValue: "Download",
      }),
      cancelButtonText: t("common.Cancel", {
        defaultValue: "Cancel",
      }),
      cancelError: true,
      dialogTitle: t("Rewards.Download Statement", {
        defaultValue: "Download Statement",
      }),
      dialogText: `${t("Rewards.Do you want to ", {
        defaultValue: "Do you want to download statement for",
      })} *${date}*`,
      typeNew: true,
      async onApply() {
        handleDownloadRewardsReport();
      },
    });
  };
  return (
    <div className={styles.container}>
      <div>
        <img src={warningIcon} alt="Warning" />
        <span>
          {t("Rewards.Scores are t", {
            defaultValue:
              "Scores are awarded on the 1st through the 10th day of the month for the previous month.",
          })}
        </span>
      </div>
      <div className={styles.container_buttonGroup}>
        {!matches && (
          <Button
            variant="text"
            onClick={() => setSearchParams({  mode: RewardMode.Filters })}
            className={styles.buttonFilter}
          >
            <img src={filterIcon} alt="Filter" />
            <span>
              {t("common.Filter", {
                defaultValue: "Filter",
              })}
            </span>
          </Button>
        )}
        <LoadingButton
          variant="text"
          onClick={
            matches
              ? handleDownloadRewardsReport
              : handleDownloadReportForMobView
          }
          disabled={!reportStatement}
          loading={isLoading}
          sx={{
            color: "#187AC9",
          }}
        >
          {!isLoading && (
            <img
              src={!reportStatement ? downloadDisabledIcon : downloadIcon}
              alt="Download"
            />
          )}
          <span>
            {t("Statements.Statement", {
              defaultValue: "Statement",
            })}
          </span>
        </LoadingButton>
      </div>
      {!matches && <Divider className={styles.divider} />}
    </div>
  );
};

export default DownloadStatement;
