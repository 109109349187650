import { Button } from 'shared/ui/buttons'
import { ChevronRightIcon } from 'shared/ui/icons'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/typography'
import { Badge } from 'shared/ui/badge'
import { ClockRestoreIcon } from 'shared/ui/icons'
import moment from 'moment'
import { FinancialAccount } from 'entities/transfers'
import helper from 'services/helper'
interface ScheduledTransferProps {
  id: number
  fromAccount: FinancialAccount
  toAccount: FinancialAccount
  transferDate: string
  amount: number
  frequency: string
  memo?: string | undefined;
  handleNavigate: (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number,
    frequency: string,
    transferDate: string,
    memo?: string | undefined
  ) => void
}

export const ScheduledTransfer = (props: ScheduledTransferProps) => {
  const {
    id,
    fromAccount,
    toAccount,
    transferDate,
    amount,
    frequency,
    handleNavigate,
    memo,
  } = props

  return (
    <Button
      className={styles.btn}
      endIcon={<ChevronRightIcon />}
      onClick={() =>
        handleNavigate(
          id,
          fromAccount,
          toAccount,
          amount,
          frequency,
          transferDate,
          memo
        )
      }
    >
      <div className={styles.transferItem}>
        <div>
          <Typography>From</Typography>
          <Typography>
            {fromAccount.name} ({fromAccount.last4Number})
          </Typography>
        </div>
        <div>
          <Typography>To</Typography>
          <Typography>
            {toAccount.name} ({toAccount.last4Number})
          </Typography>
        </div>
        <Typography>
          Transfer date: {moment(transferDate).format('MMMM DD, YYYY')}
        </Typography>
        <Typography>Transfer amount: ${helper.moneyFormat(amount)}</Typography>

        {memo && (
          <Typography className={styles.memoValue}>Memo: {memo}</Typography>
        )}

        <div className={styles.transferItem_frequency}>
          <Typography>Frequency:</Typography>
          <Badge
            label={frequency}
            startIcon={<ClockRestoreIcon className={styles.svgIcon} />}
          />
        </div>
      </div>
    </Button>
  )
}
