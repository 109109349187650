import { useEffect } from 'react'
import { Button } from 'shared/ui/buttons'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import {
  setFromFinancialAccount,
  setToPayee,
  setAmount,
  setSelectFrequency,
  setSelectDateTransfer,
  selectFromFinancialAccount,
  selectToPayee,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
} from '../../model'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { useAppSelector, useAppDispatch } from 'shared/models'
import { findObjById, FREQUENCY_DATA, FrequencyTitle } from 'entities/transfers'
import helper from 'services/helper'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MakeTransferForm } from './make-transfer-form'
import { AchTransfer, AchTransferSchema } from '../../model'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { Skeleton } from 'components/skeleton'
import { formatAccountName } from 'shared/lib/format'

export const MakeTransfer = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()

  const { payees, isLoadingPayees } = useExternalPayees()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toPayee = useAppSelector(selectToPayee)
  const amount = useAppSelector(selectAmount)
  const frequency = useAppSelector(selectFrequency)
  const dateTransfer = useAppSelector(selectDateTransfer)

  const TransferSchema = AchTransferSchema.refine(
    (data) => {
      const account = findObjById(
        data.fromFinancialAccount,
        unclosedFinancialAccounts
      )

      if (account.availableCash) {
        return data.amount?.floatValue <= account?.availableCash?.value
      } else {
        return true
      }
    },
    {
      message: 'The amount in your account should not exceed the balance',
      path: ['amount'],
    }
  )

  const {
    control,
    formState,
    getValues,
    trigger: validateForm,
    watch,
  } = useForm<AchTransfer>({
    resolver: zodResolver(TransferSchema),
    mode: 'all',
    defaultValues: {
      fromFinancialAccount: fromFinancialAccount?.id || '',
      toPayee: toPayee?.id || '',
      amount: amount,
      frequency: frequency,
      dateTransfer: dateTransfer,
    },
  })
  const fromFinAcc = watch('fromFinancialAccount')

  useEffect(() => {
    validateForm()
  }, [fromFinAcc])

  const onSubmit = () => {
    const value = getValues()

    dispatch(
      setFromFinancialAccount({
        value: findObjById(
          value.fromFinancialAccount,
          unclosedFinancialAccounts
        ),
      })
    )
    dispatch(
      setToPayee({
        value: findObjById(value.toPayee, payees),
      })
    )
    dispatch(setAmount({ value: value.amount }))

    dispatch(setSelectFrequency({ value: value.frequency || FrequencyTitle.Once}))

    dispatch(setSelectDateTransfer({ value: value.dateTransfer! }))
    goNext('1')
  }

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }))

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <MakeTransferForm
          control={control}
          optionsFromAcc={optionsFromAcc}
          optionsFrequency={optionsFrequency}
          payees={payees}
        />

        <Button
          variant='contained'
          disabled={!formState.isValid}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </div>
    </TransitionAnimation>
  )
}
