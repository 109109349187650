import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AnimateWrapper } from 'components/animate-wrapper';
import styles from './style.module.scss';
import cx from 'classnames';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { usePermission } from 'shared/hooks/usePermission';
import { SvgIcon } from '../../../svgIcon';

interface Props {
  isLightMode?: boolean;
}

const SettingsDropdown = ({ isLightMode }: Props) => {
  const { t } = useTranslationProvider();
  const { linkedAppsPermission } = usePermission();
  const { pathname } = useLocation();

  const settingsMenuItems = [
    {
      id: 'account-settings',
      label: t('SideBar.Account', {
        defaultValue: 'Account',
      }),
      link: '/settings',
      img: <SvgIcon name="Person" />,
      isShow: true,
    },
    {
      id: 'security',
      label: t('SideBar.Security', {
        defaultValue: 'Security',
      }),
      link: '/settings/security',
      img: <SvgIcon name="Security" />,
      isShow: true,
    },
    {
      id: 'integrations',
      label: t('Settings.Integrations', {
        defaultValue: 'Integrations',
      }),
      link: '/settings/integrations',
      img: <SvgIcon name="ConnectedApps" />,
      isShow: linkedAppsPermission,
    },
    {
      id: 'locator',
      label: 'ATM Locator',
      link: 'https://www.moneypass.com/',
      img: <SvgIcon name="Locator" />,
      isShow: true,
      isExternal: true,
    },
    {
      id: 'support',
      label: 'Support',
      link: '/settings/support',
      img: <SvgIcon name="Support" />,
      isShow: true,
    },
  ].filter((item) => item.isShow);

  const lastPart = pathname.substring(pathname.indexOf('/', 1) + 1);

  const activeLinkforSub = 'account-settings';
  const [activeItem, setActiveItem] = useState<string>(activeLinkforSub);

  useEffect(() => {
    setActiveItem(lastPart === '/settings' ? activeLinkforSub : lastPart);
  }, [lastPart]);

  const handleItemClick = (e, itemId) => {
    e.stopPropagation();
    setActiveItem(itemId);
  };

  const activeStyleItem = (activeLink, itemId) =>
    cx(styles.item, {
      [isLightMode ? styles.active_itemLight : styles.active_item]: activeLink === itemId,
    });

  return (
    <AnimateWrapper className='fade'>
      <div className={cx(styles.container, isLightMode && styles.containerLightMode)}>
        {settingsMenuItems.map((item) => (
          <Link
            to={item.link}
            key={item.id}
            className={activeStyleItem(activeItem, item.id)}
            onClick={(e) => handleItemClick(e, item.id)}
            target={item.isExternal ? '_blank' : undefined}
          >
            {item.img}
            <span className={styles.item_name}>{item.label}</span>
          </Link>
        ))}
      </div>
    </AnimateWrapper>
  );
};

export default SettingsDropdown;
