import { FC } from 'react';
import styles from '../style.module.scss';
import { Button } from 'shared/ui/buttons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextInput } from 'shared/ui/inputs';

const schemaPhone = yup.object().shape({
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number'),
});
interface EditPhoneNumberProps {
  phone: string;
  handleSubmit: (form: { phone: string }) => Promise<void>;
  isLoading: boolean;
}

const EditPhoneNumber: FC<EditPhoneNumberProps> = ({
  phone,
  handleSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      phone: phone,
    },
    validationSchema: schemaPhone,
    onSubmit: (form) => {
      handleSubmit(form);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapper}
    >
      <TextInput
        label='Phone number'
        inputProps={{ maxLength: 10 }}
        {...formik.getFieldProps('phone')}
        error={formik.errors.phone}
      />
      <Button
        type='submit'
        loading={isLoading}
        variant='contained'
        className={styles.button}
        disabled={
          !formik.isValid || formik.initialValues.phone === formik.values.phone
        }
      >
        Save Changes
      </Button>
    </form>
  );
};

export default EditPhoneNumber;
