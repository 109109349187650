import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { List } from '@mui/material';
import styles from './style.module.scss';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { CasesOutlinedIcon } from 'shared/ui/icons';
import helper from 'services/helper';
import { useMediaQuery } from '@mui/material';
import { formatAccountName } from 'shared/lib/format';
import { appRoutes } from 'routes';
import { useNavigate } from 'react-router-dom';
interface ConnectedAccountListProps {
  attachedFinancialAccount: FinancialAccount[];
}
export const ConnectedAccountList = ({
  attachedFinancialAccount,
}: ConnectedAccountListProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const sliceLength = isMobile ? 10 : 12;

  const handleNavigateToAccount = (id: string) => {
    navigate(appRoutes.accountById(id));
  };

  const renderList = () => {
    if (!attachedFinancialAccount?.length) {
      return (
        <Typography className={styles.empyText}>
          No connected accounts yet.
        </Typography>
      );
    }

    return (
      <List
        disablePadding
        className={styles.list}
      >
        {attachedFinancialAccount.map((acc) => (
          <div
            key={acc.financialAccountId}
            className={styles.wrapper}
            onClick={() => handleNavigateToAccount(acc.financialAccountId)}
          >
            <div className={styles.boxInfo}>
              <div className={styles.boxIcon}>
                <CasesOutlinedIcon />
              </div>
              <div>
                <Typography>
                  {formatAccountName(acc.name, sliceLength)}
                </Typography>
                <Typography className={styles.last4}>
                  ****
                  {acc?.accountNumber?.slice(-4)}
                </Typography>
              </div>
            </div>
            <Typography>
              $ {helper.moneyFormat(acc.availableCash?.value)}
            </Typography>
          </div>
        ))}
      </List>
    );
  };
  return <TransitionAnimation>{renderList()}</TransitionAnimation>;
};
