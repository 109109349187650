import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { Button as ButtonMUI } from '@mui/material';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import {
  AutomationsForm,
  AutomationsSchema,
  createOptionsAutomationsAcc,
} from 'entities/transfers';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/models';
import {
  setAutomations,
  selectAutomations,
} from 'features/transfers/transfer-to-my-own-account/model';
import styles from './styles.module.scss';



const toolTipText = 'When your Netevia account balance falls below the minimum balance for replenishment, automatic replenishment initiates to restore it. Funds are then transferred between own accounts to maintain the specified balance.'


export const ManageAutomationsAdd = () => {
  const { goNext } = useDrawerStepperContext();
  const dispatch = useAppDispatch();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
      memo: automations.memo,
    },
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(setAutomations({ value: values }));
      goNext('5');
    },
  });

  const fromFinAccountId = formik.getFieldProps('from').value;
  const toFinAccountId = formik.getFieldProps('to').value;

  const optionsToAcc = createOptionsAutomationsAcc(
    unclosedFinancialAccounts,
    fromFinAccountId
  );

  const optionsFromAcc = createOptionsAutomationsAcc(
    unclosedFinancialAccounts,
    toFinAccountId
  );

  const disabedBtn =
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        optionsFromAcc={optionsFromAcc}
        optionsToAcc={optionsToAcc}
        warningAmount={'Minimum balance for replenishment'}
        subTitle={
          'Funds will be transferred between own accounts when your balance falls below a set amount.'
        }
        toolTip={toolTipText}
        slotBtn={
          <ButtonMUI
            variant='contained'
            className={styles.btn}
            type='submit'
            disabled={disabedBtn}
          >
            Confirm
          </ButtonMUI>
        }
      />
    </TransitionAnimation>
  );
};
