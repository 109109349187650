import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import {
  TransactionData,
  FiltersArg,
  Filters,
  EnumTransactionStatuses,
} from './transaction';
import { CountryCodeAlpha3 } from 'enums';
import { Amount } from './transaction';
import { TransactionType } from './transaction';
interface CardTransactionsArg {
  paymentCardId: string;
  dateFrom: string;
  dateTo: string;
}

interface CardPendingTransactionsApi {
  openAuthorizations: any;
}
interface CardApprovedTransactionsApi {
  lastEvents: any;
  pageInfo: any;
}
export interface CardTransactionArg {
  paymentCardId: string;
  dateFrom: string;
  dateTo: string;
  filter?: FiltersArg;
  jsonFilter?: string;
  spendEvents?: string[];
  amountFilter?: {
    minValue?: number | null;
    maxValue?: number | null;
  };
}
export interface CardTransaction {
  id: string;
  date: string;
  sortDate: string;
  balance: {
    value: number;
    currencyCode: string;
  };
  status: EnumTransactionStatuses;
  amount: Amount;
  clearAmount: Amount | null;
  pendingAmount: {
    value: number;
    currencyCode: string;
  } | null;
  name: string;
  last4: {
    type: string;
    value: string;
  };
  type: string | TransactionType;
  authorizedUser: string;
  merchantCategory: string;
  transactionSource: {
    typename: string;
    id: string;
    transactionEvents: [
      {
        typename: string;
        id: string;
        merchantDetails: {
          category: string;
          categoryCode: string;
          countryCodeAlpha3: CountryCodeAlpha3 | string;
          description: string;
          name: string;
        };
        pointOfServiceDetails: {
          panEntryMode: string;
          pinEntryMode: string;
        };
        responseCode: string;
        responseDescription: string;
        approvedAmount: {
          value: number;
          currencyCode: string;
        };
        requestedAmount: {
          value: number;
          currencyCode: string;
        };
        paymentCard: {
          id: string;
          bin: string;
          last4: string;
        };
        createdAt: string;
        processingType: string;
      },
    ];
  };
  rewardPoints: number;
}

export interface CardTransactionApi {
  data: CardTransaction[];
  filter: Filters;
}
export const transactionEventsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'transactionEvents',
  endpoints: (builder) => ({
    cardPendingTransactionsController: builder.mutation<TransactionData, any>({
      query: ({ paymentCardId, dateFrom, dateTo, filter }) => ({
        url: 'api/transactionEvents/openAuthorizations/v2',
        method: 'POST',
        body: {
          paymentCardId,
          dateFrom,
          dateTo,
          filter,
        },
      }),
    }),
    cardApprovedTransactionsController: builder.mutation<TransactionData, any>({
      query: ({ paymentCardId, dateFrom, dateTo, filter }) => ({
        url: 'api/transactionEvents/last/v2',
        method: 'POST',
        body: {
          paymentCardId,
          dateFrom,
          dateTo,
          filter,
        },
      }),
    }),
    cardPendingTransactions: builder.query<
      CardTransactionApi,
      CardTransactionArg
    >({
      query: (queryArg) => ({
        url: 'api/transactionEvents/openAuthorizations/v2',
        method: 'POST',
        body: queryArg,
      }),
    }),
    cardApprovedTransactions: builder.query<
      CardTransactionApi,
      CardTransactionArg
    >({
      query: (queryArg) => ({
        url: 'api/transactionEvents/last/v2',
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const {
  useCardPendingTransactionsControllerMutation,
  useCardApprovedTransactionsControllerMutation,
  useCardPendingTransactionsQuery,
  useCardApprovedTransactionsQuery,
} = transactionEventsApi;
