import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import EditEmailaddress from './components/editEmailaddress';
import EditPhoneNumber from './components/editPhoneNumber';
import EditBillingAddress from './components/editBillingAddress';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { useCreateTicketControllerMutation } from 'api/endpoints/support';
import { useGetAllTicketsControllerQuery } from 'api/endpoints/tickets';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { useGetActiveUpdateFieldRequestsQuery } from 'api/endpoints/tickets';
import { useNavigate } from 'react-router-dom';
import { appRoutes, SettingsRouteSubRoute } from 'routes';
import { SupportModeEnum } from 'pages/support/types/enums';
import { SectionPerson, EditType } from '../accountSettings/types';
import { CurrencyCode } from 'enums';

interface OldData {
  countryCodeAlpha3: CurrencyCode;
  email: string;
  locality: string;
  phone: string;
  postalCode: string;
  region: string;
  statusUpdate: boolean;
  streetAddress: string;
  extendedAddress: string;
}

const ChangeCompanyInfo = () => {
  const navigate = useNavigate();
  const { setSnackBar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const section = searchParams.get('section') || '';
  const type = searchParams.get('type') || '';
  const title = searchParams.get('title') || '';

  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();

  const [createTickerMutation, { isLoading: createTicketIsLoading }] =
    useCreateTicketControllerMutation();
  const { refetch: allTicketsRefetch } = useGetAllTicketsControllerQuery({});
  const { refetch: updateTicketBadgeRefetch } =
    useGetActiveUpdateFieldRequestsQuery();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [streetAddress, setStreetAddress] = useState<string>('');
  const [extendedAddress, setExtendedAddress] = useState<string>("");
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [oldData, setOldData] = useState<OldData | null>(null);

  useEffect(() => {
    if (!formattedholderDataIsLoading) {
      initialDataBySection(formattedHolderData, section);
    }
  }, [formattedholderDataIsLoading, section]);

  const initialDataBySection = (formattedHolderData, section: string) => {
    const { bussinessInfo, primaryAuthorizedInfo, owners } =
      formattedHolderData;

      switch (section) {
      case SectionPerson.Business:
        setInitialData({
          ...bussinessInfo.addressInfo,
          ...bussinessInfo.emailInfo,
          ...bussinessInfo.phoneInfo,
        });
        break;
      case SectionPerson.AuthorizedPerson:
        setInitialData({
          ...primaryAuthorizedInfo.addressInfo,
          ...primaryAuthorizedInfo.emailInfo,
          ...primaryAuthorizedInfo.phoneInfo,
        });
        break;
      default:
        const findOwnerPerson = owners?.find(
          (item) => item.ownerType === Number(section)
        );
        if (findOwnerPerson) {
          setInitialData({
            ...findOwnerPerson.info.addressInfo,
            ...findOwnerPerson.info.emailInfo,
            ...findOwnerPerson.info.phoneInfo,
          });
        }
        break;
    }
  };

  const setInitialData = (currentObject) => {
    setStreetAddress(currentObject?.streetAddress);
    setExtendedAddress(currentObject?.extendedAddress);
    setCity(currentObject?.locality);
    setState(currentObject?.region);
    setZip(currentObject?.postalCode);
    setEmail(currentObject?.email);
    setPhone(currentObject?.phone);
    setOldData(currentObject);
  };

  const handleSubmitDataChenges = async (form) => {
    try {
      const formattedFieldName = formatFieldName(title);
      const template = generateFieldTemplate(formattedFieldName, type, {
        streetAddress: form.streetAddress || streetAddress,
        extendedAddress: form.extendedAddress,
        city: form.city || city,
        zip: form.zip || zip,
        email: form.email || email,
        phone: form.phone || phone,
      });

      const typePrefix = getTypePrefix(section);

      const { ticketId } = await createTickerMutation({
        topicId: 7,
        text: template.message,
        updateFieldRequest: `${typePrefix}${template.type}`,
      }).unwrap();
      allTicketsRefetch();
      updateTicketBadgeRefetch();

      setSnackBar({
        type: 'success',
        width: '400px',
        message:
          'The request to update your information has been received. Our support team will be in touch shortly to confirm the changes.',
        isShow: true,
      });

      navigate(
        appRoutes.settings(SettingsRouteSubRoute.support, {
          pageMode: SupportModeEnum.ChatSupport,
          title: 'Edit company information',
          ticket: ticketId,
        })
      );
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const generateFieldTemplate = (
    formattedFieldName: string,
    type: string,
    data?: {
      streetAddress?: string;
      extendedAddress?: string;
      city?: string;
      zip?: string;
      email?: string;
      phone?: string;
    }
  ) => {
    if (type === EditType.Email) {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.email}\n to ${data?.email}`,
        type,
      };
    } else if (type === EditType.Phone) {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.phone}\n to ${data?.phone}`,
        type,
      };
    } else {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.streetAddress}, ${oldData?.extendedAddress}, ${oldData?.locality}, ${oldData?.postalCode}\n to ${data?.streetAddress}, ${data?.extendedAddress}, ${data?.city}, ${data?.zip}`,
        type,
      };
    }
  };

  const getTypePrefix = (section) => {
    switch (section) {
      case SectionPerson.Business:
        return '';
      case SectionPerson.AuthorizedPerson:
        return 'PrimAuth';
      default:
        return `Owner${section}`;
    }
  };
  const formatFieldName = (fieldName: string): string => {
    const withoutEdit = fieldName.replace(/^Edit/, '');
    const formatted = withoutEdit.replace(/([A-Z])/g, ' $1');

    return formatted.trim();
  };

  return (
    <AnimateWrapper className='fade'>
      <div>
        {mode === EditType.Email && (
          <EditEmailaddress
            email={email}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading}
          />
        )}
        {mode === EditType.Phone && (
          <EditPhoneNumber
            phone={phone}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading}
          />
        )}
        {mode === EditType.Address && (
          <EditBillingAddress
            streetAddress={streetAddress} 
            extendedAddress={extendedAddress}
            city={city}
            state={state}
            zip={zip}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading} 
            
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default ChangeCompanyInfo;
