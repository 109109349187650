import { FinancialAccount, Features } from 'api/endpoints/financialAccounts';
import helper from 'services/helper';
import styles from './styles.module.scss';

export const createOptionsAutomationsAcc = (
  accounts: FinancialAccount[],
  idToRemove: string,
  selectedAccount: string | null  = null
) => {
  return accounts
    .filter(
      (account) =>
        account.features.includes(Features.AutoPopup) &&
        account.financialAccountId !== idToRemove || account.financialAccountId == selectedAccount  
    )
    .map((account) => ({
      id: account.financialAccountId,
      value: account.financialAccountId,
      content: (
        <div className={styles.dropDown}>
          <div>{`${account.name} (${account.accountNumber.slice(-4)})`}</div>
          <div>{`$${
            helper.moneyFormat(account?.availableCash?.value) || ''
          }`}</div>
        </div>
      ),
    }));
};
