import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { MakeTransferConfirm } from '../make-transfer-confirm';
import { MakeTransferSuccess } from '../make-transfer-success';
import { Intro } from '../intro';
import { ManageAutomations } from '../manage-automations';
import { ManageAutomationsAdd } from '../manage-automations-add';
import { AuntificatorAutomationsAdd } from '../auntificator-automations-add';
import { ManagaAutomationsEdit } from '../managa-automations-edit';
import { AuntificatorAutomationsEdit } from '../auntificator-automations-edit';
interface Props {
  isShow: boolean;
  onClose: () => void;
  startStep?: string;
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Between own accounts',
      prevId: null,
      Element: <Intro />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'Between own accounts',
      prevId: '0',
      Element: <MakeTransferConfirm />,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: false,
      title: 'Between own accounts',
      prevId: '0',
      Element: <MakeTransferSuccess />,
    },
  ],
  [
    '3',
    {
      id: '3',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '0',
      Element: <ManageAutomations />,
    },
  ],
  [
    '4',
    {
      id: '4',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '3',
      Element: <ManageAutomationsAdd />,
    },
  ],
  [
    '5',
    {
      id: '5',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '4',
      Element: <AuntificatorAutomationsAdd />,
    },
  ],
  [
    '6',
    {
      id: '6',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '3',
      Element: <ManagaAutomationsEdit />,
    },
  ],
  [
    '7',
    {
      id: '7',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '6',
      Element: <AuntificatorAutomationsEdit />,
    },
  ],
];

const MapSteps = new Map(steps);

export const ToMyOwnAccount = (props: Props) => {
  const { isShow, onClose, startStep } = props;

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep={startStep ? startStep :'0'}
      steps={MapSteps}
      onClose={onClose}
    />
  );
};
