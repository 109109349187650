import { Button } from 'shared/ui/buttons';
import { FilterByName } from '../filterTransactions/componentns/filterByName';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './style.module.scss';
import { useAccount } from 'pages/accountComponent/provider';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AccountMode } from 'pages/accountComponent/types';

export const FilterCardsControl = () => {
  const {
    paymentCardsIsFetching,
    searchCardByFunctionality,
    setSearchCardByFunctionality,
  } = useAccount();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [, setSearchParams] = useSearchParams();

  return (
    <div className={styles.wrapper}>
      <FilterByName
        searchTransactionByName={searchCardByFunctionality}
        setSearchTransactionByName={setSearchCardByFunctionality}
      />
      <Button
        onClick={() => setSearchParams({ mode: AccountMode.filtersCards })}
        variant='outlined'
        className={styles.btnFilterCard}
        disabled={paymentCardsIsFetching}
      >
        {paymentCardsIsFetching ? <FilterListIcon /> : <FilterIcon />}
        {!isMobile && 'filter'}
      </Button>
    </div>
  );
};
