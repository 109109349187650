import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Skeleton } from '@mui/material';
import { ActionTile } from 'shared/components/ActionTile';
import { useCardInformation } from '../../provider';
import ChevronRightGray from 'assets/svg/chevron-right-gray.svg';
import SpeedOutlined from 'assets/svg/SpeedOutlined.svg';
import BarChartOutlined from 'assets/svg/BarChartOutlined.svg';
import PasswordOutlined from 'assets/svg/PasswordOutlined.svg';
import LockOutlined from 'assets/svg/LockOutlined.svg';
import ReplayOutlined from 'assets/svg/ReplayOutlined.svg';
import LocalPlayOutlined from 'assets/svg/LocalPlayOutlined.svg';
import CancelOutlined from 'assets/svg/CancelOutlined.svg';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import { useConfirmDialog } from 'providers/confirm.provider';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useSnackBar } from 'providers/snackBar.provider';
import { useSpendingReport } from 'api/hooksApi/useSpendingReport';
import { useCurrentUser } from 'providers/user.provider';
import { appRoutes } from 'routes';
import { useCardRouteParams } from 'shared/hooks/useCardRouteParams';
import {
  useCloseCardControllerMutation,
  useActivateCardControllerMutation,
  CardFeatures,
} from 'api/endpoints/paymentCards';
import { SpendingReport } from 'pages/accountComponent/Actions/More/SpendingReport';
import moment from 'moment';
import DrawerModal from 'shared/components/drawerModal';
import helper from 'services/helper';
import { LockCard } from '../LockCard';
import { ChangePin } from '../ChangePin';
import useGetCardSpendingReportEXEL from 'shared/hooks/useGetSpendingReportEXEL';

const ActionsList: React.FC = () => {
  const {
    currentCard,
    cardsIsLoading,
    paymentCardDetails,
    feautersSet,
    refetchCard,
    currentCardIsLocked,
    openOrderStatus,
  } = useCardInformation();
  const confirm = useConfirmDialog();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const { isSubUser } = useCurrentUser();
  const activateProcessingBool = useBoolean();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get('mode') || '';
  const [isChangePinMode, setIsChangePinMode] = useState<boolean>(false);
  const cardRouteParams = useCardRouteParams();
  const [currentAccountId, setCurrentAccountId] = useState(
    currentCard?.financialAccounts[0]?.id
  );
  // TODO: The all functionality for spandingReport should be transferred to <SpendingReport />
  const [date, setDate] = useState<any>(new Date());
  const [dateFrom, setDateFrom] = useState<any>(
    moment(date).startOf('month').format('YYYY-MM-DD 00:00:00Z')
  );
  const [dateTo, setDateTo] = useState<any>(
    moment(date).endOf('month').format('YYYY-MM-DD 23:59:ssZ')
  );
  const { spendingReport, spendingReportIsLoading, refetch } =
    useSpendingReport(
      currentCard?.id,
      moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      moment(dateTo).format('YYYY-MM-DDTHH:mm:ss[Z]')
    );

  const { getSpendingReportEXEL, isLoading: getSpendingReportEXELIsLoading } =
    useGetCardSpendingReportEXEL({
      paymentCardId: currentCard.id,
      dateFrom,
      dateTo,
      onError: (e) => {
        snackbar.setSnackBar({
          type: 'error',
          message: 'Failed to download spending report. Please try again.',
          isShow: true,
        });
        console.error('error', e);
      },
    });

  const [activateCardMutation] = useActivateCardControllerMutation();
  const [closeCardMutation] = useCloseCardControllerMutation();

  useEffect(() => {
    refetch();
  }, [date, currentAccountId]);

  const handleDateChange = useCallback(
    (date) => {
      setDate(date);
      const dateStart = moment(new Date(date))
        .startOf('month')
        .format('YYYY-MM-DD 00:00:00Z');
      const dateEnd = moment(new Date(date))
        .endOf('month')
        .format('YYYY-MM-DD 23:59:ssZ');
      setDateFrom(dateStart);
      setDateTo(dateEnd);
    },
    [date]
  );

  const handleUpdateCardStatus = async (message: string) => {
    await refetchCard();
    snackbar.setSnackBar({
      type: 'success',
      message,
      isShow: true,
    });
  };

  const cardOrderInfo = useMemo(() => {
    if (
      paymentCardDetails &&
      paymentCardDetails.physicalPaymentCardOrders?.length
    ) {
      return paymentCardDetails.physicalPaymentCardOrders[0];
    }
  }, [paymentCardDetails]);

  const actionsList = [
    {
      title: 'Transaction Limits',
      onClick: () => {
        setSearchParams({ mode: 'limits' });
      },
      infoImg: SpeedOutlined,
      actionImg: ChevronRightGray,
      // component: <CardLimits setMessage={handleUpdateCardStatus} />,
      goBack:
        currentMode !== 'limits'
          ? () => setSearchParams({ mode: 'limits' })
          : undefined,
      mode: ['limits', 'limitsInfo', 'setLimits'],
      isShow: feautersSet.has(CardFeatures.TransactionLimits) || feautersSet.has(CardFeatures.TransactionLimitsViewOnly) ,
    },
    {
      title: 'Spending Report',
      onClick: () => {
        setSearchParams({ mode: 'spendingReport' });
      },
      mode: ['spendingReport'],
      infoImg: BarChartOutlined,
      actionImg: ChevronRightGray,
      component: (
        <SpendingReport
          data={spendingReport}
          handleDateChange={handleDateChange}
          date={date}
          setCurrentAccountId={setCurrentAccountId}
          isLoading={spendingReportIsLoading}
          downloadReport={getSpendingReportEXEL}
          isLoadingDownloadReport={getSpendingReportEXELIsLoading}
        />
      ),
      isShow: feautersSet.has(CardFeatures.SpendingReport),
    },
    {
      title: 'Set Up PIN',
      onClick: () => setIsChangePinMode(true),
      infoImg: PasswordOutlined,
      actionImg: ChevronRightGray,
      isShow: feautersSet.has(CardFeatures.SetUpPin),
    },
    {
      title: currentCardIsLocked ? 'Unlock Card' : 'Lock Card',
      onClick: () => {},
      infoImg: LockOutlined,
      actionImg: ChevronRightGray,
      actionController: (
        <LockCard
          cardId={currentCard?.id}
          onFinish={handleUpdateCardStatus}
          defaultState={currentCardIsLocked}
        />
      ),
      isShow: feautersSet.has(CardFeatures.LockUnlock),
    },
    {
      title: 'Activate Card',
      onClick: () => {
        confirm.show({
          applyButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dialogTitle: 'Activate your card',
          dialogText:
            "Are you sure you want to activate your card? Click 'Activate' to proceed.",
          cancelButtonType: 'text',
          cancelError: true,
          typeNew: true,
          async onApply() {
            activateProcessingBool.setTrue();
            try {
              await activateCardMutation({ cardId: currentCard.id }).unwrap();
              await refetchCard();
              await helper.sleep(5000);
              snackbar.setSnackBar({
                type: 'success',
                message:
                  'Your card has been activated and is now ready for use.',
                isShow: true,
              });
            } finally {
              activateProcessingBool.setFalse();
            }
          },
        });
      },
      infoImg: LocalPlayOutlined,
      actionImg: ChevronRightGray,
      isShow: feautersSet.has(CardFeatures.Activate),
      isActionLoading: activateProcessingBool.value,
    },
    {
      title: 'Re-issue Card',
      onClick: () => {
        setSearchParams({ mode: 'reissueCard' });
      },
      infoImg: ReplayOutlined,
      actionImg: ChevronRightGray,
      mode: ['reissueCard'],
      isShow: feautersSet.has(CardFeatures.ReissueCard),
    },
    {
      title: 'Order status',
      onClick: () => openOrderStatus(cardOrderInfo?.id!),
      infoImg: CreditCardOutlined,
      actionImg: ChevronRightGray,
      isShow: feautersSet.has(CardFeatures.OrderStatus),
    },
    {
      title: 'Close Card',
      onClick: () => {
        confirm.show({
          applyButtonText: 'Close card',
          cancelButtonText: 'Cancel',
          dialogTitle: 'Close your card',
          dialogText:
            "Are you sure you want to activate your card? Click 'Activate' to proceed.",
          cancelButtonType: 'text',
          cancelError: true,
          typeNew: true,
          async onApply() {
            await closeCardMutation({ cardId: currentCard.id }).unwrap();
            snackbar.setSnackBar({
              type: 'success',
              message:
                'Your card has been successfully closed. It is now deactivated and can no longer be used for transactions.',
              isShow: true,
            });
            if (isSubUser) {
              navigate(appRoutes.home());
            } else {
              navigate(
                appRoutes.accountById(cardRouteParams.accountId, {
                  accountTab: 'cards',
                })
              );
            }
          },
        });
      },
      infoImg: CancelOutlined,
      actionImg: ChevronRightGray,
      isShow: feautersSet.has(CardFeatures.Close),
    },
  ].filter((item) => item.isShow);

  if (cardsIsLoading) {
    return (
      <Skeleton
        width={530}
        height={400}
      />
    );
  }

  return (
    <>
      <StyledActionsList>
        {actionsList.map((item) => (
          <ActionTile
            key={item.title}
            title={item.title}
            onClick={item.onClick}
            infoImg={item.infoImg}
            actionImg={item.actionImg}
            isLoading={cardsIsLoading}
            isFullClickable={true}
            actionController={item.actionController}
          />
        ))}
      </StyledActionsList>
      {actionsList.map((item) => (
        <DrawerModal
          key={item.title}
          isShow={
            !!item.mode?.includes(currentMode) && currentMode !== 'reissueCard'
          }
          onClose={() => {}}
          titleText={item.title}
          goBack={item.goBack}
        >
          {item.component}
        </DrawerModal>
      ))}
      {isChangePinMode && (
        <ChangePin
          cardId={currentCard.id}
          closeModal={() => setIsChangePinMode(false)}
        />
      )}
    </>
  );
};

const StyledActionsList = styled('div')`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    max-width: 530px;
    border-radius: 4px;
    & > div {
      border: none;
      border-bottom: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 0;
      max-height: 60px;
      padding: 0;
      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;

export default ActionsList;
