import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import styles from "./style.module.scss";
import LogoIcon from "assets/svg/logo-netevia-white.svg";
import LogoIconBlack from "assets/svg/logo-netevia-new.svg";
import IconButton from "@mui/material/IconButton";
import { SvgIcon } from '../../../svgIcon';
import cx from 'classnames';

interface SideBarLogoProps {
  setIsShowDrawer?: (boolean) => void;
  isLightMode?: boolean;
}

const SideBarLogo: FC<SideBarLogoProps> = ({ setIsShowDrawer, isLightMode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  const handlerNavigateToHome = () => {
    navigate("/Home");
  };
  const handlerCloseNavBar = () => {
    if (setIsShowDrawer) {
      setIsShowDrawer(false);
    }
  };
  return (
    <div className={cx(styles.container, isLightMode && styles.containerLight)}>
      <img src={isLightMode ? LogoIconBlack : LogoIcon} onClick={handlerNavigateToHome} />
      {!matches && (
        <IconButton
          aria-label="Close main menu"
          className={styles.container_buttonClose}
          onClick={handlerCloseNavBar}
        >
          <SvgIcon name="Close" />
        </IconButton>
      )}
    </div>
  );
};

export default SideBarLogo;
