import { AnimateWrapper } from "components/animate-wrapper";
import styles from "./style.module.scss";
import { triggerBase64Download } from "common-base64-downloader-react";
import moment from "moment";
import { useAccount } from "pages/accountComponent/provider";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useBankingRequisitesControllerMutation } from "api/endpoints/account";
import Download from "@mui/icons-material/Download";
import { Button } from "shared/ui/buttons";
import { Typography } from "shared/ui/typography";
import { formatAccountName } from "shared/lib/format";
import CopyButton from 'shared/components/CopyButton';
import AccountHolder from './AccountHolder';


const AccountDetails = () => {
  const { t } = useTranslationProvider();
  const { financialAccount } = useAccount();

  const [bankingRequisitesMutation, { isLoading: bankingRequisitesIsLoading }] =
    useBankingRequisitesControllerMutation();

  const items = [
    {
      label: t('AccountRoutingNumber.Account number', {
        defaultValue: 'Account number',
      }),
      value: (
        <>
          {financialAccount?.accountNumber}
          <CopyButton textToCopy={financialAccount?.accountNumber} />
        </>
      ),
    },
    {
      label: t('common.Routing number', {
        defaultValue: 'Routing number',
      }),
      value: (
        <>
          {financialAccount?.routingNumber}
          <CopyButton textToCopy={financialAccount?.routingNumber} />
        </>
      ),
    },
    {
      label: t('AccountRoutingNumber.Account holder', {
        defaultValue: 'Account holder',
      }),
      value: <AccountHolder />      
    },
    {
      label: t('common.Currency', {
        defaultValue: 'Currency',
      }),
      value: financialAccount?.availableCash?.currencyCode,
    },
    {
      label: t('common.Name', {
        defaultValue: 'Name',
      }),
      value: (
        <>
          {formatAccountName(financialAccount?.name)}
          <CopyButton textToCopy={financialAccount?.name} />
        </>
      ),
    },
    {
      label: t('AccountInfo.Opened on', {
        defaultValue: 'Opened on',
      }),
      value: moment(financialAccount?.created).format('MM/DD/YYYY'),
    },
  ];

  const sendingLetter = async () => {
    const resBanking = await bankingRequisitesMutation({
      financialAccountId: financialAccount.financialAccountId,
    }).unwrap();
    if (resBanking) {
      const { content } = resBanking;
      const base64Str = content;
      const base64PDF = `data:application/pdf;base64, ${base64Str}`;
      triggerBase64Download(base64PDF, 'Bank_Letter');
    }
  };

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        <div>
          {items.map((item) => (
            <div key={item.label} className={styles.detailsItem}>
              <Typography className={styles.detailsItem_label}>{item.label}</Typography>
              <div className={styles.detailsItem_value}>{item.value}</div>
            </div>
         ))}
        </div>
        <div>
          <Button
            onClick={sendingLetter}
            loading={bankingRequisitesIsLoading}
            startIcon={<Download />}
          >
            Account details
          </Button>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default AccountDetails;
