import { useState, useEffect } from 'react';
import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { SetUpLimits } from './components/setUpLimits';
import { MerchantCategory } from './components/merchantCategory';
import { MerchantCountry } from './components/merchantCountry';
import { useCardInformation } from 'pages/cardManage/provider';
import { useCardLimits } from 'api/hooksApi/useCardLimits';
import { MerchantApi, MerchantType } from 'api/endpoints/enums';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import {
  useAddAmountLimitMonthlyControllerMutation,
  useAddRuleAmountLimitControllerMutation,
  useAddRuleCountriesControllerMutation,
  useAddRuleMerchantCategoriesControllerMutation,
  useDetachSpendRulesControllerMutation,
  useDetachVelocityControllerMutation,
  useAddAtmDailyLimitMutation,
} from 'api/endpoints/spendRules';
import { useBoolean } from 'shared/hooks/useBoolean';
import { compareArrays } from 'pages/cardManage/lib/compareArrays';
import { findByName } from 'pages/cardManage/lib/findByName';
import { searchWithRule } from 'pages/cardManage/lib/searchWithRule';
import { SpendRulesName } from 'pages/cardManage/types';
import { useCurrentUser } from 'providers/user.provider';
interface Props {
  isShow: boolean;
  onClose: () => void;
}

export const CardLimits = (props: Props) => {
  const { isShow, onClose } = props;
  const { isSubUser, subUserRights } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const { currentCard, cardsIsLoading } = useCardInformation();
  const { cardLimits, cardLimitsIsLoading, cardLimitsRefetch } = useCardLimits(
    currentCard?.id
  );

  const [addRuleCategoriesMutation] =
    useAddRuleMerchantCategoriesControllerMutation();
  const [addRuleCountriesMutation] = useAddRuleCountriesControllerMutation();

  const [addRuleAmountLimitMutation] =
    useAddRuleAmountLimitControllerMutation();

  const [addRuleAmountLimitMonthlyMutation] =
    useAddAmountLimitMonthlyControllerMutation();

  const [addAtmDailyLimitMutation] = useAddAtmDailyLimitMutation();

  const [detachSpendRulesMutation] = useDetachSpendRulesControllerMutation();
  const [detachVelocityMutation] = useDetachVelocityControllerMutation();

  const [categories, setCategories] = useState<MerchantApi[]>([]);
  const [countries, setCountries] = useState<MerchantApi[]>([]);
  const [amountLimit, setAmountLimit] = useState<string>('');
  const [amountLimitMonthlyData, setAmountLimitMonthlyData] =
    useState<string>('');
  const [amountLimitDailyData, setAmountLimitDailyData] = useState<string>('');

  const loadingOnSubmitBool = useBoolean();
  const allowanceForChanges = !isSubUser || subUserRights({ mainAccess: true });

  useEffect(() => {
    if (cardLimits) {
      setCategories(cardLimits.categoriesLimit);
      setCountries(cardLimits.countriesLimit);
      setAmountLimit(cardLimits.amountLimit);
      setAmountLimitMonthlyData(cardLimits.amountLimitMonthlyData);
      setAmountLimitDailyData(cardLimits.amountLimitDailyData);
    }
  }, [cardLimits, cardLimitsIsLoading]);

  const blockedCategories = searchWithRule(categories, MerchantType.blocked);
  const blockedOriginCategories = searchWithRule(
    cardLimits?.categoriesLimit,
    MerchantType.blocked
  );
  const allowedCategories = searchWithRule(categories, MerchantType.allowed);
  const allowedOriginCategories = searchWithRule(
    cardLimits?.categoriesLimit,
    MerchantType.allowed
  );

  const blockedCountries = searchWithRule(countries, MerchantType.blocked);
  const blockedOriginCountries = searchWithRule(
    cardLimits?.countriesLimit,
    MerchantType.blocked
  );
  const allowedCountries = searchWithRule(countries, MerchantType.allowed);
  const allowedOriginCountries = searchWithRule(
    cardLimits?.countriesLimit,
    MerchantType.allowed
  );

  const allCategoriesChanged =
    !compareArrays(blockedCategories, blockedOriginCategories) ||
    !compareArrays(allowedCategories, allowedOriginCategories);

  const allCountriesСhanged =
    !compareArrays(blockedCountries, blockedOriginCountries) ||
    !compareArrays(allowedCountries, allowedOriginCountries);

  const isAmountLimitChanged =
    Number(amountLimit) !== Number(cardLimits?.amountLimit);

  const isAmountLimitMonthlChanged =
    Number(amountLimitMonthlyData) !==
    Number(cardLimits?.amountLimitMonthlyData);

  const isAmountLimitDailyChanged =
    Number(amountLimitDailyData) !== Number(cardLimits.amountLimitDailyData);

  const disablenBtnSubmit =
    !allCategoriesChanged &&
    !allCountriesСhanged &&
    !isAmountLimitChanged &&
    !isAmountLimitMonthlChanged &&
    !isAmountLimitDailyChanged;

  const addOrDetachCategories = async (
    allowedCategories: string[],
    blockedCategories: string[]
  ) => {
    if (!blockedCategories.length && !allowedCategories.length) {
      const categoriesSpendRule = findByName(
        cardLimits?.attachedSpendRules,
        SpendRulesName.merchantCategory
      );
      await detachSpendRulesMutation({
        paymentCardId: currentCard.id,
        spendRuleId: categoriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCategoriesMutation({
        paymentCardId: currentCard.id,
        blocked: blockedCategories,
        allowed: allowedCategories,
      }).unwrap();
    }
  };

  const addOrDetachCountries = async (
    allowedCountries: string[],
    blockedCountries: string[]
  ) => {
    if (!blockedCountries.length && !allowedCountries.length) {
      const countriesSpendRule = findByName(
        cardLimits?.attachedSpendRules,
        SpendRulesName.merchantCountry
      );
      await detachSpendRulesMutation({
        paymentCardId: currentCard.id,
        spendRuleId: countriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCountriesMutation({
        paymentCardId: currentCard.id,
        blocked: blockedCountries,
        allowed: allowedCountries,
      }).unwrap();
    }
  };

  const addOrDetachAmountLimit = async () => {
    if (Number(amountLimit) === 0) {
      await detachSpendRulesMutation({
        paymentCardId: currentCard.id,
        spendRuleId: cardLimits?.amountLimitSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMutation({
        paymentCardId: currentCard.id,
        value: Number(amountLimit),
      }).unwrap();
    }
  };

  const addOrDetachAmountLimitMonthly = async () => {
    if (Number(amountLimitMonthlyData) === 0) {
      await detachVelocityMutation({
        paymentCardId: currentCard.id,
        spendRuleId: cardLimits?.amountLimitSpendRuleMonthly?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMonthlyMutation({
        paymentCardId: currentCard.id,
        value: Number(amountLimitMonthlyData),
      }).unwrap();
    }
  };

  const addOrDetachAmountLimitDaily = async () => {
    if (Number(amountLimitDailyData) === 0) {
      await detachVelocityMutation({
        paymentCardId: currentCard.id,
        spendRuleId: cardLimits?.amountLimitSpendRuleDaily?.node?.id,
      }).unwrap();
    } else {
      await addAtmDailyLimitMutation({
        paymentCardId: currentCard.id,
        value: Number(amountLimitDailyData),
      }).unwrap();
    }
  };

  const onSubmit = async () => {
    loadingOnSubmitBool.setTrue();

    try {
      if (allCategoriesChanged) {
        await addOrDetachCategories(allowedCategories, blockedCategories);
      }
      if (allCountriesСhanged) {
        await addOrDetachCountries(allowedCountries, blockedCountries);
      }
      if (isAmountLimitChanged) {
        await addOrDetachAmountLimit();
      }
      if (isAmountLimitMonthlChanged) {
        await addOrDetachAmountLimitMonthly();
      }
      if (isAmountLimitDailyChanged) {
        await addOrDetachAmountLimitDaily();
      }

      if (
        allCategoriesChanged ||
        allCountriesСhanged ||
        isAmountLimitChanged ||
        isAmountLimitMonthlChanged ||
        isAmountLimitDailyChanged
      ) {
        cardLimitsRefetch();
        setSnackBar({
          type: 'success',
          message: 'Changes saved',
          isShow: true,
        });
      }

      loadingOnSubmitBool.setFalse();
    } catch (e: any) {
      loadingOnSubmitBool.setFalse();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const isLoading = cardsIsLoading || cardLimitsIsLoading;

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Transaction Limits',
        prevId: null,
        Element: (
          <SetUpLimits
            isLoading={isLoading}
            amountLimit={amountLimit}
            setAmountLimit={setAmountLimit}
            amountLimitMonthlyData={amountLimitMonthlyData}
            setAmountLimitMonthlyData={setAmountLimitMonthlyData}
            amountLimitDailyData={amountLimitDailyData}
            setAmountLimitDailyData={setAmountLimitDailyData}
            onSubmit={onSubmit}
            isLoadingonSubmit={loadingOnSubmitBool.value}
            onSumbitDiabled={disablenBtnSubmit}
            allowanceForChanges={allowanceForChanges}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Transaction Limits',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <MerchantCategory
            isLoading={isLoading}
            categories={categories}
            setCategories={setCategories}
            allowanceForChanges={allowanceForChanges}
          />
        ),
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Transaction Limits',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <MerchantCountry
            isLoading={isLoading}
            countries={countries}
            setCountries={setCountries}
            allowanceForChanges={allowanceForChanges}
          />
        ),
      },
    ],
  ];

  const MapSteps = new Map(steps);

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={onClose}
    />
  );
};
