import { TransitionAnimation } from 'shared/ui/animations';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/models';
import {
  selectAutomations,
  setAutomations,
} from '../../model';
import { AutomationsSchema, AutomationsForm } from 'entities/transfers';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import helper from 'services/helper';
import { Button as ButtonMUI } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { Skeleton } from 'components/skeleton';
import styles from './styles.module.scss';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDeleteScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { formatAccountName } from 'shared/lib/format';
import { useDialog } from 'providers/dialogController.provider';

export const ManagaAutomationsEdit = () => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();
  const { setSnackBar } = useSnackBar();
  const dialog = useDialog();
  const { goNext } = useDrawerStepperContext();
  const [
    deleteScheduledTransfer,
    { isLoading: isLoadingDeleteScheduledTransfer },
  ] = useDeleteScheduledTransferMutation();

  const dispatch = useAppDispatch();
  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
      memo: automations.memo,
    },
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(setAutomations({
        value: {
          id: automations?.id,
          from: values.from,
          to: values.to,
          amount: values.amount,
          memo: values.memo,
        }
      }));
      goNext('14');
    },
  });

  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Cancel Automation
    </Button>
  )

  const handleDeleteAutomations = async () => {
    dialog.show({
      dialogTitle: 'Cancel upcoming Automation',
      dialogText: 'Are you sure you want to cancel this Automation?',
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: <Button variant='text'>Close</Button>,
      async onApply() {
        try {
          if (automations?.id) {
            await deleteScheduledTransfer({
              id: automations.id,
            }).unwrap();
            goNext('10');
          }
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    })
  }

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const disabedBtn =
    !formik.dirty ||
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  const renderSlotBtn = (
    <div className={styles.btnGroup}>
      <Button
        variant='outlined'
        className={styles.btnGroupCancel}
        onClick={handleDeleteAutomations}
        loading={isLoadingDeleteScheduledTransfer}
      >
        cancel automations
      </Button>
      <ButtonMUI
        variant='contained'
        className={styles.btnGroupSubmit}
        type='submit'
        disabled={disabedBtn || isLoadingDeleteScheduledTransfer}
      >
        save changes
      </ButtonMUI>
    </div>
  );

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        payees={plaidPayees}
        optionsToAcc={optionsToAcc}
        warningAmount={'Enter autoreplenishment amount'}
        slotBtn={renderSlotBtn}
        subTitle={'Funds will be transferred from your connected account when your balance falls below a set amount.'}
      />
    </TransitionAnimation>
  );
};
