import { AnimateWrapper } from 'components/animate-wrapper';
import { useCardInformation } from './provider';
import Card from 'shared/components/card';
import styled from 'styled-components';
import { ReissueCard } from './components/ReissueCard';
import { useSearchParams } from 'react-router-dom';
import { EnumCardStatuses } from 'api/endpoints/paymentCards';
import ActionsList from './components/ActionsList';
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess';
import ActionTiles from './components/ActionsTiles';
import { CardLimits } from './components/cardLimits';

export const CardManage = () => {
  const { currentCard, message, setMessage, currentCardIsLocked } =
    useCardInformation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get('mode') || '';

  return (
    <AnimateWrapper className='fade'>
      <Container>
        <TopBlock>
          <CardViewContainer>
            <Card
              last4={currentCard?.last4}
              cardholder={currentCard?.cardholder}
              formFactor={currentCard?.formFactor}
              cardType={currentCard?.cardType}
              cardId={currentCard?.id}
              isClosed={currentCard?.status === EnumCardStatuses.CLOSED}
              isLocked={currentCardIsLocked}
              isActivate={
                currentCard?.status === EnumCardStatuses.ACTIVATION_REQUIRED
              }
              isAuthorizedUser={currentCard?.attachedToSubProfile}
              partnerIcons={currentCard?.partnerCardIcons}
              partnerName={currentCard?.partnerName}
              isCardLoading={!currentCard}
            />
          </CardViewContainer>
          <ActionTiles />
        </TopBlock>
        <ActionsList />
      </Container>
      <CardLimits isShow={currentMode == 'limits'} onClose={() => setSearchParams({})}/>
      <ReissueCard
        isShow={currentMode == 'reissueCard'}
        onClose={() => setSearchParams({})}
      />
      <SnackBarSuccess
        isOpen={!!message}
        message={message}
        onClose={() => setMessage('')}
      />
    </AnimateWrapper>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const TopBlock = styled('div')`
  display: flex;
  gap: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    max-width: 530px;
  }
`;

const CardViewContainer = styled('div')`
  min-width: 310px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
