import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { Button } from 'shared/ui/buttons';
import AuntificatorInstructions from 'shared/components/instruction/auntificatorInstrunctions';
import {
  useSetMfaSecretMutation,
} from 'api/endpoints/account';
import { useSaveOpsConfirmationControllerMutation } from 'api/endpoints/settings';
import styles from './style.module.scss';
import { useGetSecret } from 'shared/hooks/useGetSecret';
import { SecretForm } from '../secret-form';
import { TransitionAnimation } from 'shared/ui/animations';
import { CodeInput } from 'shared/ui/inputs';
import { Typography } from 'shared/ui/typography';
import { INPUT_LENGTH } from '../../config/constants';
import { SecureOperationType } from 'enums';
import { Skeleton } from 'components/skeleton';
import { useMfa } from '../../../mfa';

interface AuntificateWithOtpProps {
  toggleType: () => Promise<void>;
  code: string;
  setCode: (code: string) => void;
  isLoadingSubmit: boolean;
  onSubmit: (secureOperationType: SecureOperationType) => Promise<void> | void;
  sumbitButtonLabel?: string | React.ReactNode;
}

export const AuntificateWithOtp = (props: AuntificateWithOtpProps) => {
  const {
    toggleType,
    onSubmit,
    code,
    setCode,
    isLoadingSubmit,
    sumbitButtonLabel = 'Confirm',
  } = props;
  const mfa = useMfa();
  const { setSnackBar } = useSnackBar();

  const [
    saveOpsConfirmationMutation,
    { isLoading: isLoadingSaveOpsConfirmation },
  ] = useSaveOpsConfirmationControllerMutation({});

  const [setMfaSecret, { isLoading: isSetMfaSecretLoading }] =
    useSetMfaSecretMutation();

  const { secretData, isLoadingSecretData } = useGetSecret(
    mfa.isTimeBasedSetUp
  );

  const { secret, qrCode } = secretData;

  const saveSettingOtp = async () => {
    try {
      if (secret) {
        await setMfaSecret({ secret }).unwrap();
        await saveOpsConfirmationMutation({
          withOneTimePassword: true,
        }).unwrap();
        await mfa.reload();
        setSnackBar({
          type: 'success',
          message: 'Multi-factor authentication enabled successfully',
          isShow: true,
        });
        mfa.config?.onOtpSecretCreated &&  mfa.config?.onOtpSecretCreated();
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleCopySecret = (secret: string) => {
    setSnackBar({
      type: 'success',
      message: 'Secret key is copy',
      isShow: true,
    });
    navigator.clipboard.writeText(secret);
  };

  const isLoading = isSetMfaSecretLoading || isLoadingSaveOpsConfirmation;

  const btnToggleTypeAuntificator = !mfa.config?.isOnlyOtpFlow && (
    <Button
      className={styles.btn}
      onClick={toggleType}
      variant='text'
    >
      Confirm transaction via SMS security code
    </Button>
  );

  const isButtonDisabled = code.length < INPUT_LENGTH;

  return (
    <TransitionAnimation>
      {mfa.isLoading ? (
        <Skeleton
          width='100%'
          height='110px'
        />
      ) : (
        <>
          {mfa.isTimeBasedSetUp ? (
            <div className={styles.container}>
              <Typography className={styles.subTitle}>
                Please enter your 6-digit security code from authenticator app
                below.
              </Typography>
              <Typography className={styles.subTitle}>
                The code is applicable for both transfer confirmation and authorization.
              </Typography>
              <CodeInput
                value={code}
                setValue={setCode}
                inputLength={INPUT_LENGTH}
                disabled={isLoadingSubmit}
              />
              {btnToggleTypeAuntificator}
              <Button
                className={styles.onSubmitBtn}
                variant='contained'
                onClick={() => onSubmit(SecureOperationType.TimeBasedCode)}
                disabled={isButtonDisabled}
                loading={isLoadingSubmit}
              >
                {sumbitButtonLabel}
              </Button>
            </div>
          ) : (
            <SecretForm
              isLoading={isLoadingSecretData}
              qrCode={qrCode}
              secret={secret}
              saveSettingOtp={saveSettingOtp}
              loadingSubmit={isLoading}
              copySecret={handleCopySecret}
              slotToggleTypeAuntificator={btnToggleTypeAuntificator}
              slotInstructions={<AuntificatorInstructions />}
            />
          )}
        </>
      )}
    </TransitionAnimation>
  );
};
