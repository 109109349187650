import {
  FormControlLabel as FormControlLabelMUI,
  FormControlLabelProps as FormControlLabelPropsMUI,
  SwitchProps as SwitchPropsMUI,
  Switch as SwitchMUI,
} from '@mui/material'
import cn from 'classnames'
import styles from './styles.module.scss'

type LabelProps = Pick<
  FormControlLabelPropsMUI,
  'labelPlacement' | 'inputRef' | 'className'
>

interface SwitchProps
  extends Pick<
    SwitchPropsMUI,
    'checked' | 'required' | 'disabled' | 'onChange' | 'className' | 'edge' | 'size' | 'onClick'
  > {
  LabelProps?: LabelProps
  label?: FormControlLabelPropsMUI['label']
  fullWidth?: boolean
}

export const Switch = (props: SwitchProps) => {
  const { fullWidth, label, LabelProps, checked, ...rest } = props

  const labelClasses: FormControlLabelPropsMUI['classes'] = {
    root: cn(styles.labelRoot, { [styles.fullWidth]: fullWidth }),
    label: cn(styles.label, rest.size === 'small' && styles.labelSizeSmall),
    labelPlacementStart: styles.labelStart,
  }

  const switchClasses: SwitchPropsMUI['classes'] = {
    checked: styles.checked,
    switchBase: styles.switchBase,
    thumb: styles.thumb,
    track: styles.track,
  }

  if (label) {
    return (
      <FormControlLabelMUI
        control={
          <SwitchMUI
            classes={switchClasses}
            checked={checked}
            {...rest}
          />
        }
        label={label}
        classes={labelClasses}
        {...LabelProps}
      />
    )
  }

  return (
    <SwitchMUI
      classes={switchClasses}
      checked={checked}
      {...rest}
    />
  )
}
