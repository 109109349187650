import { useMemo } from 'react';
import {
  useSubUsersAvailablePaymentCardsControllerQuery,
  useSubUsersControllerQuery,
} from '../endpoints/subProfiles';

const filterActiveCards = (cards) => {
  return cards.filter((card) => card.status !== 'CLOSED');
};

const mapCardsWithSelection = (cards, attachedCards) => {
  return cards.map((card) => ({
    ...card,
    selected: Boolean(
      attachedCards?.find((attachedCard) => attachedCard.id === card.id)
    ),
  }));
};

const mergeAndRemoveDuplicates = (availableCards, attachedCards) => {
  const markedAvailableCards = mapCardsWithSelection(
    availableCards,
    attachedCards
  );

  const markedAttachedCards =
    attachedCards?.map((card) => ({
      ...card,
      selected: true,
      attached: true,
    })) || [];

  const allCards = [...markedAttachedCards, ...markedAvailableCards];

  return allCards.filter(
    (card, index, self) => self.findIndex((c) => c.id === card.id) === index
  );
};

export const useSubUsersAvailablePaymentCards = (subUserId?: string) => {
  const {
    data: availablePaymentCards,
    isFetching: isFetchingAvailablePaymentCards,
    isSuccess: isSuccessAvailablePaymentCards,
    refetch: refetchAvailablePaymentCards,
  } = useSubUsersAvailablePaymentCardsControllerQuery({});

  const {
    data: subUsers,
    isLoading: isLoadingSubUsers,
    refetch: refetchSubUsers,
  } = useSubUsersControllerQuery();

  const processedCards = useMemo(() => {
    if (!availablePaymentCards) return [];

    if (subUserId && subUsers) {
      const subUser = subUsers.find((user) => user.id.toString() === subUserId);
      return mergeAndRemoveDuplicates(
        filterActiveCards(availablePaymentCards),
        subUser?.attachedPaymentCards
      );
    }

    return filterActiveCards(availablePaymentCards);
  }, [availablePaymentCards, subUserId, subUsers]);

  return {
    subUsersAvailablePaymentCards: processedCards,
    subUsersAvailablePaymentCardsIsLoading:
      isFetchingAvailablePaymentCards || isLoadingSubUsers,
    subUsersAvailablePaymentCardsIsSuccess: isSuccessAvailablePaymentCards,
    subUsersAvailablePaymentCardsRefetch: refetchAvailablePaymentCards,
    refetchSubUsers,
  };
};
