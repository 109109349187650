import * as yup from 'yup';

export const HistoryFilterSchema = yup.object().shape({
    byDate: yup
    .array()
    .of(yup.date().nullable())
    .test('both-dates-not-null', function (value) {
        if (Array.isArray(value) && value.length === 2) {
          const [startDate, endDate] = value; 
          if ((startDate && !endDate) || (!startDate && endDate)) {
            return  false
          }
        }
  
        return true; 
      }),

});
