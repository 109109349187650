import { useMemo } from 'react';
import {
  useMerchantCategoriesControllerQuery,
  useCountriesControllerQuery,
} from '../endpoints/enums';
import { usePaymentCardLimitsControllerQuery } from 'api/endpoints/spendRules';
import { MerchantApi, MerchantType } from '../endpoints/enums';
import { SpendRulesName } from 'pages/cardManage/types';

export const useCardLimits = (cardId) => {
  const {
    data: cardLimit,
    isFetching: cardLimitIsLoading,
    isSuccess: cardLimitIsSuccess,
    refetch,
  } = usePaymentCardLimitsControllerQuery({
    paymentCardId: cardId,
  });

  const {
    data: merchantCategory,
    isFetching: merchantCategoryIsLoading,
    isSuccess: merchantCategoryIsSuccess,
  } = useMerchantCategoriesControllerQuery();
  const {
    data: countriesAlpha3,
    isFetching: countriesAlpha3IsLoading,
    isSuccess: countriesAlpha3IsSuccess,
  } = useCountriesControllerQuery();

  const value: any = useMemo<any>(() => {
    if (!cardLimit || !merchantCategory || !countriesAlpha3) {
      return {};
    }

    if (cardLimit && merchantCategory && countriesAlpha3) {
      const attachedSpendRules = cardLimit?.node?.attachedSpendRules?.edges;
      const attachedVelocityRules =
        cardLimit?.node?.attachedVelocityRules?.edges;

      const findBlockedOrAllowed = (i, type, item, property) =>
        i?.node?.[`merchant${type}${property}`]?.some((i) => i === item.name);

      const mapSpendRules = (type, item) => {
        let rule: null | string = null;
        let visible = true;

        attachedSpendRules &&
          attachedSpendRules.forEach((i) => {
            if (i?.node.name === `Merchant ${type}`) {
              const blocked = findBlockedOrAllowed(i, type, item, 'Blocked');
              const allowed = findBlockedOrAllowed(i, type, item, 'Allowed');
              if (blocked) {
                rule = MerchantType.blocked;
              }
              if (allowed) {
                rule = MerchantType.allowed;
              }
            }
          });
        return {
          ...item,
          rule,
          visible,
        };
      };

      const categoriesLimit = merchantCategory?.map((item) =>
        mapSpendRules('Category', item)
      );

      const prioritizedCountries = countriesAlpha3?.reduce<MerchantApi[]>(
        (acc, item) => {
          if (item.name === 'USA') {
            acc.unshift(item);
          } else {
            acc.push(item);
          }
          return acc;
        },
        []
      );

      const countriesLimit = prioritizedCountries?.map((item) =>
        mapSpendRules('Country', item)
      );

      const amountLimitSpendRule =
        attachedSpendRules &&
        attachedSpendRules.find(
          (i) => i.node.name === SpendRulesName.amountLimit
        );
      const amountLimit = amountLimitSpendRule?.node?.maximumAmount?.value
        ? `${amountLimitSpendRule.node.maximumAmount.value}`
        : '';

      const amountLimitSpendRuleMonthly =
        attachedVelocityRules?.length &&
        attachedVelocityRules?.find(
          (i) =>
            i.node?.cumulativeRule?.name === SpendRulesName.amountLimitMonthly
        );

      const amountLimitMonthlyData = amountLimitSpendRuleMonthly?.node
        ?.cumulativeRule?.maximumAmount?.value
        ? `${amountLimitSpendRuleMonthly.node.cumulativeRule.maximumAmount.value}`
        : '';

      const amountLimitSpendRuleDaily =
        attachedVelocityRules?.length &&
        attachedVelocityRules?.find(
          (i) =>
            i.node?.cumulativeRule?.name === SpendRulesName.amountLimitDaily
        );

      const amountLimitDailyData = amountLimitSpendRuleDaily?.node
        ?.cumulativeRule?.maximumAmount?.value
        ? `${amountLimitSpendRuleDaily.node.cumulativeRule.maximumAmount.value}`
        : '';

      return {
        categoriesLimit,
        countriesLimit,
        amountLimit,
        amountLimitMonthlyData,
        attachedSpendRules,
        attachedVelocityRules,
        amountLimitSpendRule,
        amountLimitSpendRuleMonthly,
        amountLimitSpendRuleDaily,
        amountLimitDailyData,
      };
    }
  }, [cardLimit, cardId, merchantCategory, countriesAlpha3]);

  return {
    cardLimits: value,
    cardLimitsIsLoading:
      cardLimitIsLoading ||
      merchantCategoryIsLoading ||
      countriesAlpha3IsLoading,
    cardLimitsIsSuccess:
      cardLimitIsSuccess &&
      merchantCategoryIsSuccess &&
      countriesAlpha3IsSuccess,
    cardLimitsRefetch: () => refetch(),
  };
};
