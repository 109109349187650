import CurrencyDollar from "assets/svg/dollar-blue.svg";
import ItemBalance from "./components/itemBalance";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import helper from "services/helper";
import { Skeleton } from "components/skeleton";
import { Typography } from "shared/ui/typography";
import styles from './styles.module.scss'
import { Divider } from "@mui/material";

const availableBalance =
  "The amount of money in your account that you can use right now.";
const presentBalance =
  "The total amount of money recorded in your account, including funds not yet available for you to use. This includes pending transactions, authorization holds that are not yet posted or deposits that have not yet been made available.";

const AccountBalance = () => {
  const { total, financialAccountsIsLoading } = useFinancialAccounts();

  const renderItems = () => {
    if (total.availableCash === total.cash) {
      return (
        <ItemBalance
          balance={helper.moneyFormat(total.availableCash)}
          description={"Available Balance"}
          tooltip={availableBalance}
        />
      );
    } else {
      return (
        <>
          <ItemBalance
            balance={helper.moneyFormat(total.availableCash)}
            description={"Available Balance"}
            tooltip={availableBalance}
          />
          <Divider 
            orientation="vertical" 
            variant="middle" 
            flexItem
          />
          <ItemBalance
            balance={helper.moneyFormat(total.cash)}
            description={"Present Balance"}
            tooltip={presentBalance}
          />
        </>
      );
    }
  };

  return (
      <div className={styles.wrapper}>
        {financialAccountsIsLoading ? (
          <Skeleton width="100%" height="235.25px" />
        ) : (
            <div className={styles.container}>
              <div className={styles.header}>
                <img src={CurrencyDollar} />
                <Typography>All accounts</Typography>
              </div>
              <div className={styles.hint}>
                {`Here's a summary`} of your financial accounts<br />and&nbsp;balances.
              </div>
              <div className={styles.box}>{renderItems()}</div>
            </div>
        )}
      </div>
  );
};

export default AccountBalance;

