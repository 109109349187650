import { useHolderV2ControllerQuery } from "api/endpoints/account";
import PersonalInformation from "../PersonalInformation";
import { prepareUser } from "../../helper";
import { Skeleton } from 'components/skeleton';

const ConsumerInformation = () => {
  const { data: consumerInfo } = useHolderV2ControllerQuery();

  if (!consumerInfo) {
    return <Skeleton width='100%' height='200px' />
  }

  return (
    <PersonalInformation 
      user={prepareUser({ 
        ...consumerInfo.accountHolderInformation, 
        phoneNumber: consumerInfo.accountHolderInformation?.phone,
        city: consumerInfo.accountHolderInformation?.locality,
      })}
    />
  );
}

export default ConsumerInformation;
