export enum SpendRulesName {
    merchantCategory = 'Merchant Category',
    merchantCountry = 'Merchant Country',
    amountLimit = 'Amount Limit',
    amountLimitMonthly = 'Amount Limit [MONTHLY]',
    amountLimitDaily ='Daily Atm Withdrawal Limit'
  }
  


