import { Select, SelectItem, Radio } from 'shared/ui/inputs'
import { Control, useController } from 'react-hook-form'
import styles from './styles.module.scss'
import { BatchSelectTypeForm } from 'features/transfers/transfer-batch/model'
import { TransferType } from 'features/transfers/transfer-batch/model'
import { RadioGroup } from '@mui/material'
import { Typography } from 'shared/ui/typography'
import { Divider } from '@mui/material'
import { useCurrentUser } from '../../../../../../providers/user.provider';

interface BatchTransferTypeSelectFormProps {
  control: Control<BatchSelectTypeForm>
  optionsFromAcc: SelectItem[]
}

export const BatchTransferTypeSelectForm = (
  props: BatchTransferTypeSelectFormProps
) => {
  const { control, optionsFromAcc } = props
  const { isConsumer } = useCurrentUser();

  const fromFinancialAccount = useController({
    name: 'fromFinancialAccount',
    control,
  })
  const transferType = useController({ name: 'transferType', control })

  return (
    <div className={styles.root}>
      <Typography>Select account</Typography>
      <Select
        label='From'
        options={optionsFromAcc}
        {...fromFinancialAccount.field}
      />
      <Typography className={styles.label}>Select transfer type</Typography>
      <RadioGroup
        {...transferType.field}
        className={styles.radioGroup}
      >
        {!isConsumer && (
          <Radio
            LabelProps={{ className: styles.radioLabelProps }}
            value={TransferType.toNetevia}
            label={
              <div className={styles.boxToNetevia}>
                <Typography>To Netevia’s customer</Typography>
                <Typography>Up to 10 transactions daily</Typography>
              </div>
            }
          />
        )}

        <Divider className={styles.divider} />

        <Radio
          LabelProps={{ className: styles.radioLabelProps }}
          value={TransferType.toAch}
          label='ACH transfer'
        />
      </RadioGroup>
    </div>
  )
}
