import { Button } from 'shared/ui/buttons';
import { TransitionAnimation } from 'shared/ui/animations';
import { LoanStatusEnum } from '../../types';
import { Chart } from './components/chart';
import { AlertLoansInfo } from './components/alertLoansInfo';
import { AmountLoansInfo } from './components/amountLoansInfo';
import styles from './style.module.scss';
import { Loan } from 'api/endpoints/loans';

interface LoansInfoProps {
  loans: Loan;
  signUrl: string;
}
export const LoansInfo = ({ loans, signUrl }: LoansInfoProps) => {
  const isDisable = LoanStatusEnum.Defaulted === loans?.status;

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <AlertLoansInfo status={loans?.status} />
        <div className={styles.box}>
          <Chart loans={loans} />
          <AmountLoansInfo loans={loans} />
        </div>
        <Button
          variant='text'
          className={styles.btn}
          onClick={() => window.open(signUrl, '_blank')}
          disabled={isDisable}
        >
          View details
        </Button>
      </div>
    </TransitionAnimation>
  );
};
