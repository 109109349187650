import {
  RewardCategoryType,
  RewardLimitsAndConditionsToolTip,
} from 'pages/reward/types';
import CashBack from 'assets/svg/cash-back.svg';
import DebitSpending from 'assets/svg/debit-spending-xceptions.svg';
import SpendingCredit from 'assets/svg/spending-credit.svg';
import WelcomeBonus from 'assets/svg/welcome-bonus.svg';
import WelcomeBonusSpending from 'assets/svg/welcome-bonus-spending.svg';
import EarnedCredit from 'assets/svg/apr.svg';
import EarnedSpendingCredit from 'assets/svg/earned-spending-credit.svg';
import ManualAdjustment from 'assets/svg/manual-adjustment.svg';
import UncommingTransfer from 'assets/svg/uncommingTransfer.svg';
import IncommingTransfer from 'assets/svg/incommingTransfer.svg';
import RefundIcon from 'assets/svg/refund.svg';

export const RewardCategory: RewardCategoryType[] = [
  {
    id: 1,
    title: 'Rewards.Cash Back',
    defaultTitle: 'Spending rewards',
    img: CashBack,
    color: '#FF7043',
    toolTip: RewardLimitsAndConditionsToolTip.SpendingRewards,
  },
  {
    id: 2,
    title: 'Rewards.Debit Spending Exceptions',
    defaultTitle: 'Bonus Categories Spending',
    img: DebitSpending,
    color: '#9575CD',
    toolTip: RewardLimitsAndConditionsToolTip.SpecialSpendingRewardsCategories,
  },
  {
    id: 3,
    title: 'Rewards.Spending Credit',
    defaultTitle: 'Spending Credit',
    img: SpendingCredit,
    color: '#FFC107',
    toolTip: RewardLimitsAndConditionsToolTip.SpendingCredit,
  },
  {
    id: 4,
    title: 'Rewards.Refund',
    defaultTitle: 'Refund',
    img: RefundIcon,
    color: '#78909C',
  },
  {
    id: 5,
    title: 'Rewards.Welcome Bonus',
    defaultTitle: 'Welcome Bonus',
    img: WelcomeBonus,
    color: '#29B6F6',
    toolTip: RewardLimitsAndConditionsToolTip.WelcomeBonus,
  },
  {
    id: 6,
    title: 'Rewards.Welcome Bonus Spending',
    defaultTitle: 'Welcome Bonus Spending',
    img: WelcomeBonusSpending,
    color: '#00897B',
    toolTip: RewardLimitsAndConditionsToolTip.WelcomeSpendingBonus,
  },
  {
    id: 7,
    title: 'Rewards.Earned Credit',
    defaultTitle: 'Earned Credit',
    img: EarnedCredit,
    color: '#E57373',
    toolTip: RewardLimitsAndConditionsToolTip.EarnedCreditRewards,
  },
  {
    id: 8,
    title: 'Rewards.Earned Spending Credit',
    defaultTitle: 'Earned Spending Credit',
    img: EarnedSpendingCredit,
    color: '#D81B60',
    toolTip: RewardLimitsAndConditionsToolTip.EarnedSpendingCredit,
  },
  {
    id: 9,
    title: 'Rewards.Manual Adjustment',
    defaultTitle: 'Manual Adjustment',
    img: ManualAdjustment,
    color: '#66BB6A',
  },
  {
    id: 10,
    title: 'Rewards.Cash Back',
    defaultTitle: 'Spending rewards',
    img: CashBack,
    color: '#FF7043',
    toolTip: RewardLimitsAndConditionsToolTip.SpendingRewards,
  },
  {
    id: 11,
    title: 'Converted to financial account',
    defaultTitle: 'Converted to financial account',
    img: UncommingTransfer,
    color: '#78909C',
  },
  {
    id: 12,
    title: 'Rewards.Points transferred to',
    defaultTitle: 'Points transferred to',
    img: UncommingTransfer,
    color: '#78909C',
  },
  {
    id: 13,
    title: 'Points received from',
    defaultTitle: 'Points received from',
    img: IncommingTransfer,
    color: '#78909C',
  },
  {
    id: 14,
    title: 'Gift Card Purchase',
    defaultTitle: 'Gift Card Purchase',
    img: UncommingTransfer,
    color: '#78909C',
  },
];
