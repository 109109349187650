import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Table } from './components/table';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import {
  useDeleteGiftCardMutation,
  GetGiftCardApi,
} from 'api/endpoints/giftCards';
import { Skeleton } from 'components/skeleton';
import { useSnackBar } from 'providers/snackBar.provider';
import { DrawerStepperGiftCard } from './components/drawerStepperGiftCard';
import { TypeGiftCards, GiftCardData } from './types';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import moment from 'moment';
import helper from 'services/helper';
import {
  ClockRestoreIcon,
  OpenedNewFilledIcon,
  RotateIcon,
} from 'shared/ui/icons';
import cn from 'classnames';
import { NavigationHeader } from './components/navigationHeader';
import {useSearchParams } from 'react-router-dom';
import { RewardMode } from 'pages/reward/types';

export const GiftCard = () => {
  const {
    showDrawerGiftCardBool,
    giftCardsData,
    giftCardsArchiveData,
    isLoadingGiftCards,
    closeDrawerStepper,
  } = useGiftCards();
 
  const { setSnackBar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode') || "";

  const [deleteGiftCard] = useDeleteGiftCardMutation();
  const [loadingArchiveOrRestoreCardId, setLoadingArchiveOrRestoreCardId] =
    useState<string | null>(null);

  const openLink = (link: string) => {
    window.open(link);
  };

  const archiveOrRestoreGiftCard = async (id: string) => {
    try {
      setLoadingArchiveOrRestoreCardId(id);
      await deleteGiftCard({
        id: id,
      }).unwrap();
      setLoadingArchiveOrRestoreCardId(null);
    } catch (e: any) {
      setLoadingArchiveOrRestoreCardId(null);
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  const transformGiftCardData = (
    data: GetGiftCardApi[] | undefined,
    archiveOrRestoreGiftCard: (id: string) => Promise<void>,
    loadingArchiveOrRestoreCardId: string | null,
    openLink?: (url: string) => void
  ): GiftCardData[] => {
    if (!data) {
      return [];
    }

    return data.map((item) => {
      const isLoading = loadingArchiveOrRestoreCardId === item.id;

      return {
        id: item.id,
        logo: (
          <img
            src={item.logoUrl}
            alt={item.brandName}
          />
        ),
        card: <Typography className={styles.text}>{item.brandName}</Typography>,
        dateOfActivation: (
          <Typography className={cn(styles.date, styles.text)}>
            {moment(item.createdDate).format('MM/DD/YYYY')}
          </Typography>
        ),
        amount: (
          <Typography className={styles.text}>
            ${helper.moneyFormat(item.faceAmount)}
          </Typography>
        ),
        seeDetails: (
          <Button
            variant='text'
            className={styles.btn}
            onClick={() => openLink && openLink(item?.url)}
          >
            open
            <OpenedNewFilledIcon />
          </Button>
        ),
        archivateCard: (
          <Button
            variant='text'
            className={styles.archivateBtn}
            onClick={() => archiveOrRestoreGiftCard(item.id)}
            loading={isLoading}
          >
            archive
            {!isLoading && <ClockRestoreIcon />}
          </Button>
        ),
        restoreCard: (
          <Button
            variant='text'
            className={styles.btn}
            onClick={() => archiveOrRestoreGiftCard(item.id)}
            loading={isLoading}
          >
            restore card {!isLoading && <RotateIcon />}
          </Button>
        ),
      };
    });
  };

  const giftCards = transformGiftCardData(
    giftCardsData,
    archiveOrRestoreGiftCard,
    loadingArchiveOrRestoreCardId,
    openLink
  );
  const giftCardsArchive = transformGiftCardData(
    giftCardsArchiveData,
    archiveOrRestoreGiftCard,
    loadingArchiveOrRestoreCardId
  );

  return isLoadingGiftCards ? (
    <div className={styles.wrapSkeleton}>
      <Skeleton
        width='100%'
        height='110px'
      />
    </div>
  ) : (
    <TransitionAnimation>
      <DrawerStepperGiftCard
        isShow={showDrawerGiftCardBool.value || mode === RewardMode.Brands}
        onClose={closeDrawerStepper}
      />
      <div className={styles.container}>
        <NavigationHeader />
        <Table
          data={giftCards}
          typeTable={TypeGiftCards.Active}
        />
        <Table
          data={giftCardsArchive}
          typeTable={TypeGiftCards.Archive}
        />
      </div>
    </TransitionAnimation>
  );
};
