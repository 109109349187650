import {
  useRewardIntegrationsControllerQuery,
  RewardIntegrationsApi,
  RewardIntegrationsArg,
  Place,
} from 'api/endpoints/rewardIntegrations';
import { BannerName } from 'pages/reward/types';
import { useMemo } from 'react';
import { useCurrentUser } from 'providers/user.provider';
interface RewardIntegrationsReturnType {
  rewardIntegrations: RewardIntegrationsApi[];
  rewardIntegrationsIsLoading: boolean;
}

const mainHome = [BannerName.GiftCardDeals, BannerName.FileTaxesForFree];
const consumerHome = [
  BannerName.GiftCardDeals,
  BannerName.FileTaxesForFree,
  BannerName.DirectDeposit,
];

export const useRewardIntegrations = (
  queryArg?: RewardIntegrationsArg
): RewardIntegrationsReturnType => {
  const { isSubUser, isConsumer, isMainBusiness, userIsLoading, user } =
    useCurrentUser();

  const { data, isLoading } = useRewardIntegrationsControllerQuery();
  // TODO: add  queryArg to Api
  // queryArg || {}

  const removeDuplicatesByName = (
    items: RewardIntegrationsApi[]
  ): RewardIntegrationsApi[] => {
    return Array.from(new Map(items.map((item) => [item.name, item])).values());
  };

  const filterAndSortByUserType = (
    data: RewardIntegrationsApi[],
    userType: number
  ): RewardIntegrationsApi[] => {
    return data
      .filter((item) => item.userType === userType)
      .sort((a, b) => a.order - b.order);
  };

  const value = useMemo(() => {
    if (!data) {
      return [];
    }

    const mapBanners = (): RewardIntegrationsApi[] => {
      let filteredData: RewardIntegrationsApi[] = [];

      if (isMainBusiness && queryArg?.place === Place.Reward && user) {
        filteredData = filterAndSortByUserType(data, user.type);
      }

      if (isMainBusiness && queryArg?.place === Place.Home) {
        const temptData = data
          .filter((item) => mainHome.includes(item.name as BannerName))
          .sort((a, b) => a.order - b.order);
        filteredData = removeDuplicatesByName(temptData);
      }

      if (isSubUser && queryArg?.place === Place.Home && user) {
        filteredData = filterAndSortByUserType(data, user.type);
      }

      if (isConsumer && queryArg?.place === Place.Home) {
        const temptData = data
          .filter((item) => consumerHome.includes(item.name as BannerName))
          .sort((a, b) => a.order - b.order);
        filteredData = removeDuplicatesByName(temptData);
      }

      return filteredData;
    };

    return mapBanners();
  }, [data, queryArg, user]);

  return {
    rewardIntegrations: value,
    rewardIntegrationsIsLoading: isLoading || userIsLoading,
  };
};
