import { useTranslationProvider } from "providers/translation/translation.provider";
import { useHolderV2ControllerQuery } from "api/endpoints/account";
import AccountSettingsContainer from "../AccountSettingsContainer";
import UserInformation from "./components/userInformation";
import styles from "./style.module.scss";

const CompanyInformation = () => {
  const { t } = useTranslationProvider();
  const { data: holder } = useHolderV2ControllerQuery();

  return (
    <AccountSettingsContainer 
      title={t("Settings.Company information", {
        defaultValue: "Company information",
      })}>
      <>
        <span className={styles.container_holderName}>
          {holder?.businessAccountHolder?.legalBusinessName ?? "Company information"}
        </span>
        <UserInformation />
      </>
    </AccountSettingsContainer>
  );
};

export default CompanyInformation;
