import { Typography } from 'shared/ui/typography';
import { replaceNumberWithComma } from 'shared/utils/price';
import styles from './style.module.scss';
import { Loan } from 'api/endpoints/loans';
interface AmountLoansInfoProps {
  loans: Loan;
}

export const AmountLoansInfo = ({ loans }: AmountLoansInfoProps) => {
  return (
    <div className={styles.boxAmount}>
      <div>
        <Typography className={styles.boxAmountLabel}>
          Original funding amount:
        </Typography>
        <Typography>${replaceNumberWithComma(loans.originalAmount)}</Typography>
      </div>
      <div>
        <Typography className={styles.boxAmountLabel}>
          Funded amount:
        </Typography>
        <Typography>${replaceNumberWithComma(loans.fundedAmount)}</Typography>
      </div>
      <div>
        <Typography className={styles.boxAmountLabel}>
          Amount remained:
        </Typography>
        <Typography>${replaceNumberWithComma(loans.currentAmount)}</Typography>
      </div>
    </div>
  );
};
