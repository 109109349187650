import { useState, useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Divider } from '@mui/material';
import { useAccount } from 'pages/accountComponent/provider';
import styles from './style.module.scss';
import { FilterByMoneyMovement } from './componentns/filterByMoneyMovement';
import { Filters } from 'api/endpoints/transaction';
import { FilterByTransactionType } from './componentns/filterByTransactionType';
import { FilterByMerchantType } from './componentns/filterByMerchantType';
import { FilterByTransferType } from './componentns/filterByTransferType';
import { useSearchParams } from 'react-router-dom';
import { FilterByDate } from './componentns/filterByDate';
import { FilterByAmount } from './componentns/filterByAmount';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';
import { TransactionFilterSchema } from './componentns/validationFilterTransactionSchema';
import { LoadingButton } from '@mui/lab';
import {
  initialAccountTransactionFilter,
  initialTransactionApiParams,
} from 'pages/accountComponent/contsants';

export const FilterTransactions = () => {
  const [, setSearchParams] = useSearchParams();
  const {
    transactionFilterData,
    transactionApiParams,
    setTransactionApiParams,
    isFetchingaccoutnTransactions,
    setTransactionApiFilter,
    expandFilter,
    setExpandFilter,
  } = useAccount();

  const [filterObj, setFilterObj] = useState<Filters>(transactionFilterData);
  const { transfers, pos } = filterObj;

  useEffect(() => {
    if (!isFetchingaccoutnTransactions && transactionFilterData) {
      setFilterObj(transactionFilterData);
    }
  }, [transactionFilterData, isFetchingaccoutnTransactions]);

  const updateFilterSelection = (filterObj: Filters) => {
    const depositWithdrawEqual =
      (filterObj.deposit.value && filterObj.withdraw.value) ||
      (!filterObj.deposit.value && !filterObj.withdraw.value);

    const transfersPosEqual =
      (filterObj.transfers.value && filterObj.pos.value) ||
      (!filterObj.transfers.value && !filterObj.pos.value);

    const isAllSelected = depositWithdrawEqual && transfersPosEqual;

    return {
      ...filterObj,
      all: {
        displayName: 'Select all',
        value: isAllSelected,
        items: null,
      },
    };
  };

  const formik = useFormik({
    initialValues: {
      date: [transactionApiParams.dateFrom, transactionApiParams.dateTo] as [
        Dayjs | null,
        Dayjs | null,
      ],
      amountFrom: transactionApiParams.amountFrom,
      amountTo: transactionApiParams.amountTo,
    },
    validationSchema: TransactionFilterSchema,
    onSubmit: (values) => {
      setTransactionApiParams({
        dateFrom: values.date[0] as Dayjs,
        dateTo: values.date[1] as Dayjs,
        amountFrom: values.amountFrom,
        amountTo: values.amountTo,
      });
      setTransactionApiFilter(updateFilterSelection(filterObj));
      setSearchParams({});
    },
  });

  const onReset = () => {
    formik.setValues({
      date: [
        initialTransactionApiParams.dateFrom,
        initialTransactionApiParams.dateTo,
      ],
      amountFrom: initialTransactionApiParams.amountFrom,
      amountTo: initialTransactionApiParams.amountTo,
    });
    setFilterObj(initialAccountTransactionFilter);
  };

  return (
    <TransitionAnimation>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.boxScroll}>
          <FilterByDate formik={formik} />
          <FilterByAmount formik={formik} />
          <Divider />
          <FilterByMoneyMovement
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            loading={isFetchingaccoutnTransactions}
            expandFilter={expandFilter}
            setExpandFilter={setExpandFilter}
          />
          <Divider />
          <FilterByTransactionType
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            loading={isFetchingaccoutnTransactions}
            expandFilter={expandFilter}
            setExpandFilter={setExpandFilter}
          />
          {transfers?.value && !pos?.value && (
            <>
              <Divider />
              <FilterByTransferType
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                loading={isFetchingaccoutnTransactions}
                expandFilter={expandFilter}
                setExpandFilter={setExpandFilter}
              />
            </>
          )}
          {pos?.value && !transfers?.value && (
            <>
              <Divider />
              <FilterByMerchantType
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                loading={isFetchingaccoutnTransactions}
                expandFilter={expandFilter}
                setExpandFilter={setExpandFilter}
              />
            </>
          )}
        </div>
        <div className={styles.boxBtn}>
          <LoadingButton
            variant='text'
            onClick={() => onReset()}
            loading={isFetchingaccoutnTransactions}
          >
            clear all
          </LoadingButton>
          <LoadingButton
            type='submit'
            variant='contained'
            classes={{ disabled: styles.btnDisabled }}
            disabled={Object.values(formik.errors).some(Boolean)}
            loading={isFetchingaccoutnTransactions}
          >
            apply
          </LoadingButton>
        </div>
      </form>
    </TransitionAnimation>
  );
};
