import { createContext, useContext } from 'react';

type TDrawerStepperContext = {
  goNext: (step: string) => void;
  goBack: () => void;
  close: () => void;
  prevStepId: string | undefined;
  lockForClose: () => void;
  unlockForClose: () => void;
  setLockForCloseStatus: (flag: boolean) => void;
  isCloseDisabled: boolean;
};

export const DrawerStepperContext = createContext<TDrawerStepperContext>({
  goNext: () => {},
  goBack: () => {},
  close: () => {},
  prevStepId: undefined,
  lockForClose: () => {},
  unlockForClose: () => {},
  setLockForCloseStatus: () => {},
  isCloseDisabled: false,
});
export const useDrawerStepperContext = () => useContext(DrawerStepperContext);
