import styles from "./style.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import transferIn from "assets/svg/transferIn.svg";
import transferOut from "assets/svg/transferOut.svg";
import AccountBalanceOutlined from "assets/svg/AccountBalanceOutlined.svg";
import cardImg from "assets/svg/CreditCardOutlined.svg";
import {
  Toolbar,
  Typography,
  styled, Chip,
  useMediaQuery
} from '@mui/material';
import { Transaction } from "api/endpoints/transaction";
import { PaymentCard } from "api/endpoints/paymentCards";
import helper from "services/helper";
import cx from 'classnames';
import { TransactionHints } from './TableCells/TransactionHints';
import { CardTransaction } from 'api/endpoints/transactionEvents';

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(24, 122, 201, 0.04)",
      color: "rgba(0, 0, 0, 0.87)",
      padding: "8px 16px",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: "14%",
    },
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.common.white,
  },
  "& td, & th": {
    border: 0,
  },
}));

interface TableBlockProps {
  rows: Transaction[] | PaymentCard[] | CardTransaction[];
  title?: string | React.ReactNode;
  tabCells: any;
  Action?: any;
  onClick?: (id: string) => void;
  isCards?: boolean;
  isRowsClickable?: boolean;
}

const TableBlock = ({
  rows,
  title,
  tabCells,
  Action,
  onClick,
  isCards,
  isRowsClickable
}: TableBlockProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
 
  const handleClick = (id: string) => {
    onClick && onClick(id);
  };

  return (
    <div className={cx(isRowsClickable && styles.tableRowsHovered)}>
      {!!(title || Action) && (
        <Toolbar
          sx={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }
              : {}
          }
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>

          {Action && <Action />}
        </Toolbar>
      )}
      {!isMobile ? (
        <TableContainer
          sx={{
            boxShadow: "none",
            borderRadius: "0px 0px 10px 10px",
          }}
          component={Paper}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            {rows.length ? (
              <>
                <TableHead>
                  <TableRow>
                    {tabCells.map((tableCell: any) => (
                      <StyledTableCell
                        key={tableCell.name}
                        {...tableCell.props}
                      >
                        {tableCell.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  rows.map((row, index) => (
                    <StyledTableRow
                      onClick={() => handleClick(row.id)}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      {tabCells.map((tableCell: any) => (
                        <StyledTableCell
                          key={tableCell.name}
                          {...tableCell.props}
                        >
                          {tableCell.transform
                            ? tableCell.transform(
                                row[tableCell.name],
                                row,
                                styles.iconContaier
                              )
                            : row[tableCell.name]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) :  (
              <div className={styles.noTransactions}>{isCards  ? 'No cards were found' : 'No transactions'}</div>
            )}
          </Table>
        </TableContainer>
      ) : (
        <div className={styles.mobileContainer}>
          {rows.map((transaction) => (
        
            <div
              key={transaction.id}
              className={styles.mobileContainer_transaction}
              onClick={() => handleClick(transaction.id)}
            >
              <div className={styles.transactionInfo}>
                <img
                  src={
                    transaction.amount.symbol !== "-" ? transferIn : transferOut
                  }
                />
                <div className={styles.transactionName}>
                  <div className={styles.transactionName}>
                    {transaction.name}
                    {transaction.authorizedUser && (
                      <div style={{ marginTop: '4px' }}>
                        <Chip size="small" label={`AU: ${transaction.authorizedUser}`} clickable={false} />
                      </div>
                    )}
                  </div>
                  {transaction?.last4 && (
                    <div>
                      <div className={styles.iconContaier}>
                        <img
                          src={
                            transaction?.last4?.type === "ACCOUNT"
                              ? AccountBalanceOutlined
                              : cardImg
                          }
                        />
                        {`**** ${transaction?.last4?.value ? transaction?.last4.value : ''}`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.amountWrapper}>
                <div>${helper.formatUsdValue(transaction.amount.value)}</div>
                <TransactionHints transaction={transaction} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableBlock;
