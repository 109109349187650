import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { Typography } from 'shared/ui/typography';
import { CasesOutlinedIcon } from 'shared/ui/icons';
import { useMediaQuery } from '@mui/material';
import helper from 'services/helper';
import styles from './style.module.scss';
import { formatAccountName } from 'shared/lib/format';
interface AccountItemProps {
  item: FinancialAccount;
  endSlot?: React.ReactNode;
}

export const AccountItem = ({ item, endSlot }: AccountItemProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const sliceLength = isMobile ? 10 : 12;

  const renderLast4 = () => (
    <Typography className={styles.last4}>
      ****
      {item?.accountNumber?.slice(-4)}
    </Typography>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.boxIcon}>
          <CasesOutlinedIcon />
        </div>
        <div className={styles.boxName}>
          <Typography>{formatAccountName(item.name, sliceLength)}</Typography>
          {isMobile && renderLast4()}
          <Typography>
            $ {helper.moneyFormat(item.availableCash?.value)}
          </Typography>
        </div>
      </div>
      {!isMobile && renderLast4()}
      {endSlot && endSlot}
    </div>
  );
};
