import { useHolderV2ControllerQuery } from "api/endpoints/account";
import { useCurrentUser } from "providers/user.provider";
import { useMemo } from "react";
import CopyButton from "shared/components/CopyButton";

const AccountHolder = () => {
  const { data: holder } = useHolderV2ControllerQuery();
  const { isConsumer } = useCurrentUser();

  const fullName = useMemo(() => {
    if (!holder) return '';

    if (isConsumer && holder?.accountHolderInformation) {
      const { givenName, familyName } = holder.accountHolderInformation;
      return `${givenName} ${familyName}`;
    } else {
      return holder?.businessAccountHolder?.legalBusinessName ?? '';
    }
  }, [holder, isConsumer]);



  if (fullName) {
    return (
      <>
        {fullName}
        <CopyButton textToCopy={fullName} />
      </>
    );
  }

  return null;
}

export default AccountHolder;