import { useState } from 'react'
import { RadioGroup } from '@mui/material'
import { Typography } from 'shared/ui/typography'
import { Radio } from 'shared/ui/inputs'
import { useAccount } from 'pages/accountComponent/provider'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'shared/ui/buttons'
import styles from './style.module.scss'
import { FilterCards } from 'pages/accountComponent/types'
import { useCurrentUser } from '../../../../providers/user.provider';

export const FilterForCards = () => {
  const { filterCards, setFilterCards } = useAccount()
  const [, setSearchParams] = useSearchParams()
  const { isConsumer, isSubUser,subUserRights } = useCurrentUser();

  const [selectedFilter, setSelectedFilter] = useState<FilterCards>(filterCards)

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedFilter(value as FilterCards)
  }

  const applyFilter = () => {
    setFilterCards(selectedFilter)
    setSearchParams({})
  }

  const permissionAvaibleCards =
    !isSubUser ||
    subUserRights({
      viewAllCardsAndFinancialAccounts: true,
      mainAccess: true,
    });

  return (
    <div className={styles.container}>
      <RadioGroup
        value={selectedFilter}
        onChange={handleChange}
        name='Filter cards'
        className={styles.groupRadio}
      >
      {permissionAvaibleCards && (
        <Radio
          key={FilterCards.AllAvailableCards}
          LabelProps={{ className: styles.boxRadio }}
          value={FilterCards.AllAvailableCards}
          label={<Typography>{FilterCards.AllAvailableCards}</Typography>}
        />
        )}
        {!isConsumer && (
          <Radio
            key={FilterCards.MyCards}
            LabelProps={{ className: styles.boxRadio }}
            value={FilterCards.MyCards}
            label={<Typography>{FilterCards.MyCards}</Typography>}
          />
        )}
        <Radio
          key={FilterCards.ClosedCards}
          LabelProps={{ className: styles.boxRadio }}
          value={FilterCards.ClosedCards}
          label={<Typography>{FilterCards.ClosedCards}</Typography>}
        />
      </RadioGroup>
      <Button
        className={styles.btn}
        onClick={applyFilter}
      >
        Apply filter
      </Button>
    </div>
  )
}
