import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { requireNotNull } from '../../shared/utils/require-not-null';
import DrawerModal from '../../shared/components/drawerModal';
import { AuntificatorComponent } from './auntificatorComponent';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { SecureOperationType, ShortMessageType } from '../../enums';
import { useCheckIsTimeBasedSetUpQuery, useSetMfaSignInMutation, useDeleteMfaSecretMutation } from '../../api/endpoints/account';
import { useSnackBar } from '../snackBar.provider';
import { useConfirmDialog } from '../confirm.provider';
import helper from '../../services/helper';
import { useDrawerBehavior } from '../drawer-behavior';
import styles from './style.module.scss';
import { LinearProgress } from '@mui/material';

interface ShowConfig {
  title: string;
  // при включении игнорирует функционал работы с SMS
  isOnlyOtpFlow?: boolean;
   // при включении игнорирует функционал работы с OTP
  isOnlySmsFlow?: boolean;
  onOtpSecretCreated?(): void;
  shortMessageType?: ShortMessageType;
  onDrawerClose?(): void;
  onCodeSubmit?( secureOperationType: SecureOperationType, code: string): Promise<void>;
}

interface ContextProps {
  isLoading: boolean;
  isTimeBasedSetUp: boolean;
  config: ShowConfig | null;
  reload(): Promise<void>;
  show(config: ShowConfig): void;
  close(): void;
  setMfaSignIn(isEnabled: boolean): Promise<void>;
  disableOtp(): Promise<void>;
}

const Context = createContext<ContextProps | undefined>(undefined);

export const MfaProvider = ({ children }: PropsWithChildren) => {
  const [config, setConfig] = useState<ShowConfig | null>(null);
  const snackbar = useSnackBar();
  const confirm = useConfirmDialog();
  const drawerBehavior = useDrawerBehavior();
  const loadingIndicatorBool = useBoolean(false);

  const [setMfaSignIn] = useSetMfaSignInMutation();
  const [deleteMfaSignIn] = useDeleteMfaSecretMutation();

  const {
    data: checkIsTimeBasedSetUp,
    isFetching: isFetchingCheckIsTimeBasedSetUp,
    refetch: refetchIsTimeBasedSetup,
  } = useCheckIsTimeBasedSetUpQuery({});

  const onDrawerClose = useCallback(() => {
    setConfig(null);
    if (config?.onDrawerClose) {
      config.onDrawerClose();
    }
  }, [config])

  const value = useMemo((): ContextProps => {
    return {
      config,
      isTimeBasedSetUp: checkIsTimeBasedSetUp,
      isLoading: isFetchingCheckIsTimeBasedSetUp,
      disableOtp() {
        return new Promise(resolve => {
          confirm.show({
            dialogTitle: 'Disable MFA',
            dialogText: `Are you sure to delete OTP?`,
            onCancel: () => resolve(),
            async onApply() {
              try {
                await deleteMfaSignIn().unwrap();
                await refetchIsTimeBasedSetup();
                snackbar.setSnackBar({
                  isShow: true,
                  type: 'success',
                  message: `Multi-factor authentication successfully disabled`,
                });
              } catch (err) {
                snackbar.setSnackBar({
                  isShow: true,
                  type: 'error',
                  message: helper.formatErrors(err),
                });
              } finally {
                resolve();
              }
            }
          })
        })
      },
      show(config: ShowConfig) {
        setConfig(config);
      },
      close() {
        setConfig(null);
      },
      async reload() {
        await refetchIsTimeBasedSetup();
      },
      async setMfaSignIn(isEnabled: boolean): Promise<void> {
        return new Promise((resolve) => {
          confirm.show({
            dialogTitle: 'Authenticator for Login',
            dialogText: `Are you sure to ${isEnabled ? 'enable' : 'disable'} authenticator for login?`,
            onCancel: () => resolve(),
            async onApply() {
              try {
                await setMfaSignIn({ isEnabled }).unwrap();
                snackbar.setSnackBar({
                  isShow: true,
                  type: 'success',
                  message: `Authenticator for login ${isEnabled ? 'enabled' : 'disabled'}`,
                });
              } catch (err) {
                snackbar.setSnackBar({
                  isShow: true,
                  type: 'error',
                  message: helper.formatErrors(err),
                });
              } finally {
                resolve();
              }
            }
          })
        });
      }
    }
  }, [checkIsTimeBasedSetUp, isFetchingCheckIsTimeBasedSetUp, config]);

  const handleCodeSubmit: ShowConfig['onCodeSubmit'] = useCallback(async (secureOperationType, code) => {
    if (config?.onCodeSubmit) {
      try {
        loadingIndicatorBool.setTrue();
        drawerBehavior.lockForClose();
        await config.onCodeSubmit(secureOperationType, code);
      } finally {
        drawerBehavior.unlockForClose();
        loadingIndicatorBool.setFalse();
      }
    }
  }, [config]);

  return (
    <Context.Provider value={value}>
      {children}
      <DrawerModal titleText={config?.title} isShow={!!config} onClose={onDrawerClose}>
        {loadingIndicatorBool.value && (
          <div className={styles.loaderContainer}>
            <LinearProgress/>
          </div>
        )}
        {config && (
          <AuntificatorComponent
            isLoadingSubmit={loadingIndicatorBool.value}
            isOnlyOtpFlow={config.isOnlyOtpFlow}
            isOnlySmsFlow={config.isOnlySmsFlow}
            onSubmit={handleCodeSubmit}
            shortMessageType={config.shortMessageType || ShortMessageType.ApproveTransaction}
          />
        )}
      </DrawerModal>
    </Context.Provider>
  )
};

export const useMfa = () => requireNotNull(useContext(Context));
