import { FC } from "react";
import CompanyInfoButton from "pages/settings/components/companyInfoButton";
import styles from "./style.module.scss";
import Mail from "assets/svg/mail-gray.svg";
import Home from "assets/svg/home-gray.svg";
import Phone from "assets/svg/phone-gray.svg";
import { List, Divider } from "@mui/material";
import { SectionPerson,EditType } from "pages/settings/accountSettings/types";
import helper from "services/helper";
import {
  IAddressInfo,
  IEmailInfo,
  IPhoneInfo,
} from "shared/lib/hooks/useFormattedHolderData";


interface TInformationBattonsGroupProps {
  handleNavigate: (
    mode: string,
    type: EditType,
    section?: SectionPerson,
    titleForm?: string
  ) => void;
  holder?: any;
  title: string;
  phoneInfo?: IPhoneInfo;
  emailInfo?: IEmailInfo;
  addressInfo?: IAddressInfo;
  id?: string;
  isBusiness?: boolean;
  titleForm?: string;
  section: SectionPerson;
  customPhoneBadge?: string;
}

const InformationBattonsGroup: FC<TInformationBattonsGroupProps> = ({
  handleNavigate,
  title,
  phoneInfo,
  emailInfo,
  addressInfo,
  isBusiness,
  titleForm,
  section,
  id,
  customPhoneBadge
}) => {
  const titleEmail = `Edit${titleForm}EmailAddress`;
  const titlePhone = `Edit${titleForm}PhoneNumber`;
  const titleAddress = `Edit${titleForm}Address`;

  const internationalDialCode = (countryCodeAlpha3: string) =>
    countryCodeAlpha3 === "USA" ? "+1" : "+";

  return (
    <>
      <p className={styles.title}>{title}</p>
      <List disablePadding className={styles.listWrapper}>
        <CompanyInfoButton
          title={"Email"}
          img={Mail}
          description={emailInfo?.email}
          handleNavigate={handleNavigate}
          mode={EditType.Email}
          titleForm={titleEmail}
          id={id}
          section={section}
          type={EditType.Email}
          updateItemBadge={emailInfo?.statusUpdate}
          updateTicket={emailInfo?.updateTicket}
        />
        <Divider />
        <CompanyInfoButton
          title={"Phone"}
          img={Phone}
          description={`${
            addressInfo && internationalDialCode(addressInfo.countryCodeAlpha3)
          }${helper.formatPhone(phoneInfo?.phone)}`}
          handleNavigate={handleNavigate}
          mode={EditType.Phone}
          titleForm={titlePhone}
          badge={customPhoneBadge}
          id={id}
          section={section}
          type={EditType.Phone}
          updateItemBadge={phoneInfo?.statusUpdate}
          updateTicket={phoneInfo?.updateTicket}
        />
        <Divider />
        <CompanyInfoButton
          title={"Address"}
          img={Home}
          badge={isBusiness ? "Billing address" : undefined}
          description={helper.formatAddress(addressInfo)}
          handleNavigate={handleNavigate}
          mode={EditType.Address}
          titleForm={titleAddress}
          id={id}
          section={section}
          type={EditType.Address}
          updateItemBadge={addressInfo?.statusUpdate}
          updateTicket={addressInfo?.updateTicket}
        />
      </List>
    </>
  );
};

export default InformationBattonsGroup;
