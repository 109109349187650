import NewProductCard from '../newProductCard';
import NewVirtualCard from '../newVirtualCard';
import NewPhisicalCard from '../newPhysicalCard';
import NewBurnerCard from '../newBurnerCard';
import NewPrimaryCard from '../newPrimaryCard';
import { useNavigate } from 'react-router-dom';

interface CreateCardCoponentProps {
  backRoute?: string;
  setPageStatus?: () => void;
  setNewCardId?: () => void;
  setCurrentMode: (value: string) => void;
  currentMode: string;
  setDrawerLock?: (isLocked: boolean) => void;
}

export const CreateCardComponent = ({
  backRoute,
  setPageStatus = () => {},
  setNewCardId = () => {},
  setCurrentMode,
  currentMode,
  setDrawerLock,
}: CreateCardCoponentProps) => {
  const navigate = useNavigate();
  if (currentMode === 'openCard') {
    return (
      <NewProductCard
        setChooseCardsMode={setCurrentMode}
      />
    );
  }

  if (currentMode === 'openVirtualcard') {
    return (
      <NewVirtualCard
        onBack={backRoute ? () => navigate(backRoute) : undefined}
        setChooseCardsMode={setCurrentMode}
        isAuthorizedUsers
        onStart={() => setDrawerLock && setDrawerLock(true)}
        onFinish={() => setDrawerLock && setDrawerLock(false)}
      />
    );
  }
  if (currentMode === 'physicalCard') {
    return (
      <NewPhisicalCard
        backRoute={backRoute}
        setChooseCardsMode={setCurrentMode}
        isAuthorizedUsers
        onStart={() => setDrawerLock && setDrawerLock(true)}
        onFinish={() => setDrawerLock && setDrawerLock(false)}
      />
    );
  }
  if (currentMode === 'burnerCard') {
    return (
      <NewBurnerCard
        setPageStatus={setPageStatus}
        setChooseCardsMode={setCurrentMode}
        isAuthorizedUsers
        onStart={() => setDrawerLock && setDrawerLock(true)}
        onFinish={() => setDrawerLock && setDrawerLock(false)}
      />
    );
  }

  if (currentMode === 'openPrimaryCard') {
    return (
      <NewPrimaryCard
        setPageStatus={setPageStatus}
        setOpenNewCardId={setNewCardId}
        backRoute={backRoute}
      />
    );
  }

  return <></>;
};
