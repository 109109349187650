export enum ApiTags {
  GetPaymentCards = 'GetPaymentCards',
  GetFianancialAccounts = 'GetFianancialAccounts',
  GetHolder = 'GetHolder',
  GetSettingsNotifications = 'GetSettingsNotifications',
  GetExternalBankAccounts = 'GetExternalBankAccounts',
  GetPayees = 'GetPayees',
  GetFinAccontsTransfer = 'GetFinAccontsTransfer',
  GetSubUsersList = 'GetSubUsersList',
  GetSubUserAccessRights = 'GetSubUserAccessRights',
  GetSubUsersFinAcc = 'GetSubUsersFinAcc',
  GetSubUserCard = 'GetSubUserCard',
  GetTopics = 'GetTopics',
  GetTickets = 'GetTickets',
  GetTicketById = 'GetTicketById',
  GetPaymentCardInformation = 'GetPaymentCardInformation',
  GetCardTransactionEvents = 'GetCardTransactionEvents',
  GetSubUserME = 'GetSubUserME',
  ChekSecretOtp = 'ChekSecretOtp',
  GetDevices = 'GetDevices',
  GetlinkedAccount = 'GetlinkedAccount',
  GetlinkedAccountMe = 'GetlinkedAccountMe',
  GetPendingLinkedAccount = 'GetPendingLinkedAccount',
  GetdefaultTransfersFinAccount = 'GetdefaultTransfersFinAccount',
  GetUser = 'GetUser',
  GetRewards = 'GetRewards',
  GetRewardsPoints = 'GetRewardsPoints',
  GetRewardsHistory = 'GetRewardsHistory',
  GetLoanSettings = 'GetLoanSettings',
  GetScheduledTransfer = 'GetScheduledTransfer',
  GetNotifications = 'GetNotifications',
  GetGiftCards = 'GetGiftCards',
  GetNotificationsTransfers = 'GetNotificationsTransfers',
  GetPinWheelToken = 'GetPinWheelToken',
  GetNotificationSettingsTypes = 'GetNotificationSettingsTypes'
}
