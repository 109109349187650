import { Button } from 'shared/ui/buttons';
import styles from './style.module.scss';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterByName } from 'pages/accountComponent/components/filterTransactions/componentns/filterByName';
import { useCardInformation } from 'pages/cardComponent/provider';
export const FilterControl = () => {
  const {
    searchTransactionByName,
    setSearchTransactionByName,
    isFetchingCardTransactions,
  } = useCardInformation();

  const isMobile = useMediaQuery('(max-width: 600px)');
  const [, setSearchParams] = useSearchParams();
  return (
    <div className={styles.wrapper}>
      <FilterByName
        searchTransactionByName={searchTransactionByName}
        setSearchTransactionByName={setSearchTransactionByName}
      />
      <Button
        onClick={() => setSearchParams({ mode: 'filters' })}
        variant='outlined'
        className={styles.btnFilterCard}
        disabled={isFetchingCardTransactions}
      >
        {isFetchingCardTransactions ? <FilterListIcon /> : <FilterIcon />}
        {!isMobile && 'filter'}
      </Button>
    </div>
  );
};
