import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Amount, FinancialAccount,Automations } from 'entities/transfers'

// export type FormValues = {
//   fromFinancialAccount: FinancialAccount | null
//   toFinancialAccount: FinancialAccount | null
//   amount: Amount
// }

type ToMyOwnAccount = {
  fromFinancialAccount: FinancialAccount | null
  toFinancialAccount: FinancialAccount | null
  amount: Amount
  automations: Automations;
  memo: string;
};
const initialState: ToMyOwnAccount = {
  memo: '',
  fromFinancialAccount: null,
  toFinancialAccount: null,
  amount: {
    floatValue: undefined,
    formattedValue: undefined,
    value: undefined,
  },
  automations: {
    id: null,
    from: '',
    to: '',
    amount: '',
    memo: '',
  },
}

export const slice = createSlice({
  name: 'toMyOwnAccount',
  initialState,
  reducers: {
    clearDataToMyOwnAccount: () => {
      return initialState
    },

    clearDataAutomations: (state) => {
      state.automations = initialState.automations;
    },

    setFromFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromFinancialAccount = payload.value
    },

    setToFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.toFinancialAccount = payload.value
    },

    setAmount: (state, { payload }: PayloadAction<{ value: Amount }>) => {
      state.amount = payload.value
    },

    setMemo: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.memo = payload.value
    },

    setAutomations: (
      state,
      { payload }: PayloadAction<{ value: Automations }>
    ) => {
      state.automations = payload.value;
    },
  },
})

export const selectFromFinancialAccount = (state: RootState) =>
  state.toMyOwnAccount.fromFinancialAccount

export const selectTransferMemo = (state: RootState) =>
  state.toMyOwnAccount.memo;

export const selectToFinancialAccount = (state: RootState) =>
  state.toMyOwnAccount.toFinancialAccount

export const selectAmount = (state: RootState) => state.toMyOwnAccount.amount

export const selectAutomations = (state: RootState) =>
  state.toMyOwnAccount.automations;

export const {
  clearDataToMyOwnAccount,
  setFromFinancialAccount,
  setToFinancialAccount,
  setAmount,
  setAutomations,
  clearDataAutomations,
  setMemo,
} = slice.actions
