import React, { FC } from 'react';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Skeleton } from 'components/skeleton';
import Switch from '@mui/material/Switch';
import { SvgIcon } from '../../../../../../../shared/components/svgIcon';
import { useNavigate } from 'react-router-dom';
import { appRoutes, SettingsRouteSubRoute } from '../../../../../../../routes';
import { NotificationsDrawerMode } from '../../types';
import { NOTIFICATIONS } from '../../../../securitySearchParams';

interface EmailNotificationsProps {
  isLoading: boolean;
  toogleEmail: boolean | undefined;
  setToogleEmail: (boolean) => void;
}

const EmailNotifications: FC<EmailNotificationsProps> = ({
  isLoading,
  toogleEmail,
  setToogleEmail,
}) => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToogleEmail(event.target.checked);
  };

  const handleGoToNotSettings = () => {
    navigate(
      appRoutes.settings(SettingsRouteSubRoute.security, {
        page: NOTIFICATIONS,
        drawerMode: NotificationsDrawerMode.notificationsSettings,
      })
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          width={500}
          height={100}
        />
      ) : (
        <div className={styles.notificationsWrapper}>
          <div className={styles.notifications}>
            <div className={styles.notifications_box}>
              <span className={styles.title}>
                {t('Settings.Email notifications', {
                  defaultValue: 'Email notifications',
                })}
              </span>

              <p className={styles.subTitle}>
                {t(
                  'Settings.Turn on email notifications to receive updates about',
                  {
                    defaultValue:
                      'Turn on email notifications to receive updates about account activity, transactions, and other important events. Stay informed and in control of your finances. Avoid unexpected overdraft fees.',
                  }
                )}
              </p>
            </div>
            {toogleEmail !== undefined && (
              <Switch
                checked={toogleEmail}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  '& .Mui-checked': {
                    color: '#187AC9',
                  },
                }}
              />
            )}
          </div>
          <div className={styles.separator} />
          <div
            onClick={handleGoToNotSettings}
            className={styles.navButton}
            tabIndex={0}
          >
            <div className={styles.navButtonContent}>
              <div className={styles.navButtonLeftSide}>
                <SvgIcon
                  name='Settings'
                  sizePx={24}
                />
                <span className={styles.navButtonContentLabel}>
                  Notification settings
                </span>
              </div>
              <SvgIcon
                name='ChevronRightFilled'
                sizePx={24}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EmailNotifications;
