import { Typography } from 'shared/ui/typography';
import { EnumTransactionStatuses, Amount } from 'api/endpoints/transaction';
import { Badge } from 'shared/ui/badge';
import helper from 'services/helper';
import cn from 'classnames';
import styles from './style.module.scss';
import { Transaction } from 'api/endpoints/transaction';
import { ListItemButton } from '@mui/material';
import UncommingTransfer from 'assets/svg/uncommingTransfer.svg';
import IncommingTransfer from 'assets/svg/incommingTransfer.svg';

interface TransfersHistoryItemProps {
  item: Transaction;
  handleNavigate: (value: Transaction) => void;
  stylesWrapper?: string;
}

export const TransfersHistoryItem = (props: TransfersHistoryItemProps) => {
  const { item, handleNavigate, stylesWrapper } = props;

  const isPositiveTransfer = item.amount.symbol === '+';

  const completedStatus = item.status === EnumTransactionStatuses.COMPLETED;

  return (
    <ListItemButton
      className={cn(styles.wrapper, stylesWrapper)}
      onClick={() => handleNavigate(item)}
    >
      <div className={styles.box}>
        <img src={isPositiveTransfer ? IncommingTransfer : UncommingTransfer} />
        <div className={styles.boxName}>
          <Typography>
            {item.name} {item.last4.value ? `(${item.last4.value})` : ''}
          </Typography>
          <Typography>{item.type}</Typography>
        </div>
      </div>
      <div className={styles.boxAmount}>
        <Typography
          className={
            isPositiveTransfer
              ? cn(styles.amountText, styles.amountPositive)
              : styles.amountText
          }
        >
          {item.amount.symbol}${helper.formatUsdValue(item.amount.value)}
        </Typography>
        <Badge
          label={helper.capitalizeFirstLetter(item.status)}
          styledWrapper={
            completedStatus ? styles.completedBadge : styles.peddingBadge
          }
          styledLabel={cn(
            styles.labelBadge,
            completedStatus ? styles.completedLabel : styles.peddingLabel
          )}
        />
      </div>
    </ListItemButton>
  );
};
