import { AnimateWrapper } from 'components/animate-wrapper';
import { Skeleton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { SectionPerson } from 'pages/settings/accountSettings/types';
import styles from './style.module.scss';
import { useAccount } from 'pages/settings/accountSettings/provider';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { EditType } from 'pages/settings/accountSettings/types';
import { COMPANY_INFORMATION } from 'pages/settings/accountSettings/searchParamsTypes';
import InformationBattonsGroup from '../informationBattonsGroup';
const renderLoader = () => (
  <div className={styles.container}>
    <Skeleton
      width='100%'
      height='250px'
    />
    <Skeleton
      width='100%'
      height='250px'
    />
  </div>
);

const UserInformation = () => {
  const { t } = useTranslationProvider();
  const { setIsShowDrawer } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();

  const handleNavigate = (
    mode: string,
    type: EditType,
    section?: SectionPerson,
    titleForm?: string
  ) => {
    const page = COMPANY_INFORMATION;
    const pageMode = 'ChangeCompanyInfo';

    setIsShowDrawer(true);

    setSearchParams({
      page,
      pageMode,
      mode,
      title: titleForm || '',
      section: section || '',
      type: type,
    });
  };

  return formattedholderDataIsLoading ? (
    renderLoader()
  ) : (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        <InformationBattonsGroup
          title={t('Settings.Business address and contacts', {
            defaultValue: 'Business address and contacts',
          })}
          phoneInfo={formattedHolderData?.bussinessInfo?.phoneInfo}
          emailInfo={formattedHolderData?.bussinessInfo?.emailInfo}
          addressInfo={formattedHolderData?.bussinessInfo?.addressInfo}
          handleNavigate={handleNavigate}
          section={SectionPerson.Business}
          isBusiness
          titleForm={'Business'}
        />

        <InformationBattonsGroup
          title={t('Settings.Primary Authorized Person', {
            defaultValue: 'Primary Authorized Person',
          })}
          phoneInfo={formattedHolderData?.primaryAuthorizedInfo?.phoneInfo}
          emailInfo={formattedHolderData?.primaryAuthorizedInfo?.emailInfo}
          addressInfo={formattedHolderData?.primaryAuthorizedInfo?.addressInfo}
          handleNavigate={handleNavigate}
          section={SectionPerson.AuthorizedPerson}
          titleForm={'Primary Authorized Person'}
          customPhoneBadge='Connected number'
        />

        {formattedHolderData?.owners?.map((owner) => (
          <InformationBattonsGroup
            key={owner.ownerType}
            title={`${
              owner.ownerType === 1
                ? t('Settings.First', {
                    defaultValue: 'Primary',
                  })
                : owner.givenName
            } ${t('Settings.Owner', {
              defaultValue: 'Owner',
            })}`}
            phoneInfo={owner?.info?.phoneInfo}
            emailInfo={owner?.info?.emailInfo}
            addressInfo={owner?.info?.addressInfo}
            handleNavigate={handleNavigate}
            section={owner.ownerType.toString() as SectionPerson}
            titleForm={`${
              owner.ownerType === 1 ? 'Primary' : owner.givenName
            } Owner`}
          />
        ))}
      </div>
    </AnimateWrapper>
  );
};

export default UserInformation;
