export const formatAccountName = (
  accountName: string,
  sliceLength: number = 12,
  maxLength: number = 12
) => {
  if (accountName.length > maxLength) {
    return `${accountName.slice(0, sliceLength)}...`;
  }

  return accountName;
};
