import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import {
  setFromFinancialAccount,
  setToFinancialAccount,
  setAmount,
  setMemo,
} from 'features/transfers/transfer-to-my-own-account/model'
import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/buttons'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from 'shared/models'
import { findObjById } from 'entities/transfers'
import {
  ToMyOwnAccount,
  ToMyOwnAccountSchema,
} from 'features/transfers/transfer-to-my-own-account/model'
import { zodResolver } from '@hookform/resolvers/zod'
import { ToMyOwnAccountForm } from './to-my-own-accoutn-form'
import helper from 'services/helper'
import { TransitionAnimation } from 'shared/ui/animations'
import { FinancialAccount } from 'api/endpoints/financialAccounts'
import {
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
  selectTransferMemo,
} from 'features/transfers/transfer-to-my-own-account/model'
import { Skeleton } from 'components/skeleton'
import { formatAccountName } from 'shared/lib/format'

export const MakeTransfer = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)
  const memo = useAppSelector(selectTransferMemo);

  const TransferSchema = ToMyOwnAccountSchema.refine(
    (data) => {
      const account = findObjById(
        data.fromFinancialAccount,
        unclosedFinancialAccounts
      )

      if (account.availableCash) {
        return data.amount.floatValue <= account?.availableCash?.value
      } else {
        return true
      }
    },
    {
      message: 'The amount in your account should not exceed the balance',
      path: ['amount'],
    }
  )
  const {
    control,
    formState,
    getValues,
    watch,
    trigger: validateForm,
  } = useForm<ToMyOwnAccount>({
    resolver: zodResolver(TransferSchema),
    mode: 'all',
    defaultValues: {
      fromFinancialAccount: fromFinancialAccount?.id || '',
      toFinancialAccount: toFinancialAccount?.id || '',
      amount: amount,
      memo,
    },
  })

  const fromFinAccount = watch('fromFinancialAccount')
  const toFinAccount = watch('toFinancialAccount')

  useEffect(() => {
    validateForm()
  }, [fromFinAccount])

  const onSubmit = () => {
    const value = getValues()
    dispatch(
      setFromFinancialAccount({
        value: findObjById(
          value.fromFinancialAccount,
          unclosedFinancialAccounts
        ),
      })
    )
    dispatch(
      setToFinancialAccount({
        value: findObjById(value.toFinancialAccount, unclosedFinancialAccounts),
      })
    )
    dispatch(setAmount({ value: value.amount }))
    dispatch(setMemo({ value: value.memo }))
    goNext('1')
  }

  const createOptionsToAcc = (
    accounts: FinancialAccount[],
    idToRemove: string
  ) => {
    return accounts
      .filter((account) => account.financialAccountId !== idToRemove)
      .map((account) => ({
        id: account.financialAccountId,
        value: account.financialAccountId,
        content: (
          <div className={styles.dropDown}>
            <div>{`${formatAccountName(account.name)} (****${account.accountNumber.slice(-4)})`}</div>
            <div>{`$${
              helper.moneyFormat(account?.availableCash?.value) || ''
            }`}</div>
          </div>
        ),
      }))
  }
  const optionsToAcc = createOptionsToAcc(
    unclosedFinancialAccounts,
    fromFinAccount
  )
  const optionsFromAcc = createOptionsToAcc(
    unclosedFinancialAccounts,
    toFinAccount
  )

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <ToMyOwnAccountForm
          control={control}
          optionsFromAcc={optionsFromAcc}
          optionsToAcc={optionsToAcc}
        />
        <Button
          variant='contained'
          disabled={!formState.isValid}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </div>
    </TransitionAnimation>
  )
}
