import { TransitionAnimation } from 'shared/ui/animations';
import ChooseCardsToConnectSubUser from 'components/chooseCardsToConnectSubUser';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';

export const ManageConnectedCards = () => {
  const { lockForClose, unlockForClose } = useDrawerStepperContext();

  return (
    <TransitionAnimation>
      <ChooseCardsToConnectSubUser
        drawerSteperLockForClose={lockForClose}
        drawerSteperUnlockForClose={unlockForClose}
        setPageStatus={() => {}}
        backRoute={'EditSubUserCard'}
        setNewCardId={() => {}}
      />
    </TransitionAnimation>
  );
};
