import { useEffect } from 'react'
import { Button } from 'shared/ui/buttons'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { useAppSelector, useAppDispatch } from 'shared/models'
import helper from 'services/helper'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ManageScheduleTransferForm } from './manage-schedule-transfer-form'
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement'
import { FREQUENCY_DATA } from 'entities/transfers'
import { setSelectScheduleTransfer, selectScheduleTransfer } from '../../model'
import { findObjById } from 'entities/transfers'
import {
  ScheduleTransfer,
  ScheduleTransferSchema,
} from 'entities/transfers/model'
import { formatAccountName } from 'shared/lib/format'

export const ManageScheduleTransferEdit = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()
  const { data: neteviaPayees, isLoading: isLoadingNeteviaPayees } =
    useNeteviaPayeesControllerQuery()

  const scheduleTransfer = useAppSelector(selectScheduleTransfer)

  const TransferSchema = ScheduleTransferSchema.refine(
    (data) => {
      const account = findObjById(
        data.fromFinancialAccount,
        unclosedFinancialAccounts
      )
      if (account.availableCash) {
        return data.amount.floatValue <= account?.availableCash?.value
      } else {
        return true
      }
    },
    {
      message: 'The amount in your account should not exceed the balance',
      path: ['amount'],
    }
  )

  const { control, formState, getValues , watch, trigger: validateForm,} = useForm<ScheduleTransfer>({
    resolver: zodResolver(TransferSchema),
    mode: 'all',
    defaultValues: {
      fromFinancialAccount: scheduleTransfer?.fromAccount.id,
      toAccount: scheduleTransfer?.toAccount.id,
      amount: scheduleTransfer?.amount,
      frequency: scheduleTransfer?.frequency,
      dateTransfer: scheduleTransfer?.dateTransfer,
      memo: scheduleTransfer?.memo,
    },
  })


  const fromFinAccount = watch('fromFinancialAccount')

  useEffect(() => {
    validateForm()
  }, [fromFinAccount])


  const isFormDirty = formState.isDirty


  const onSubmit = () => {
    const value = getValues()

    dispatch(
      setSelectScheduleTransfer({
        value: {
          id: scheduleTransfer?.id!,
          fromAccount: findObjById(
            value.fromFinancialAccount,
            unclosedFinancialAccounts
          ),
          toAccount: findObjById(value.toAccount, neteviaPayees?.payees),
          amount: value.amount,
          frequency: value.frequency,
          dateTransfer: value.dateTransfer,
          memo: value.memo,
        },
      })
    )
    goNext('6')
  }

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash.value)}</div>
      </div>
    ),
  }))

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }))

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <ManageScheduleTransferForm
          control={control}
          optionsFromAcc={optionsFromAcc}
          optionsFrequency={optionsFrequency}
          payees={neteviaPayees?.payees || []}
        />

        <Button
          variant='contained'
          disabled={!formState.isValid || !isFormDirty}
          onClick={onSubmit}
        >
          save changes
        </Button>
      </div>
    </TransitionAnimation>
  )
}
