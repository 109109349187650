import React, { FC } from 'react';
import styles from '../style.module.scss';
import { Button } from 'shared/ui/buttons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextInput } from 'shared/ui/inputs';

const schemaEmail = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ),
});
interface EditEmailaddressProps {
  email: string;
  handleSubmit: (form: { email: string }) => Promise<void>;
  isLoading: boolean;
}

const EditEmailaddress: FC<EditEmailaddressProps> = ({
  email,
  handleSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: schemaEmail,
    onSubmit: (form) => {
      handleSubmit(form);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapper}
    >
      <TextInput
        label='Email address'
        {...formik.getFieldProps('email')}
        error={formik.errors.email}
      />
      <Button
        type='submit'
        loading={isLoading}
        variant='contained'
        className={styles.button}
        disabled={
          !formik.isValid || formik.initialValues.email === formik.values.email
        }
      >
        Save Changes
      </Button>
    </form>
  );
};
export default EditEmailaddress;
