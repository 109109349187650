import { usePermission } from 'shared/hooks/usePermission';
import { IconButton, useMediaQuery } from '@mui/material';
import { ArrowBackIcon } from 'shared/ui/icons';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import { appRoutes } from 'routes';

export const NavigationHeader = () => {
  const { giftCardPermission } = usePermission();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const navigate = useNavigate();

  const handleNavigateSubUser = () => {
    navigate(appRoutes.home());
  };

  const handleNavigateOwner = () => {
    navigate(appRoutes.rewards());
  };

  const renderBackNavigation = (handleNavigate: () => void) => (
    <div className={styles.navMob}>
      <IconButton
        color='inherit'
        aria-label='Go Back'
        onClick={handleNavigate}
        className={styles.arrowBack}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography>Gift Cards</Typography>
    </div>
  );

  const renderGiftCardNavigation = () => {
    if (giftCardPermission) {
      return renderBackNavigation(handleNavigateSubUser);
    }
    if (isMobile && !giftCardPermission) {
      return renderBackNavigation(handleNavigateOwner);
    }

    return <></>;
  };

  return <>{renderGiftCardNavigation()}</>;
};
