import styles from "./style.module.scss";
import LogoIcon from "assets/svg/logo-netevia-white.svg";
import zeroFeesIcon from "assets/svg/finance-blue.svg";
import cashIcon from "assets/svg/cash-blue.svg";
import financialIcon from "assets/svg/currency-blue.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import { AnimateWrapper } from "components/animate-wrapper";
import cx from "classnames";
import { useTranslationProvider } from "providers/translation/translation.provider";
import HeaderButton from "pages/login/components/headerButton";
import { useNavigate } from "react-router-dom";

const StartMainSection = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  const renderMobVer = () => {
    return <img src={LogoIcon} alt="Netevia logo" width={80} onClick={() => navigate('/login')}/>;
  };

  const renderDesctopVer = () => {
    return (
      <div>
        <img 
          onClick={() => navigate('/login')}
          src={LogoIcon} 
          className={styles.img} 
          alt="Netevia logo" 
          width={160} 
        />
        <div className={styles.title}>
          <p className={styles.title_text}>
            {t("Login.Payments, banking, and cash flow", {
              defaultValue: "Payments, banking, and cash flow",
            })}{" "}
            -
          </p>
          <p className={cx(styles.title_text, styles.title_opacity)}>
            {t("Login.united for you", {
              defaultValue: "united for you",
            })}
          </p>
        </div>
        <div className={styles.info}>
          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <img src={zeroFeesIcon} width={24} />
              <span className={styles.info_box_title_text}>
                {t("Login.Zero Fees", {
                  defaultValue: "Zero Fees",
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t("Login.Enjoy clear and cost-free", {
                defaultValue:
                  "Enjoy clear and cost-free transactions without hidden charges",
              })}
            </p>
          </div>

          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <img src={cashIcon} width={24} />
              <span className={styles.info_box_title_text}>
                {t("Login.Cash Back Rewards", {
                  defaultValue: "Cash Back Rewards",
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t("Login.Receive a generous", {
                defaultValue:
                  "Receive a generous 1% unlimited cash back on every purchase, giving you extra value.",
              })}
            </p>
          </div>

          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <img src={financialIcon} width={24} />
              <span className={styles.info_box_title_text}>
                {t("Login.Financial Flexibility", {
                  defaultValue: "Financial Flexibility",
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t("Login.Netevia offers", {
                defaultValue:
                  "Netevia offers financial flexibility with customizable spend limits, including employee cards.",
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <HeaderButton />
        {matches ? renderDesctopVer() : renderMobVer()}
      </div>
    </AnimateWrapper>
  );
};

export default StartMainSection;
