import { Outlet } from 'react-router-dom';
import { AnimateWrapper } from 'components/animate-wrapper';
import Footer from 'shared/components/footer';
import styles from './style.module.scss';
import StartMainSection from 'layouts/LoginLayout/components/startMainSection';

const RegistrationLayout = () => {
  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.content}>
          <StartMainSection/>
          <Outlet/>
        </div>
        <Footer stylesWrapper={styles.stylesWrapperFooter}/>
      </div>
    </AnimateWrapper>
  );
};

export default RegistrationLayout;
