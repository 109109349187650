import { Divider } from '@mui/material'
import styles from './styles.module.scss'
import { CercleSuccessGreenIcon } from 'shared/ui/icons'
import { Typography } from 'shared/ui/typography'
import { FinancialAccount } from 'entities/transfers'

interface Props {
  amount: string
  accountFrom: FinancialAccount | null
  accountTo: FinancialAccount | null
  title?: string
  date?: string
  frequency?: string
  memo?: string;
}

export const DetailsTransfer = (props: Props) => {
  const { amount, accountFrom, accountTo, title, date, frequency, memo } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_title}>
        {title && (
          <div className={styles.wrapper_title_success}>
            <CercleSuccessGreenIcon />
            <Typography>{title}</Typography>
          </div>
        )}

        <Typography className={styles.wrapper_title_amount}>
          {amount}
        </Typography>
        <Typography className={styles.wrapper_title_last4}>
          to {accountTo?.name} ({accountTo?.last4Number})
        </Typography>
      </div>
      <Divider />
      <div className={styles.wrapper_info}>
        <div>
          <Typography>From</Typography>
          <Typography>
            {accountFrom?.name} ({accountFrom?.last4Number})
          </Typography>
        </div>
        <div>
          <Typography>To</Typography>
          <Typography>
            {accountTo?.name} ({accountTo?.last4Number})
          </Typography>
        </div>
        <div>
          <Typography>Amount</Typography>
          <Typography>{amount}</Typography>
        </div>
        {memo && (
          <div>
            <Typography>Memo</Typography>
            <Typography style={{ 'overflow-wrap': 'break-word' } as any}>{memo}</Typography>
          </div>
        )}
        {frequency && (
          <div>
            <Typography>Frequency</Typography>
            <Typography>{frequency}</Typography>
          </div>
        )}
        {date && (
          <div>
            <Typography>Transfer date</Typography>
            <Typography>{date}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}
