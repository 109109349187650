import Mail from 'assets/svg/mail-gray.svg';
import Home from 'assets/svg/home-gray.svg';
import Phone from 'assets/svg/phone-gray.svg';
import { useMediaQuery, List, Divider } from "@mui/material";
import { PERSONAL_INFORMATION } from "pages/settings/accountSettings/searchParamsTypes";
import { EditType } from "pages/settings/accountSettings/types";
import CompanyInfoButton from "pages/settings/components/companyInfoButton";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSearchParams } from "react-router-dom";
import helper from "services/helper";
import { useTheme } from "styled-components";
import { User } from "../../types";
import { useAccount } from "pages/settings/accountSettings/provider";
import styles from './style.module.scss';
import AccountSettingsContainer from '../../../AccountSettingsContainer';
import { useHolderV2ControllerQuery } from '../../../../../../../api/endpoints/account';
import moment from 'moment';
import EmployerBug from 'assets/svg/emploer-bug.svg';


interface PersonalInformationProps {
  user: User;
}

const PersonalInformation = ({ user }: PersonalInformationProps) => {
  const { t } = useTranslationProvider();
  const [_, setSearchParams] = useSearchParams();
  const { setIsShowDrawer } = useAccount();
  const { data: holder } = useHolderV2ControllerQuery();

  const handleNavigate = (mode: string, type: EditType, titleForm) => {
    const page = PERSONAL_INFORMATION;
    const pageMode = 'ChangePersonalInfo';

    setIsShowDrawer(true);
    setSearchParams({
      page,
      pageMode,
      mode,
      title: titleForm || 'Edit data',
      type,
    });
  };

  const titleEmail = t('Settings.EditPersonalEmailAddress', {
    defaultValue: 'Edit Personal Email Address',
  });
  const titlePhone = t('Settings.EditPersonalPhoneNumber', {
    defaultValue: 'Edit Personal Phone Number',
  });
  const titleAddress = t('Settings.EditPersonalAddress', {
    defaultValue: 'Edit Personal Address',
  });

  return (
    <AccountSettingsContainer title={t('Settings.Personal Information', {
      defaultValue: 'Personal Information',
    })} >
        <>
        <span className={styles.container_holderName}>
          {user.fullName}
        </span>

        <List disablePadding className={styles.listWrapper}>
          <CompanyInfoButton
            title={t('Settings.Email', { defaultValue: 'Email' })}
            img={Mail}
            description={user?.email}
            handleNavigate={handleNavigate}
            mode={EditType.Email}
            titleForm={titleEmail}
            type={EditType.Email}
          />
          <Divider />

          <CompanyInfoButton
            title={t('Settings.Phone', { defaultValue: 'Phone' })}
            img={Phone}
            description={`${user?.countryCodeAlpha3 === 'USA' ? '+1' : '+'} ${helper.formatPhone(user.phoneNumber)}`}
            handleNavigate={handleNavigate}
            mode={EditType.Phone}
            titleForm={titlePhone}
            type={EditType.Phone}
          />
          <Divider />

          <CompanyInfoButton
            title={t('Settings.Address', { defaultValue: 'Address' })}
            img={Home}
            description={helper.formatAddress({
              countryCodeAlpha3: user?.countryCodeAlpha3,
              extendedAddress: user.extendedAddress,
              locality: user.city,
              postalCode: user.postalCode,
              region: user.region?.label,
              streetAddress: user.streetAddress,
            })}
            handleNavigate={handleNavigate}
            mode={EditType.Address}
            titleForm={titleAddress}
            type={EditType.Address}
          />

          {holder && holder.employer && (
            <>
              <Divider />
              <CompanyInfoButton
                title='Employee information'
                img={EmployerBug}
                description={
                  <div>
                    {holder.employer.name}
                    <br />
                    Since: {moment(holder.employer.date).format('Do MMMM YYYY')}
                  </div>
                }
                handleNavigate={handleNavigate}
                mode={EditType.EmployerInformation}
                titleForm='Edit Employee Information'
                type={EditType.EmployerInformation}
              />
            </>
          )}
        </List>
      </>
    </AccountSettingsContainer>

  );
};

export default PersonalInformation;
