import { TransitionAnimation } from 'shared/ui/animations';
import { TextInput } from 'shared/ui/inputs';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { useFormik } from 'formik';
import { RenameFinAccountSchema } from './validationRenameSchema';
import { useRenameFinancialControllerMutation } from 'api/endpoints/financialAccounts';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Button } from 'shared/ui/buttons';
interface RenameFinAccountProps {
  financialAccounts: FinancialAccount[];
  financialAccountId: string | undefined;
}

export const RenameFinAccount = (props: RenameFinAccountProps) => {
  const { financialAccounts, financialAccountId } = props;
  const { setSnackBar } = useSnackBar();
  const { close } = useDrawerStepperContext();

  const currentAccountName = financialAccounts?.find(
    (account) => account.financialAccountId === financialAccountId
  )?.name;

  const [renameFinAccount, { isLoading: isLoadingRenameFinAccount }] =
    useRenameFinancialControllerMutation();

  const renameAccoutn = async (newName: string) => {
    try {
      await renameFinAccount({
        financialAccountId: financialAccountId!,
        name: newName,
      }).unwrap();
      setSnackBar({
        type: 'success',
        message: 'Account name changed successfully',
        isShow: true,
      });
      close();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      accountName: currentAccountName,
    },
    validationSchema: RenameFinAccountSchema,

    onSubmit: (values) => {
      if (values.accountName) {
        renameAccoutn(values.accountName);
      }
    },
  });

  const disabled =
    !!formik.errors.accountName ||
    formik.initialValues.accountName === formik.values.accountName;

  return (
    <TransitionAnimation>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <TextInput
          label='Account name'
          inputProps={{ maxLength: 30 }}
          autoComplete="off"
          {...formik.getFieldProps('accountName')}
          error={formik.errors.accountName}
        />
        <Button
          variant='contained'
          className={styles.btn}
          type='submit'
          disabled={disabled}
          loading={isLoadingRenameFinAccount}
        >
          save changes
        </Button>
      </form>
    </TransitionAnimation>
  );
};
