import { AnimateWrapper } from '../../components/animate-wrapper'
import AccountSelect from './components/AccountSelect'
import ActionButton from './components/ActionButton'
import TableBlock from '../../shared/components/TableBlock'
import transfersIcon from 'assets/svg/transfers.svg'
import statementsIcon from 'assets/svg/statements.svg'
import infoOutlinedIcon from 'assets/svg/InfoOutlined.svg'
import moreVertFilled from 'assets/svg/MoreVertFilled.svg'
import AddIcon from '@mui/icons-material/Add'
import styles from './style.module.scss'
import { useAccount } from './provider'
import { Skeleton, useMediaQuery } from '@mui/material'
import PaperComponent from 'shared/components/PaperComponent'
import AccordionComponent from 'shared/components/AccordionComponent'
import { StatusComponent } from 'shared/components/StatusComponent'
import TabsPanel from 'shared/components/containers/customContainer/componetns/tabsPanel'
import { useEffect, useState } from 'react'
import { TransferCells, CardCells,} from '../../shared/components/TableBlock/TableCells'
import DrawerModal from 'shared/components/drawerModal'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Statements from './Actions/Statements'
import TransactionDetails from '../../shared/components/TransactionDetails'
import AccountDetails from './Actions/AccountDetails'
import More from './Actions/More'
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess'
import { NewCardFromAccount } from './Actions/NewCardFromAccount'
import { MobileCards } from '../../shared/components/TableBlock/MobileCards'
import helper from 'services/helper'
import SimpleContainer from 'shared/components/containers/simpleContainer'
import { Transfers } from './Actions/Transfers'
import { ACCOUNT_NAME_ORDEROUT } from 'entities/orderout-card'
import { appRoutes } from '../../routes';
import { FilterForCards } from './components/filterForCards'
import { Button } from 'shared/ui/buttons'
import { FilterIcon } from 'shared/ui/icons'
import { AccountMode, AccountAction} from './types'
import FilterListIcon from "@mui/icons-material/FilterList";
import { usePermission } from 'shared/hooks/usePermission'
import { FilterTransactions } from './components/filterTransactions'
import { FilterTransactionsControls } from './components/filterTransactionsControls'
import { useCurrentUser } from '../../providers/user.provider';
import { FilterCardsControl } from './components/filterCardsControls'
const actions = [
  {
    title: 'Transfer',
    modalTitle: 'Transfer',
    description: 'Easy, Secure Money Transfers',
    img: transfersIcon,
    path: AccountAction.transfer,
    component: <Transfers />,
  },
  {
    title: 'Statements',
    modalTitle: 'Statements',
    description: 'Discover  financial reports',
    img: statementsIcon,
    path: AccountAction.statements,
    component: <Statements />,
  },
  {
    title: 'Account details',
    modalTitle: 'Account details',
    description: 'Explore your financial profile',
    img: infoOutlinedIcon,
    path: AccountAction.accountDetails,
    component: <AccountDetails />,
  },
  {
    title: 'More',
    modalTitle: 'More',
    description: 'Access comprehensive financial reports',
    img: moreVertFilled,
    path: AccountAction.more,
    // component: <More />,
    isControled: true,
  },
]

const tabItems = [
  { label: 'Transactions', img: '', id: 'transactions' },
  { label: 'Your cards', img: '', id: 'cards' },
]

const ACCOUNT_TAB_NAME = 'accountTab';

const resolveAccountTab = (accountTab?: string | undefined | null) => {
  const existTab = tabItems.find(t => t.id === accountTab)
  if (existTab) {
    return existTab.id;
  }

  return tabItems[0].id;
}

const Account = () => {
  const {
    approvedTransactions,
    pendingTransactions,
    isFetchingaccoutnTransactions,
    filteredCardList,
    paymentCardsIsFetching,
    setMessage,
    message,
    allTransactions,
    paymentCards,
    financialAccount,
    setSearchTransactionByName,
  } = useAccount()
  const {transferPermission, canOpenCard} =usePermission()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [searchParams, setSearchParams] = useSearchParams()
  const currentAction = searchParams.get('action') || ''
  const currentTransactionId = searchParams.get('transaction') || ''
  const currentMode = searchParams.get('mode') || ''
  const { isConsumer } = useCurrentUser();

  const [currentTab, setCurrentTab] = useState<string>(resolveAccountTab(searchParams.get(ACCOUNT_TAB_NAME)))

  const isAccountTransactionContent = currentTab === tabItems[0].id
  const isCardsContent = currentTab === tabItems[1].id


  useEffect(() => {
    if (currentTab !== searchParams.get(ACCOUNT_TAB_NAME)) {
      setSearchParams({
        [ACCOUNT_TAB_NAME]: currentTab,
      })
      setSearchTransactionByName('')
    }
  }, [currentTab])

  const handleChangeTub = (value: string) => {
    setCurrentTab(value)
  }

  const handleChangeCardAction = (action: AccountAction) => {
    setSearchParams({ action })
  }
  const handleChangeTransaction = async (transaction) => {
    if (currentTransactionId) {
      setSearchParams({})
      await helper.sleep(300)
    }
    setSearchParams({ transaction })
  }

  const isOrderOutAccount = financialAccount?.name?.toLocaleLowerCase() === ACCOUNT_NAME_ORDEROUT.toLowerCase();

  const NewCardButton = () => (
    canOpenCard && !isOrderOutAccount && (
      <Button
        onClick={() => setSearchParams({ mode: AccountMode.openCard })}
        className={styles.btn}
        variant='text'
        disabled={isFetchingaccoutnTransactions}
      >
        <AddIcon /> open new card
      </Button>
    )
  )

  const goToCard = (id) => {
    navigate(appRoutes.cardById(financialAccount.financialAccountId, id))
  }


  const renderActionBtn = () =>
    actions
      .filter(({ path }) => transferPermission || path !== 'transfer')
      .map((action) => (
        <ActionButton
          {...action}
          key={action.path}
          onClick={() => handleChangeCardAction(action.path)}
        />
      ));

  const cardCells = isConsumer
    ? CardCells().filter(c => !['cardholder', 'department'].includes(c.name))
    : CardCells();

  return (
    <SimpleContainer
      title={'Your Account'}
      onTitleClick={() => navigate(appRoutes.home())}
    >
      <AnimateWrapper className='fade'>
        <div className={styles.container}>
          <div className={!isMobile  ? styles.plates : styles.platesMobile}>
            {!isMobile && <AccountSelect />}
            {renderActionBtn()}
          </div>
          <div className={styles.box}>
            <TabsPanel
              tabsArr={tabItems}
              handleNavigate={handleChangeTub}
              page={currentTab}
              stylesWrapper={{
                container: styles.tabsContainer,
                list: styles.tabs
              }}
            />
           {/* {isCardsContent && isMobile && <FilterCardsControl/>} */}
          </div>


          {isAccountTransactionContent && (
            <>
              <FilterTransactionsControls/>
              {isFetchingaccoutnTransactions ? (
                <Skeleton height={200} />
              ) : (
                <>
                  {!!pendingTransactions.length && (
                    <PaperComponent>
                      <AccordionComponent
                        title='Pending Transactions'
                        bage={
                          <StatusComponent
                            counter={pendingTransactions.length}
                            status={'PROCESSING'}
                            text='Pending'
                          />
                        }
                      >
                        <TableBlock
                          rows={pendingTransactions}
                          tabCells={TransferCells()}
                          onClick={handleChangeTransaction}
                          isRowsClickable
                        />
                      </AccordionComponent>
                    </PaperComponent>
                  )}
                  <PaperComponent>
                    <TableBlock
                      rows={approvedTransactions}
                      tabCells={TransferCells()}
                      onClick={handleChangeTransaction}
                      title='Recent Transactions'
                      isRowsClickable
                    />
                  </PaperComponent>
                </>
              )}
            </>
          )}

          {isCardsContent && (
            <>
              {isMobile && <FilterCardsControl/>}
              {paymentCardsIsFetching ? (
                <Skeleton height={200} />
              ) : (
                <PaperComponent>
                  {isMobile ? (
                    <MobileCards
                      cards={filteredCardList}
                      Action={NewCardButton}
                      onClick={goToCard}
                    />
                  ) : (
                    <TableBlock
                      isRowsClickable
                      rows={filteredCardList}
                      tabCells={cardCells}
                      title={<FilterCardsControl/>}
                      Action={NewCardButton}
                      isCards
                      onClick={goToCard}
                    />
                  )}
                </PaperComponent>
              )}
            </>
          )}
        </div>

        {actions.map((action) => {
          if (action.path === AccountAction.more) {
            return null;
          }

          return (
            <DrawerModal
              key={action.path}
              isShow={action.path === currentAction}
              onClose={() => {}}
              titleText={action.modalTitle}
              isControled={action.isControled}
            >
              {action.component}
            </DrawerModal>
          );
        })}
         <More
          isShow={currentAction === AccountAction.more}
          onClose={() => setSearchParams({ })}
         />
         <DrawerModal
          isShow={
            currentMode === AccountMode.filtersTransactions
            || currentMode === AccountMode.filtersCards
          }
          onClose={() => {}}
          titleText={currentMode === AccountMode.filtersTransactions ? 'Filters' : 'Filter cards'}
        >
          {currentMode === AccountMode.filtersTransactions  && <FilterTransactions/>}
          {currentMode === AccountMode.filtersCards && <FilterForCards/>}
        </DrawerModal>

        <NewCardFromAccount />
        <DrawerModal
          isShow={!!currentTransactionId}
          onClose={() => {}}
          titleText={'Transaction details'}
        >
          <TransactionDetails
            allTransactions={allTransactions}
            paymentCards={paymentCards}
          />
        </DrawerModal>
        <SnackBarSuccess
          isOpen={!!message}
          message={message}
          onClose={() => setMessage('')}
        />
      </AnimateWrapper>
    </SimpleContainer>
  )
}

export default Account
