import { Select, SelectItem, TextInput } from 'shared/ui/inputs';
import { Divider } from '@mui/material'
import { CurrencyInput } from 'shared/ui/inputs'
import { Control, useController } from 'react-hook-form'
import { ToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account/model'
import styles from './styles.module.scss'

interface ToMyOwnAccountFormProps {
  control: Control<ToMyOwnAccount>
  optionsFromAcc: SelectItem[]
  optionsToAcc: SelectItem[]
}

export const ToMyOwnAccountForm = (props: ToMyOwnAccountFormProps) => {
  const { control, optionsFromAcc, optionsToAcc } = props

  const fromFinancialAccount = useController({
    name: 'fromFinancialAccount',
    control,
  })
  const toCustomer = useController({ name: 'toFinancialAccount', control })
  const amount = useController({ name: 'amount', control })
  const memo = useController({ name: 'memo', control })
  return (
    <div className={styles.root}>
      <Select
        label='From'
        options={optionsFromAcc}
        {...fromFinancialAccount.field}
      />
      <Select
        label='To'
        options={optionsToAcc}
        {...toCustomer.field}
      />

      <Divider />

      <CurrencyInput
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        error={amount.fieldState.error?.message}
        getCaretBoundary={() => [false]}
        {...amount.field}
      />

      <TextInput
        label="Memo"
        autoComplete="off"
        error={memo.fieldState.error?.message}
        {...memo.field}
      />
    </div>
  )
}
