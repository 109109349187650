import { useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { ShortMessageType } from 'enums';
import { useCreateGiftCardMutation } from 'api/endpoints/giftCards';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { SecureOperationType } from 'enums';
import { useCreateGiftCardPointsMutation } from 'api/endpoints/rewards';
import { GiftCardMethod } from 'pages/reward/components/giftCard/types';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Skeleton } from 'components/skeleton';
import { useMfa } from 'providers/mfa/mfa';

const successMessage = 'You have successfully purchased gift card';


export const AuntificatorSms = () => {
  const { close, goBack } = useDrawerStepperContext();
  const {
    selectBrand,
    amount,
    point,
    paymnetMethod,
    financialAccountId,
    refetchGiftCardsData,
    refetchRewards
  } = useGiftCards();
  const mfa = useMfa()
  const { setSnackBar } = useSnackBar();

  const [createGiftCard] = useCreateGiftCardMutation();
  const [createGiftCardPoints] = useCreateGiftCardPointsMutation();

  const isFinancialAccountPayment =
    paymnetMethod === GiftCardMethod.PayUsingYourFinancialAccount;

  const createGiftCardWithFinAccount = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      await createGiftCard({
        brandId: selectBrand.id,
        faceAmount: Number(amount),
        oneTimeCode: code,
        secureOperationType: secureOperationType,
        fromFinancialAccountId: financialAccountId,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: successMessage,
        isShow: true,
      });
      mfa.close()
      close();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const createGiftCardWithRewardPoints = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      await createGiftCardPoints({
        brandId: selectBrand.id,
        faceAmount: point,
        oneTimeCode: code,
        secureOperationType: secureOperationType,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: successMessage,
        isShow: true,
      });
      refetchGiftCardsData();
      refetchRewards();
      mfa.close()
      close();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handlerSubmit = async (
    secureOperationType: SecureOperationType,
    code: string
  ): Promise<void> => {
    if (isFinancialAccountPayment) {
      await createGiftCardWithFinAccount(secureOperationType, code);
    } else {
      await createGiftCardWithRewardPoints(secureOperationType, code);
    }
  };


  useEffect(() => {
    mfa.show({
      title: 'Confirm with one time password',
      shortMessageType: isFinancialAccountPayment ? ShortMessageType.ApproveTransaction : ShortMessageType.TransferPoints,
      isOnlySmsFlow: true,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await handlerSubmit(secureOperationType, code);
      }
    })
  }, []);

  return (
    <TransitionAnimation>
          <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
