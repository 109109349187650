import { useState } from "react";
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useChangeLoginMutation } from "api/endpoints/account";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useConfirmDialog } from "providers/confirm.provider";
import styles from "./style.module.scss";
import helper from "services/helper";
import { useSnackBar } from "providers/snackBar.provider";
import { useSearchParams } from 'react-router-dom';
import { LOGIN_OPTIONS } from "pages/settings/security/securitySearchParams";

const ChangeLogin = () => {
  const { setSnackBar } = useSnackBar();
  const { t } = useTranslationProvider();
  const [, setSearchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const [changeLoginMutation, { isLoading: changeLoginIsLoading }] =
    useChangeLoginMutation();

  const [newLogin, setNewLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const buttonStyled = {
    backgroundColor: "#187AC9",
    width: "fit-content",
  };

  const changeLogin = () => {
    confirm.show({
      applyButtonText: t("common.Confirm", {
        defaultValue: "Confirm",
      }),
      cancelButtonText: t("common.Cancel", {
        defaultValue: "Cancel",
      }),
      dialogTitle: t("Settings.Change Login", {
        defaultValue: "Change Login",
      }),
      dialogText: `${t("Settings.Do you want to change", {
        defaultValue: "Do you want to change your current login to",
      })} "${newLogin}"?`,
      cancelButtonType: "text",
      cancelError: true,
      typeNew: true,
      async onApply() {
        try {
          await changeLoginMutation({
            login: newLogin,
            password: password,
          }).unwrap();
          setSnackBar({
            type: "success",
            message: t("Settings.Change Login - Success", {
              defaultValue: "Change Login - Success",
            }),
            isShow: true,
          })

          setSearchParams({ page: LOGIN_OPTIONS });
        } catch (e: any) {
          console.log("error", e);
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          })
        }
      },
    });
  };

  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);
  return (
    <div className={styles.container}>
      <TextField
        id="outlined-required"
        value={newLogin}
        label={t("Settings.New login", {
          defaultValue: "New login",
        })}
        inputProps={{ maxLength: 40 }}
        onChange={(e: any) => {
          setNewLogin(e.target.value.trim());
        }}
      />
      <FormControl
        sx={{ backgroundColor: "transparent", padding: 0 }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment">
          {t("Login.Password", {
            defaultValue: "Password",
          })}
        </InputLabel>
        <OutlinedInput
          id="filled-adornment-password"
          label={t("Login.Password", {
            defaultValue: "Password",
          })}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          sx={{ backgroundColor: "transparent" }}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <LoadingButton
        onClick={changeLogin}
        loading={changeLoginIsLoading}
        variant="contained"
        sx={buttonStyled}
        disabled={!newLogin || !password || newLogin.length < 6}
      >
        {t("common.Save Changes", {
          defaultValue: "Save Changes",
        })}
      </LoadingButton>
      {/* <SnackBarSuccess
        message={message}
        isOpen={showIsSuccess}
        onClose={() => setShowIsSuccess(false)}
      />
      <SnackBarWarning
        message={message}
        isOpen={showIsWarning}
        onClose={() => setShowIsWarning(false)}
      /> */}
    </div>
  );
};
export default ChangeLogin;
