import { useRewards } from "pages/reward/provider/useRewards";
import { Skeleton } from "components/skeleton";
import styles from "./styles.module.scss";
import { replaceNumberWithComma } from "shared/utils/price";
import EarnderCategoriesItem from "pages/reward/components/dashboard/components/earnedMonthDashboard/components/earnedCategoriesItem";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { findRewardCategoryByType } from "pages/reward/lib/findRewardCategoryByType";
import { Typography } from "shared/ui/typography";
const HistoryLastEarnedPoints = () => {
  const { t } = useTranslationProvider();
  const {
    isDataLoading,
    lastPeriodPoints,
    lastPeriodGrouped,
  } = useRewards();

  const calculatePercentageAmount = (amount) => {
    if (lastPeriodPoints === 0) {
      return 0;
    }
    return (amount / lastPeriodPoints) * 100;
  };
  const renderLinner = () => {
    return lastPeriodGrouped?.map((el, index) => {
      const itemProccent = calculatePercentageAmount(el.amount);
      const item = findRewardCategoryByType(el.type);
      return (
        <LinearProgress
          key={index}
          variant="determinate"
          value={100}
          sx={{
            height: "100%",
            width: `${itemProccent}%`,
            "& .MuiLinearProgress-bar": {
              backgroundColor: item?.color,
            },
          }}
        />
      );
    });
  };

  const renderListCategorys = () => {
    return lastPeriodGrouped?.filter(el => el.points > 0).map((el, index) => (
      <EarnderCategoriesItem
        key={index}
        points={el.points}
        type={el.type}
        stylesWrapper={styles.wrapperItem}
      />
    ));
  };

  const totalPoints = replaceNumberWithComma(lastPeriodPoints)

  return isDataLoading ? (
    <Skeleton width="27%" height="194px" />
  ) : (
    <div className={styles.container}>
      <div className={styles.box}>
        <Typography className={styles.boxTitleText}>
          {t("Rewards.Last earned points", {
            defaultValue: "Last earned points",
          })}
        </Typography>
        <Typography className={styles.boxPointsText}>
          {totalPoints}{" "}
          {t("Rewards.points", {
            defaultValue: "points",
          })}
        </Typography>
       {!!lastPeriodPoints && <div className={styles.boxLinner}>{renderLinner()}</div>}
      </div>
      <div className={styles.list}>
        {renderListCategorys()}
      </div>
    </div>
  );
};

export default HistoryLastEarnedPoints;
