import { useGetCardSpendingReportEXELMutation } from 'api/endpoints/paymentCards';
import { triggerBase64Download } from 'common-base64-downloader-react';
import moment from 'moment';
import { offExtFromFilename } from 'shared/utils/pipes';

interface UseGetCardSpendingReportEXELProps {
  paymentCardId: string;
  dateFrom: string;
  dateTo: string;
  onError?: (error: any) => void;
  onSuccess?: () => void;
}

const useGetCardSpendingReportEXEL = ({
  paymentCardId,
  dateFrom,
  dateTo,
  onError,
  onSuccess,
}: UseGetCardSpendingReportEXELProps) => {
  const [
    getSpendingReportEXELMutation,
    { isLoading: getSpendingReportEXELIsLoading },
  ] = useGetCardSpendingReportEXELMutation({});

  const getSpendingReportEXEL = async () => {
    try {
      const resEXEL = await getSpendingReportEXELMutation({
        paymentCardId,
        dateFrom: moment(new Date(dateFrom)).format('YYYY-MM-DDTHH:mm:ssZ'),
        dateTo: moment(new Date(dateTo)).format('YYYY-MM-DDT23:59:ssZ'),
      }).unwrap();

      if (resEXEL) {
        const { name, content, contentType } = resEXEL;
        const base64Str = content;
        const base64EXEL = `data:${contentType};base64, ${base64Str}`;
        triggerBase64Download(base64EXEL, offExtFromFilename(name));
      }

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (onError) onError(e);
    }
  };

  return { getSpendingReportEXEL, isLoading: getSpendingReportEXELIsLoading };
};

export default useGetCardSpendingReportEXEL;
