import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import { Skeleton } from 'components/skeleton';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { FormikProps } from 'formik';
import { SubUserFormValues } from 'pages/settings/accountSettings/types';
import { Radio } from 'shared/ui/inputs';
import { AccountItem } from '../../../accountItem';
import { List } from '@mui/material';
import styles from './style.module.scss';
import { useBoolean } from 'shared/hooks/useBoolean';
import DrawerModal from 'shared/components/drawerModal';
import { AddNewAccount } from 'pages/home/components/accountList/components/addNewAccount';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { sortByDate } from 'pages/settings/accountSettings/lib/sortByDate';
interface AddPrimaryAccountProps {
  formik: FormikProps<SubUserFormValues>;
}

export const AddPrimaryAccount = ({ formik }: AddPrimaryAccountProps) => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const { goNext } = useDrawerStepperContext();
  const openNewAccountBool = useBoolean();

  const [selectedAccountId, setSelectedAccountId] = useState<string>('');

  const renderList = () => {
    if (!unclosedFinancialAccounts?.length) return <></>;

    return (
      <List
        disablePadding
        className={styles.list}
      >
        {unclosedFinancialAccounts.sort(sortByDate).map((item) => (
          <AccountItem
            key={item.financialAccountId}
            item={item}
            endSlot={
              <Radio
                name='primaryAccounId'
                value={item.financialAccountId}
                checked={selectedAccountId === item.financialAccountId}
                onChange={(e) => setSelectedAccountId(e.target.value)}
              />
            }
          />
        ))}
      </List>
    );
  };

  const handleAccountCreationSuccess = (id: string | undefined) => {
    if (id) {
      setSelectedAccountId(id);
    }
    openNewAccountBool.setFalse();
  };

  const handleSaveChanges = () => {
    formik.setFieldValue('primaryAccounId', selectedAccountId);
    goNext('0');
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography className={styles.text}>
          Choose an account for primary card
        </Typography>
        <Button
          variant='text'
          className={styles.btnOpen}
          onClick={() => openNewAccountBool.setTrue()}
        >
          + Open new account
        </Button>
        {financialAccountsIsLoading ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          renderList()
        )}
        <Button
          className={styles.btnSave}
          onClick={handleSaveChanges}
          disabled={!selectedAccountId}
        >
          Save changes
        </Button>
        <DrawerModal
          isShow={openNewAccountBool.value}
          onClose={() => openNewAccountBool.setFalse()}
          goBack={() => openNewAccountBool.setFalse()}
          titleText='Set primary card account'
        >
          <AddNewAccount onSuccessRedirect={handleAccountCreationSuccess} />
        </DrawerModal>
      </div>
    </TransitionAnimation>
  );
};
