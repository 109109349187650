import {
  useCreateFinancialAccountControllerMutation,
  useFinancialAccountsControllerQuery,
} from "api/endpoints/financialAccounts";
import { useNavigate, useSearchParams } from "react-router-dom";
import helper from "services/helper";
import { useSnackBar } from "providers/snackBar.provider";
import { useBoolean } from "shared/hooks/useBoolean";
import styles from './style.module.scss'
import { Typography } from "shared/ui/typography";
import { Button } from "shared/ui/buttons";
import { TextInput } from "shared/ui/inputs";
import { useFormik } from "formik";
import { RenameFinAccountSchema } from "pages/accountComponent/Actions/More/renameFinAccount/validationRenameSchema";
import { useDrawerBehavior } from "providers/drawer-behavior";

interface AddNewAccountProps {
  onSuccessRedirect?: (id?: string) => void
}

export const AddNewAccount =  ({onSuccessRedirect} : AddNewAccountProps) => {
  const drawerBehavior = useDrawerBehavior();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { setSnackBar } = useSnackBar();
  const [createAccountMutation] = useCreateFinancialAccountControllerMutation();
  const { refetch } = useFinancialAccountsControllerQuery({});

  const loadingBool = useBoolean()

  const onClose = (id: string) => {
    setSearchParams({});
    navigate(`/account/${id}`);
  }

  const createAccount = async (name: string) => {
    try {
      loadingBool.setTrue()
      drawerBehavior.lockForClose()
       const res = await createAccountMutation({ Name: name }).unwrap();
      await helper.sleep(8000);
      await refetch();
      loadingBool.setFalse()
      drawerBehavior.unlockForClose()
      setSnackBar({
        type: "success",
        message: "New account successfully created.",
        isShow: true,
      });
      onSuccessRedirect ? onSuccessRedirect(res) : onClose(res)
    } catch (e: any) {
      loadingBool.setFalse()
      drawerBehavior.unlockForClose()
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      accountName: '',
    },
    validationSchema: RenameFinAccountSchema,

    onSubmit: (values) => {
        createAccount(values.accountName);
    },
  });

  return (
      <form 
        className={styles.container}
        onSubmit={formik.handleSubmit}
      >
        <Typography className={styles.header}>
            Let`s get started on creating your new bank account. Please provide a
            nickname for your account.
        </Typography>
        <TextInput
          label={"Account name"}
          autoComplete="off"
          {...formik.getFieldProps('accountName')}
          error={formik.errors.accountName}
          inputProps={{ maxLength: 30 }}
        />
        <Button
          type="submit"
          variant="contained"
          className={styles.btn}
          disabled={!!formik.errors.accountName || !formik.values.accountName}
          loading={loadingBool.value}
        >
          Create account
        </Button>
      </form>
  );
};

