import styles from './style.module.scss';
import cx from 'classnames';
import { SvgIcon } from '../../../../../../shared/components/svgIcon';
import { useCashFlow } from '../provider';
import { centsToDollars, replaceNumberWithComma } from '../../../../../../shared/utils/price';
import { useMemo } from 'react';
import { CashFlowPeriodEnum } from '../../../../../../api/endpoints/financialAccounts';
import moment from 'moment/moment';

export const PeriodInfo = () => {
  const cashFlow = useCashFlow();

  const dateFrom = moment(cashFlow?.cashFlowApi?.total.dateFrom);
  const dateTo = moment(cashFlow?.cashFlowApi?.total.dateTo);

  const formatDateRange = (start, end) => `${start.format('MMM D')} - ${end.format('MMM D')}`;

  const label = useMemo(() => {
    const period = cashFlow.selectedPeriod;
    if (!cashFlow.cashFlowApi) {
      return '';
    }

    if (period === CashFlowPeriodEnum.D7) {
      return `Total for ${formatDateRange(dateFrom, dateTo)}`
    }

    if (period === CashFlowPeriodEnum.M1) {
      return `Total for ${formatDateRange(dateFrom.startOf('month'), dateTo.endOf('month'))}`
    }

    if (period === CashFlowPeriodEnum.M6) {
      return `Total for ${formatDateRange(dateFrom, dateTo)}`
    }

    if (period === CashFlowPeriodEnum.Y1) {
      return `Total for ${formatDateRange(dateFrom, dateFrom.clone().add(11, 'months').endOf('month'))}`
    }

    if (period === CashFlowPeriodEnum.QTD) {
      return `Total for ${formatDateRange(dateFrom, dateFrom.clone().add(2, 'months').endOf('month'))}`
    }

    if (period === CashFlowPeriodEnum.YTD) {
      return `Total for ${formatDateRange(dateFrom, moment())}`
    }

    return 'Results: '
  }, [cashFlow.cashFlowApi?.total, cashFlow.selectedPeriod]);

  if (!cashFlow.cashFlowApi?.total) {
    return null;
  }

  const cashInString = replaceNumberWithComma(centsToDollars(cashFlow.cashFlowApi.total.cashIn));
  const cashOutString = replaceNumberWithComma(centsToDollars(cashFlow.cashFlowApi.total.cashOut));

  return (
    <div className={styles.wrapper}>
      <div className={styles.periodLabel}>{label}</div>
      <div className={styles.totals}>
        <div className={styles.totalItem}>
          <div className={cx(styles.totalCircle, styles.circleIn)}>
            <SvgIcon name="ArrowUpwardFilled" sizePx={19}/>
          </div>
          <div className={styles.totalAmount}>${cashInString}</div>
        </div>
        <div className={styles.totalItem}>
          <div className={cx(styles.totalCircle, styles.circleOut)}>
            <SvgIcon name="ArrowUpwardFilled" sizePx={19}/>
          </div>
          <div className={styles.totalAmount}>${cashOutString}</div>
        </div>
      </div>
    </div>
  )
}
