import { useEffect, useMemo } from 'react';
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { Skeleton } from "components/skeleton";
import helper from "services/helper";
import { FinancialAccount } from "api/endpoints/financialAccounts";
import styles from './style.module.scss'
import { formatAccountName } from 'shared/lib/format';

interface FinancialAccountsSelectProps {
  selectAccount: FinancialAccount;
  setSelectAccount: (account: FinancialAccount) => void;
  isPartnersAccountsHidden?: boolean;
  isDisabled?: boolean;
}

const FinancialAccountsSelect = (props: FinancialAccountsSelectProps) => {
  const { selectAccount, setSelectAccount, isPartnersAccountsHidden } = props;
  const { financialAccountsIsLoading, unclosedFinancialAccounts } =
    useFinancialAccounts();

  const accountOptions = useMemo(() => {
    if (isPartnersAccountsHidden) {
      return unclosedFinancialAccounts.filter(ac => !ac.partnerName);
    }
    return unclosedFinancialAccounts
  }, [unclosedFinancialAccounts, isPartnersAccountsHidden])

  useEffect(() => {
    if (!selectAccount && !financialAccountsIsLoading) {
      setSelectAccount(unclosedFinancialAccounts[0]);
    }
  }, [financialAccountsIsLoading]);

  const handleChooseAccount = (event) => {
    setSelectAccount(
      unclosedFinancialAccounts.find(
        (account) => account.name === event.target.value
      )!
    );
  };

  if (financialAccountsIsLoading) {
    return <Skeleton width=" 60%" height="75px" />;
  } else {
    return (
      <FormControl fullWidth>
        <Select
          disabled={props.isDisabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectAccount?.name}
          onChange={handleChooseAccount}
        >
          {accountOptions.map((user: FinancialAccount) => (
            <MenuItem key={user.financialAccountId} value={user?.name}>
              <div className={styles.dropDown}>
                <div>
                  {formatAccountName(user?.name)} (****{user.accountNumber.slice(-4)})
                </div>
                <div>$ {helper.moneyFormat(user?.availableCash?.value)}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
};
export default FinancialAccountsSelect;
