import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

const ForgotNavigate = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate()
  
  return (
    <span className={styles.forgot} onClick={() => navigate('forgot-options')}>
      {t("Login.Forgot", {
        defaultValue: "Forgot",
      })}{" "}
      {t("Login.Username", {
        defaultValue: "Username",
      })?.toLowerCase()}{" "}
      {t("common.or", {
        defaultValue: "or",
      })}{" "}
      {t("Login.password", {
        defaultValue: "password",
      })}
      ?
    </span>
  );
};

export default ForgotNavigate;
