import React from "react";
import AcoountItem from "./components/accountItem";
import { Divider } from "@mui/material";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import helper from "services/helper";
import { Skeleton } from "components/skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import DrawerModal from "shared/components/drawerModal";
import { useMediaQuery } from "@mui/material";
import AccountListHeader from "./components/accountListHeader";
import AccountListFooter from "./components/accountListFooter";
import { useCurrentUser } from "providers/user.provider";
import EmtyItem from "./components/emtyItem";
import { AddNewAccount } from "./components/addNewAccount";
import styles from './style.module.scss'
import { usePermission } from '../../../../shared/hooks/usePermission';
import { formatAccountName } from "shared/lib/format";
const addNewAccount = "Add new account";

export const AccountList = () => {
  const navigate = useNavigate();
  const { canOpenNewFa } = usePermission()
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [searchParams, setSearchParams] = useSearchParams();

  const { financialAccountsIsLoading, unclosedFinancialAccounts, total } =
    useFinancialAccounts();

  const userOrSubUserRigthOpenNewFinancialAccount = canOpenNewFa;
  const lengthAccountName = isMobile ? 9 : 20

  const renderItem = () => {
    return unclosedFinancialAccounts?.map((account, index) => (
      <React.Fragment key={index}>
        <AcoountItem
          key={account.financialAccountId}
          id={account.financialAccountId}
          name={formatAccountName(account.name, lengthAccountName)}
          last4={`(**** ${account.accountNumber?.slice(-4)})`}
          avaibleBalance={`$${helper.moneyFormat(account.availableCash?.value) || ''}`}
          accountType={account.accountType}
          handleNavigate={navigateToAccount}
        />
        {isMobile && index < unclosedFinancialAccounts.length - 1 && (
          <Divider />
        )}
      </React.Fragment>
    ));
  };

  const renderListAcc = () => {
    return !!unclosedFinancialAccounts?.length ? (
      <>
        <div className={styles.list}>
          {renderItem()}
          <Divider />
        </div>
        <AccountListFooter balance={helper.moneyFormat(total.availableCash)} />
      </>
    ) : (
      <EmtyItem />
    );
  };

  const navigateToAccount = (id: string) => {
    navigate(`/account/${id}`);
  };

  const openNewFinAcc = (mode: string) => {
    setSearchParams({ mode });
  };

  return financialAccountsIsLoading ? (
    <Skeleton width="100%" height="210px" />
  ) : (
      <div className={styles.container}>
        <AccountListHeader
          handleAddAcc={openNewFinAcc}
          rights={userOrSubUserRigthOpenNewFinancialAccount}
          title="Your accounts"
          openLink={addNewAccount}
          buttonLabel="add account"
        />
        {renderListAcc()}
        <DrawerModal
          isShow={searchParams.get("mode") === addNewAccount}
          onClose={() => setSearchParams({})}
          titleText={addNewAccount}
        >
          <AddNewAccount />
        </DrawerModal>
      </div>
  );
};


