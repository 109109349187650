import { Popup } from 'components/popup';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import styles from './style.module.scss';
import { useBoolean } from 'shared/hooks/useBoolean';

interface RemoveSubUserPopupProps {
  onDelete: (deleteCards: boolean) => Promise<void>;
}
export const RemoveSubUserPopupGroup = ({
  onDelete,
}: RemoveSubUserPopupProps) => {
  const removeBool = useBoolean(true);
  const linkedCardsBool = useBoolean();

  const handleRemoveSub = () => {
    removeBool.setFalse();
    linkedCardsBool.setTrue();
  };

  const handleRemoveLinkedCards = (deleteCards: boolean) => {
    onDelete(deleteCards);
  };

  const removeSubModal = () => (
    <Popup onClose={() => removeBool.setFalse()}>
      <div className={styles.wrapper}>
        <Typography>
          Are you sure you want to remove Authorized User?
        </Typography>
        <div className={styles.boxBtn}>
          <Button
            variant='text'
            onClick={() => removeBool.setFalse()}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            onClick={handleRemoveSub}
          >
            Remove
          </Button>
        </div>
      </div>
    </Popup>
  );

  const removeLinkedCards = () => (
    <Popup onClose={() => linkedCardsBool.setFalse()}>
      <div className={styles.wrapper}>
        <Typography>
          Do you want to close cards linked to the authorized user?
        </Typography>
        <div className={styles.boxBtn}>
          <Button
            variant='text'
            onClick={() => handleRemoveLinkedCards(false)}
          >
            No
          </Button>
          <Button
            variant='outlined'
            color='error'
            onClick={() => handleRemoveLinkedCards(true)}
          >
            Yes
          </Button>
        </div>
      </div>
    </Popup>
  );

  return (
    <>
      {removeBool.value && removeSubModal()}
      {linkedCardsBool.value && removeLinkedCards()}
    </>
  );
};
