import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from 'api/api-tags';

export const pinWheelApi = createApi({
    baseQuery: rtkBaseQuery,
    reducerPath: 'pinWheelApi',
    tagTypes: [
        ApiTags.GetPinWheelToken
    ],
    endpoints: (builder) => ({
      getPinWheelToken: builder.query({
        query: (financialAccountId) => ({
            url: `api/PinW/pinToken?financialAccountId=${financialAccountId}`,
            method: 'GET',
          }),
      }),
    }),
  });

export const { useLazyGetPinWheelTokenQuery } = pinWheelApi;
