import { FC, ReactNode, useEffect, useState } from 'react';
import styles from './style.module.scss';
import TabsPanel from 'shared/components/containers/customContainer/componetns/tabsPanel';
import { useMediaQuery, useTheme } from '@mui/material';
import ListDividerWithContainer from 'shared/components/containers/customContainer/componetns/listDividerWithContainer';
import { useSearchParams } from 'react-router-dom';

export interface ItemArr {
  label: string;
  img: string;
  id: string;
  isShow?: boolean;
}
interface CustomContainerProp {
  children?: ReactNode;
  title?: string;
  onTitleClick?: () => void;
  tabsArr?: ItemArr[];
}
const CustomContainer: FC<CustomContainerProp> = ({
  children,
  title,
  onTitleClick,
  tabsArr,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up('sm'));
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const pageMode = searchParams.get('pageMode');
  const hasTabsArr = !!tabsArr?.length;

  useEffect(() => {
    if (!matches) {
      setSearchParams({});
    }
  }, [matches]);

  useEffect(() => {
    if (matches) {
      setOrUpdatePageInSearchParamsDesctop(tabsArr);
    }
  }, [matches, page]);

  const setOrUpdatePageInSearchParamsDesctop = (tabsArr) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page || tabsArr[0].id,
    });
  };

  const handleNavigate = (id) => {
    setSearchParams({ page: id });
  };

  const renderTabs = () =>
    hasTabsArr && (
      <TabsPanel
        tabsArr={tabsArr}
        handleNavigate={handleNavigate}
        page={page}
      />
    );

  const renderListDivider = () =>
    hasTabsArr && (
      <ListDividerWithContainer
        tabsArr={tabsArr}
        page={page}
        handleNavigate={handleNavigate}
      />
    );
  const shouldDisplayTitleWithMob = title && !matches && !page;
  const shouldDisplayTitleWithDesctop = title && matches;

  return (
    <div className={styles.container}>
      {(shouldDisplayTitleWithMob || shouldDisplayTitleWithDesctop) && (
        <span
          className={styles.container_title}
          onClick={onTitleClick}
        >
          {title}
        </span>
      )}
      <>
        {matches ? renderTabs() : renderListDivider()}
        {children}
      </>
    </div>
  );
};
export default CustomContainer;
