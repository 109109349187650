import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  PaymentCard,
  useRenamePaymentCardNameControllerMutation,
} from 'api/endpoints/paymentCards';
import {
  EnumTransactionStatuses,
  Filters,
  useAvailableToSpendCardControllerQuery,
} from 'api/endpoints/transaction';
import {
  PaymentCardDetails,
  usePaymentCardByid,
} from 'api/hooksApi/usePaymentCardById';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import {
  useCardPendingTransactionsQuery,
  useCardApprovedTransactionsQuery,
  CardTransaction
} from 'api/endpoints/transactionEvents';
import { TransactionApiParams, Expand } from 'pages/accountComponent/types';
import {
  initialCardTransactionFilter,
  initialTransactionApiParams,
} from 'pages/accountComponent/contsants';


export interface AvailableToSpend {
  available: number;
  availableWithLimit: number;
  hasLimit: boolean;
  limit: number;
  spend: number;
}

interface ContextProps {
  cardId: string;
  paymentCards: PaymentCard[];
  currentCard: PaymentCard;
  paymentCardById: (paymentCardId: string) => Promise<PaymentCardDetails>;
  renamePaymentCard: (value: {
    paymentCardId: string;
    nickName: string;
  }) => any;
  paymentCardDetails: PaymentCardDetails;
  availableToSpend: AvailableToSpend;
  cardsIsLoading: boolean;
  renamePaymentCardIsLoading: boolean;
  paymentCardsIsFetching: boolean;
  transactionFilterData: Filters;
  isFetchingCardTransactions: boolean;
  expandFilter: Expand;
  setExpandFilter: (value: Expand) => void;
  transactionApiParams: TransactionApiParams;
  setTransactionApiParams: (value: TransactionApiParams) => void;
  transactionApiFilter: Filters;
  setTransactionApiFilter: (value: Filters) => void;
  pendingTransactions: CardTransaction[]; 
  approvedTransactions: CardTransaction[]; 
  searchTransactionByName: string;
  setSearchTransactionByName: (value: string) => void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const CardInformationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { cardId } = useParams();
  const { setSnackBar } = useSnackBar();
  const { data: availableToSpend, isLoading: availableToSpendIsLoading } =
    useAvailableToSpendCardControllerQuery({
      paymentCardId: cardId!,
    });

  const { paymentCards, paymentCardsIsLoading, paymentCardsIsFetching } =
    usePaymentCards();
  const { paymentCardById, paymentCardByIdIsLoading } = usePaymentCardByid();
  const [renamePaymentCard, { isLoading: renamePaymentCardIsLoading }] =
    useRenamePaymentCardNameControllerMutation({});

  const [paymentCardDetails, setPaymentCardDetails] =
    useState<PaymentCardDetails>();

  const [transactionApiParams, setTransactionApiParams] =
    useState<TransactionApiParams>(initialTransactionApiParams);

  const [transactionApiFilter, setTransactionApiFilter] = useState<Filters>(
    initialCardTransactionFilter
  );

  const [searchTransactionByName, setSearchTransactionByName] =
    useState<string>('');

  const [expandFilter, setExpandFilter] = useState<Expand>({
    expandByMoneyMovement: true,
    expandByMerchantType: false,
  });

  const {
    data: cardPendingTransactions,
    isFetching: isFetchingCardPendingTransactions,
  } = useCardPendingTransactionsQuery(
    {
      paymentCardId: cardId!,
      dateFrom: moment(transactionApiParams.dateFrom.toDate())
        .startOf('day')
        .utc()
        .format(),
      dateTo: moment(transactionApiParams.dateTo.toDate())
        .endOf('day')
        .utc()
        .format(),
      filter: transactionApiFilter,
      amountFilter: {
        minValue: !!parseFloat(transactionApiParams.amountFrom)
          ? parseFloat(transactionApiParams.amountFrom)
          : null,
        maxValue: !!parseFloat(transactionApiParams.amountTo)
          ? parseFloat(transactionApiParams.amountTo)
          : null,
      },
    },
    { skip: !cardId }
  );

  const {
    data: cardApprovedTransactions,
    isFetching: isFetchingCardApprovedTransactions,
  } = useCardApprovedTransactionsQuery(
    {
      paymentCardId: cardId!,
      dateFrom: moment(transactionApiParams.dateFrom.toDate())
        .startOf('day')
        .utc()
        .format(),
      dateTo: moment(transactionApiParams.dateTo.toDate())
        .endOf('day')
        .utc()
        .format(),
      filter: transactionApiFilter,
      amountFilter: {
        minValue: !!parseFloat(transactionApiParams.amountFrom)
          ? parseFloat(transactionApiParams.amountFrom)
          : null,
        maxValue: !!parseFloat(transactionApiParams.amountTo)
          ? parseFloat(transactionApiParams.amountTo)
          : null,
      },
    },
    { skip: !cardId }
  );

  useEffect(() => {
    getCardInfo(cardId);
  }, [cardId]);

  const getCardInfo = async (paymentCardId) => {
    try {
      const resCardInfo = await paymentCardById(paymentCardId);

      if (resCardInfo) {
        setPaymentCardDetails(resCardInfo);
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const isFetchingCardTransactions =
    isFetchingCardPendingTransactions || isFetchingCardApprovedTransactions;

  const value = useMemo((): ContextProps => {
    const cardsIsLoading =
      availableToSpendIsLoading ||
      paymentCardsIsLoading ||
      paymentCardByIdIsLoading;

    const filterByName = (transactions: CardTransaction[]) => {
      return transactions.filter((item) =>
        item.name.toLowerCase().includes(searchTransactionByName.toLowerCase())
      );
    };
    const pendingTransactions = filterByName(
      cardPendingTransactions?.data || []
    ).filter((t) => t.status === EnumTransactionStatuses.PENDING);

    const approvedTransactions = filterByName(
      cardApprovedTransactions?.data || []
    ).filter((t) => t.status !== EnumTransactionStatuses.PENDING);

    const currentCard = paymentCards.find((card) => card.id === cardId);

    return {
      cardsIsLoading,
      renamePaymentCardIsLoading,
      cardId,
      paymentCards,
      currentCard,
      paymentCardDetails,
      availableToSpend,
      pendingTransactions,
      approvedTransactions,
      renamePaymentCard,
      paymentCardsIsFetching,
      transactionFilterData: cardPendingTransactions?.filter,
      isFetchingCardTransactions,
      expandFilter,
      setExpandFilter,
      transactionApiParams,
      setTransactionApiParams,
      transactionApiFilter,
      setTransactionApiFilter,
      searchTransactionByName,
      setSearchTransactionByName,
    } as unknown as ContextProps;
  }, [
    cardId,
    paymentCards,
    paymentCardDetails,
    availableToSpend,
    renamePaymentCardIsLoading,
    paymentCardsIsLoading,
    paymentCardByIdIsLoading,
    availableToSpendIsLoading,
    paymentCardsIsFetching,
    isFetchingCardTransactions,
    expandFilter,
    cardPendingTransactions,
    cardApprovedTransactions,
    searchTransactionByName,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCardInformation = () =>
  React.useContext(Context) as ContextProps;
