import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';
import {
  OnboardingStateEnum,
  LoanStatusEnum,
} from 'pages/home/components/loans/types';
interface LoanRequestArg {
  loanAmount: string;
  financialAccountId: string;
}

export interface Option {
  amount: number;
  fee: number;
  currencyISOCode: string;
  fundedAmount: number;
  sweep: number;
}
export interface Loan {
  id: string;
  loanNumber: number;
  borrowerName: string;
  currentAmount: number;
  loanType: string;
  status: LoanStatusEnum | string;
  maturityDate: string;
  sweep: number;
  autoRepaymentSweep: boolean;
  currencyISOCode: string;
  currentAmountAtRepay: number;
  currentAmountAtDelete: number;
  currentAmountAtWriteOff: number;
  merchantIds: string[];
  creditRating: string;
  fundingDate: string;
  fundedAmount: number;
  originalAmount: number;
  aheadBehindValue: number;
  aheadBehindPercentage: number;
}
interface SigningDetails {
  eligibleForInstantSigning: boolean;
  instantSigningDetails: {
    leadId: string;
    documentId: string;
    documentName: string;
    signatoryName: string;
    companyname: string;
  };
}
export interface LeadDetails {
  leadSourceLendingPartnerId: string;
  onboardingState: OnboardingStateEnum | string;
  onboardingStateReason: string;
  organisationDetailsStepComplete: boolean;
  paymentDataStepComplete: boolean;
  offerStepComplete: boolean;
  kycDocumentsStepComplete: boolean;
  companyName: string;
  leadType: string;
  signingDetails: SigningDetails;
  owner: string;
  thirdPartyCustomerId: string;
  signUpDate: string;
  stateUpdated: string;
  merchantIds: string[];
  additionalInfo: {
    additionalProp: string;
  };
}
interface LoanSettingsApi {
  id: number;
  isNotLoan: boolean;
  exportedLoanServie: string;
  options?: Option[];
  signUrl: string;
  leadId: string;
  loans?: Loan[];
  leadDetails?: LeadDetails;
  showLoanSection: boolean;
  isDismiss: boolean;
}

export const loansApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'loans',
  tagTypes: [ApiTags.GetLoanSettings],
  endpoints: (builder) => ({
    loanSettings: builder.query<LoanSettingsApi, void>({
      providesTags: [ApiTags.GetLoanSettings],
      query: () => ({
        url: 'api/loans/loanSettings',
        method: 'GET',
      }),
    }),
    loanRequest: builder.query<string, LoanRequestArg>({
      query: ({ loanAmount, financialAccountId }) => ({
        url: 'api/loans/loanRequest',
        method: 'GET',
        responseHandler: 'text',
        params: {
          loanAmount,
          financialAccountId,
        },
      }),
    }),

    laonIsNotLoan: builder.mutation<boolean, void>({
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: () => ({
        url: 'api/loans/IsNotLoan',
        method: 'POST',
      }),
    }),
    laonDismiss: builder.mutation<boolean, boolean>({ 
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: (queryArg) => ({
        url: 'api/loans/dismiss',
        method: 'POST',
        body: JSON.stringify(queryArg), 
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useLoanSettingsQuery,
  useLoanRequestQuery,
  useLazyLoanRequestQuery,
  useLaonIsNotLoanMutation,
  useLaonDismissMutation,
} = loansApi;
