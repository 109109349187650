import { useEffect, useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { DetailsInfo } from '../detailsInfo';
import { calculateDiscountWithAmount } from 'pages/reward/components/giftCard/utils/calculateDiscountWithAmount';
import { Typography } from 'shared/ui/typography';
import { Divider } from '@mui/material';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import helper from 'services/helper';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { FormPaymentMethod } from './formPaymentMethod';
import { GiftCardMethod } from 'pages/reward/components/giftCard/types';
import { createPaymentMethodSchema } from 'pages/reward/components/giftCard/validationSchema/paymentMethodSchema';

const warningText = `Buying for yourself? After your purchase, you will be transferred to the 'My Gift Card' menu, where you can see a list of all the gift cards you have.`;

export const DetailsPaymentMethod = () => {
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const {
    selectBrand,
    amount,
    currentPoint,
    financialAccountId,
    paymnetMethod,
    setFinacialAccountId,
    setPaymentMethod,
    setPoint
  } = useGiftCards();

  const { logoUrl, name, clientDiscount, description } = selectBrand;

  const [method, setMethod] = useState<GiftCardMethod>(paymnetMethod);
  const amountWithDiscount = calculateDiscountWithAmount(
    Number(amount),
    clientDiscount
  );

  const formik = useFormik({
    initialValues: {
      paymentMethod: paymnetMethod,
      from: financialAccountId,
      point: amountWithDiscount,
    },
    validationSchema: createPaymentMethodSchema(
      unclosedFinancialAccounts,
      currentPoint,
      amountWithDiscount,
      method
    ),
    onSubmit: (values) => {
      setMethod(values.paymentMethod);
      setPaymentMethod(values.paymentMethod);
      if (
        values.paymentMethod === GiftCardMethod.PayUsingYourFinancialAccount
      ) {
        setFinacialAccountId(values.from);
      }else{
        setPoint(Number(amount))
      }
      goNext('3');
    },
  });

  
  useEffect(() => {
    formik.validateForm();
  }, [formik.values.paymentMethod]);

  const validFinAcc =
    formik.values.paymentMethod ===
      GiftCardMethod.PayUsingYourFinancialAccount && !formik.values.from;

  const validPoint =
    formik.values.paymentMethod === GiftCardMethod.PayWithRewardPoints &&
    !formik.values.point;

  const disabedBtn = !formik.isValid || validFinAcc || validPoint;

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div
        key={account.financialAccountId}
        className={styles.dropDown}
      >
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsInfo
          logo={logoUrl}
          name={name}
          clientDiscount={clientDiscount}
          description={description}
          giftCardBalance={amount}
          priceToPay={amountWithDiscount}
        />

        <div className={styles.boxTab}>
          <Typography>This is for me</Typography>
          <Divider />
        </div>

        <Typography>{warningText}</Typography>
        <FormPaymentMethod
          formik={formik}
          optionsFromAcc={optionsFromAcc}
          disabedBtn={disabedBtn}
          setMethod={setMethod}
        />
      </div>
    </TransitionAnimation>
  );
};
