import { TransitionAnimation } from 'shared/ui/animations';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { selectAutomations, setAutomations } from '../../model';
import {
  AutomationsSchema,
  AutomationsForm,
  createOptionsAutomationsAcc,
} from 'entities/transfers';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import helper from 'services/helper';
import { Button as ButtonMUI } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { Skeleton } from 'components/skeleton';
import styles from './styles.module.scss';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDeleteScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useDialog } from 'providers/dialogController.provider';

const toolTipText = 'When your Netevia account balance falls below the minimum balance for replenishment, automatic replenishment initiates to restore it. Funds are then transferred between own accounts to maintain the specified balance.'

export const ManagaAutomationsEdit = () => {
  const {
    unclosedFinancialAccounts,
    financialAccountsIsLoading,
    finAccountsRefetch,
  } = useFinancialAccounts();

  const { setSnackBar } = useSnackBar();
  const { goNext } = useDrawerStepperContext();
  const dialog = useDialog();
  const [
    deleteScheduledTransfer,
    { isLoading: isLoadingDeleteScheduledTransfer },
  ] = useDeleteScheduledTransferMutation();

  const dispatch = useAppDispatch();
  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
      memo: automations.memo,
    },
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(
        setAutomations({
          value: {
            id: automations?.id,
            from: values.from,
            to: values.to,
            amount: values.amount,
            memo: values.memo,
          },
        })
      );
      goNext('7');
    },
  });
  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Cancel Automation
    </Button>
  )
  const handleDeleteAutomations = async () => {
    dialog.show({
      dialogTitle: 'Cancel upcoming Automation',
      dialogText: 'Are you sure you want to cancel this Automation?',
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: <Button variant='text'>Close</Button>,
      async onApply() {
        try {
          if (automations?.id) {
            await deleteScheduledTransfer({
              id: automations.id,
            }).unwrap();
            finAccountsRefetch();
            goNext('3');
          }
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    })
  }

  const fromFinAccountId = formik.getFieldProps('from').value;
  const toFinAccountId = formik.getFieldProps('to').value;

  const optionsFromAcc = createOptionsAutomationsAcc(
    unclosedFinancialAccounts,
    toFinAccountId,
    fromFinAccountId
  );

  const optionsToAcc = createOptionsAutomationsAcc(
    unclosedFinancialAccounts,
    fromFinAccountId,
    toFinAccountId
  );

  const disabedBtn =
    !formik.dirty ||
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  const renderSlotBtn = (
    <div className={styles.btnGroup}>
      <Button
        variant='outlined'
        className={styles.btnGroupCancel}
        onClick={handleDeleteAutomations}
        loading={isLoadingDeleteScheduledTransfer}
      >
        cancel automations
      </Button>
      <ButtonMUI
        variant='contained'
        className={styles.btnGroupSubmit}
        type='submit'
        disabled={disabedBtn || isLoadingDeleteScheduledTransfer}
      >
        save changes
      </ButtonMUI>
    </div>
  );

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        optionsFromAcc={optionsFromAcc}
        optionsToAcc={optionsToAcc}
        warningAmount={'Enter autoreplenishment amount'}
        slotBtn={renderSlotBtn}
        subTitle={
          'Funds will be transferred between own accounts when your balance falls below a set amount.'
        }
        toolTip={toolTipText}
      />
    </TransitionAnimation>
  );
};
