import { useSubUserMEControllerQuery } from "api/endpoints/subProfiles";
import { prepareUser } from "../../helper";
import PersonalInformation from "../PersonalInformation";
import { Skeleton } from "components/skeleton";

const SubUserInformation = () => {
  const { data: subUserInfo } = useSubUserMEControllerQuery({});
  
  if (!subUserInfo) {
    return <Skeleton width='100%' height='200px' />
  }

  return <PersonalInformation user={prepareUser(subUserInfo)} />;
};

export default SubUserInformation;
