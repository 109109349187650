import { Select } from 'shared/ui/inputs';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';
import { formatAccountName } from 'shared/lib/format';

interface Props {
  value: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
  hideAll?: boolean;
}

export const SelectAccount = ({ value, onChange, fullWidth = false, hideAll = false }: Props) => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
    
  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='55px'
    />
  ) : (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth={fullWidth}
    >
      {unclosedFinancialAccounts?.map((account) => (
        <MenuItem
          key={account.financialAccountId}
          value={account.financialAccountId}
        >
          <div className={styles.dropDown}>
            <div>
              {formatAccountName(account?.name)}{' '}
              {account?.accountNumber && `(****${account.accountNumber.slice(-4)})`}
            </div>
            {account?.availableCash && (
              <div>$ {helper.moneyFormat(account.availableCash?.value)}</div>
            )}
          </div>
        </MenuItem>
      ))}
     
      {hideAll &&
        <>
          <Divider className={styles.divider} />
          <MenuItem
            key={'All accounts'}
            value={'All accounts'}
          >
            All accounts
          </MenuItem>
        </>
      }
    </Select>
  );
};
