import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { replaceNumberWithComma } from 'shared/utils/price';
import { FundingApplicationDrawer } from './components/fundingApplicationDrawer';
import { useBoolean } from 'shared/hooks/useBoolean';
import styles from './style.module.scss';
import { Option } from 'api/endpoints/loans';

interface GotLoanOfferProps {
  options: Option[];
}

export const GotLoanOffer = ({ options }: GotLoanOfferProps) => {
  const isShowDrawerBool = useBoolean();

  const renderOptions = () => {
    return options.map((item, index) => (
      <Typography
        key={index}
        className={styles.boxOptionsLabel}
      >
        {'  '}•{'  '}
        {item.sweep}% for ${replaceNumberWithComma(item.amount)}
      </Typography>
    ));
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography className={styles.title}>
          You’ve got a funding offer!
        </Typography>
        <div className={styles.boxOptions}>
          <Typography className={styles.boxOptionsTitle}>
            You’ve been preselected for an exclusive business funding
            opportunity!
            <br />
            <br />
            Take advantage of competitive interest rates:
          </Typography>

          {renderOptions()}
        </div>

        <Button
          className={styles.btn}
          onClick={() => isShowDrawerBool.setTrue()}
        >
          Apply now
        </Button>
      </div>
      <FundingApplicationDrawer
        isShow={isShowDrawerBool.value}
        onClose={() => isShowDrawerBool.setFalse()}
      />
    </TransitionAnimation>
  );
};
