import { useMemo } from 'react'
import { useGetActiveUpdateFieldRequestsQuery } from 'api/endpoints/tickets'
import { useHolderV2ControllerQuery } from 'api/endpoints/account'
import helper from 'services/helper'

interface useFormattedHolderDataReturnType {
  formattedHolderData: IData | null
  formattedholderDataIsLoading: boolean
}

export interface IData {
  owners?: IOwner[]
  bussinessInfo: IInfo
  primaryAuthorizedInfo: IInfo
}

export interface IInfo {
  addressInfo: IAddressInfo
  emailInfo: IEmailInfo
  phoneInfo: IPhoneInfo
}

export interface IAddressInfo {
  countryCodeAlpha3: string
  streetAddress: string
  extendedAddress: string
  locality: string
  postalCode: string
  region: string
  statusUpdate: boolean
  updateTicket?:number
}

export interface IEmailInfo {
  email: string
  statusUpdate: boolean
  updateTicket?:number
}

export interface IPhoneInfo {
  phone: string
  statusUpdate: boolean
  updateTicket?:number
}

export interface IOwner {
  highNoteId: null
  businessAccountId: number
  givenName: string
  familyName: string
  middleName: string
  email: string
  dateOfBirth: Date
  percentageOwnership: number
  ssn: string
  extendedAddress: string
  state: number
  id: number
  createdDate: Date
  updatedDate: null
  ownerType: number
  info: IInfo
}

export const useFormattedHolderData = (): useFormattedHolderDataReturnType => {
  const { data: holder, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery()
  const { data: status, isLoading: isLoadingStatus } =
    useGetActiveUpdateFieldRequestsQuery()

  const value = useMemo(() => {
    if (holder && holder.businessAccountHolder) {
      const {
        email,
        phone,
        countryCodeAlpha3,
        extendedAddress,
        streetAddress,
        locality,
        postalCode,
        state,
        primaryAuthorizedStreetAddress,
        primaryAuthorizedLocality,
        primaryAuthorizedState,
        primaryAuthorizedPostalCode,
        primaryAuthorizedExtendedAddress,
        owners,
      } = holder.businessAccountHolder
      const updatedOwners = (owners) =>
        owners
        .slice()
        .sort((a, b) => a.ownerType - b.ownerType)
          .map(
            ({
              phoneNumber,
              countryCodeAlpha3,
              extendedAddress,
              streetAddress,
              locality,
              postalCode,
              ownerType,
              ...rest
            }) => {
              const ownerTypeString = `Owner${ownerType}`             
              return {
                ...rest,
                ownerType,
                info: {
                  addressInfo: {
                    extendedAddress,
                    countryCodeAlpha3,
                    streetAddress,
                    locality,
                    postalCode,
                    region: helper.getLabelForStateNumber(rest.state) || '',
                    statusUpdate: Boolean(
                      status?.[`${ownerTypeString}Address`]
                    ),
                    updateTicket:status?.[`${ownerTypeString}Address`]
                  },
                  emailInfo: {
                    email,
                    statusUpdate: Boolean(status?.[`${ownerTypeString}Email`]),
                    updateTicket: status?.[`${ownerTypeString}Email`]
                  },
                  phoneInfo: {
                    phone: phoneNumber,
                    statusUpdate: Boolean(status?.[`${ownerTypeString}Phone`]),
                    updateTicket: status?.[`${ownerTypeString}Phone`]
                  },
                },
              }
            }
          )


      const updatedOwnersArray = updatedOwners(owners)

      return {
        owners: updatedOwnersArray,
        bussinessInfo: {
          addressInfo: {
            countryCodeAlpha3,
            streetAddress,
            extendedAddress,
            locality,
            postalCode,
            region: helper.getLabelForStateNumber(state) || '',
            statusUpdate: Boolean(status?.Address),
            updateTicket: status?.Address
          },
          emailInfo: {
            email,
            statusUpdate: Boolean(status?.Email),
            updateTicket: status?.Email
          },
          phoneInfo: {
            phone,
            statusUpdate: Boolean(status?.Phone),
            updateTicket: status?.Phone
          },
        },
        primaryAuthorizedInfo: {
          addressInfo: {
            countryCodeAlpha3,
            streetAddress: primaryAuthorizedStreetAddress,
            extendedAddress: primaryAuthorizedExtendedAddress,
            locality: primaryAuthorizedLocality,
            postalCode: primaryAuthorizedPostalCode,
            region: helper.getLabelForStateNumber(primaryAuthorizedState) || '',
            statusUpdate: Boolean(status?.PrimAuthAddress),
            updateTicket: status?.PrimAuthAddress
          },
          emailInfo: {
            email,
            statusUpdate: Boolean(status?.PrimAuthEmail),
            updateTicket: status?.PrimAuthEmail
          },
          phoneInfo: {
            phone,
            statusUpdate: Boolean(status?.PrimAuthPhone),
            updateTicket: status?.PrimAuthPhone
          },
        },
      }
    } else {
      return null
    }
  }, [holder, status, isLoadingHolder, isLoadingStatus])

  return {
    formattedHolderData: value,
    formattedholderDataIsLoading: isLoadingHolder || isLoadingStatus,
  }
}
