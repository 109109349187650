import { Typography } from 'shared/ui/typography';
import { DollarCircleIcon } from 'shared/ui/icons';
import styles from './style.module.scss';

export const EmptyLoans = () => {
  return (
    <div className={styles.container}>
      <DollarCircleIcon />
      <Typography>No funding offers at this time.</Typography>
      <Typography className={styles.subTitle}>
        Currently, there are no funding offers available for you. Please check
        back later.
      </Typography>
    </div>
  );
};
