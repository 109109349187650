import { useLazyGetPinWheelTokenQuery } from "api/endpoints/pinWheel";
import { useCallback } from "react";

const usePinWheelToken = () => {  
    const [query, { data, error, isLoading }] = useLazyGetPinWheelTokenQuery();

    const getPinWheelToken = useCallback(async (financialAccountId: string) => {
        try {
            await query(financialAccountId).unwrap();
        } catch (err) {
            console.error('Failed to fetch pin token:', err);
        }
    }, [query]);

    return {
        token: data?.response?.data?.token ?? '',
        getPinWheelToken,
        error, 
        isLoading,
    };
}

export default usePinWheelToken;
