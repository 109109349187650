export enum ScheduleTransferType {
  IncommingAchTransfer = 'IncommingAchTransfer',
  OutcommingAchTrasfer = 'OutcommingAchTrasfer',
  InternalTransfer = 'InternalTransfer',
  TransferBetweenOwnAccounts = 'TransferBetweenOwnAccounts',
  AutoPopup = 'AutoPopup',
  AutoPopupFromOwnAccount = 'AutoPopupFromOwnAccount',
}

export enum FrequencyTitle {
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}
