import { FormControl, MenuItem, Select,Divider } from "@mui/material";
import styles from "./style.module.scss";
import helper from "services/helper";
import { FinancialAccount } from "api/endpoints/financialAccounts";
import { formatAccountName } from "shared/lib/format";

interface AccountsSelectProprs {
  value: string;
  onChange: (value) => void;
  accounts: FinancialAccount[];
  title?: string;
  height?: number;
  hideBalance?: boolean;
  isPartnersAccountsHidden?: boolean
  isDisabled?: boolean
  isShowAllAccount?: boolean
}

export const AccountsFromSelect = ({
  onChange,
  value,
  accounts,
  title,
  height,
  hideBalance,
  isPartnersAccountsHidden,
  isDisabled,
  isShowAllAccount
}: AccountsSelectProprs) => {


  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      <FormControl>
        <Select
          value={value}
          onChange={onChange}
          className={styles.select}
          sx={height ? { height: `${height}px` } : {}}
          disabled={isDisabled}
        >
         
          {(isPartnersAccountsHidden
              ? accounts.filter(ac => !ac.partnerName)
              : accounts
          ).map((account: FinancialAccount) => (
            <MenuItem
              key={account.financialAccountId}
              value={account?.financialAccountId}
            >
              <div className={styles.item}>
                <div>
                  {formatAccountName(account?.name)}
                  {account.accountNumber &&
                    ` (****${account.accountNumber.slice(-4)})`}
                </div>
                {!hideBalance && (
                  <div>
                    $ {helper.moneyFormat(account.availableCash?.value)}
                  </div>
                )}
              </div>
            </MenuItem>
          ))}
          {isShowAllAccount &&  <Divider className={styles.divider}/>}
           {isShowAllAccount && 
           <MenuItem
              key={'All accounts'}
               value={'All accounts'}
              className={styles.itemAllAcounts}
            >
             All accounts
            </MenuItem>}
        </Select>
      </FormControl>
    </div>
  );
};

