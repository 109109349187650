import { useEffect } from 'react';
import { useAddScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { useAppSelector, useAppDispatch } from 'shared/models';
import {
  selectAutomations,
  clearDataAutomations,
} from 'features/transfers/transfer-to-my-own-account/model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useSnackBar } from 'providers/snackBar.provider';
import moment from 'moment';
import { ScheduleTransferType } from 'entities/transfers';
import helper from 'services/helper';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import { useMfa } from 'providers/mfa/mfa';

export const AuntificatorAutomationsAdd = () => {
  const dispatch = useAppDispatch();
  const mfa = useMfa();
  const { goNext, goBack } = useDrawerStepperContext();
  const { setSnackBar } = useSnackBar();
  const { finAccountsRefetch } = useFinancialAccounts();
  const [addScheduledTransfer] =
    useAddScheduledTransferMutation();

  const automations = useAppSelector(selectAutomations);

  const addAutomations = async (secureOperationType: SecureOperationType, code: string) => {
    try {
      const { from, to, amount, memo } = automations;
      if (from && to && amount) {
        await addScheduledTransfer({
          fromFinancialAccountId: from,
          toFinancialAccountId: to,
          amount: Number(amount),
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          frequency: null,
          nextDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          transferType: ScheduleTransferType.AutoPopupFromOwnAccount,
          memo,
        }).unwrap();
        finAccountsRefetch();
        setSnackBar({
          type: 'success',
          message: 'Automation created successfully.',
          isShow: true,
        });
        dispatch(clearDataAutomations());
        mfa.close();
        goNext('3');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await addAutomations(secureOperationType, code);
      }
    });
  }, []);
  return (
    <Skeleton width="100%" height={100}/>
  );
};
