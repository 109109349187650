import { Typography } from "@mui/material";
import { SelectAccount } from "pages/accountComponent/Actions/Statements/components/selectAccount";
import { useState } from "react";
import styles from './styles.module.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import PinwheelModal from "@pinwheel/react-modal";
import usePinWheelToken from "api/hooksApi/usePeenWheelToken";
import { useBoolean } from "shared/hooks/useBoolean";
import { useSnackBar } from "providers/snackBar.provider";
import classNames from "classnames";

interface SwitchDirectDepositProps {
  financialAccountId?: string;
  onSuccess?: () => void;
  className?: string | string[];
}

const SwitchDirectDeposit = ({ financialAccountId, onSuccess, className }: SwitchDirectDepositProps) => {
  const { setSnackBar } = useSnackBar();
  const [currentAccountId, setCurrentAccountId] = useState<string>(financialAccountId ?? '');
  const { token, isLoading, getPinWheelToken } = usePinWheelToken();
  const isOpen = useBoolean();

  const handleClick = async () => {
    await getPinWheelToken(currentAccountId);
    isOpen.setTrue();
  };

  const handlChangeAccount = (id: string) => {
    setCurrentAccountId(id);
  };

  const handleSuccess = () => {
    setSnackBar({
      type: "success",
      message: 'Direct deposit successfully switched. You can change it anytime in the account settings.',
      isShow: true,
    });
    onSuccess?.();
  };

  const handleExit = () => {
    isOpen.setFalse();
  };

  const containerClassNames = classNames(styles.container, className);

  return (
    <div className={containerClassNames}>
      <Typography>Select the account for direct deposit</Typography>
      <SelectAccount
        value={currentAccountId}
        onChange={handlChangeAccount}
        hideAll={false}
      />
      <LoadingButton
        variant="contained"
        onClick={handleClick}
        disabled={!currentAccountId}
        loading={isLoading}
      >
        Next
      </LoadingButton>
     
      {isOpen.value && 
        <PinwheelModal 
          linkToken={token} 
          open={isOpen.value}
          onSuccess={handleSuccess}
          onExit={handleExit} 
        />
      }
    </div>
  );
}

export default SwitchDirectDeposit;
