import { useEffect } from 'react';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import helper from 'services/helper';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAddExternalPayeeMutation } from 'api/endpoints/fundsMovement';
import { selectNewExternalPayee, clearDataAch } from '../../model';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { SecureOperationType, ShortMessageType } from 'enums';
import { Skeleton } from 'components/skeleton';
import { useMfa } from 'providers/mfa/mfa';

export const AuntificatorExternalPayee = () => {
  const dispatch = useAppDispatch();
  const mfa = useMfa()
  const { setSnackBar } = useSnackBar();
  const { close, goBack } = useDrawerStepperContext();

  const [addExternalPayee] = useAddExternalPayeeMutation();

  const newExternalPayee = useAppSelector(selectNewExternalPayee);

  const handlerSubmit = async (secureOperationType: SecureOperationType, code: string) => { 
    try {
      if (code) {
        await addExternalPayee({
          routingNumber: newExternalPayee.routingNumber,
          accountNumber: newExternalPayee.accountNumber,
          name: newExternalPayee.name,
          bankAccountType: newExternalPayee.type.toUpperCase(),
          oneTimeCode: code,
          secureOperationType: secureOperationType,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message:
            'Success! Your payee has been added successfully! In less than a minute, you’ll find it listed in your payee list', //'Payee added successfully.',
          isShow: true,
        });

        dispatch(clearDataAch());
        mfa.close();
        close();
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  
  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      isOnlySmsFlow: true,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await handlerSubmit(secureOperationType, code);
      }
    })
  }, []);
  return (
    <TransitionAnimation>
       <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
