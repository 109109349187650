import AccountSettingsContainer from "../AccountSettingsContainer";
import SwitchDirectDepositComponent from 'shared/components/SwitchDirectDeposit';

const SwitchDirectDeposit = () => {
  return (
    <AccountSettingsContainer title="Switch Direct Deposit">
      <SwitchDirectDepositComponent />
    </AccountSettingsContainer>
  )
}

export default SwitchDirectDeposit;