import { Select, SelectItem } from 'shared/ui/inputs'
import { Divider, InputAdornment, ListSubheader } from '@mui/material'
import { SearchIcon } from 'shared/ui/icons'
import { Button } from 'shared/ui/buttons'
import { CurrencyInput } from 'shared/ui/inputs'
import AddIcon from '@mui/icons-material/Add'
import { Control, useController } from 'react-hook-form'
import { NeteviaTransfer } from 'features/transfers/transfer-to-netevia-customer/model'
import styles from './styles.module.scss'
import { TextInput } from 'shared/ui/inputs'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { DatePicker } from 'shared/ui/pickers'
import { CalendarOutlineIcon } from 'shared/ui/icons'
import { Typography } from 'shared/ui/typography'

type HandleSearchPayee = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => Promise<void>

interface MakeTransferFormProps {
  control: Control<NeteviaTransfer>
  optionsFromAcc: SelectItem[]
  handleSearchPayee: HandleSearchPayee
  optionsFrequency: SelectItem[]
  disabledDateAndFrequenc: boolean
  showRegisteredPayee: React.ReactNode
  showNotRegisteredPayee: () => React.ReactNode
}

export const MakeTransferForm = (props: MakeTransferFormProps) => {
  const { goNext } = useDrawerStepperContext()
  const {
    control,
    optionsFromAcc,
    handleSearchPayee,

    optionsFrequency,
    disabledDateAndFrequenc,
    showRegisteredPayee,
    showNotRegisteredPayee,
  } = props

  const fromFinancialAccount = useController({
    name: 'fromFinancialAccount',
    control,
  })
  const toCustomer = useController({ name: 'toCustomer', control })
  const amount = useController({ name: 'amount', control })
  const frequency = useController({ name: 'frequency', control })
  const dateTransfer = useController({ name: 'dateTransfer', control })
  const memo = useController({ name: 'memo', control });

  return (
    <div className={styles.root}>
      <Select
        label='From'
        options={optionsFromAcc}
        {...fromFinancialAccount.field}
      />
      <Select
        label='To'
        {...toCustomer.field}
      >
        <ListSubheader className={styles.box}>
          <TextInput
            fullWidth
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation()
              }
            }}
            onChange={handleSearchPayee}
            placeholder={'Search by acc number, phone or email'}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListSubheader>
        <Button
          className={styles.button}
          onClick={() => goNext('10')}
          startIcon={<AddIcon />}
          variant='text'
        >
          Add Payee
        </Button>
        {showNotRegisteredPayee()}
        <Divider className={styles.divider} />
        {showRegisteredPayee}
      </Select>
      <Divider />
      <CurrencyInput
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        error={amount.fieldState.error?.message}
        getCaretBoundary={() => [false]}
        {...amount.field}
      />
      <TextInput
        label="Memo"
        autoComplete="off"
        error={memo.fieldState.error?.message}
        {...memo.field}
      />
      <Typography className={styles.warningText}>
        Schedule and recurring transfers are only available for registered
        payees. Please add a payee before scheduling transfers.
      </Typography>
      <Select
        label='Frequency'
        options={optionsFrequency}
        disabled={disabledDateAndFrequenc}
        {...frequency.field}
      />
      <DatePicker
        label='Date'
        slots={{
          openPickerIcon: CalendarOutlineIcon,
        }}
        disabled={disabledDateAndFrequenc}
        disablePast
        {...dateTransfer.field}
      />
    </div>
  )
}
