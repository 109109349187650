import { FinancialAccount } from 'api/endpoints/financialAccounts'
import { PaymentCard } from 'api/endpoints/paymentCards'
import { Filters, Transaction, FilterItem } from 'api/endpoints/transaction'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { usePaymentCards } from 'api/hooksApi/usePaymentCards'
import moment from 'moment'
import React, {
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { filteredCards } from '../lib/filteredCards'
import { useFinancialAccountTransactionsServerControllerQuery } from 'api/endpoints/transaction'
import { EnumTransactionStatuses } from 'api/endpoints/transaction'
import { Expand ,FilterCards, TransactionApiParams} from '../types'
import { initialAccountTransactionFilter, initialTransactionApiParams } from '../contsants'
import { useCurrentUser } from 'providers/user.provider'
interface ContextProps {
  isFetchingaccoutnTransactions: boolean
  handleChangeSearchParams: (props: {
    cardAction?: string
    transaction?: string
    mode?: string
  }) => void
  setMessage: (value: string) => void
  message: string
  financialAccountId: string
  paymentCardsIsFetching: boolean
  approvedTransactions: Transaction[]
  pendingTransactions: Transaction[]
  allTransactions: Transaction[]
  unclosedFinancialAccounts: FinancialAccount[]
  financialAccounts: FinancialAccount[]
  financialAccount: FinancialAccount
  filteredCardList: PaymentCard[]
  paymentCards: PaymentCard[]
  transactionFilterData: Filters
  filterCards: FilterCards
  setFilterCards: (filter: FilterCards) => void
  expandFilter: Expand
  setExpandFilter: (value: Expand) => void
  transactionApiParams: TransactionApiParams
  setTransactionApiParams:(value: TransactionApiParams) => void
  searchTransactionByName: string
  setSearchTransactionByName: (value: string) => void
  transactionApiFilter:  Filters;
  setTransactionApiFilter: (value:  Filters | undefined | FilterItem) => void
  searchCardByFunctionality: string
  setSearchCardByFunctionality: (value: string) => void
}


const Context = React.createContext<ContextProps | undefined>(undefined)

export const AccountProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { accountId } = useParams()
  const {  subUserRights } = useCurrentUser();
  const { unclosedFinancialAccounts, financialAccounts } =
    useFinancialAccounts()

  const financialAccount = unclosedFinancialAccounts?.find(
      (i) => i.financialAccountId === accountId
  )
  const financialAccountId = accountId

  const [transactionApiParams, setTransactionApiParams] = useState<TransactionApiParams>(
    initialTransactionApiParams
  )
  
  const [transactionApiFilter, setTransactionApiFilter] = useState<Filters>(initialAccountTransactionFilter)

  const [searchTransactionByName, setSearchTransactionByName] = useState<string>('')

  const [searchCardByFunctionality, setSearchCardByFunctionality] = useState<string>('')

  const {
    data: accoutnTransactions,
    isFetching: isFetchingaccoutnTransactions,
  } = useFinancialAccountTransactionsServerControllerQuery({
    financialAccountId: financialAccountId!,
    dateFrom:
      moment(transactionApiParams.dateFrom.toDate()).startOf('day')
      .utc()
      .format(),
    dateTo:
      moment(transactionApiParams.dateTo.toDate())
      .endOf('day')
      .utc()
      .format(),
     filter:  transactionApiFilter,
     amountFilter:{
      minValue:  !!parseFloat(transactionApiParams.amountFrom) ? parseFloat(transactionApiParams.amountFrom) : null,
      maxValue:  !!parseFloat(transactionApiParams.amountTo) ? parseFloat(transactionApiParams.amountTo) : null,
     }
  },{skip: !financialAccountId})


  const [expandFilter, setExpandFilter] = useState<Expand>({
    expandByMoneyMovement: true,
    expandByTransactionType: true,
    expandByTransferType: false,
    expandByMerchantType: false,
  });
  const [, setSearchParams] = useSearchParams()

  const [message, setMessage] = useState<string>('')

  const hasLimitedCardAccess =
  subUserRights({
    viewAllCardsAndFinancialAccounts: false,
    mainAccess: false,
  },{ matchAll: true }
);

  const [filterCards, setFilterCards] = useState<FilterCards>(
    hasLimitedCardAccess ? FilterCards.MyCards :  FilterCards.AllAvailableCards
  )


  const { paymentCards, paymentCardsIsFetching } =
    usePaymentCards()
  const handleChangeSearchParams = (params) => {
    setSearchParams(params)
  }

  const cardList = paymentCards
    .filter(
      (card) =>
        card.financialAccounts
          ?.map((account) => account.id)
          .includes(financialAccountId!)
    )
    .sort((cardA, cardB) => {
      const statusOrder = {
        ACTIVATION_REQUIRED: 1,
        ACTIVE: 2,
        SUSPENDED: 3,
        CLOSED: 4,
      }

      const statusA = cardA.status || ''
      const statusB = cardB.status || ''

      return statusOrder[statusA] - statusOrder[statusB]
    })
 


  const value = useMemo((): ContextProps => {

    //Transactions
    const filterByNameTransaction = (transactions: Transaction[]) => {
      return transactions.filter((item) =>
          item.name.toLowerCase().includes(searchTransactionByName.toLowerCase())
      );
    };

    const filteredTransactions = filterByNameTransaction(accoutnTransactions?.data || []);

    const pendingTransactions = filteredTransactions.filter(
      (t) => t.status === EnumTransactionStatuses.PENDING
    );

    const approvedTransactions = filteredTransactions.filter(
      (t) => t.status !== EnumTransactionStatuses.PENDING
    );

    const allTransactions = [...pendingTransactions, ...approvedTransactions];

    //Card
    const filterCardsByFunctionality = (cards:PaymentCard[]) => 
      cards.filter(card =>
        [
          card.last4,
          card.cardholder,
          card.subProfile,
          card.department,
          card.cardName
        ].some(field => field?.toLowerCase().includes(searchCardByFunctionality.toLowerCase())) 
        ||
        card.financialAccounts.some(account =>
          account?.name?.toLowerCase().includes(searchCardByFunctionality.toLowerCase())
        )
    );

    const filteredCardList = filterCardsByFunctionality(filteredCards(cardList, filterCards))

    return {
      pendingTransactions: pendingTransactions,
      approvedTransactions: approvedTransactions,
      isFetchingaccoutnTransactions,
      financialAccountId,
      filteredCardList,
      paymentCardsIsFetching: paymentCardsIsFetching && filteredCardList.length === 0,
      unclosedFinancialAccounts,
      financialAccount,
      allTransactions,
      paymentCards,
      transactionFilterData : accoutnTransactions?.filter,
      handleChangeSearchParams,
      setMessage,
      message,
      financialAccounts,
      filterCards,
      setFilterCards,
      expandFilter,
      setExpandFilter,
      transactionApiParams,
      setTransactionApiParams,
      initialTransactionApiParams,
      searchTransactionByName,
      setSearchTransactionByName,
      transactionApiFilter,
      setTransactionApiFilter,
      searchCardByFunctionality,
      setSearchCardByFunctionality

    } as unknown as ContextProps;
  }, [
    isFetchingaccoutnTransactions,
    financialAccountId,
    paymentCards,
    paymentCardsIsFetching,
    unclosedFinancialAccounts,
    financialAccount,
    filterCards,
    accoutnTransactions,
    expandFilter,
    searchTransactionByName,
    searchCardByFunctionality
  ])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useAccount = () => React.useContext(Context) as ContextProps
