import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import currencyIcon from "assets/svg/currency-new-blue.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useRewards } from "pages/reward/provider/useRewards";
import { Skeleton } from "components/skeleton";
import { useSearchParams } from "react-router-dom";
import DrawerModal from "shared/components/drawerModal";
import GroupingInputRedeemPoints from "./componentns/groupingInputRedeemPoints";
import { RewardMode } from "pages/reward/types";

export const RedeemPoints = () => {
  const { t } = useTranslationProvider();
  const { isDataLoading } = useRewards();
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode") || "";

  const handleClickButton = (mode: string) => {
    setSearchParams({ mode });
  };
  return isDataLoading ? (
    <Skeleton width="100%" height="194px" />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.boxTitle}>
        <img
          className={styles.boxTitle_img}
          src={currencyIcon}
          alt="currencyIcon"
        />
        <span className={styles.boxTitle_text}>
          {t("Rewards.Redeem points", {
            defaultValue: "Redeem points",
          })}
        </span>
      </div>
      <p className={styles.subTitle}>
        {t("Rewards.Convert your points", {
          defaultValue:
            "Convert your points in dollars and enjoy the benefits instantly",
        })}
      </p>
      <div className={styles.boxButton}>
        <Button
          onClick={() => handleClickButton(RewardMode.RedeemPoints)}
          className={styles.button}
        >
          {t("Rewards.redeem", {
            defaultValue: "redeem",
          })}
        </Button>
      </div>
      <DrawerModal
        isShow={mode === RewardMode.RedeemPoints}
        onClose={() => setSearchParams({ })}
        titleText={t("Rewards.Redeem points", {
          defaultValue: "Redeem points",
        })}
      >
        <GroupingInputRedeemPoints />
      </DrawerModal>
    </div>
  );
};
