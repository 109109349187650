import { FC } from 'react';
import isEqual from 'lodash/isEqual';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MenuItem } from '@mui/material';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Button } from 'shared/ui/buttons';
import { TextInput, Select } from 'shared/ui/inputs';
import MyAutocomplete_v3 from 'pages/settings/autocomplete/MyAutocomplete_v3';
import { states } from 'constants/data';
import styles from '../style.module.scss';

const schemaAddress = yup.object({
  streetAddress: yup.string().required('Address line 1 is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup
    .string()
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits')
    .required('Zip code is required'),
});

interface EditBillingAddressProps {
  streetAddress: string;
  extendedAddress?: string;
  city: string;
  state: string;
  zip: string;
  handleSubmit: (form: {
    streetAddress: string;
    extendedAddress?: string;
    city: string;
    zip: string;
  }) => Promise<void>;
  isLoading: boolean;
}

const EditBillingAddress: FC<EditBillingAddressProps> = ({
  streetAddress,
  extendedAddress,
  city,
  state,
  zip,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslationProvider();

  const formik = useFormik({
    initialValues: {
      streetAddress: streetAddress,
      extendedAddress: extendedAddress,
      state: state,
      city: city,
      zip: zip,
    },
    validationSchema: schemaAddress,
    onSubmit: (form) => {
      handleSubmit(form);
    },
  });

  const handleSearchAddressChange = (adressFull) => {
    formik.setValues({
      streetAddress: adressFull.address1,
      extendedAddress: '',
      state: adressFull.adminArea1Short,
      city: adressFull.locality,
      zip: adressFull.postalCode
    })
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapper}
    >
      <MyAutocomplete_v3
        label={t("Settings.Search address", {
          defaultValue: "Search address",
        })}
        setAddressFull={handleSearchAddressChange} 
      />
      <TextInput
        label={t('Settings.Address Line', {
          defaultValue: 'Address Line',
        })}
        {...formik.getFieldProps('streetAddress')}
        error={formik.errors.streetAddress}
      />
      <TextInput
        label={t('Settings.Address Line 2 (optional)', {
          defaultValue: 'Address Line 2 (optional)',
        })}
        {...formik.getFieldProps('extendedAddress')}
      />
      <TextInput
        label={t('Settings.City', {
          defaultValue: 'City',
        })}
        {...formik.getFieldProps('city')}
        error={formik.errors.city}
      />
      <Select
        label={t('Settings.State', {
          defaultValue: 'State',
        })}
        {...formik.getFieldProps('state')}
      >
        {states.map((i) => {
          return (
            <MenuItem
              key={i.value}
              value={i.label}
            >
              {i.label}
            </MenuItem>
          );
        })}
      </Select>
      <TextInput
        label='Zip Code'
        {...formik.getFieldProps('zip')}
        error={formik.errors.zip}
      />
      <Button
        type='submit'
        loading={isLoading}
        variant='contained'
        className={styles.button}
        disabled={
          !formik.isValid || isEqual(formik.initialValues, formik.values)
        }
      >
        Save Changes
      </Button>
    </form>
  );
};

export default EditBillingAddress;
