import { FC } from 'react';
import suitcaseIcon from 'assets/svg/suitcase-blue.svg';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';

interface AcoountItemProps {
  id: string;
  name: string;
  last4: string;
  avaibleBalance: string;
  accountType: string;
  handleNavigate: (id: string) => void;
}
const AcoountItem: FC<AcoountItemProps> = ({
  id,
  name,
  last4,
  avaibleBalance,
  accountType,
  handleNavigate,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up('sm'));
  return (
    <Wrapper onClick={() => handleNavigate(id)}>
      <div>
        <img src={suitcaseIcon} />
        <Box>
          <Text>{name}</Text>
          <DescriptionText>{last4}</DescriptionText>
        </Box>
      </div>

      {matches && (
        <Box>
          <DescriptionText>Account Type</DescriptionText>
          <Text>{accountType}</Text>
        </Box>
      )}
      <Box>
        {matches && <DescriptionText>Available Balance</DescriptionText>}
        <BalanceText>{avaibleBalance}</BalanceText>
      </Box>
      {matches && (
        <ButtonNavigate onClick={() => handleNavigate(id)}>
          View Details
        </ButtonNavigate>
      )}
    </Wrapper>
  );
};
export default AcoountItem;

const Wrapper = styled('div')`
  cursor: pointer;
  padding: 8px 24px;
  background-color: transparent;
  transition: 0.2s background-color;

  &:hover {
    background-color: var(--color-button-hover);
  }

  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > *:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > img {
        margin-right: 16px;
        width: 40px;
        height: 40px;
      }
    }
  `}
`;
const Box = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 350px;

    @media screen and (max-width: 1024px) {
      width: 180px;
    }

    ${theme.breakpoints.down('sm')} {
      width: fit-content;
      justify-content: center;
    }
  `}
`;

const DescriptionText = styled(Typography)`
  color: #949494;
  font-family: 'Noto Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
`;
const BalanceText = styled(Typography)`
  ${({ theme }) => css`
    font-family: 'Noto Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 150%;
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 0.15px;
    }
  `}
`;
const Text = styled(Typography)`
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

const ButtonNavigate = styled(Button)`
  ${({ theme }) => css`
    white-space: nowrap;
    color: ${theme.colors.primaryBlue};
    text-transform: none;
    text-align: center;
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  `}
`;
