import { useCurrentUser } from "providers/user.provider";
import ConsumerChangePersonalInformation from "./components/ConsumerChangePersonalInformation";
import SubUserChangePersonalInformation from "./components/SubUserChangePersonalInformation";

const WrappedChangePersonalInformation = () => {
  const { isConsumer } = useCurrentUser();

  if (isConsumer) {
    return <ConsumerChangePersonalInformation />
  }

  return <SubUserChangePersonalInformation />
}

export default WrappedChangePersonalInformation;


