import { FC } from "react";
import styles from "./style.module.scss";
import Chevron from "../../../../assets/svg/chevron-right5.svg";
import ListItemButton from "@mui/material/ListItemButton";
import { SectionPerson , EditType} from "pages/settings/accountSettings/types";
import { ClockRestoreRewerseIcon } from "shared/ui/icons";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "routes";
import { SettingsRouteSubRoute } from "routes";
import { SupportModeEnum } from "pages/support/types/enums";
import cn from 'classnames'
import { Button } from "shared/ui/buttons";

interface CompanyInfoButtonProps {
  title: string;
  img?: string;
  badge?: string;
  updateItemBadge?: boolean;
  updateTicket?:number
  description?: string | React.ReactNode;
  handleNavigate: (
    mode: string,
    type: EditType,
    section?: SectionPerson,
    titleForm?: string,
    id?: string
  ) => void;
  mode: string;
  titleForm: string;
  id?: string | undefined;
  section?: SectionPerson;
  type: EditType;
  customBadge?: string;
}
const CompanyInfoButton: FC<CompanyInfoButtonProps> = ({
  title,
  img,
  badge,
  description,
  handleNavigate,
  mode,
  id,
  titleForm,
  section,
  type,
  updateItemBadge,
  updateTicket,
  customBadge,
}) => {
  const navigate = useNavigate()

const handleNavigateToSupport = (ticketId) => {
  if(ticketId){
    navigate(appRoutes.settings(SettingsRouteSubRoute.support,{
      pageMode:SupportModeEnum.ChatSupport,
      title:'Edit company information',
       ticket: ticketId
      }));
  }
}

  return (
    <ListItemButton
      onClick={updateItemBadge ? undefined : () => handleNavigate(mode, type, section, titleForm, id)}
      classes={{root: updateItemBadge  ? cn(styles.update,styles.wrapper) :  styles.wrapper}}
    >
      <div className={styles.wrapper_boxContent}>
        <img src={img} className={styles.wrapper_boxContent_img} />
        <div className={styles.boxInfo}>
          <p className={styles.boxInfo_title}>{title}</p>
          {description && (
            <div className={styles.description}>
              <p className={styles.description_text}>{description}</p>
            </div>
          )}
          <div className={styles.groupBadge}>
            {customBadge && (
              <div className={styles.updateBadge}>
                <span>{customBadge}</span>
              </div>
            )}
            {badge && (
              <div className={styles.badge}>
                <span className={styles.badge_label}>{badge}</span>
              </div>
            )}
            {updateItemBadge && (
              <Button variant="text"className={styles.updateBadge} onClick={() => handleNavigateToSupport(updateTicket)}>
                <ClockRestoreRewerseIcon/>
                <span>Update requested</span>
              </Button>
            )}
          </div>
        </div>
      </div>
      {!updateItemBadge && <img className={styles.box_img} src={Chevron} />}
    </ListItemButton>
  );
};

export default CompanyInfoButton;
