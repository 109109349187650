import styles from './style.module.scss';
import { Switch } from '../../../../../../../../shared/ui/inputs';
import { InfoIcon } from '../../../../../../../../shared/ui/icons';
import TooltipChildren from '../../../../../../../../shared/components/toolTip/toolTipChildren';
import {
  NotificationTypeEnum,
  useNotificationsGetSettingTypesQuery,
  useNotificationsUpdateSettingTypesMutation,
} from '../../../../../../../../api/endpoints/notifications';
import { Skeleton } from '../../../../../../../../components/skeleton';
import { useDrawerBehavior } from '../../../../../../../../providers/drawer-behavior';
import { useSnackBar } from '../../../../../../../../providers/snackBar.provider';
import helper from '../../../../../../../../services/helper';

const items = [
  {
    title: 'Deposit',
    type: NotificationTypeEnum.deposit,
    tooltipText:
      'Whenever a deposit is made to your financial account or card, you will receive a notification about the transaction.',
  },
  {
    title: 'Withdrawals',
    type: NotificationTypeEnum.withdrawals,
    tooltipText:
      'Whenever a withdrawal is made from your financial account or card, you will receive a notification about the transaction.',
  },
];

export const BasicSettings = () => {
  const { data } = useNotificationsGetSettingTypesQuery();
  const [updateNotificationsRule] =
    useNotificationsUpdateSettingTypesMutation();
  const drawerBehavior = useDrawerBehavior();
  const { setSnackBar } = useSnackBar();

  const handleChange = async (type: NotificationTypeEnum, checked: boolean) => {
    if (!data) {
      return;
    }
    try {
      drawerBehavior.setLockForCloseStatus(true);
      await updateNotificationsRule(
        data.map((item) => ({
          ...item,
          emailEnabled:
            type === item.notificationType ? checked : item.emailEnabled,
        }))
      );
      setSnackBar({
        isShow: true,
        type: 'success',
        message: 'Notification rule updated',
      });
    } catch (err: any) {
      setSnackBar({
        isShow: true,
        type: 'error',
        message: helper.formatErrors(err?.date),
      });
    } finally {
      drawerBehavior.setLockForCloseStatus(false);
    }
  };

  if (!data) {
    return (
      <Skeleton
        width='100%'
        height='50px'
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {items.map(({ title, tooltipText, type }) => {
        const dataItem = data.find((item) => item.notificationType === type);
        if (!dataItem) {
          return null;
        }
        const checked = dataItem.emailEnabled;
        return (
          <div
            className={styles.item}
            key={type}
          >
            <div className={styles.itemSection}>
              <div className={styles.titleWrapper}>
                {title}
                <TooltipChildren description={tooltipText}>
                  <div>
                    <InfoIcon />
                  </div>
                </TooltipChildren>
              </div>
            </div>
            <div className={styles.itemSection}>
              <div className={styles.itemSubTitle}>Email notifications</div>
              <Switch
                disabled={drawerBehavior.isCloseDisabled}
                checked={checked}
                onChange={(_: unknown, checked) => handleChange(type, checked)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
