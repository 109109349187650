import { Rights } from 'api/endpoints/user';
import { AccessRightsForm } from '../types';

export const hasSubUserAccessChanged = (
  current: AccessRightsForm,
  initial: Rights
) => {
  const keysToCheck = [
    'openBankingCard',
    'openFinancialAccount',
    'viewAllCardsAndFinancialAccounts',
    'makeTransfers',
    'mainAccess',
  ];

  return keysToCheck.some((key) => current[key] !== initial[key]);
};
