import styles from "./style.module.scss";
import React, { useState, ChangeEvent } from "react";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import { useSearchParams } from "react-router-dom";
import moment from "moment/moment";
import {
  useUploadTicketMessageControllerMutation,
  useGetTicketControllerQuery,
  useUploadTicketAttachmentControllerMutation,
} from "../../../api/endpoints/tickets";
import BeatLoader from "react-spinners/BeatLoader";
import { convertFileToBase64 } from "shared/utils/convertFileToBase64";
import DeleteImg from "../../../assets/svg/x-circle.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import HeaderChat from "./components/headerChat";
import InputChat from "./components/inputChat";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";
import { Skeleton } from "components/skeleton";

const ChatWithSupport = () => {
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar()
  const { history, historyIsLoading } = useSupportHistory();
  const [searchParams, setSearchParams] = useSearchParams();
  const ticketId = searchParams.get("ticket");
  const item = history?.find((i) => i.ticketId == Number(ticketId));
  const formattedDate = moment(item?.createdDate).format(
    " hh:mm A, MM/DD/YYYY"
  );
  const { data: dataTickets, isFetching } = useGetTicketControllerQuery({
    ticketId,
  });
  const [uploadTicketMessageMutation, { isLoading: isLoadingTicketMessage }] =
    useUploadTicketMessageControllerMutation();
  const [uploadTicketFiles] = useUploadTicketAttachmentControllerMutation();
  const [message, setMessage] = useState<string>("");
  const [files, setFiles] = useState<FileList | undefined>();

  const handleSendMessage = async () => {
    try {
      if (message) {
        uploadTicketMessageMutation({
          ticketId: Number(ticketId),
          text: message,
        }).unwrap();
        setMessage("");
        if (files?.length) {
          const fileList = Array.from(files);
          await Promise.all([
            fileList.map(async (file, i) => {
              const fileBase64 = (await convertFileToBase64(file)) as string;
              const formattedFileBase64 = fileBase64?.substring(
                fileBase64.indexOf(",") + 1
              );

              await uploadTicketFiles({
                ticketId: Number(ticketId),
                fileName: file.name,
                contentType: file.type,
                fileBase64: formattedFileBase64,
              }).unwrap();
            }),
          ]);
          setFiles(undefined);
        }
      } else {
        setSnackBar({
          type: 'error',
          message:   t("Support.Ask a question", {
            defaultValue: "Ask a question",
          }),
          isShow: true,
        })
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files;
      setFiles(files);
    }
  };

  const removeUploadImg = (index: number) => {
    if (files) {
      const updatedFileList = Array.from(files);
      updatedFileList.splice(index, 1);
      const newFileList = new DataTransfer();
      updatedFileList.forEach((file) => {
        newFileList.items.add(file);
      });

      setFiles(newFileList.files);
    }
  };

  const renderUploadImg = () => {
    if (files) {
      const fileList = Array.from(files);
      return fileList.map((file, i) => (
        <div key={i} className="flex flex-row items-center gap-[2px]">
          <p>{file.name}</p>
          <img
            src={DeleteImg}
            width={15}
            height={15}
            onClick={() => removeUploadImg(i)}
          />
        </div>
      ));
    } else {
      return <></>;
    }
  };

  return ( historyIsLoading ?
  <Skeleton width="100%" height="110px" />
  :
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.chat}>
          <div>
            <HeaderChat
              date={formattedDate}
              ticketId={item?.ticketId}
              status={item?.status}
            />
            <div className={styles.chat_content}>
              <p className={styles.chat_content_yourQuestion}>
                {t("Support.Your question", {
                  defaultValue: "Your question",
                })}
              </p>

              <div className={styles.ticket}>
                <p className={styles.ticket_text}>{item?.text}</p>
                <p className={styles.ticket_date}>{formattedDate}</p>
              </div>
              <div className={styles.ticket_imgConatiner}>
                {dataTickets?.ticketAttachments.map((attachment, index) => (
                  <p key={index}>{attachment.fileName}</p>
                ))}
              </div>

              {isFetching && <BeatLoader className={styles.loader} />}

              {dataTickets?.ticketMessages.map((message, index) => (
                <React.Fragment key={index}>
                  <p className={styles.chat_content_yourQuestion}>
                    {t("Support.Support", {
                      defaultValue: "Support",
                    })}
                  </p>
                  <div key={index} className={styles.ticket}>
                    <div>
                      <p className={styles.ticket_text}>{message.text}</p>
                      <p></p>
                    </div>

                    <p className={styles.ticket_date}>
                      {moment(message.createdDate).format(
                        " hh:mm A, MM/DD/YYYY"
                      )}
                    </p>
                  </div>
                </React.Fragment>
              ))}
              <p className={styles.chat_content_signature}>
                {t(
                  "Support.Your request has been sent to our consumer support service",
                  {
                    defaultValue:
                      "Your request has been sent to our consumer support service. We will get back to you soon!",
                  }
                )}
              </p>
              {renderUploadImg()}
            </div>
          </div>
          <InputChat
            message={message}
            setMessage={setMessage}
            handleFileChange={handleFileChange}
            handleSendMessage={handleSendMessage}
            isLoadingMessage={isLoadingTicketMessage}
          />
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default ChatWithSupport;
