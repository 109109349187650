import _ from "lodash";
import moment from "moment";
import MerchantIcon from "../assets/svg/Merchant-icon.svg";
import BankIcon from "../assets/svg/Bank-Icon.svg";
import TransferAccount from "../assets/svg/transfer-account.svg";
import { states } from "constants/data";

export default {
  formatCardExpDate: (dateString) => {
    return moment(dateString).format('MM/YY');
  },
  processString: (inputString) => {
    const parts = inputString.split("/");

    const lastPart = parts[parts.length - 1].split("-");

    const capitalizedWords = lastPart.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    const result = capitalizedWords.join(" ");

    return result;
  },
  sleep: (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
  isEmail: (email) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  validateUsername: (username) => {
    let re = /^(?=[a-zA-Z0-9._]{6,10}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    return re.test(String(username).toLowerCase());
  },
  validateName: (name) => {
    let re = /^[a-zA-Z ]+$/;
    return re.test(String(name));
  },
  validateIntNumbers: (name) => {
    let re = /^[1-9]\d*$/;
    return re.test(String(name));
  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  moneyFormat(cents) {
    if (!cents && cents !== 0) return "";
    let p = (cents / 100).toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
        }, "") +
      "." +
      p[1]
    );
  },
  currencyCode(currency) {
    switch (currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
    }
  },
  moneyFormatWithoutCents(amount) {
    if (!amount) return amount;
    let p = amount.replace(",", "");
    return p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  formatErrors(data) {
    if (!data)
      return "Something went wrong! Please try again later or contact our support";
    let errors =
      typeof data === "object" && data !== null
        ? Object.values(data).join(`\n`)
        : data;
    return errors;
  },
  formatCardNumber(number) {
    const cleanedNumber = number?.replace(/\D/g, "");

    const blocks = cleanedNumber?.match(/.{1,4}/g) || [];

    return blocks.map((block, index) => (
      <div key={index}>
        {block.split("").map((digit, i) => (
          <span key={i} className="inline-block">
            {digit}
          </span>
        ))}
      </div>
    ));
  },
  formatPhone(phoneNumber) {
    if (!phoneNumber) return;
    var formatted = phoneNumber.replace(
      /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
      function (_, p1, p2, p3, p4) {
        let output = "";
        if (p1) output = `${p1}`;
        if (p2) output += `${p2}`;
        if (p3) output += ` ${p3}`;
        if (p4) output += ` ${p4}`;
        return output;
      }
    );
    return formatted;
  },
  formatAddress(address) {
    let extendedAddress = '';
    if (!address) return;

    if (address.extendedAddress) {
      extendedAddress = `${address.extendedAddress},`
    }
    return `${address.streetAddress}, ${extendedAddress} \n${address.locality}, ${address.region} ${address.postalCode}`;
  },
  enumerateDaysBetweenDates: (startDate, endDate) => {
    var dates: Date[] = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }

    return [startDate, ...dates, endDate];
  },

  formatTransaction(item, dateFormat = "DD MMM hh:mm A") {
    let type = "";
    let symbol = "";
    let amount = "";
    let date = "";
    let name = "";
    let description = "";
    let status = "";
    let statusDescription = "";
    let icon = MerchantIcon;
    let currencyCode = "";
    let cardLast4 = item?.paymentCard?.last4;
    let cardId = item?.paymentCard?.id;
    let transferFrom: string | null = null;
    let transferTo: string | null = null;
    let lastEvent = item?.lastEvent;
    let amountObj;
    let transactionId = item?.transaction?.id || item.id;

    if (
      [
        "AuthorizationAndClearEvent",
        "VerificationEvent",
        "ClearingEvent",
        "AuthorizationEvent",
        "ReversalEvent",
        "InterFinancialAccountTransfer",
        "DebitLedgerEntry",
        "CreditLedgerEntry",
      ].includes(item?.typename)
    ) {
      let amountData = item?.approvedAmount;
      type =
        item?.merchantDetails?.category === "AUTOMATED_CASH_DISBURSE"
          ? "ATM withdrawal"
          : "Payment";
      symbol = "-";
      date = moment(item.createdAt).format(dateFormat);
      name = item?.merchantDetails?.name;
      description = item.marchantCategoryTitle;
      statusDescription = item.responseDescription;
      status = item.responseCode;

      icon =
        item?.merchantDetails?.category === "AUTOMATED_CASH_DISBURSE"
          ? BankIcon
          : MerchantIcon;
      if (item?.typename === "VerificationEvent") {
        type = "Verification";
      }

      if (item?.typename === "AuthorizationEvent") {
        type = "Authorization";
        amountData = item.requestedAmount;
      }

      if (item?.typename === "ReversalEvent") {
        type = "Reversal";
      }

      if (item?.transaction?.typename === "CreditTransaction") {
        type = "Refund";
        amountData = item?.approvedAmount;
        symbol = "+";
      }

      if (item?.typename === "ReversalEvent") {
        type = "Reversal";
        symbol = "+";
      }

      if (item?.typename === "DebitLedgerEntry") {
        type = item?.isBetweenOwnAccounts
          ? "Transfer between own accounts"
          : "Netevia Transfer";
        symbol = "+";
        amountData = item?.amount;
        status = item.transferStatus.status;
        statusDescription = item?.transferStatus?.statusReasonCode;
        name = item?.isBetweenOwnAccounts
          ? item?.fromFinancialAccount?.name
          : item?.companyName || "";
        icon = icon = item?.isBetweenOwnAccounts
          ? TransferAccount
          : MerchantIcon;
        transferFrom = `${
          item?.isBetweenOwnAccounts
            ? item?.fromFinancialAccount?.name
            : item?.companyName
        } ${
          item.fromFinancialAccount.last4 && item.isBetweenOwnAccounts
            ? `****${item.fromFinancialAccount.last4}`
            : ""
        }`;
        transferTo = null;
      }

      if (item?.typename === "CreditLedgerEntry") {
        type = item?.isBetweenOwnAccounts
          ? "Transfer between own accounts"
          : "Netevia Transfer";
        symbol = "-";
        amountData = item?.amount;
        status = item.transferStatus.status;
        statusDescription = item?.transferStatus?.statusReasonCode;
        name = item?.isBetweenOwnAccounts
          ? item?.toFinancialAccount?.name
          : item?.companyName || "";
        icon = icon = item?.isBetweenOwnAccounts
          ? TransferAccount
          : MerchantIcon;
        transferTo = `${
          item?.isBetweenOwnAccounts
            ? item?.toFinancialAccount?.name
            : item.companyName
        } ${
          item.toFinancialAccount?.last4 && item.isBetweenOwnAccounts
            ? `****${item?.toFinancialAccount?.last4}`
            : ""
        }`;
        transferFrom = null;
      }

      if (item?.name === "Rewards deposit") {
        type = item?.name;
        symbol = "+";
        amountData = item?.amount;
        status = item.transferStatus.status;
        statusDescription = item?.transferStatus?.statusReasonCode;
        name = "Rewards";
        transferTo = null;
        transferFrom = null;
      }

      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      ///  amount = `${symbol}$${this.moneyFormat(amountData?.value)}`;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      currencyCode = amountData?.currencyCode;
      amountObj = amountData;
    }

    if (
      item?.__typename === "IntegratorInitiatedFundsWithdrawalACHTransferEvent"
    ) {
      type = "Transfer";
      symbol = "-";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.toFinancialAccount.name;
      description = item.companyEntryDescription;
      status = item.status.status;
      icon = TransferAccount;
    }

    if (item?.__typename === "Transfer") {
      type = "Deposit";
      symbol = "+";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = "";
      description = "";
      status = item.transferStatus;
      statusDescription = item?.transferStatus?.statusReasonCode;
      icon = TransferAccount;
    }

    if (item?.__typename === "CreditFundsACHTransferEvent") {
      type = "Deposit";
      symbol = "+";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.companyName;
      description = item.companyEntryDescription;
      status = item?.status?.transferStatus;
      statusDescription = item?.transferStatus?.statusReasonCode;
      icon = TransferAccount;
    }

    if (
      item?.__typename === "IntegratorInitiatedFundsDepositACHTransferEvent"
    ) {
      type = "Deposit";
      symbol = "+";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.companyName;
      description = item.companyEntryDescription;
      status = item?.status?.status;
      icon = TransferAccount;
    }

    if (item?.__typename === "FinancialAccountActivity") {
      const lastEvent =
        item.transactionSource?.transactionEvents?.length &&
        item.transactionSource?.transactionEvents[0];
      let amountData = lastEvent
        ? lastEvent.approvedAmount
        : item.pendingAmount;
      type =
        item?.merchantDetails?.category === "AUTOMATED_CASH_DISBURSE"
          ? "ATM withdrawal"
          : "Payment";
      symbol = "-";
      date = moment(item.createdAt).format(dateFormat);
      name = item?.merchantDetails?.name;
      description = item.marchantCategoryTitle;
      status = item.responseCode;
      statusDescription = item?.lastEvent?.responseDescription;
      icon =
        item?.merchantDetails?.category === "AUTOMATED_CASH_DISBURSE"
          ? BankIcon
          : MerchantIcon;

      if (lastEvent?.typename === "VerificationEvent") {
        type = "Verification";
      }

      if (lastEvent?.typename === "AuthorizationEvent") {
        type = "Authorization";
      }

      if (lastEvent?.typename === "ReversalEvent") {
        type = "Reversal";
      }

      if (
        [
          "DO_NOT_HONOR",
          "INCORRECT_PIN",
          "UNACTIVATED_CARD",
          "INSUFFICIENT_FUNDS",
        ].includes(lastEvent?.responseCode)
      ) {
        amountData = lastEvent?.requestedAmount;
      }

      cardLast4 = lastEvent?.paymentCard?.last4;
      cardId = lastEvent?.paymentCard?.id;

      if (
        item?.transactionSource?.typename === "CreditTransaction" ||
        item?.transaction?.__typename === "CreditTransaction"
      ) {
        type = "Refund";
        amountData = item?.amount;
        symbol = "+";
      }
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        // item?.amount?.value
        amountData?.value
      )}`;
      currencyCode = amountData?.currencyCode;
      amountObj = amountData;
    }

    if (item?.__typename === "IntegratorInitiatedFundsWithdrawalACHTransfer") {
      type = "Transfer";
      symbol = "-";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item?.toFinancialAccount?.name;
      description = item.companyEntryDescription;
      status = item?.transferStatus?.status;
      statusDescription = item?.transferStatus?.statusReasonCode;
      icon = TransferAccount;
      transferTo = `${item?.toFinancialAccount?.name || "Netevia"} ${
        item?.toFinancialAccount?.externalBankAccountDetails?.last4
          ? `****${item?.toFinancialAccount?.externalBankAccountDetails?.last4}`
          : ""
      }`;
      transferFrom = null;
    }

    if (item?.__typename === "IntegratorInitiatedFundsDepositACHTransfer") {
      type = "Deposit";
      symbol = "+";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.companyName;
      description = item.companyEntryDescription;
      status = item?.transferStatus?.status;
      statusDescription = item?.transferStatus?.statusReasonCode;
      icon = BankIcon;
      transferFrom = `${item?.fromFinancialAccount?.name || "Netevia"} ${
        item?.fromFinancialAccount?.externalBankAccountDetails?.last4
          ? `****${item?.fromFinancialAccount?.externalBankAccountDetails?.last4}`
          : ""
      }`;
      transferTo = null;
    }

    if (item?.__typename === "DebitFunds") {
      type = "Transfer";
      symbol = "-";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.companyName;
      description = item.companyEntryDescription;
      statusDescription = item?.transferStatus?.statusReasonCode;
      status = item.transferStatus.status;
      icon = TransferAccount;
    }

    if (item?.__typename === "CreditFunds") {
      type = "Deposit";
      symbol = "+";
      const currentCodeAmount = this.currencyCode(item?.amount?.currencyCode)
        ? this.currencyCode(item?.amount?.currencyCode)
        : item?.amount?.currencyCode;
      amount = `${symbol}${currentCodeAmount}${this.moneyFormat(
        item?.amount?.value
      )}`;
      // amount = `${symbol}$${this.moneyFormat(item.amount.value)}`;
      currencyCode = item.amount.currencyCode;
      date = moment(item.createdAt).format(dateFormat);
      name = item.companyName;
      description = item.companyEntryDescription;
      status = item?.transferStatus?.status;
      statusDescription = item?.transferStatus?.statusReasonCode;
      icon = BankIcon;
    }

    return {
      type,
      symbol,
      amount,
      date,
      name,
      description,
      status,
      statusDescription,
      icon,
      currencyCode,
      cardLast4,
      cardId,
      dateFormat,
      transferFrom,
      transferTo,
      lastEvent,
      amountObj,
      transactionId,
    };
  },
  formatUsdValue: (amount) => {
    let options: {
      minimumFractionDigits: number;
      maximumFractionDigits: number;
      useGrouping?: boolean;
    } = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (amount >= 1000) {
      options.useGrouping = true;
    }
    return (amount / 100).toLocaleString("en-US", options);
  },
  accountNameWithNumber: (account) => {
    return !!account?.last4
      ? `${account?.name} ***${account?.last4}`
      : account?.externalBankAccountDetails?.last4
        ? `${account.name}****${account?.externalBankAccountDetails?.last4}`
        : `${
            account.name
          }****${account?.directDeposit?.restrictedDetails?.number.slice(-4)}`;
  },
  debounce: (func, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  },

  capitalizeFirstLetter: (str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  },
  getLabelForStateNumber: (num: number) => {
    return states.find((i) => i.value === num)?.label;
  },
};
