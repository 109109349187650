import { FC } from 'react';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Button } from '@mui/material';
import deleteIcon from 'assets/svg/basket-red.svg';
import { IconButton, useMediaQuery } from '@mui/material';
import { LinkedAccountApi } from 'api/endpoints/linkedAccount';
import { Typography } from 'shared/ui/typography';
import { Badge } from 'shared/ui/badge'
import { ClockIcon } from 'shared/ui/icons';
import { AccountsItem } from '../..';

const COLOR_BUTTON = '#187AC9';

interface ConnectedAccountItemProps {
  item: AccountsItem;
  handleDelete?: (id: number) => Promise<void>;
  handleSumbit?: (id: number) => void;
  approveButtonText?: string;
  cancelButtonText?: string;
  cancelButtonType?: 'outlined' | 'contained';
  buttonAppropveContained?: boolean;
}

const ConnectedAccountItem: FC<ConnectedAccountItemProps> = ({
  item,
  handleDelete,
  handleSumbit,
  approveButtonText,
  cancelButtonText,
  cancelButtonType,
  buttonAppropveContained,
}) => {
  const { t } = useTranslationProvider();
  const isSubUser = item.type === 4;
  const statusPending = (item as LinkedAccountApi)?.status === 'PENDING';
  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleSumbitButton = (id: number) => {
    if (handleSumbit) {
      handleSumbit(id);
    }
  };

  const renderCancelButton = () => {
    return (
      handleDelete &&
      (cancelButtonText ? (
        <Button
          variant={cancelButtonType ? cancelButtonType : 'text'}
          onClick={() => handleDelete(item.id)}
          color={cancelButtonType ? undefined : 'error'}
        >
          {cancelButtonText}
        </Button>
      ) : (
        <IconButton
          color='error'
          aria-label='Delete'
          onClick={() => handleDelete(item.id)}
        >
          <img src={deleteIcon} />
        </IconButton>
      ))
    );
  };
  return (
    <div className={styles.wrapper}>
      <div>
        <Typography className={styles.dba}>{item.dba}</Typography>
        <Typography className={styles.nickName}>{item.nickName}</Typography>
        {isSubUser &&  (
          <Badge 
            label={t('Settings.Authorized user', {
                defaultValue: 'Authorized user',
              })}
          />
        )}
         {statusPending && isMobile &&(
          <Badge 
            startIcon={<ClockIcon className={styles.pendingIcon}/>}
            label='Pending approval'
            styledLabel={styles.pendingLabel}
        />
        )}
      </div>
      <div className={styles.boxButton}>
        {statusPending && !isMobile &&(
          <Badge 
            startIcon={<ClockIcon className={styles.pendingIcon}/>}
            label='Pending approval'
            styledLabel={styles.pendingLabel}
        />
        )}
        {approveButtonText && (
          <Button
            variant={buttonAppropveContained ? 'contained' : 'outlined'}
            onClick={() => handleSumbitButton(item.id)}
            sx={{
              color: buttonAppropveContained ? undefined : COLOR_BUTTON,
              backgroundColor: buttonAppropveContained
                ? COLOR_BUTTON
                : undefined,
            }}
          >
            {approveButtonText}
          </Button>
        )}
        {renderCancelButton()}
      </div>
    </div>
  );
};
export default ConnectedAccountItem;
