import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';

const subtractDate = dayjs().subtract(18, 'years');
const minDate = dayjs('1925-01-01');

export const CreateSubUserSchema = yup.object().shape({
  firstName: yup.string().min(2).required('Name is required'),
  lastName: yup.string().min(2).required('Last name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ),
  phone: yup
    .string()
    .min(10, 'Phone number must be exactly 10 digits')
    .max(10, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  date: yup
    .mixed<Dayjs>()
    .required('Date is required')
    .test('is-dayjs', 'Invalid date format', (value) => {
      return value && dayjs.isDayjs(value) && value.isValid();
    })
    .test(
      'max-date',
      'Authorized user should be at least 18 years old to proceed',
      (value) => {
        return value && dayjs.isDayjs(value) && value.isBefore(subtractDate);
      }
    )
    .test(
      'min-date',
      'The date of birth must be no older than 01.01.1925',
      (value) => {
        return value && dayjs.isDayjs(value) && value.isAfter(minDate);
      }
    ),
  primaryAccounId: yup.string().min(1).required(),
});

export const EditSubUserSchema = yup.object().shape({
  firstName: yup.string().min(2).required('Name is required'),
  lastName: yup.string().min(2).required('Last name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ),
});
