import { useState, useEffect, useMemo } from 'react';
import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { Main } from './components/main';
import { Filter } from './components/filter';
import {
  useFinancialAccountTransactionsServerControllerQuery,
  Transaction,
} from 'api/endpoints/transaction';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { TransferDetail } from './components/transferDetail';
import { useBoolean } from 'shared/hooks/useBoolean';
import { staticFilterParams } from 'pages/transfer/contsants';
import { DATE_30_DAYS_AGO } from 'shared/constants';

interface Props {
  isShow: boolean;
  onClose: () => void;
}

export interface ApiFilterOptions {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  fromAmount: string;
  toAmount: string;
  deposit: boolean;
  withdraw: boolean;
  achTransfers: boolean;
  neteviaTransfers: boolean;
}

export const useInitialApiFilterOptions = (financialAccountId: string) => {
  const initialApiFilterOptions = useMemo(
    () => ({
      financialAccountId,
      dateFrom: DATE_30_DAYS_AGO,
      dateTo: dayjs().endOf('day'),
      fromAmount: '',
      toAmount: '',
      deposit: false,
      withdraw: false,
      achTransfers: false,
      neteviaTransfers: false,
    }),
    [financialAccountId]
  );

  return initialApiFilterOptions;
};

const useQueryParams = (
  apiFilterOptions: ApiFilterOptions,
  selectAccount: string
) => {
  return useMemo(
    () => ({
      financialAccountId: selectAccount,
      dateFrom: moment(apiFilterOptions.dateFrom.toDate())
        .startOf('day')
        .utc()
        .format(),
      dateTo: moment(apiFilterOptions.dateTo.toDate())
        .endOf('day')
        .utc()
        .format(),
      filter: {
        deposit: {
          displayName: 'Deposits',
          value: apiFilterOptions.deposit,
          items: null,
        },
        withdraw: {
          displayName: 'Withdrawals',
          value: apiFilterOptions.withdraw,
          items: null,
        },
        transfers: {
          displayName: 'Transfers',
          value: true,
          items: [
            {
              displayName: 'ACH transfers',
              value: apiFilterOptions.achTransfers,
              items: null,
            },
            {
              displayName: 'Netevia transfers',
              value: apiFilterOptions.neteviaTransfers,
              items: null,
            },
          ],
        },
        ...staticFilterParams,
      },
    }),
    [selectAccount, apiFilterOptions.dateFrom, apiFilterOptions.dateTo]
  );
};

export const TransferHistory = (props: Props) => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { isShow, onClose } = props;

  const initialApiFilterOptions = useInitialApiFilterOptions(
    unclosedFinancialAccounts[0]?.financialAccountId
  );

  const [apiFilterOptions, setApiFilterOptions] = useState<ApiFilterOptions>(
    initialApiFilterOptions
  );

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [updateTransfers, setUpdateTarsfer] = useState<Transaction[]>([]);
  const [selectTransfer, setSelectTransfer] = useState<Transaction | null>(
    null
  );
  const [selectAccount, setSelectAcoount] = useState<string>('');
  const queryParams = useQueryParams(apiFilterOptions, selectAccount);

  const expandByMoneyMovement = useBoolean(true);
  const expandByTransferType = useBoolean(true);
  const expandPedningTransferBool = useBoolean(true);

  useEffect(() => {
    if (initialApiFilterOptions.financialAccountId) {
      setSelectAcoount(initialApiFilterOptions.financialAccountId);
      setApiFilterOptions(initialApiFilterOptions);
    }
  }, [initialApiFilterOptions.financialAccountId]);

  const { data: transfers, isFetching: isFetchingTransfers } =
    useFinancialAccountTransactionsServerControllerQuery(queryParams, {
      skip: !queryParams.financialAccountId,
    });

  useEffect(() => {
    if (transfers) {
      filtereByAmount(transfers);
    }
  }, [apiFilterOptions.fromAmount, apiFilterOptions.toAmount, transfers]);

  const filtereByAmount = (transfers) => {
    let completedTransers = transfers.data;

    if (apiFilterOptions.fromAmount) {
      completedTransers = completedTransers.filter(
        (transfer) =>
          transfer.amount.value >= parseFloat(apiFilterOptions.fromAmount)
      );
    }

    if (
      apiFilterOptions.toAmount &&
      parseFloat(apiFilterOptions.toAmount) !== 0
    ) {
      completedTransers = completedTransers.filter(
        (transfer) =>
          transfer.amount.value <= parseFloat(apiFilterOptions.toAmount)
      );
    }

    setUpdateTarsfer(completedTransers);
  };

  const closeDrawer = () => {
    setApiFilterOptions(initialApiFilterOptions);
    setSearchQuery('');
    setSelectTransfer(null);
    onClose && onClose();
  };

  const getFiltersLength = (): number => {
    let filtersCount = 0;

    switch (true) {
      case !!apiFilterOptions.dateFrom && !!apiFilterOptions.dateTo:
        filtersCount++;
        break;
      case !!apiFilterOptions.fromAmount || !!apiFilterOptions.toAmount:
        filtersCount++;
        break;
      case !!searchQuery:
        filtersCount++;
        break;
    }

    return filtersCount;
  };

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Transfer history',
        prevId: null,
        Element: (
          <Main
            loadingPage={financialAccountsIsLoading}
            loadingList={isFetchingTransfers}
            transfers={updateTransfers || []}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filtersLength={getFiltersLength()}
            setSelectTransfer={setSelectTransfer}
            expandPedningTransferBool={expandPedningTransferBool}
            financialAccounts={unclosedFinancialAccounts}
            selectAccount={selectAccount}
            setSelectAcoount={setSelectAcoount}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Filters',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <Filter
            loadingFinancialAccounts={financialAccountsIsLoading}
            apiFilterOptions={apiFilterOptions}
            setApiFilterOptions={setApiFilterOptions}
            setSearchQuery={setSearchQuery}
            initialApiFilterOptions={initialApiFilterOptions}
            expandByMoneyMovement={expandByMoneyMovement}
            expandByTransferType={expandByTransferType}
          />
        ),
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Transfer detail',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <TransferDetail
            selectAccount={selectAccount}
            selectTransfer={selectTransfer}
          />
        ),
      },
    ],
  ];

  const MapSteps = new Map(steps);
  return (
    <DrawerStepper
      {...props}
      startStep='0'
      steps={MapSteps}
      isShow={isShow}
      onClose={closeDrawer}
    />
  );
};
