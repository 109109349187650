import { Department } from "api/endpoints/subProfiles";

export const departmentList = [
  Department.Marketing,
  Department.Finance,
  Department.OperationsManagement,
  Department.HumanResource,
  Department.Technology,
  Department.RiskManagement,
  Department.Sales,
  Department.Support,
  Department.TopManagement,
];