import { useMemo } from 'react';
import { useCurrentUser } from 'providers/user.provider';
import {
  FinancialAccount,
  useFinancialAccountsControllerQuery,
  useFinancialAccountsMyControllerQuery,
  FinancialAccountTotal,
} from 'api/endpoints/financialAccounts';

interface FinancialAccountsReturnType {
  financialAccounts: FinancialAccount[];
  total: FinancialAccountTotal;
  unclosedFinancialAccounts: FinancialAccount[];
  unclosedFinancialAccountsSet: Set<string>;
  financialAccountsIsLoading: boolean;
  financialAccountsIsSuccess: boolean;
  finAccountsRefetch: () => void;
}

export const useFinancialAccounts = (): FinancialAccountsReturnType => {
  const { isSubUser } = useCurrentUser();
  const {
    data: financialAccountsData,
    isLoading: finAccountsIsLoading,
    isSuccess: financialAccountsIsSuccess,
    refetch: finAccountsRefetch,
  } = useFinancialAccountsControllerQuery({}, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    pollingInterval: 60_000, // 1 min
  });

  const { data: myFinancialAccounts, isLoading: myFinancialAccountsIsLoading } =
    useFinancialAccountsMyControllerQuery({ enable: isSubUser });

  const financialAccounts = useMemo(() => {
    if (!financialAccountsData) return [];
    if (isSubUser && !myFinancialAccounts) return [];

    let accounts = [...financialAccountsData.financialAccounts];

    if (isSubUser) {
      accounts = accounts.map((account) => ({
        ...account,
        isMine: myFinancialAccounts.includes(account.financialAccountId),
      }));
    }

    return accounts.sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
    );
  }, [financialAccountsData, isSubUser, myFinancialAccounts]);

  const unclosedFinancialAccounts = useMemo(
    () =>
      financialAccounts.filter((fa) =>
        ['ACTIVE', 'UNDER_REVIEW'].includes(fa.status)
      ),
    [financialAccounts]
  );

  const unclosedFinancialAccountsSet = useMemo(() => {
    return new Set(
      unclosedFinancialAccounts.map((fa) => fa.financialAccountId)
    );
  }, [unclosedFinancialAccounts]);

  const total = useMemo(() => {
    if (!financialAccountsData?.totals) {
      return {
        cash: 0,
        availableCash: 0,
        cashIn: 0,
        cashOut: 0,
      };
    }
    return financialAccountsData.totals;
  }, [financialAccountsData]);

  return {
    financialAccounts,
    total,
    unclosedFinancialAccounts,
    unclosedFinancialAccountsSet,
    financialAccountsIsLoading:
      finAccountsIsLoading || myFinancialAccountsIsLoading,
    financialAccountsIsSuccess: financialAccountsIsSuccess,
    finAccountsRefetch,
  };
};
