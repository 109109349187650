import { AnimateWrapper } from "components/animate-wrapper";
import usernameIcon from "assets/svg/drive-file-rename-gray.svg";
import passwordIcon from "assets/svg/password-outlined.svg";
import { useNavigate } from "react-router-dom";
import { ListItemButton } from "@mui/material";
import ChevronRight from "assets/svg/chevron-right-gray.svg";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Typography } from "shared/ui/typography";

const ForgotOptions = () => {
  const navigate = useNavigate();
  const { t } = useTranslationProvider();
  
  return (
    <AnimateWrapper className="fade">
      <div className={styles.box}>
        <div className={styles.container}>
          <Typography className={styles.container_title}>
            {t("Login.Select one of the options below", {
              defaultValue: "Select one of the options below",
            })}
          </Typography>
          <div className={styles.container_groupButton}>
            <ListItemButton
              onClick={() => navigate("/login/forgot-userName")}
              className={styles.wrapper}
            >
              <div className={styles.wrapper_boxTitle}>
                <img src={usernameIcon} />
                <span className={styles.wrapper_boxTitle_text}>
                  {t("Login.Forgot username", {
                    defaultValue: "Forgot username",
                  })}
                </span>
              </div>

              <img src={ChevronRight} />
            </ListItemButton>

            <ListItemButton
              onClick={() => navigate("/login/forgot-password")}
              className={styles.wrapper}
            >
              <div className={styles.wrapper_boxTitle}>
                <img src={passwordIcon} />
                <span className={styles.wrapper_boxTitle_text}>
                  {t("Login.Forgot password", {
                    defaultValue: "Forgot password",
                  })}
                </span>
              </div>
              <img src={ChevronRight} />
            </ListItemButton>
          </div>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default ForgotOptions;
