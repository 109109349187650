export enum LoanStatusEnum {
  Defaulted = 'Defaulted',
  DiscountedSettlement = 'Discounted Settlement',
  Funded = 'Funded',
  FundedPendingSettlement = 'Funded (Pending Settlement)',
  Repaid = 'Repaid',
  RequestedFunding = 'Requested Funding',
  WrittenOff = 'Written Off',
}

export enum OnboardingStateEnum {
  contractGenerated = 'contractgenerated',
  contractSent = 'contractsent',
  contractSigned = 'contractsigned',
  ineligible = 'ineligible',
  offerAccepted = 'offerAccepted',
  offersExpired = 'offersExpired',
  offersProvided = 'offersProvided',
  rescinded = 'rescinded',
  stage1Incomplete = 'stage1incomplete',
  stage1Rejected = 'stage1rejected',
  stage1Submitted = 'stage1submitted',
}
