import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoIcon from 'assets/svg/logo-netevia-white.svg';
import LogoIconBlack from 'assets/svg/logo-netevia-new.svg';
import styles from './style.module.scss';
import cx from 'classnames';
import IconButton from '@mui/material/IconButton';
import { appRoutes } from '../../../../routes';
import { BellNotificationWidget } from 'widget/bellNotificationWidget';
import { useCurrentUser } from '../../../../providers/user.provider';
import { SvgIcon } from '../../../../shared/components/svgIcon';

interface NavBarProps {
  children: React.ReactNode;
  isShowDrawer?: boolean;
  setIsShowDrawer?: (boolean) => void;
}

const Navbar: FC<NavBarProps> = ({
  children,
  isShowDrawer,
  setIsShowDrawer,
}) => {
  const { isConsumer } = useCurrentUser();
  const navigate = useNavigate();

  const handlerNavigateToHome = () => {
    navigate(appRoutes.home());
  };
  const handlerOpenNavBar = () => {
    if (setIsShowDrawer) {
      setIsShowDrawer(true);
    }
  };
  return (
    <nav className={cx(styles.nav, isConsumer && styles.navLight)}>
      <div className={styles.containerNavBar}>
        <div className={styles.box}>
          <IconButton
            aria-label='Open main menu'
            className={styles.boxButtonOpen}
            onClick={handlerOpenNavBar}
          >
            <SvgIcon name='MenuBurger' />
          </IconButton>
          <img
            src={isConsumer ? LogoIconBlack : LogoIcon}
            width={78}
            height={32}
            onClick={handlerNavigateToHome}
          />
        </div>
        <div>
          <BellNotificationWidget />
        </div>
      </div>
      <div
        className={cx(styles.containerDropDown, {
          [styles.isOpen]: isShowDrawer,
          [styles.isClosed]: !isShowDrawer,
        })}
      >
        {children}
      </div>
    </nav>
  );
};
export default Navbar;
