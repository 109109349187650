import styles from './style.module.scss';
import cx from 'classnames';
import { SvgIcon } from 'shared/components/svgIcon';
import { replaceNumberWithComma } from 'shared/utils/price';
import { useMemo } from 'react';
import { RewardActivityEnum, RewardActivityItem } from 'api/endpoints/rewards';

import moment from 'moment/moment';
interface PeriodInfoProps {
  selectedPeriod: string;
  total: RewardActivityItem | undefined;
}
export const PeriodInfo = ({ selectedPeriod, total }: PeriodInfoProps) => {
  const dateFrom = moment(total?.dateFrom);
  const dateTo = moment(total?.dateTo);

  const formatDateRange = (start, end) =>
    `${start.format('MMM D')} - ${end.format('MMM D')}`;

  const label = useMemo(() => {
    const period = selectedPeriod;

    if (period === RewardActivityEnum.M6) {
      return `Total for ${formatDateRange(dateFrom, dateTo)}`;
    }

    if (period === RewardActivityEnum.Y1) {
      return `Total for ${formatDateRange(
        dateFrom,
        dateFrom.clone().add(11, 'months').endOf('month')
      )}`;
    }

    if (period === RewardActivityEnum.QTD) {
      return `Total for ${formatDateRange(
        dateFrom,
        dateFrom.clone().add(2, 'months').endOf('month')
      )}`;
    }

    if (period === RewardActivityEnum.YTD) {
      return `Total for ${formatDateRange(dateFrom, moment())}`;
    }

    return 'Results: ';
  }, [total, selectedPeriod]);

  if (!total) {
    return null;
  }

  const pointsInString = replaceNumberWithComma(total.pointsIn);
  const pointsOutString = replaceNumberWithComma(total.pointsOut);

  return (
    <div className={styles.wrapper}>
      <div className={styles.periodLabel}>{label}</div>
      <div className={styles.totals}>
        <div className={styles.totalItem}>
          <div className={cx(styles.totalCircle, styles.circleIn)}>
            <SvgIcon
              name='ArrowUpwardFilled'
              sizePx={19}
            />
          </div>
          <div className={styles.totalAmount}>{pointsInString}</div>
        </div>
        <div className={styles.totalItem}>
          <div className={cx(styles.totalCircle, styles.circleOut)}>
            <SvgIcon
              name='ArrowUpwardFilled'
              sizePx={19}
            />
          </div>
          <div className={styles.totalAmount}>{pointsOutString}</div>
        </div>
      </div>
    </div>
  );
};
