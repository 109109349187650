import NewAccessRequestForNavbar from 'pages/settings/security/components/loginOptions/components/newAccessRequestForNavbar';
import { useBoolean } from 'shared/hooks/useBoolean';

const PopupGroup = () => {
  const showAccessRequestBool = useBoolean(true);

  return (
    <>
      {showAccessRequestBool.value && (
        <NewAccessRequestForNavbar
          onClose={() => showAccessRequestBool.setFalse()}
        />
      )}
    </>
  );
};
export default PopupGroup;
