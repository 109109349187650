import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { Button } from 'shared/ui/buttons';
import { WalletIcon } from 'shared/ui/icons';
import {
  filteredScheduleTransferByType,
  ScheduleTransferType,
  findObjById,
  EmptyPayees,
  AutomationsItem,
  FinancialAccount,
} from 'entities/transfers';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppDispatch } from 'shared/models';
import {
  clearDataAutomations,
  setAutomations,
} from 'features/transfers/transfer-to-my-own-account/model';
import styles from './styles.module.scss';

export const ManageAutomations = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
    useGetScheduledTransferQuery();

  const handleNavigateToEditAutomations = (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number,
    memo?: string,
  ) => {
    dispatch(
      setAutomations({
        value: {
          id,
          from: fromAccount.id,
          to: toAccount.id,
          amount: amount.toString(),
          memo,
        },
      })
    );
    goNext('6');
  };

  const renderAutoPopupList = () => {
    const autoPopupTransfers = filteredScheduleTransferByType(
      scheduledTransfers ?? [],
      ScheduleTransferType.AutoPopupFromOwnAccount
    );

    return !!autoPopupTransfers.length ? (
      <div className={styles.box}>
        {autoPopupTransfers.map((transfer) => {
          const from = findObjById(
            transfer.fromFinancialAccountId,
            unclosedFinancialAccounts
          );
          const to = findObjById(
            transfer.toFinancialAccountId,
            unclosedFinancialAccounts
          );

          return (
            <AutomationsItem
              key={transfer.id}
              id={transfer.id}
              fromAccount={from}
              toAccount={to}
              amount={transfer.amount}
              memo={transfer.memo}
              handleNavigate={handleNavigateToEditAutomations}
            />
          );
        })}
      </div>
    ) : (
      <EmptyPayees
        icon={<WalletIcon />}
        title={'Automations list is empty'}
        subTitle={'Automations will be displayed here.'}
      />
    );
  };

  const navigateToAddNewAutomation = () => {
    dispatch(clearDataAutomations());
    goNext('4');
  };

  return financialAccountsIsLoading || isLoadingScheduledTransfers ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        {renderAutoPopupList()}
        <Button
          className={styles.btn}
          variant='contained'
          onClick={navigateToAddNewAutomation}
        >
          Add new automation
        </Button>
      </div>
    </TransitionAnimation>
  );
};
