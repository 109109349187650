import { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { states } from "../../constants/data";
import MyAutocomplete_v3 from "../../pages/settings/autocomplete/MyAutocomplete_v3";
import { AnimateWrapper } from "../animate-wrapper";

const DeliveryAddress = ({ setAddress, setPerson }: any) => {
  const [streetAddress, setStreetAddress] = useState<any>();
  const [city, setCity] = useState<any>();
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<any>();
  const [addressFull, setAddressFull] = useState<any>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  useEffect(() => {
    saveAnotherAdress();
  }, [streetAddress, city, state, zipCode]);

  useEffect(() => {
    if (addressFull) {
      transform(addressFull);
    }
  }, [addressFull]);

  const transform = (addressFull) => {
    setStreetAddress(addressFull.address1);
    setCity(addressFull.locality);
    setState(addressFull.adminArea1Short);
    setZipCode(addressFull.postalCode);
  };

  const saveAnotherAdress = () => {
    setAddress({
      streetAddress: streetAddress,
      postalCode: zipCode,
      region: state,
      locality: city,
    });
  };

  const handleFirstNameChange = (e) => {
    const newFirstName = e.target.value;
    setFirstName(newFirstName);
    setPerson((prevPerson) => ({
      ...prevPerson,
      firstName: newFirstName,
    }));
  };

  const handleLastNameChange = (e) => {
    const newLastName = e.target.value;
    setLastName(newLastName);
    setPerson((prevPerson) => ({
      ...prevPerson,
      lastName: newLastName,
    }));
  };

  return (
    <div className="flex gap-6 flex-col mt-11 mb-10">
      <AnimateWrapper delay={100}>
        <TextField
          id="outlined-required"
          value={firstName}
          onChange={handleFirstNameChange}
          label="First name"
          inputProps={{ maxLength: 16 }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </AnimateWrapper>
      <AnimateWrapper delay={200}>
        <TextField
          id="outlined-required"
          value={lastName}
          onChange={handleLastNameChange}
          label="Last name"
          inputProps={{ maxLength: 16 }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </AnimateWrapper>
      <AnimateWrapper delay={300}>
        <MyAutocomplete_v3
          address={streetAddress}
          label={"Address"}
          onAddressChange={setStreetAddress}
          setAddressFull={setAddressFull}
          error=''
        />
      </AnimateWrapper>
      <AnimateWrapper delay={400}>
        <div className="mt-4">
          <TextField
            id="city-input"
            type="text"
            label="City"
            placeholder="Anytown, Idaho"
            value={city}
            fullWidth
            onChange={(e) => setCity(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </AnimateWrapper>
      <AnimateWrapper delay={500}>
        <div className="mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state}
              label="State"
              onChange={(e) => setState(e.target.value)}
            >
              {states.map((i) => {
                return (
                  <MenuItem key={i.value} value={i.label}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </AnimateWrapper>
      <AnimateWrapper delay={600}>
        <div className="mt-4">
          <TextField
            id="outlined-required"
            label="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </AnimateWrapper>
      {/* <div className='mt-4'>
                <TextField
                    id='outlined-required'
                    label='Add comment'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx= {{
                        width: 509,
                    }}
                />
            </div> */}
    </div>
  );
};

export default DeliveryAddress;
