import { useSubUserMEControllerQuery } from "api/endpoints/subProfiles";
import { useUpdateSubUser } from "../../hooks";
import ChangePersonalInformation from "../ChangePersonalInformation";
import { Skeleton } from "components/skeleton";
import { prepareUser } from "pages/settings/accountSettings/components/PersonalInformation/helper";

const SubUserChangePersonalInformation = () => {
  const { data: subUserInfo } = useSubUserMEControllerQuery({});
  const { updateSubUser, isLoading } = useUpdateSubUser();
  
  if (!subUserInfo) {
    return <Skeleton width='100%' height='200px' />
  }
  
  return <ChangePersonalInformation 
    user={prepareUser(subUserInfo)} 
    isLoading={isLoading} 
    updateUser={updateSubUser} 
  />
}

export default SubUserChangePersonalInformation;