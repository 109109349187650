import { BurnerCardInfo } from 'api/hooksApi/usePaymentCardById';
import { AvailableToSpend } from 'pages/cardComponent/provider';
import styles from './style.module.scss';
import { Typography } from '@mui/material';
import helper from 'services/helper';
import moment from 'moment';

interface BalanceBlockInfoProps {
  availableToSpend: AvailableToSpend;
  cardBurnerInfo: BurnerCardInfo | boolean;
}

interface Row {
  title: string;
  content: string;
  isHide: boolean;
}

const getRows = (
  availableToSpend: BalanceBlockInfoProps['availableToSpend'],
  cardBurnerInfo: BalanceBlockInfoProps['cardBurnerInfo']
): Row[] => {
  if (typeof cardBurnerInfo === 'object' && cardBurnerInfo !== null) {
    return [
      {
        title: 'Expiry date:',
        content: moment(cardBurnerInfo.BurnerExpirationDate).format(
          'MM/DD/YYYY'
        ),
        isHide: !cardBurnerInfo.BurnerExpirationDate,
      },
      {
        title: 'Spending limit:',
        content: `${cardBurnerInfo.TotalSpending}/${cardBurnerInfo.MaxTotalSpending}`,
        isHide: !(
          cardBurnerInfo.TotalSpending && cardBurnerInfo.MaxTotalSpending
        ),
      },
      {
        title: 'Transaction limit:',
        content: `${cardBurnerInfo.TotalTransactionsCount}/${cardBurnerInfo.MaxTransactionsCount}`,
        isHide: !cardBurnerInfo.BurnerExpirationDate,
      },
    ];
  }

  return [
    {
      title: 'Spent this month:',
      content: helper.formatUsdValue(availableToSpend.spend),
      isHide: false,
    },
  ];
};

export const BalanceBlockInfo = ({
  availableToSpend,
  cardBurnerInfo,
}: BalanceBlockInfoProps) => {
  return (
    <div className={styles.container}>
      {getRows(availableToSpend, cardBurnerInfo).map((item) => {
        if (item.isHide) return null;

        return (
          <div
            className={styles.row}
            key={item.title}
          >
            <Typography>{item.title}</Typography>
            <Typography>{item.content}</Typography>
          </div>
        );
      })}
    </div>
  );
};
