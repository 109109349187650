import { TransitionAnimation } from 'shared/ui/animations';
import { Accordion } from '../accordion';
import { Filters } from 'api/endpoints/transaction';
import { RadioGroup } from '@mui/material';
import { Typography } from 'shared/ui/typography';
import { Radio } from 'shared/ui/inputs';
import styles from './style.module.scss';
import { FilterProps } from 'pages/accountComponent/types';
import { Skeleton } from 'components/skeleton';

export const FilterByTransactionType = (props: FilterProps) => {
  const { filterObj, setFilterObj, loading, expandFilter, setExpandFilter } =
    props;
  const { expandByTransactionType } = expandFilter;

  const handleChangeExpand = (value: boolean) => {
    setExpandFilter({
      ...expandFilter,
      expandByTransactionType: value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const updatedFilters = {
      ...filterObj,
      all: {
        value: false,
        displayName: filterObj?.all?.displayName || '',
        items: filterObj?.all?.items || null,
      },
      transfers: {
        value: value === 'transfers' || value === 'all',
        displayName: filterObj?.transfers?.displayName || '',
        items: filterObj?.transfers?.items || null,
      },
      pos: {
        value: value === 'pos' || value === 'all',
        displayName: filterObj?.pos?.displayName || '',
        items: filterObj?.pos?.items || null,
      },
    };

    setFilterObj(updatedFilters);
  };

  const getSelectedFilter = (filterObj: Filters) => {
    let selectedFilter = '';
    switch (true) {
      case filterObj?.transfers?.value && filterObj?.pos?.value:
        selectedFilter = 'all';
        break;
      case filterObj?.transfers?.value:
        selectedFilter = 'transfers';
        break;
      case filterObj?.pos?.value:
        selectedFilter = 'pos';
        break;
      default:
        selectedFilter = '';
    }

    return selectedFilter;
  };

  return loading ? (
    <Skeleton
      width='100%'
      height='126px'
    />
  ) : (
    <TransitionAnimation>
      <Accordion
        expanded={expandByTransactionType ?? false}
        onChangeExpanded={handleChangeExpand}
        summary='By transaction type'
        styleWrapperContent={styles.wrapperAccordion}
      >
        <RadioGroup
          value={getSelectedFilter(filterObj)}
          onChange={handleChange}
          className={styles.groupRadio}
        >
          <Radio
            key='all'
            LabelProps={{ className: styles.boxRadio }}
            value='all'
            checked={filterObj?.transfers?.value && filterObj?.pos?.value}
            label={<Typography>All</Typography>}
          />
          <Radio
            key={filterObj?.transfers?.displayName}
            LabelProps={{ className: styles.boxRadio }}
            value='transfers'
            checked={filterObj?.transfers?.value && !filterObj?.pos?.value}
            label={<Typography>{filterObj?.transfers?.displayName}</Typography>}
          />
          <Radio
            key={filterObj?.pos?.displayName}
            LabelProps={{ className: styles.boxRadio }}
            value='pos'
            checked={filterObj?.pos?.value && !filterObj?.transfers?.value}
            label={<Typography>{filterObj?.pos?.displayName}</Typography>}
          />
        </RadioGroup>
      </Accordion>
    </TransitionAnimation>
  );
};
