import { FormControlLabel, Checkbox } from '@mui/material';
import { Accordion } from 'pages/accountComponent/components/filterTransactions/componentns/accordion';
import { ReturnType } from 'shared/hooks/useBoolean';
import { FormValues } from '..';
import { FormikProps } from 'formik';
import styles from './style.module.scss';

interface ByTransferTypeProps {
  formik: FormikProps<FormValues>;
  expandByTransferType: ReturnType;
}

export const ByTransferType = ({
  formik,
  expandByTransferType,
}: ByTransferTypeProps) => {
  return (
    <Accordion
      expanded={expandByTransferType.value}
      onChangeExpanded={() => expandByTransferType.toggle()}
      summary='By transfer type'
    >
      <FormControlLabel
        key='ACH transfers'
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            name='achTransfers'
            checked={formik.values.achTransfers}
            onChange={(e) =>
              formik.setFieldValue('achTransfers', e.target.checked)
            }
          />
        }
        label='ACH transfers'
      />
      <FormControlLabel
        key='Netevia transfers'
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            name='neteviaTransfers'
            checked={formik.values.neteviaTransfers}
            onChange={(e) =>
              formik.setFieldValue('neteviaTransfers', e.target.checked)
            }
          />
        }
        label='Netevia transfers'
      />
    </Accordion>
  );
};
