import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginLayout from 'layouts/LoginLayout';
import RegistrationLayout from 'layouts/RegistrationLayout';
import Login from 'pages/login';
import ForgotPassword from 'pages/login/components/forgotPassword';
import VerificationInProcess from 'pages/login/components/verifications/verificationInProcess';
import ForgotUserName from 'pages/login/components/forgotUserName';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import AccountLocked from 'pages/login/components/accountLocked';
import ChangePasswordHQ from 'pages/login/components/changePasswordHQ';
import VerificationFailed from 'pages/login/components/verifications/verificationFailed';
import VerifyInProcessLayout from 'layouts/VerifyInProcessLayout';
import { appRoutes, VerifyDocsSubRoute } from '../../routes';
import { VerificationDocumentUnauthorizedPage } from '../../pages/login/components/verifications/verificationDocumentUnatuhorized';
import ForgotOptions from 'pages/login/components/forgotOptions';
import { SignUp } from '../../pages/signUp';

export const UnauthorizedRoutes = () => {
  const { currentLanguage } = useTranslationProvider();
  const [lang, setlang] = useState("");

  useEffect(() => {
    setlang(currentLanguage);
  }, [currentLanguage]);

  return (
    <Routes>
      <Route path={appRoutes.verifyInfo()} element={<RegistrationLayout />}>
        <Route index element={<VerificationDocumentUnauthorizedPage />} />
        <Route path="failure" element={<VerificationFailed />} />
      </Route>
      <Route path={appRoutes.verifyDocs()} element={<VerifyInProcessLayout />}>
        <Route
          path={appRoutes.verifyDocs(VerifyDocsSubRoute.inProcess)}
          element={<VerificationInProcess />}
        />
      </Route>
      <Route path="/login" element={<LoginLayout />}>
        <Route index element={<Login />} />
        <Route path="consumer" element={<Login isConsumer />} />
        <Route path="forgot-options" element={<ForgotOptions />} />
        <Route path="forgot-userName" element={<ForgotUserName />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="change-password" element={<ChangePasswordHQ />} />
        <Route path="account-locked" element={<AccountLocked />} />
      </Route>
      <Route path={appRoutes.signUp()} element={<LoginLayout />}>
        <Route index element={<SignUp />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
