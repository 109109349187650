import { IconButton } from '@mui/material';
import IconCopy from '../../../assets/svg/copy-blue.svg';
import { useSnackBar } from 'providers/snackBar.provider';

interface CopyButtonProps {
  textToCopy?: string;
}

const CopyButton = ({ textToCopy }: CopyButtonProps) => {
  const { setSnackBar } = useSnackBar();

  const handleCopy = async () => {
    if (!textToCopy) {
      return null;
    }

    try {
      await navigator.clipboard.writeText(textToCopy);
      setSnackBar({
        type: 'success',
        message: 'Data is copied successfully!',
        isShow: true,
      });
    } catch (err) {
      setSnackBar({
        type: 'error',
        message: 'Failed to copy data',
        isShow: true,
      });
      console.error('Failed to copy text:', err);
    }
  };

  if (!textToCopy) {
    return null;
  }

  return (
    <IconButton
      edge='end'
      onClick={handleCopy}
      aria-label='Copy text'
    >
      <img
        src={IconCopy}
        alt='Copy icon'
      />
    </IconButton>
  );
};

export default CopyButton;
