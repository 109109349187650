import React, { PropsWithChildren, useLayoutEffect, useMemo } from 'react';
import {
  Rights,
  UserApi,
  useCurrentUserControllerQuery,
} from '../api/endpoints/user';
import { hideAppLoader, showAppLoader } from '../components/appLoader';

interface User extends UserApi {
  fullName: string;
}

interface UserContextProps {
  user: User | undefined;
  isSubUser: boolean;
  isMainBusiness: boolean;
  isConsumer: boolean;
  userIsLoading: boolean;
  currentUserRefetch: () => Promise<any>;
  subUserRights(
    rights: Partial<Rights>,
    options?: { matchAll?: boolean }
  ): boolean;
}

const Context = React.createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading, refetch } = useCurrentUserControllerQuery({});

  useLayoutEffect(() => {
    if (isLoading && !data) {
      showAppLoader();
    } else {
      hideAppLoader();
    }
  }, [isLoading]);

  const value = useMemo((): UserContextProps => {
    const isSubUser = data?.type === 4 ?? false;
    const isMainBusiness = data?.type === 2 ?? false;
    const isConsumer = data?.type == 1 ?? false;

    const user: User | undefined = data
      ? {
          ...data,
          fullName: `${data.givenName} ${data.familyName}`,
        }
      : undefined;

    return {
      user,
      userIsLoading: isLoading,
      isSubUser,
      isMainBusiness,
      isConsumer,
      currentUserRefetch: refetch,
      subUserRights(
        rights: Partial<Rights>,
        options?: { matchAll?: boolean }
      ): boolean {
        if (!isSubUser || !data?.accessRights) {
          return false;
        }

        if (Object.keys(rights).length === 0) {
          return true;
        }

        if (options?.matchAll) {
          return Object.entries(rights).every(([key, value]) => {
            return data?.accessRights[key as keyof Rights] === value;
          });
        } else {
          return Object.entries(rights).some(([key, value]) => {
            return data?.accessRights[key as keyof Rights] === value;
          });
        }
      },
    };
  }, [data, isLoading, refetch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCurrentUser = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useCurrentUser must be used within a UserProvider');
  }
  return context;
};
