import { TransitionAnimation } from 'shared/ui/animations';
import { Dayjs } from 'dayjs';
import { Skeleton } from 'components/skeleton';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useFormik } from 'formik';
import { FilterSchema } from '../validationFilterSchema';
import { ApiFilterOptions } from '../..';
import { ReturnType } from 'shared/hooks/useBoolean';
import { FilterForm } from './filterForm';

export interface FormValues {
  date: [Dayjs | null, Dayjs | null];
  fromAmount: string;
  toAmount: string;
  withdraw: boolean;
  deposit: boolean;
  achTransfers: boolean;
  neteviaTransfers: boolean;
}
interface FilterProps {
  loadingFinancialAccounts: boolean;
  apiFilterOptions: ApiFilterOptions;
  setApiFilterOptions: (value: ApiFilterOptions) => void;
  setSearchQuery: (value: string) => void;
  initialApiFilterOptions: ApiFilterOptions;
  expandByMoneyMovement: ReturnType;
  expandByTransferType: ReturnType;
}

export const Filter = (props: FilterProps) => {
  const {
    loadingFinancialAccounts,
    apiFilterOptions,
    setApiFilterOptions,
    setSearchQuery,
    initialApiFilterOptions,
    expandByMoneyMovement,
    expandByTransferType,
  } = props;

  const { goNext } = useDrawerStepperContext();

  const formik = useFormik({
    initialValues: {
      date: [apiFilterOptions.dateFrom, apiFilterOptions.dateTo] as [
        Dayjs | null,
        Dayjs | null,
      ],
      fromAmount: apiFilterOptions.fromAmount,
      toAmount: apiFilterOptions.toAmount,
      withdraw: apiFilterOptions.withdraw,
      deposit: apiFilterOptions.deposit,
      achTransfers: apiFilterOptions.achTransfers,
      neteviaTransfers: apiFilterOptions.neteviaTransfers,
    },

    validationSchema: FilterSchema,
    onSubmit: (values) => {
      setApiFilterOptions({
        dateFrom: values.date[0] as Dayjs,
        dateTo: values.date[1] as Dayjs,
        fromAmount: values.fromAmount,
        toAmount: values.toAmount,
        withdraw: values.withdraw,
        deposit: values.deposit,
        achTransfers: values.achTransfers,
        neteviaTransfers: values.neteviaTransfers,
      });
      goNext('0');
    },
  });

  const onReset = () => {
    formik.setValues({
      date: [initialApiFilterOptions.dateFrom, initialApiFilterOptions.dateTo],
      fromAmount: initialApiFilterOptions.fromAmount,
      toAmount: initialApiFilterOptions.toAmount,
      withdraw: initialApiFilterOptions.withdraw,
      deposit: initialApiFilterOptions.deposit,
      achTransfers: initialApiFilterOptions.achTransfers,
      neteviaTransfers: initialApiFilterOptions.neteviaTransfers,
    });
    setApiFilterOptions(initialApiFilterOptions);
    setSearchQuery('');
  };

  return loadingFinancialAccounts ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <FilterForm
        formik={formik}
        expandByMoneyMovement={expandByMoneyMovement}
        expandByTransferType={expandByTransferType}
        onReset={onReset}
      />
    </TransitionAnimation>
  );
};
