import { useCurrentUser } from 'providers/user.provider';
import ConsumerInformation from './components/ConsumerInformation';
import SubUserInformation from './components/SubUserInformation';

const WrappedPersonalInformation = () => {
  const { isSubUser } = useCurrentUser();

  return isSubUser ? <SubUserInformation /> : <ConsumerInformation />;
}

export default WrappedPersonalInformation;
