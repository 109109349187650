import { useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import {
  useSubUsersControllerQuery,
  SubUsersApi,
} from 'api/endpoints/subProfiles';
import { useState } from 'react';
import { Button } from 'shared/ui/buttons';
import { useMediaQuery } from '@mui/material';
import { Typography } from 'shared/ui/typography';
import { CreateSubUser } from './components/createSubUser';
import { useBoolean } from 'shared/hooks/useBoolean';
import { EditSubUser } from './components/editSubUser';
import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { ChevronRightIcon } from 'shared/ui/icons';
import { ListButton } from 'shared/ui/lists';
import AccountSettingsContainer from '../AccountSettingsContainer';
import { SubUsersList } from '../../../../../components/SubUsersList';

const AuthorizedUser = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { data: subUsers, isLoading: isLoadingSubUsers } =
    useSubUsersControllerQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectEditSubUer, setSelectEditSubUer] = useState<SubUsersApi>();
  const page = searchParams.get('page') || '';

  const createBool = useBoolean();
  const editBool = useBoolean();

  const handleOpenEditSubUser = (id: number) => {
    const subUser = subUsers?.find((user) => user.id === id);
    if (subUser) {
      setSelectEditSubUer(subUser);
      editBool.setTrue();
    }
  };
  const onCloseEditSubuser = () => {
    setSearchParams({
      page,
    });
    editBool.setFalse();
  };

  const renderSubUserList = () => {
    if (!subUsers?.length) return <></>;

    return (
      <SubUsersList
        data={subUsers}
        onClick={(id) => handleOpenEditSubUser(id)}
      />
    );
  };

  return (
    <AccountSettingsContainer title='Authorized Users'>
      <div className={styles.container}>
        <Button
            onClick={() => createBool.setTrue()}
            disabled={isLoadingSubUsers}
            className={styles.btn}
          >
            + Add new authorized user
        </Button>

        {isLoadingSubUsers ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          renderSubUserList()
        )}

        <CreateSubUser
          isShow={createBool.value}
          onClose={() => createBool.setFalse()}
        />
        {selectEditSubUer && (
          <EditSubUser
            isShow={editBool.value}
            onClose={onCloseEditSubuser}
            subUserGivenName={selectEditSubUer?.givenName}
            subUserFamilyName={selectEditSubUer?.familyName}
            subId={selectEditSubUer?.id}
          />
        )}
      </div>
    </AccountSettingsContainer>
  );
};

export default AuthorizedUser;
