import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { useCardLimits } from 'api/hooksApi/useCardLimits';
import { useCardInformation } from 'pages/cardManage/provider';
import { Skeleton } from 'components/skeleton';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useBoolean } from 'shared/hooks/useBoolean';
import { TextInput } from 'shared/ui/inputs';
import { SearchIcon } from 'shared/ui/icons';
import { MerchantApi, MerchantType } from 'api/endpoints/enums';
import styles from './style.module.scss';

interface MerchantCategoryProps {
  isLoading: boolean;
  categories: MerchantApi[];
  setCategories: (categories: MerchantApi[]) => void;
  allowanceForChanges: boolean;
}

export const MerchantCategory = (props: MerchantCategoryProps) => {
  const { isLoading, categories, setCategories, allowanceForChanges } = props;
  const { currentCard } = useCardInformation();
  const { cardLimits } = useCardLimits(currentCard?.id);

  const [searchInput, setSearchInput] = useState<string>('');

  const selectAllBool = useBoolean(
    cardLimits?.categoriesLimit?.every(
      (category: MerchantApi) => category.rule === MerchantType.allowed
    )
  );

  const filteredCategories = categories?.filter((item) =>
    item.description.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === 'Select All') {
      selectAllBool.setValue(checked);
      const updatedCategoriesAll = categories.map((category) => ({
        ...category,
        rule: checked ? MerchantType.allowed : null,
      }));
      setCategories(updatedCategoriesAll);
    } else {
      const updatedCategories = categories.map((category) =>
        category.name === name
          ? { ...category, rule: checked ? MerchantType.allowed : null }
          : category
      );
      setCategories(updatedCategories);
    }
  };

  const renderCheckBox = () => {
    return filteredCategories?.map((item, index) => (
      <FormControlLabel
        key={index}
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              root: styles.rootCheckBox,
              checked: styles.checked,
            }}
            checked={item?.rule === MerchantType.allowed}
            onChange={handleCheckBoxChange}
            name={item?.name}
            disabled={!allowanceForChanges}
          />
        }
        label={item?.description}
      />
    ));
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <TextInput
          autoComplete='off'
          inputProps={{ maxLength: 140 }}
          placeholder='Search'
          value={searchInput}
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <div className={styles.list}>
          <FormControlLabel
            key='Select All'
            classes={{ root: styles.rootFormControlLabel }}
            control={
              <Checkbox
                classes={{
                  checked: styles.checked,
                }}
                checked={selectAllBool.value}
                onChange={handleCheckBoxChange}
                name='Select All'
                disabled={!allowanceForChanges}
              />
            }
            label='Select All'
          />
          {renderCheckBox()}
        </div>
      </div>
    </TransitionAnimation>
  );
};
