import { PaymentCard } from 'api/endpoints/paymentCards';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { useCurrentUser } from 'providers/user.provider';
import { useMemo } from 'react';
import { usePermission } from './usePermission';

const filterActiveCard = (card: PaymentCard) => {
  return card.status !== 'CLOSED';
};

const useIsUserCanOpenPrimaryCard = () => {
  const { user, isMainBusiness, isConsumer } = useCurrentUser();
  const { canOpenCard } = usePermission();
  const { paymentCards } = usePaymentCards();

  const hasAvailablePaymentCards = useMemo(
    () =>
      paymentCards
        .filter((paymentCard) => {
          if (isMainBusiness) {
            return filterActiveCard(paymentCard);
          }

          return (
            paymentCard.subProfile === user?.fullName &&
            filterActiveCard(paymentCard)
          );
        })
        .some((card) => card.isMainCard),
    [paymentCards]
  );

  return {
    canOpenPrimaryCard: canOpenCard && !hasAvailablePaymentCards && !isConsumer,
    hasAvailablePaymentCards: hasAvailablePaymentCards && canOpenCard,
  };
};

export default useIsUserCanOpenPrimaryCard;
