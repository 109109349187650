import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { Typography } from 'shared/ui/typography';
import { TransitionAnimation } from 'shared/ui/animations';
import { SubUserFormValues } from 'pages/settings/accountSettings/types';
import styles from './style.module.scss';

interface PropTypes {
  formik: FormikProps<SubUserFormValues>;
}

export const ManageAccess = ({ formik }: PropTypes) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === 'mainAccess' && checked) {
      formik.setFieldValue('access', {
        ...formik.values.access,
        openBankingCard: true,
        openFinancialAccount: true,
        viewAllCardsAndFinancialAccounts: true,
        makeTransfers: true,
        mainAccess: true,
      });
    } else {
      formik.setFieldValue(`access.${name}`, checked);
    }
  };

  const handleToggleManageAccess = () => {
    const currentManageState = formik.values.access.manage;
    const newManageState = !currentManageState;

    formik.setFieldValue('access', {
      manage: newManageState,
      openBankingCard: newManageState
        ? formik.values.access.openBankingCard
        : false,
      openFinancialAccount: newManageState
        ? formik.values.access.openFinancialAccount
        : false,
      viewAllCardsAndFinancialAccounts: newManageState
        ? formik.values.access.viewAllCardsAndFinancialAccounts
        : false,
      makeTransfers: newManageState
        ? formik.values.access.makeTransfers
        : false,
      mainAccess: newManageState ? formik.values.access.mainAccess : false,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.boxTitle}>
        <div>
          <Typography className={styles.title}>Manage access</Typography>
          <Typography className={styles.description}>
            Set permissions and access controls for stored card information
          </Typography>
        </div>

        <div className={styles.boxSwitch}>
          <div>
            <Switch
              onClick={handleToggleManageAccess}
              checked={formik.values.access.manage}
            />
          </div>
        </div>
      </div>

      {formik.values.access.manage && (
        <TransitionAnimation>
          <FormGroup className={styles.list}>
            <FormControlLabel
              label='Open banking card'
              control={
                <Switch
                  checked={formik.values.access.openBankingCard}
                  onChange={handleChange}
                  name='openBankingCard'
                  disabled={formik.values.access.mainAccess}
                />
              }
            />
            <FormControlLabel
              label='Open financial account'
              control={
                <Switch
                  checked={formik.values.access.openFinancialAccount}
                  onChange={handleChange}
                  name='openFinancialAccount'
                  disabled={formik.values.access.mainAccess}
                />
              }
            />
            <FormControlLabel
              label='View all cards and financial accounts'
              control={
                <Switch
                  checked={
                    formik.values.access.viewAllCardsAndFinancialAccounts
                  }
                  onChange={handleChange}
                  name='viewAllCardsAndFinancialAccounts'
                  disabled={formik.values.access.mainAccess}
                />
              }
            />
            <FormControlLabel
              label='Make transfers'
              control={
                <Switch
                  checked={formik.values.access.makeTransfers}
                  onChange={handleChange}
                  name='makeTransfers'
                  disabled={formik.values.access.mainAccess}
                />
              }
            />
            <FormControlLabel
              label='Main-user access'
              control={
                <Switch
                  checked={formik.values.access.mainAccess}
                  onChange={handleChange}
                  name='mainAccess'
                />
              }
            />
          </FormGroup>
        </TransitionAnimation>
      )}
    </div>
  );
};
