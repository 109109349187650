import { TransitionAnimation } from 'shared/ui/animations';
import { useFormik } from 'formik';
import { Button as ButtonMUI } from '@mui/material';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import helper from 'services/helper';
import { Skeleton } from 'components/skeleton';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { selectAutomations, setAutomations } from '../../model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import styles from './styles.module.scss';
import { formatAccountName } from 'shared/lib/format';
import { AutomationsForm, AutomationsSchema } from 'entities/transfers';

export const ManageAutomationsAdd = () => {
  const { goNext } = useDrawerStepperContext();
  const dispatch = useAppDispatch();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();

  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
      memo: automations.memo
    },
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(setAutomations({ value: values }));
      goNext('12');
    },
  });

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const disabedBtn =
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        payees={plaidPayees}
        optionsToAcc={optionsToAcc}
        warningAmount={'Minimum balance for replenishment'}
        subTitle={'Funds will be transferred from your connected account when your balance falls below a set amount.'}
        slotBtn={
          <ButtonMUI
            variant='contained'
            className={styles.btn}
            type='submit'
            disabled={disabedBtn}
          >
            Confirm
          </ButtonMUI>
        }
      />
    </TransitionAnimation>
  );
};
