import { useEffect, useState } from 'react';
import { useBoolean } from './useBoolean';

interface Props {
  imageUrl: string;
}

interface ReturnType {
  isLoading: boolean;
  contrast: 'black' | 'white';
}

export const useImageContrast = ({ imageUrl }: Props): ReturnType => {
  const loadingBool = useBoolean();
  const [contrast, setContrast] = useState<ReturnType['contrast']>('black');

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        // Устанавливаем размеры канваса
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Получаем данные пикселей
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        let totalBrightness = 0;
        const pixelCount = data.length / 4;

        // Рассчитываем среднюю яркость
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          // Используем формулу для расчета яркости
          const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
          totalBrightness += brightness;
        }

        const avgBrightness = totalBrightness / pixelCount;

        // Определяем цвет текста
        setContrast(avgBrightness > 128 ? 'black' : 'white');
        URL.revokeObjectURL(imageUrl);
      };
    };

    loadingBool.setTrue();
    fetchImage().finally(() => loadingBool.setFalse());
  }, [imageUrl]);

  return {
    contrast,
    isLoading: loadingBool.value,
  }
};
