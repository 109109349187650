import { FrequencyTitle } from "./enums"

export const FREQUENCY_DATA = [
  {
    title: FrequencyTitle.Once,
    value: null,
  },
  {
    title: FrequencyTitle.Daily,
    value: '1d',
  },
  {
    title: FrequencyTitle.Weekly,
    value: '1w',
  },
  {
    title: FrequencyTitle.Monthly,
    value: '1m',
  },
  {
    title: FrequencyTitle.Yearly,
    value: '1y',
  },
] as const
