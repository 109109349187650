import Status from "pages/support/history/components/status/Status";
import Checkbox from "@mui/material/Checkbox";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { TicketStatus } from "api/endpoints/tickets";
import { Typography } from "shared/ui/typography";
import { FormikProps } from "formik";
import { FilterFormValues } from "pages/support/types/interfaces";

interface FilterByTypeProps {
  formik: FormikProps<FilterFormValues>;
}

const FilterByType = ({ formik }: FilterByTypeProps) => {
  const { t } = useTranslationProvider();

  const checkBoxData = [
    TicketStatus.NEW,
    TicketStatus.WORKING_IN_PROGRESS,
    TicketStatus.RESOLVED,
  ];

  const handleChangeNew = (status: TicketStatus) => {
    const currentValues = formik.values.byType;

    if (currentValues.includes(status)) {
      formik.setFieldValue(
        'byType',
        currentValues.filter((s) => s !== status)
      );
    } else {
      formik.setFieldValue('byType', [...currentValues, status]);
    }
  };

  const renderCheckBox = () => {
    return checkBoxData.map((status, index) => (
      <div
        className={styles.box}
        key={index}
      >
        <Checkbox
          checked={formik.values.byType.includes(status)}
          onChange={() => handleChangeNew(status)}
          classes={{checked: styles.checked}}
        />
        <Status status={status} />
      </div>
    ));
  };

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.wrapperTitle}>
        {t('Support.Filter by ticket type', {
          defaultValue: 'Filter by ticket type',
        })}
      </Typography>
      {renderCheckBox()}
    </div>
  );
};

export default FilterByType;
