import { useEffect, useState, FC, Dispatch } from 'react';
import DeliveryAddress from 'components/open-new-card/DeliveryAddress';
import helper from 'services/helper';
import DeliveryService from '../../deliveryService';
import styles from '../style.module.scss';
import { Switch } from '@mui/material';
import { useCurrentUser } from 'providers/user.provider';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Person, Address } from '..';
import { useSubUserInfo } from 'api/hooksApi/useSubUrerInfo';
import { Skeleton } from 'components/skeleton';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { useHolderV2ControllerQuery } from '../../../../api/endpoints/account';

interface OpenPhysicalCardProps {
  setAddress: Dispatch<Address>;
  setPerson?: Dispatch<Person>;
  handlerChoiseDelevery: (string) => void;
  deliveryChoise: string;
  isDisabled?: boolean;
}
const OpenPhysicalCard: FC<OpenPhysicalCardProps> = ({
  setAddress,
  setPerson,
  handlerChoiseDelevery,
  deliveryChoise,
  isDisabled,
}) => {
  const { t } = useTranslationProvider();
  const { isSubUser, isConsumer } = useCurrentUser();
  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();
  const { subUserInfo, subUserInfoIsLoading } = useSubUserInfo(isSubUser);
  const [personAddress, setPersonAddress] = useState<boolean>(true);
  const [anotherAddres, setAnotherAddres] = useState<boolean>(false);
  const { data: holder, isLoading: isHolderLoading } =
    useHolderV2ControllerQuery(undefined, { skip: !isConsumer });

  const isLoading = formattedholderDataIsLoading ||  subUserInfoIsLoading || isHolderLoading;

  const updateAddress = () => {
    if (isConsumer && holder?.accountHolderInformation) {
      setAddress({
        streetAddress: holder?.accountHolderInformation?.streetAddress || '',
        postalCode: holder?.accountHolderInformation?.postalCode || '',
        region: helper.getLabelForStateNumber(holder?.accountHolderInformation?.state) || '',
        locality: holder?.accountHolderInformation?.locality || '',
      });
    } else {
      setAddress(
        isSubUser
          ? subUserInfo?.fullAdress
          : formattedHolderData?.bussinessInfo?.addressInfo
      );
    }
  };

  useEffect(() => {
    updateAddress();
  }, [isLoading]);

  const toogleAddress = () => {
    if (anotherAddres) {
      updateAddress();
    }
    setPersonAddress(!personAddress);
    setAnotherAddres(!anotherAddres);
  };

  return (
    <div className={styles.container}>
      <p className={styles.container_titleAddress}>
        {t('common.Your address', {
          defaultValue: 'Your address',
        })}
      </p>
      {isLoading ? (
        <Skeleton
          height={200}
          width={'100%'}
        />
      ) : (
        <>
          <div className={styles.wrapperAddress}>
            <div>Delivery address</div>
            <div className={styles.wrapperAddress_box}>
              <p className={styles.wrapperAddress_text}>
                {helper.formatAddress(
                  isSubUser
                    ? subUserInfo?.fullAdress
                    : formattedHolderData?.bussinessInfo?.addressInfo
                )}
              </p>
            </div>
            <div className={styles.wrapperAddress_box}>
              <p>Enter another delivery address</p>
              <div className='cursor-pointer'>
                <Switch
                  disabled={isDisabled}
                  checked={anotherAddres}
                  onClick={toogleAddress}
                />
              </div>
            </div>
          </div>
          {anotherAddres && (
            <DeliveryAddress
              setAddress={setAddress}
              setPerson={setPerson}
            />
          )}
        </>
      )}
      <p className={styles.container_titleDelivery}>
        {t('common.Enter delivery address', {
          defaultValue: 'Choose delivery service',
        })}
      </p>
      <div className={styles.wrapperDelivery}>
        <DeliveryService
          deliveryChoise={deliveryChoise}
          deliveryChoiseSave={handlerChoiseDelevery}
        />
      </div>
    </div>
  );
};

export default OpenPhysicalCard;
