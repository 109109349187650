import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import helper from 'services/helper';
import { formatAccountName } from 'shared/lib/format';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Select } from 'shared/ui/inputs';
import { TextInput } from 'shared/ui/inputs';
import { useFormik } from 'formik';
import styles from './styles.module.scss';
import { replaceNumberWithComma } from 'shared/utils/price';
import * as yup from 'yup';
import { Skeleton } from 'components/skeleton';

const validationSchema = yup.object().shape({
  loanAmount: yup
    .string()
    .required('Loan amount is required')
    .test(
      'is-valid-amount',
      'Loan amount must be between $500 and $1,000,000',
      (value) => {
        const numericValue = parseFloat(value.replace(/[$\s,]/g, ''));
        return numericValue >= 500 && numericValue <= 1000000;
      }
    ),
  selectAccount: yup.string().required('Please select an account'),
});

const formatCurrency = (value: string) => {
  const numberValue = value.replace(/[^0-9.]/g, '');
  return numberValue ? `$ ${replaceNumberWithComma(numberValue)}` : '';
};

interface FormFundingApplicationProps {
  loanAmount: string;
  selectAccountId: string;
  setLoansAmount: (loanAmount: string) => void;
  setSelectAccountId: (selectAccountId: string) => void;
}

export const FormFundingApplication = (props: FormFundingApplicationProps) => {
  const { loanAmount, selectAccountId, setLoansAmount, setSelectAccountId } =
    props;
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const formik = useFormik({
    initialValues: {
      loanAmount: loanAmount,
      selectAccount: selectAccountId,
    },
    validationSchema,
    onSubmit: (form) => {
      const formattedLoanAmount = form.loanAmount.replace(/[$\s,]/g, '');
      setLoansAmount(formattedLoanAmount);
      setSelectAccountId(form.selectAccount);
      goNext('1');
    },
  });

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <Typography className={styles.text}>
          Complete your loan application now. Don’t miss out on this special
          offer.
        </Typography>
        <TextInput
          label='Loan amount'
          name='loanAmount'
          value={formatCurrency(formik.values.loanAmount)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.loanAmount && formik.errors.loanAmount}
          autoComplete='off'
        />
        <Typography className={styles.text}>
          Select the financial account where you would like the funds to be
          deposited:
        </Typography>
        <Select
          fullWidth
          label='Select account'
          options={optionsFromAcc}
          {...formik.getFieldProps('selectAccount')}
        />
        <Button
          type='submit'
          className={styles.btn}
          disabled={
            !formik.isValid ||
            !formik.values.loanAmount ||
            !formik.values.selectAccount
          }
        >
          Next
        </Button>
      </form>
    </TransitionAnimation>
  );
};
