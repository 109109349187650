import { AvaiblePointsRewards } from './components/avaiblePointsRewards';
import { RedeemPoints } from './components/redeemPoints';
import TransferPoints from './components/transferPoints';
import styles from './styles.module.scss';
import { AnimateWrapper } from 'components/animate-wrapper';
import ProfessionalCards from './components/professionalCards';
import FeaturedRewards from './components/featuredRewards';
import RecentTransactions from './components/recentTransactions';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { useMediaQuery, useTheme } from '@mui/material';
import { appRoutes } from 'routes';
import { RewardsActivity } from './components/rewardsActivity';

export const Dashboard = () => {
  const { t } = useTranslationProvider();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down('sm'));
  const navigate = useNavigate()
 

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {isMobile && (
          <div className={styles.container_nav}>
            <img
              src={ArrowLeft}
              onClick={() => navigate(appRoutes.rewards())}
            />
            <span className={styles.container_nav_title}>
              {t('rewards.titlte2', {
                defaultValue: 'Dashboard',
              })}
            </span>
          </div>
        )}
        <div className={styles.boxConditionsAndRedeem}>
          <AvaiblePointsRewards />
          <RedeemPoints />
        </div>
        <div className={styles.boxActivityAndRecent}>
          <RewardsActivity/>
          <RecentTransactions />
        </div>
        <FeaturedRewards />
        <div className={styles.box}>
            <TransferPoints />
          {/* <ProfessionalCards /> */}
        </div>
      </div>
    </AnimateWrapper>
  );
};
