import { useState, useEffect, FC } from "react";
import SideBarList from "./components/sideBarList";
import { useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import cx from 'classnames';

import SideBarLogo from "./components/sideBarLogo";
interface SidebarProps {
  setIsShowDrawer?: (boolean) => void;
  isLightMode?: boolean;
}

const SideBar: FC<SidebarProps> = ({ setIsShowDrawer, isLightMode }) => {
  const { pathname } = useLocation();
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const activeLink = pathname.split("/")[1].trim();

  // useEffect(() => {
  //   toggleSelected(activeLink);
  // }, [activeLink]);

  // const toggleSelected = (activeLink) => {
  //   setIsSettingsOpen(activeLink === "settings");
  //   setIsSupportOpen(activeLink === "support");
  // };

  return (
    <div className={cx(styles.container, isLightMode && styles.containerLightMode)}>
      <SideBarLogo isLightMode={isLightMode} setIsShowDrawer={setIsShowDrawer} />

      <SideBarList
        isLightMode={isLightMode}
        isSettingsOpen={isSettingsOpen}
        setIsSettingsOpen={setIsSettingsOpen}
      />
    </div>
  );
};
export default SideBar;
