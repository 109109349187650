import SwitchDirectDeposit from 'shared/components/SwitchDirectDeposit';
import DrawerModal from 'shared/components/drawerModal';

interface SwitchDirectDepositDrawerProps {
  isShow: boolean,
  onClose: () => void,
  onSuccess?: () => void,
}

const SwitchDirectDepositDrawer = ({ isShow, onClose, onSuccess}: SwitchDirectDepositDrawerProps) => {
  const handleOnSuccess = () => {
    onClose();
    onSuccess?.();
  }

  return (
    <DrawerModal
      isShow={isShow}
      onClose={onClose}
    >
      <SwitchDirectDeposit onSuccess={handleOnSuccess} />
    </DrawerModal>
  )
}

export default SwitchDirectDepositDrawer;