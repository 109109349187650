import { Skeleton } from 'components/skeleton';
import { TransitionAnimation } from 'shared/ui/animations';
import { ButtonTab } from 'shared/ui/buttons';
import { WalletIcon } from 'shared/ui/icons';
import { ChevronRightIcon } from 'shared/ui/icons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import styles from './styles.module.scss';

export const Manage = () => {
  const { goNext } = useDrawerStepperContext();
  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <ButtonTab
          startIcon={<WalletIcon />}
          endIcon={<ChevronRightIcon />}
          onClick={() => goNext('3')}
        >
          Manage automations
        </ButtonTab>
      </div>
    </TransitionAnimation>
  );
};
