import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from '../baseApi'
import { ApiTags } from '../api-tags'
import { ExternalBankAccountsProvider, AccountStatus } from './fundsMovement'

export enum Features {
  GiftCardPurchase = 'GiftCardPurchase',
  RedeemsPoints = 'RedeemsPoints',
  AutoPopup = 'AutoPopup'
}
export interface FinancialAccount {
  financialAccountId: string
  name: string
  accountNumber: string
  routingNumber: string
  status: string
  accountType: string
  availableCash: {
    currencyCode: string
    value: number
  }
  cash: {
    currencyCode: string
    value: number
  }
  created: string
  isPrimary: boolean
  cashIn: number
  cashOut: number
  isMine?: boolean
  partnerName: string | null
  features: Features[]
}
export interface FinancialAccountTotal {
  cash: number
  availableCash: number
  cashIn: number
  cashOut: number
}
export interface FinancialAccountsResponse {
  financialAccounts: FinancialAccount[]
  totals: FinancialAccountTotal
}

interface CreateFinancialAccountArg {
  Name: string
}

interface RenameFinancialArg {
  financialAccountId: string
  name: string
}

export enum CashFlowPeriodEnum {
  D7 = 'D7',
  M1 = 'M1',
  M6 = 'M6',
  Y1 = 'Y1',
  QTD = 'QTD',
  YTD = 'YTD',
}

interface CashFlowArg {
  period: CashFlowPeriodEnum;
  financialAccountId?: string;
}

interface GetBalanceArg {
  period: string;
  financialAccountId?: string;
}

export interface CashFlowDataItem {
  cashIn: number;
  cashOut: number;
  dateFrom: string;
  dateTo: string;
}

export interface CashFlowApi {
  data: CashFlowDataItem[];
  total: CashFlowDataItem;
}

export interface PayeeHistoryAllApi {
  accountNickname: string
  accountNumberDisplay: string
  bankName: string
  createdDate: Date
  id: string
  isVerifiedHighNote: boolean
  lastUpdatedDate: Date
  name: string
  number: string
  provider: ExternalBankAccountsProvider
  routingNumber: string
  status: AccountStatus
  verificationError: string | null
}

export interface BalanceHistoryItemApi {
  balance: number;
  date: string;
}

export const financialAccountsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'financialAccounts',
  tagTypes: [
    ApiTags.GetFianancialAccounts,
    ApiTags.GetdefaultTransfersFinAccount,
  ],
  endpoints: (builder) => ({
    financialAccountsController: builder.query<FinancialAccountsResponse, any>({
      providesTags: [ApiTags.GetFianancialAccounts],
      query: () => ({
        url: 'api/financialAccounts/v2',
        method: 'GET',
      }),
    }),
    financialAccountsMyController: builder.query<any, any>({
      providesTags: [ApiTags.GetFianancialAccounts],
      query: () => ({
        url: 'api/financialAccounts/me',
        method: 'GET',
      }),
    }),
    financialAccountController: builder.query<FinancialAccount, any>({
      providesTags: [ApiTags.GetFianancialAccounts],
      query: ({ financialAccountId }) => ({
        url: `api/financialAccounts/findFinancialAccount`,
        method: 'GET',
        params: {
          financialAccountId,
        },
      }),
    }),
    payeeHistoryActionController: builder.query<any, any>({
      query: () => ({
        url: 'api/financialAccounts/external/finicity',
        method: 'GET',
      }),
    }),
    createFinancialAccountController: builder.mutation<
      string,
      CreateFinancialAccountArg
    >({
      invalidatesTags: [
        ApiTags.GetFianancialAccounts,
        ApiTags.GetdefaultTransfersFinAccount,
      ],
      query: ({ Name }) => ({
        url: 'api/financialAccounts/open',
        responseHandler: 'text',
        method: 'POST',
        params: {
          Name,
        },
      }),
    }),
    renameFinancialController: builder.mutation<any, RenameFinancialArg>({
      invalidatesTags: [ApiTags.GetFianancialAccounts],
      query: (queryArg) => ({
        url: 'api/financialAccounts/rename',
        method: 'POST',
        body: queryArg,
      }),
    }),
    payeeHistoryAllController: builder.query<PayeeHistoryAllApi[], void>({
      query: () => ({
        url: 'api/financialAccounts/external/all',
        method: 'GET',
      }),
    }),
    cashFlowController: builder.query<CashFlowApi, CashFlowArg>({
      query: ({ period, financialAccountId }) => ({
        url: 'api/financialAccounts/cashFlow/v2',
        method: 'GET',
        params: {
          period,
          financialAccountId,
        },
      }),
    }),
    balanceController: builder.query<BalanceHistoryItemApi[], GetBalanceArg>({
      query: ({ period, financialAccountId }) => ({
        url: 'api/financialAccounts/balance',
        method: 'GET',
        params: {
          period,
          financialAccountId,
        },
      }),
    }),
  }),
})

export const {
  useFinancialAccountsControllerQuery,
  useFinancialAccountsMyControllerQuery,
  useFinancialAccountControllerQuery,
  usePayeeHistoryActionControllerQuery,
  useCreateFinancialAccountControllerMutation,
  useRenameFinancialControllerMutation,
  usePayeeHistoryAllControllerQuery,
  useCashFlowControllerQuery,
  useBalanceControllerQuery,
} = financialAccountsApi
