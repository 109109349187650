import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as AccordionMUI,
  AccordionActions as AccordionActionsMUI,
  AccordionSummary as AccordionSummaryMUI,
  AccordionDetails as AccordionDetailsMUI,
} from '@mui/material';
import styles from './style.module.scss';
import cx from 'classnames';

interface AccordionProsp {
  summary: string | React.ReactNode;
  children: React.ReactNode;
  styleWrapperContent?: string;
  expanded: boolean;
  onChangeExpanded: (value: boolean) => void;
  styleWrapperRoot?: string;
  styleWrapperSummary?: string;
}

export const Accordion = (props: AccordionProsp) => {
  const {
    summary,
    children,
    styleWrapperContent,
    expanded,
    onChangeExpanded,
    styleWrapperRoot,
    styleWrapperSummary,
  } = props;

  const handleChange = (value) => (_, isExpanded) => {
    onChangeExpanded(isExpanded);
  };

  return (
    <AccordionMUI
      expanded={expanded}
      onChange={handleChange(expanded)}
      classes={{ root: cx(styles.rootAccardion, styleWrapperRoot) }}
    >
      <AccordionSummaryMUI
        classes={{
          root: cx(styles.rootAccordionSummary, styleWrapperSummary),
          content: styles.contentAccordionSummary,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
      >
        {summary}
      </AccordionSummaryMUI>
      <AccordionDetailsMUI
        classes={{ root: cx(styles.rootAccordionDetails, styleWrapperContent) }}
      >
        {children}
      </AccordionDetailsMUI>
    </AccordionMUI>
  );
};
