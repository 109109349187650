import { FilterCards } from 'pages/accountComponent/types'
import { PaymentCard, EnumCardStatuses } from 'api/endpoints/paymentCards'
import { useCurrentUser } from 'providers/user.provider';

export const filteredCards = (cards: PaymentCard[], filter: FilterCards): PaymentCard[] => {
  const { isSubUser, user, subUserRights } = useCurrentUser();

  const userProfileName = isSubUser ? user?.fullName : null;

  const subUserNotHaveAccess = subUserRights({
    viewAllCardsAndFinancialAccounts: false,
    mainAccess: false,
  });

  switch (filter) {
    case FilterCards.MyCards:
      return cards.filter(
        (card) =>
          card.status !== EnumCardStatuses.CLOSED &&
          card.subProfile === userProfileName
      );

    case FilterCards.AllAvailableCards:
      return cards.filter((card) => card.status !== EnumCardStatuses.CLOSED);

    case FilterCards.ClosedCards:
      return subUserNotHaveAccess
        ? cards.filter(
            (card) =>
              card.status === EnumCardStatuses.CLOSED &&
              card.subProfile === user?.fullName
          )
        : cards.filter((card) => card.status === EnumCardStatuses.CLOSED);

    default:
      return cards;
  }
  };