import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { Button } from 'shared/ui/buttons';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { CircularLoader } from 'shared/ui/loaders';
import { TransitionAnimation } from 'shared/ui/animations';
import { useState } from 'react';
import { formatAddress } from 'shared/lib/format';
import { Switch } from 'shared/ui/inputs/switch';
import { DeliveryService } from 'entities/delivery-service/ui/delivery-service';
import { DeliveryForm } from './delivery-form';
import { useAppDispatch } from 'store/hooks';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PhysicalCardOrder, PhysicalCardOrderSchema } from '../../model';
import { OrderOutCardModel } from 'entities/orderout-card';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useMediaQuery } from '@mui/material';

export const OpenPhysicalCardStep1 = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();

  const [deliveryService, setDeliveryService] = useState('');
  const [useAnotherAddress, setUseAnotherAddress] = useState(false);

  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();

  const { data: holderData, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery();

  const { control, formState, getValues, trigger } = useForm<PhysicalCardOrder>({
    resolver: zodResolver(PhysicalCardOrderSchema),
    mode: 'all',
    defaultValues: {
      state: '',
      address: '',
      firstName: '',
      lastName: '',
      postCode: '',
      city: '',
    },
  });

  const fullAddress = formattedHolderData?.bussinessInfo.addressInfo
    ? formatAddress(formattedHolderData?.bussinessInfo.addressInfo)
    : '';

  const handleChangeDeliveryService = (value: string) => {
    setDeliveryService(value);
    trigger();
  };

  const handleChangeAnotherAddress = () => {
    setUseAnotherAddress((prev) => !prev);
  };

  const handleSubmit = () => {
    if (useAnotherAddress) {
      const formValues = getValues();

      dispatch(
        OrderOutCardModel.setDeliveryInfo({
          ...formValues,
          method: deliveryService,
          countryCodeAlpha3:
            formattedHolderData?.bussinessInfo.addressInfo.countryCodeAlpha3 ??
            'USA',
          companyName:
            holderData?.businessAccountHolder?.legalBusinessName ?? 'Unknown',
        })
      );
    } else {
      const adddressInfo = formattedHolderData?.bussinessInfo.addressInfo;

      dispatch(
        OrderOutCardModel.setDeliveryInfo({
          address: adddressInfo?.streetAddress!,
          city: adddressInfo?.locality!,
          postCode: adddressInfo?.postalCode!,
          state: adddressInfo?.region!,
          method: deliveryService,
          firstName: holderData?.businessAccountHolder?.givenName ?? '',
          lastName: holderData?.businessAccountHolder?.familyName ?? '',
          countryCodeAlpha3:
            formattedHolderData?.bussinessInfo.addressInfo.countryCodeAlpha3 ??
            'USA',
          companyName:
            holderData?.businessAccountHolder?.legalBusinessName ?? 'Unknown',
        })
      );
    }

    goNext('5');
  };

  if (formattedholderDataIsLoading || isLoadingHolder) {
    return (
      <div className={styles.loaderContainer}>
        <CircularLoader/>
      </div>
    );
  }

  return (
    <TransitionAnimation>
      <div className={styles.root}>
        <Typography variant="h6">
          Select where you would like your card to be delivered
        </Typography>

        <div className={styles.addressContainer}>
          <Typography variant="subtitle1">Delivery address</Typography>

          <Typography variant="body2">{fullAddress}</Typography>
        </div>

        <Switch
          label={'Ship to a different address'}
          LabelProps={{ labelPlacement: 'start' }}
          fullWidth
          checked={useAnotherAddress}
          onChange={handleChangeAnotherAddress}
        />

        <TransitionAnimation
          inState={useAnotherAddress}
          containerProps={{ className: styles.containerForm }}
        >
          <DeliveryForm control={control}/>
        </TransitionAnimation>

        <DeliveryService
          value={deliveryService}
          onChange={handleChangeDeliveryService}
        />

        <Button
          fullWidth={isMobile}
          disabled={
            fullAddress === '' ||
            deliveryService === '' ||
            (useAnotherAddress && !formState.isValid)
          }
          onClick={handleSubmit}
        >
          NEXT
        </Button>
      </div>
    </TransitionAnimation>
  );
};
