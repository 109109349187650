import { FC } from "react";
import styles from "./styles.module.scss";
import { replaceNumberWithComma } from "shared/utils/price";
import convertIcon from "assets/svg/convrt-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import cx from "classnames";
import { findRewardCategoryByType } from "pages/reward/lib/findRewardCategoryByType";
interface RecentPointsItemProps {
  title: string;
  amount: string;
  points: number;
  period: string;
  type: number;
  typeName: string;
  subjectName: string | null;
}

const RecentPointsItem: FC<RecentPointsItemProps> = ({
  title,
  amount,
  points,
  period,
  type,
  subjectName,
  typeName
}) => {
  const { t } = useTranslationProvider();
  const item = findRewardCategoryByType(type);

  const isPositive = points > 0;
  const pointsColor = isPositive
    ? cx(styles.point, styles.positive)
    : styles.point;

  const titleLabel = t(item?.title!, {
    defaultValue: item?.defaultTitle!,
  }) || typeName

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_title}>
        <span>
           {titleLabel ? titleLabel : subjectName ? subjectName : ''}
        </span>
        <span>{period}</span>
      </div>
      <div className={styles.wrapper_points}>
        <span className={pointsColor}>
          {isPositive && "+"}
          {replaceNumberWithComma(points)} points
        </span>
        <div>
          <img src={convertIcon} alt="Convert" />
          <span>${amount}</span>
        </div>
      </div>
    </div>
  );
};
export default RecentPointsItem;
