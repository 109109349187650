import { useMediaQuery, useTheme } from '@mui/material';
import { useCurrentUser } from '../../providers/user.provider';
import { useFinancialAccounts } from '../../api/hooksApi/useFinancialAccounts';
import { AnimateWrapper } from '../../components/animate-wrapper';
import { AccountList } from './components/accountList';
import AccountBalance from './components/accountBalance';
import { usePermission } from 'shared/hooks/usePermission';
import CardList from './components/cardList';
import { LoginAs } from './components/loginAs';
import { HomeDiagrams } from './components/diagrams';
import { HomeSlider } from './components/homeSlide';
import { Loans } from './components/loans';
import styles from './style.module.scss';

const Home = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { cashflowPermission, homeBannerPermission } = usePermission();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const { isSubUser, subUserRights, isConsumer, isMainBusiness } =
    useCurrentUser();

  const renderHeader = isConsumer ? (
    <div className={styles.staticTitle}>Welcome to Netevia.</div>
  ) : (
    <LoginAs />
  );
  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {renderHeader}
          <div className={styles.content}>
            <div className={styles.box}>
              {isMobile && <AccountBalance />}
              <AccountList />
              {isSubUser &&
                subUserRights({ viewAllCardsAndFinancialAccounts: false }) && (
                  <CardList />
                )}
              {!isMobile && cashflowPermission && <HomeDiagrams />}
            </div>
            <div className={styles.widgetBox}>
              {!isMobile && <AccountBalance />}
              {homeBannerPermission && <HomeSlider />}
              {isMobile && cashflowPermission && <HomeDiagrams />}
              {isMainBusiness && <Loans />}
            </div>
          </div>
        </div>
      </div>
    </AnimateWrapper>
  );
};
export default Home;
