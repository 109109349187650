import { TextField } from '@mui/material';
import React from 'react';
import { useHolderV2ControllerQuery } from '../../../../../api/endpoints/account';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Button } from '../../../../../shared/ui/buttons';
import { useConfirmDialog } from '../../../../../providers/confirm.provider';
import { useSnackBar } from '../../../../../providers/snackBar.provider';
import {
  TicketType,
  useCreateTicketControllerMutation,
} from '../../../../../api/endpoints/support';
import helper from '../../../../../services/helper';

interface Props {
  onCloseDrawer: () => void;
}

const emptyFn = () => {};

export const EditEmployerInformation = ({ onCloseDrawer }: Props) => {
  const { data: holder } = useHolderV2ControllerQuery();
  const confirm = useConfirmDialog();
  const { setSnackBar } = useSnackBar();
  const [createTicket, { isLoading }] = useCreateTicketControllerMutation();

  const handleDropEmployer = () => {
    if (isLoading) {
      return;
    }

    confirm.show({
      dialogTitle: 'No longer an employee',
      dialogText: (
        <div>
          Are you sure you are no longer an employee of{' '}
          <b>{holder?.employer?.name}</b>?
        </div>
      ),
      async onApply() {
        try {
          await createTicket({
            topicId: 5,
            text: `Hello! I am no longer an employee of ${holder?.employer?.name}. Please remove this information from my account.`,
            ticketType: TicketType.banking,
          }).unwrap();

          setSnackBar({
            isShow: true,
            type: 'success',
            message:
              'Your request to update employee information has been sent successfully',
          });

          onCloseDrawer();
        } catch (err: any) {
          setSnackBar({
            isShow: true,
            type: 'error',
            message: helper.formatErrors(err.data),
          });
        }
      },
    });
  };

  if (!holder?.employer) {
    return null;
  }

  return (
    <div>
      <TextField
        type='text'
        label='Current employee'
        value={holder.employer.name}
        fullWidth
        onChange={emptyFn}
        InputLabelProps={{ shrink: true }}
        disabled={true}
      />
      <br />
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label='Connected since'
            disabled={true}
            value={dayjs(new Date(holder.employer.date))}
            onChange={emptyFn}
            sx={{
              width: '100%',
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <br />
      <Button
        onClick={handleDropEmployer}
        variant='outlined'
        color='error'
      >
        No longer an employee
      </Button>
    </div>
  );
};
