import { TransitionAnimation } from 'shared/ui/animations'
import SimpleContainer from 'shared/components/containers/simpleContainer'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/ui/buttons'
import { BatchTransferTypeSelectForm } from './batch-transfer-type-select-form'
import { useAppDispatch, useAppSelector } from 'shared/models'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import helper from 'services/helper'
import styles from './styles.module.scss'
import { useForm } from 'react-hook-form'
import { BatchSelectTypeForm, BatchTypeSelectFormSchema } from '../../model'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  setFromFinancialAccount,
  setTransferType,
  selectFromFinancialAccount,
  selectTransferType,
  TransferType,
  StepContent,
  clearTransfers
} from '../../model'
import { findObjById } from 'entities/transfers'
import { Skeleton } from 'components/skeleton'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { appRoutes } from 'routes'
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement'
import { formatAccountName } from 'shared/lib/format'
import { useEffect } from 'react';
import { useCurrentUser } from '../../../../../providers/user.provider';

interface BatchTransferTypeSelectProps {
  setStepContent: (step: StepContent) => void
}

export const BatchTransferTypeSelect = ({
  setStepContent,
}: BatchTransferTypeSelectProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isConsumer } = useCurrentUser();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()
  const { isLoadingPayees } = useExternalPayees()
  const { isLoading: isLoadingPayeesNetevia } =
    useNeteviaPayeesControllerQuery()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const transferType = useAppSelector(selectTransferType)

  const { control, formState, getValues, setValue } = useForm<BatchSelectTypeForm>({
    resolver: zodResolver(BatchTypeSelectFormSchema),
    mode: 'all',
    defaultValues: {
      fromFinancialAccount: fromFinancialAccount?.id || '',
      transferType: transferType || '',
    },
  })

  useEffect(() => {
    if (isConsumer) {
      setValue('transferType', TransferType.toAch);
    }
  }, [isConsumer]);

  const handleNavigateToNextStep = () => {
    const value = getValues()

    dispatch(
      setFromFinancialAccount({
        value: findObjById(
          value.fromFinancialAccount,
          unclosedFinancialAccounts
        ),
      })
    )
    const transferType = value.transferType as TransferType
    dispatch(setTransferType({ value: transferType }))
    dispatch(clearTransfers())
    setStepContent(StepContent.form)
  }

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))
  const isLaoding =
    isLoadingPayees || financialAccountsIsLoading || isLoadingPayeesNetevia

  return (
    <TransitionAnimation>
      <SimpleContainer
        title={'Batch transfer'}
        onTitleClick={() => navigate(appRoutes.transfer())}
      >
        <div className={styles.container}>
          {isLaoding ? (
            <Skeleton
              width='100%'
              height='110px'
            />
          ) : (
            <>
              <BatchTransferTypeSelectForm
                control={control}
                optionsFromAcc={optionsFromAcc}
              />
              <Button
                className={styles.btn}
                variant='contained'
                disabled={!formState.isValid}
                onClick={handleNavigateToNextStep}
              >
                continue
              </Button>
            </>
          )}
        </div>
      </SimpleContainer>
    </TransitionAnimation>
  )
}
