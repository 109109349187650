import { LoanStatusEnum } from '../types';
import { OnboardingStateEnum } from '../types';

export const loanInfoWarningText = {
  [LoanStatusEnum.Defaulted]:
    'Unfortunately, your funding has defaulted. Please contact us immediately to discuss next steps and potential solutions.',
  [LoanStatusEnum.WrittenOff]:
    'You have missed a payment. Please make the payment to avoid any penalties.',
  [LoanStatusEnum.DiscountedSettlement]:
    'An agreement has been reached, and your loan has been settled for less than the full amount.',
  [LoanStatusEnum.Funded]: '',
  [LoanStatusEnum.FundedPendingSettlement]: '',
  [LoanStatusEnum.Repaid]: '',
  [LoanStatusEnum.RequestedFunding]: '',
};

export const onboardingStateText = {
  [OnboardingStateEnum.contractGenerated]: {
    description:
      'Your contract is now ready for signing. Please review and sign the contract to finalize your funding.',
    title: 'Your funding application has been approved',
  },
  [OnboardingStateEnum.contractSent]: {
    description:
      "Great news! Your funding application has been approved. We're currently preparing your contract.",
    title: 'Your funding application has been approved',
  },
  [OnboardingStateEnum.contractSigned]: {
    description:
      'Your funding request has been submitted and is about to be processed. We`re getting everything ready to fund your funds.',
    title: 'Funding in progress',
  },
  [OnboardingStateEnum.ineligible]: {
    description:
      "Your funding application was declined. Unfortunately, your funding request has been declined. To get more information, please click 'View details'.",
    title: 'Funding application was declined',
  },
  [OnboardingStateEnum.offerAccepted]: {
    description:
      'We`ve recently updated our terms and conditions related to your funding application. Please take a moment to review the updated documents to stay informed.',
    title: 'Review new conditions',
  },
  [OnboardingStateEnum.offersExpired]: {
    description:
      "Your funding offer has expired. If you're still interested, please click 'view details' to explore your options or reapply when you're ready.",
    title: 'Funding offer expired',
  },
  [OnboardingStateEnum.offersProvided]: {
    description:
      'We`ve recently updated our terms and conditions related to your funding application. Please take a moment to review the updated documents to stay informed.',
    title: 'Review new conditions',
  },
  [OnboardingStateEnum.rescinded]: {
    description:
      "Your funding offer has expired. If you're still interested, please click 'View details' to explore your options or reapply when you're ready.",
    title: 'Funding offer expired',
  },
  [OnboardingStateEnum.stage1Incomplete]: {
    description:
      "You've initiated your funding application but haven't completed it yet. To continue and finalize your application, please click the button below.",
    title: 'Funding application in progress',
  },
  [OnboardingStateEnum.stage1Rejected]: {
    description:
      "Your funding application has been declined. To get more information, please, click 'View details'.",
    title: 'Funding application was declined',
  },
  [OnboardingStateEnum.stage1Submitted]: {
    description:
      'Your funding application has been successfully submitted and is currently under review. We appreciate your patience, and you will be notified of the decision shortly.',
    title: 'Funding application in review',
  },
};
