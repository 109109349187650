import { useEffect, useState } from "react";
import { useTranslationProvider } from "providers/translation/translation.provider";
import helper from "services/helper";
import SnackBarSuccess from "shared/components/snackBar/snackBarSuccess";
import Radiogroup from "shared/components/radioGroup";
import AccountSettingsContainer from "../AccountSettingsContainer";

const Language = () => {
  const { t, changeLanguage, currentLanguage } = useTranslationProvider();
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const handleChangeLanguage = async (value: string) => {
    changeLanguage(value);
    await helper.sleep(500);
    setIsShowSuccess(true);
  };
  const items = [
    {
      label: t("common.English", {
        defaultValue: "English",
      }),
      value: "en",
    },
    {
      label: t("common.Español", {
        defaultValue: "Español",
      }),
      value: "es",
    },
  ];
  return (
    <AccountSettingsContainer title="" >
    <>
      <Radiogroup
        iteams={items}
        onChange={handleChangeLanguage}
        currentValue={currentLanguage}
      />
      <SnackBarSuccess
        message={t("Settings.The language has been successfully changed", {
          defaultValue: "The language has been successfully changed",
        })}
        isOpen={isShowSuccess}
        onClose={() => setIsShowSuccess(false)}
      />
    </>
    </AccountSettingsContainer>
  );
};

export default Language;