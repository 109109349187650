import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';
import { CreateSubUserForm } from './components/createSubUserForm';
import { AddPrimaryAccount } from './components/addPrimaryAccount';
import { CreateSubUserSchema } from '../validationSchema';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import moment from 'moment';
import { useAddSubUserControllerMutation } from 'api/endpoints/subProfiles';
import { useEditSubUserAccessRightsControllerMutation } from 'api/endpoints/usersManagement';
import { SubUserFormValues } from 'pages/settings/accountSettings/types';
interface Props {
  isShow: boolean;
  onClose: () => void;
}

export const CreateSubUser = (props: Props) => {
  const { isShow, onClose } = props;
  const { setSnackBar } = useSnackBar();
  const [addSubUserMutation] = useAddSubUserControllerMutation();
  const [editSubUserAccessRightsMutation] =
    useEditSubUserAccessRightsControllerMutation();

  const onSubmitLoadingBool = useBoolean();

  const formik = useFormik<SubUserFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: null as Dayjs | null,
      department: '',
      access: {
        manage: false,
        openBankingCard: false,
        openFinancialAccount: false,
        viewAllCardsAndFinancialAccounts: false,
        makeTransfers: false,
        mainAccess: false,
      },
      primaryAccounId: '',
    },
    validationSchema: CreateSubUserSchema,
    onSubmit: (form) => {
      createNewSubUser(form);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const createNewSubUser = async (form: SubUserFormValues) => {
    try {
      onSubmitLoadingBool.setTrue();

      const res = await addSubUserMutation({
        email: form.email,
        givenName: form.firstName,
        familyName: form.lastName,
        department: form.department,
        dateOfBirth: moment(form?.date?.toDate()).toISOString(),
        phoneNumber: form.phone,
        financialAccountId: form.primaryAccounId,
      }).unwrap();

      const subUserId = res?.id;

      if (subUserId && Object.values(form.access).some((value) => value)) {
        const data = {
          openBankingCard: form.access.openBankingCard,
          openFinancialAccount: form.access.openFinancialAccount,
          viewAllCardsAndFinancialAccounts:
            form.access.viewAllCardsAndFinancialAccounts,
          makeTransfers: form.access.makeTransfers,
          mainAccess: form.access.mainAccess,
        };

        await editSubUserAccessRightsMutation({
          data,
          subUserId,
        }).unwrap();
      }
      onSubmitLoadingBool.setFalse();

      handleClose();
      setSnackBar({
        type: 'success',
        message: 'New authorized user added successfully',
        isShow: true,
      });
    } catch (error: any) {
      onSubmitLoadingBool.setFalse();

      setSnackBar({
        type: 'error',
        message: helper.formatErrors(error.data),
        isShow: true,
      });
    }
  };

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Create New Authorized User',
        prevId: null,
        Element: (
          <CreateSubUserForm
            formik={formik}
            onSubmitLoading={onSubmitLoadingBool.value}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Set primary card account',
        prevId: '0',
        resetStepsOnReach: true,
        Element: <AddPrimaryAccount formik={formik} />,
      },
    ],
  ];

  const MapSteps = new Map(steps);

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={handleClose}
    />
  );
};
