import { useEffect } from 'react';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import helper from 'services/helper';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAddNeteviaCustomerPayeeV2Mutation } from 'api/endpoints/fundsMovement';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { selectNewPayee, clearDataNewPayee, selectNotRegisteredPayee, clearDataToNeteviaCustomer  } from '../../model';
import { SecureOperationType, ShortMessageType } from 'enums';
import { useMfa } from 'providers/mfa/mfa';
import { Skeleton } from 'components/skeleton';

export const AuntificatorPayee = () => {
  const dispatch = useAppDispatch();
  const mfa = useMfa()
  const { setSnackBar } = useSnackBar();
  const { goNext, goBack, close } = useDrawerStepperContext();

  const [addPayee] = useAddNeteviaCustomerPayeeV2Mutation();


  const newPayee = useAppSelector(selectNewPayee);
  const notRegisteredPayee = useAppSelector(selectNotRegisteredPayee)

  const navigateToNextStep = () => {
    if (notRegisteredPayee) {
      dispatch(clearDataToNeteviaCustomer())
      close()
    }else{
      goNext('0')
    }
  }

  const handlerSubmit = async (secureOperationType: SecureOperationType, code: string) => {
    try {
      if (code) {
        await addPayee({
          accountNumber: newPayee.number,
          name: newPayee.name,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
        }).unwrap();
        dispatch(clearDataNewPayee());
        mfa.close();
        setSnackBar({
          type: 'success',
          message: 'Payee added successfully.',
          isShow: true,
        })
        navigateToNextStep()
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      isOnlySmsFlow: true,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await handlerSubmit(secureOperationType, code);
      }
    })
  }, []);


  return (
    <TransitionAnimation>
      <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
