import { Popup } from "components/popup";
import styles from "./style.module.scss";
import FilterByDate from "../filterByDate";
import FilterByType from "../filterByType";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import { Ticket } from "api/endpoints/tickets";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "shared/ui/buttons";
import { useFormik } from "formik";
import { Typography } from "shared/ui/typography";
import { ReturnType } from "shared/hooks/useBoolean";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Button as ButtonMui } from "@mui/material";
import { TicketStatus } from "api/endpoints/tickets";
import { HistoryFilterSchema } from "../ validationSchema";

interface FilterProps {
  showFilterBool: ReturnType
  periodDate: DateRange<Dayjs | null>
  setPeriodDate: (periodDate: DateRange<Dayjs | null>) => void
  setTickets: (tickets: Ticket[]) => void;
  type: TicketStatus[]
  setType: ( type: TicketStatus[] ) => void
}

const Filter = (props: FilterProps) => {
  const {
    showFilterBool,
    periodDate,
    setPeriodDate,
    setTickets,
    type,
    setType
  } = props
  const { history } = useSupportHistory();
  const { t } = useTranslationProvider();

  const formik = useFormik({
    initialValues: {
      byDate: periodDate,
      byType:type
    },
    validationSchema: HistoryFilterSchema,
    onSubmit: (values) => {
    setPeriodDate(values.byDate)
    setType(values.byType)
    showFilterBool.setFalse()
    },
  });


  const resetFilters = () => {
    setPeriodDate([null, null])
    setType([])
    setTickets(history);
    showFilterBool.setFalse()
  };

  const  hasDataForm = () => {
    const hasByDateData = formik.values.byDate.some(date => date !== null); 
    const hasByTypeData = formik.values.byType.length > 0; 

    return hasByDateData || hasByTypeData; 
  }

  return (
    <Popup onClose={() =>  showFilterBool.setFalse()} stylesWrapper={styles.popup}>
      <form className={styles.container}
            onSubmit={formik.handleSubmit}
      >
        <div className={styles.container_boxTitle}>
          <Typography className={styles.container_boxTitle_text}>
            {t("common.Filter requests", {
              defaultValue: "Filter requests",
            })}
          </Typography>
          <Button
          variant="text"
            onClick={resetFilters}
          >
            {t("common.Clear all", {
              defaultValue: "Clear all",
            })}
          </Button>
        </div>

        <FilterByDate
          formik={formik}
        />
        <FilterByType
         formik={formik}
        />
        <div className={styles.container_boxButtons}>
          <Button
          variant="text"
            onClick={() =>  showFilterBool.setFalse()}
          >
            {t("common.Cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
          <ButtonMui
            className={styles.btnApply}
            variant="contained"
            type="submit"
            disabled={Object.values(formik.errors).some(Boolean) || !formik.dirty || !hasDataForm()} 
          >
            {t("common.Apply filter", {
              defaultValue: "Apply filter",
            })}
          </ButtonMui>
        </div>
      </form>
    </Popup>
  );
};

export default Filter;
