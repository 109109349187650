import AccountListHeader from '../accountList/components/accountListHeader';
import { useCurrentUser } from 'providers/user.provider';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import {
  Checkbox,
  css,
  FormControlLabel,
  Skeleton,
  styled,
} from '@mui/material';
import { AnimateWrapper } from 'components/animate-wrapper';
import DrawerModal from 'shared/components/drawerModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBoolean } from 'shared/hooks/useBoolean';
import { EnumCardStatuses, PaymentCard } from 'api/endpoints/paymentCards';
import { useSubUserInfo } from 'api/hooksApi/useSubUrerInfo';
import { CardCells } from 'shared/components/TableBlock/TableCells';
import TableBlock from 'shared/components/TableBlock';
import { appRoutes } from 'routes';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { CreateCardComponent } from 'shared/components/CreateCardComponent';
import { usePermission } from 'shared/hooks/usePermission';

export const OPEN_CARD = 'openCard';

const avalibleMods = [
  { path: 'openCard', title: 'Open new card' },
  { path: 'physicalCard', title: 'Open physical card' },
  { path: 'burnerCard', title: 'Open burner card' },
  { path: 'openVirtualcard', title: 'Open virtual card' },
  { path: 'openPrimaryCard', title: 'Open primary card' },
];

const CardList = () => {
  const navigateRoute = useNavigate();

  const { userIsLoading } = useCurrentUser();
  const { subUserInfo: subProfileMe } = useSubUserInfo(true);

  const { paymentCards, paymentCardsIsFetching } = usePaymentCards();
  const [searchParams, setSearchParams] = useSearchParams();
  const { unclosedFinancialAccountsSet } = useFinancialAccounts();
  const { canOpenCard, viewAllFaAndCards } = usePermission();

  const showClosedCardsBool = useBoolean();

  const isLoading = [userIsLoading, paymentCardsIsFetching].some(Boolean);

  const cardPredicate = (pc: PaymentCard) => {
    if (viewAllFaAndCards) {
      return true;
    }

    return pc.subProfile === subProfileMe?.fullName;
  };

  const hasNoUnclosedAccounts = (pc: PaymentCard) =>
    !pc.financialAccounts.some((fa) => unclosedFinancialAccountsSet.has(fa.id));

  const subUserConnectedCard = paymentCards?.filter(
    (i) =>
      cardPredicate(i) &&
      hasNoUnclosedAccounts(i) &&
      (showClosedCardsBool.value ? true : i.status !== EnumCardStatuses.CLOSED)
  );

  const openNewCard = () => {
    setSearchParams({ mode: 'openCard' });
  };

  const currentMode = searchParams.get('mode') || '';

  const handleChangeMode = (mode: string) => {
    setSearchParams({ mode });
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='250px'
    />
  ) : (
    <AnimateWrapper className='fade'>
      <Container>
        <AccountListHeader
          handleAddAcc={openNewCard}
          rights={canOpenCard}
          title='Your cards'
          openLink={OPEN_CARD}
          buttonLabel={'open new card'}
        />
        {!!subUserConnectedCard.length ? (
          <TableBlock
            isRowsClickable
            rows={subUserConnectedCard}
            tabCells={CardCells().filter((c) => c.name !== 'department')}
            isCards
            onClick={(cardId) =>
              navigateRoute(appRoutes.cardTransactions(cardId))
            }
          />
        ) : (
          <EmptyListFallback>
            You don’t have payment cards yet
          </EmptyListFallback>
        )}
        <DrawerModal
          isShow={avalibleMods.map((mode) => mode.path).includes(currentMode)}
          onClose={() => {}}
          titleText={
            avalibleMods.find((mode) => mode.path === currentMode)?.title ||
            avalibleMods[0].title
          }
          goBack={
            currentMode !== avalibleMods[0].path
              ? () => handleChangeMode(avalibleMods[0].path)
              : undefined
          }
        >
          <CreateCardComponent
            currentMode={currentMode}
            setCurrentMode={handleChangeMode}
            backRoute='Home'
          />
        </DrawerModal>
      </Container>
    </AnimateWrapper>
  );
};

export default CardList;

const Container = styled('div')`
  ${({ theme }) => css`
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    border: 1px solid ${theme.colors.borderLightGrey};

    & > *:nth-child(1) {
      padding: 16px 24px;
    }

    ${theme.breakpoints.down('sm')} {
      & > *:nth-child(1) {
        padding: 16px;
      }
    }
  `}
`;

const EmptyListFallback = styled('div')`
  margin-left: 24px;
  margin-bottom: 40px;
  padding: 16px 24px;
`;
