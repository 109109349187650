import React, { PropsWithChildren, useMemo, useState } from "react";
import {
  RewardsHistory,
  useRewardsControllerQuery,
  RewardslastPeriodGroupedApi,
  RewardsLastPeriodApi,
} from "../../../../api/endpoints/rewards";
import moment from "moment/moment";
import { useRewardsHistory } from "api/hooksApi/useRewardsHistory";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useRewardsHistoryControllerQuery } from "../../../../api/endpoints/rewards";
import { RewardsTypesApi } from "api/endpoints/enums";
import { invertPointsForType } from "pages/reward/lib/invertPointsForType";

export interface RewardsHistoryDatePeriod {
  dateFrom: string;
  dateTo: string;
}

interface ContextProps {
  totalPoints: number;
  lastPeriodPoints: number;
  lastPeriodGrouped: RewardslastPeriodGroupedApi[];
  lastPeriodRewards: RewardsLastPeriodApi[];
  isDataLoading: boolean;
  rewardsHistory: RewardsHistory[];
  isFetchingHistory: boolean;
  refetchRewardsHistory: () => void;
  recentTransactions5: RewardsHistory[];
  recentTransactionsIsLoading: boolean;
  refetchRecentTransactions: () => void;
  publishedReports: { item1: string; item2: number }[];
  rewardsHistoryDatePeriod: RewardsHistoryDatePeriod;
  setRewardsHistoryDatePeriod: ({
    dateFrom,
    dateTo,
  }: RewardsHistoryDatePeriod) => void;
  rewardHistoryType: RewardsTypesApi;
  setRewardsHistoryType: ({ id, description }: RewardsTypesApi) => void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);
const dateFrom = moment.utc().startOf("month").format("YYYY-MM-DD");
const dateTo = moment.utc().endOf("month").format("YYYY-MM-DD");

export const RewardsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslationProvider();
  const { data, isFetching } = useRewardsControllerQuery({});
  const [rewardsHistoryDatePeriod, setRewardsHistoryDatePeriod] =
    useState<RewardsHistoryDatePeriod>({
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  const [rewardHistoryType, setRewardsHistoryType] = useState<RewardsTypesApi>({
    name: "All",
    id: 15,
    description: t("common.All", {
      defaultValue: "All",
    }),
    groupName: null,
  });

  const { history, isFetchingHistory, refetchRewardsHistory } =
    useRewardsHistory(rewardsHistoryDatePeriod, rewardHistoryType);

  const {
    data: recentTransactions,
    isFetching: recentTransactionsIsLoading,
    refetch: refetchRecentTransactions,
  } = useRewardsHistoryControllerQuery({
    dateFrom: dateFrom,
    dateTo: dateTo,
  });


  const value = useMemo((): ContextProps => {

    const updatedHistoryForType11 = invertPointsForType(history?.histories, 11);

    const recentTransactions5 = invertPointsForType(
      recentTransactions?.histories?.slice(0, 5) ?? [],
      11
    );

    return {
      totalPoints: data?.totalPoints || 0,
      lastPeriodPoints: data?.lastPeriodPoints || 0,
      lastPeriodGrouped: data?.lastPeriodGrouped,
      lastPeriodRewards: data?.lastPeriodRewards,
      isDataLoading: isFetching,
      rewardsHistory: updatedHistoryForType11, 
      isFetchingHistory,
      refetchRewardsHistory,
      recentTransactions5,
      recentTransactionsIsLoading,
      refetchRecentTransactions,
      publishedReports: history?.publishedReports,
      rewardsHistoryDatePeriod,
      setRewardsHistoryDatePeriod,
      rewardHistoryType,
      setRewardsHistoryType,
    } as unknown as ContextProps;
  }, [data, isFetching, history, rewardHistoryType, recentTransactions]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useRewards = () => React.useContext(Context) as ContextProps;
