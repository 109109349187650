import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { useRewardIntegrations } from 'api/hooksApi/useRewardsIntegrations';
import { RewardIntegrationsApi, Place } from 'api/endpoints/rewardIntegrations';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { appRoutes, RewardsRouteSubRoute } from 'routes';
import { SliderRewards } from 'pages/reward/components/dashboard/components/featuredRewards/componetns/sliderRewards';
import { useAprilURLMutation } from 'api/endpoints/user';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import aprilHomeBanner from 'assets/img/april-home-banner.png';
import { RewardMode } from 'pages/reward/types';
import SwitchDirectDeposit from 'shared/components/SwitchDirectDeposit';
import { useBoolean } from 'shared/hooks/useBoolean';
import DrawerModal from 'shared/components/drawerModal';
import SwitchDirectDepositDrawer from './component/SwitchDirectDepositDrawer';

const SLIDER_LENGTH = 1;

export const HomeSlider = () => {
  const isShow = useBoolean(false);
  const navigate = useNavigate();
  const { setSnackBar } = useSnackBar();
  const { rewardIntegrations, rewardIntegrationsIsLoading } =
    useRewardIntegrations({ place: Place.Home });
  const [aprilURLMutation, { isLoading: aprilURLIsLoading }] =
    useAprilURLMutation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: SLIDER_LENGTH,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderData = rewardIntegrations.map((item) => {
    if (item.type === 2 && item.imageUrls.length > 1) {
      return {
        ...item,
        imageUrls: item.imageUrls.map((image, index) =>
          index === 1 ? { ...image, filePath: aprilHomeBanner } : image
        ),
      };
    }
    return item;
  });

  const getAprilLink = async () => {
    try {
      const res = await aprilURLMutation().unwrap();
      window.open(res);
    } catch (e: any) {
      console.log('error', e);
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const sliderHandler = (item: RewardIntegrationsApi) => {
    if (item.type === 4 && item.name === 'Gift card deals') {
      navigate(
        appRoutes.rewards(RewardsRouteSubRoute.myGiftCard, {
          mode: RewardMode.Brands,
        })
      );
    }
    if (item.type === 2) {
      getAprilLink();
    }

    if (item.type === 6 && item.name === 'Direct deposit') {
      isShow.setTrue();
    }
  };

  const BANNER_LENGTH = sliderData?.length;

  const disabledPrevSlide = currentSlideIndex === 0;

  const disabledNextSlide = currentSlideIndex === BANNER_LENGTH - SLIDER_LENGTH;

  return (
    <>
    <TransitionAnimation>
      <SliderRewards
        data={sliderData as any}
        loadingData={rewardIntegrationsIsLoading}
        disabledPrevSlide={disabledPrevSlide}
        disabledNextSlide={disabledNextSlide}
        setCurrentSlideIndex={setCurrentSlideIndex}
        sliderHandler={sliderHandler}
        settingsSlider={settingsSlider}
        styleWrapper={styles.wrapper}
        styleWrapperHeader={styles.styledHeader}
        styleItem={{
          wrapper: styles.styledItemWrapper,
          item: styles.styledItem,
        }}
        aprilURLIsLoading={aprilURLIsLoading}
      />
    </TransitionAnimation>
    <SwitchDirectDepositDrawer isShow={isShow.value} onClose={isShow.setFalse} />
    </>
  );
};
