export enum SupportModeEnum {
  Faq = "Faq",
  CreateNewRequest = "CreateNewRequest",
  History = "History",
  ChatSupport = "ChatSupport",
}

export const NEW_REQUEST_PARAMS = {
  pageMode: SupportModeEnum.CreateNewRequest,
  title: 'Create new request',
}


