import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PropsWithChildren } from "react";
import styles from "./style.module.scss";
import cx from "classnames";

interface Props {
  buttonLable: string;
  img: React.ReactNode;
  expanded?: boolean;
  setExpanded: (value: boolean) => void;
  activeSummary: boolean;
  isLightMode?: boolean;
}

const AccordionSideBar = ({
  children,
  buttonLable,
  img,
  expanded,
  setExpanded,
  activeSummary,
  isLightMode
}: PropsWithChildren<Props>) => {
  const activeStyleItem = (activeSummary) =>
    cx(
      styles.container_summary,
      isLightMode && styles.container_summaryLight,
    { [isLightMode ? styles.activeBlack : styles.active]: activeSummary });

  const renderTitleOrImgTitle = () => (
    <div className={cx(styles.container_summary_box, isLightMode && styles.container_summary_boxLight)}>
      {img}
      <span className={styles.container_summary_label}>{buttonLable}</span>
    </div>
  );
  return (
    <Accordion
      className={cx(styles.container, isLightMode && styles.containerLightMode)}
      expanded={expanded}
      onClick={() => setExpanded(!expanded)}
      sx={{
        "&::before": {
          content: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={cx(isLightMode && styles.IconLight)} />}
        className={activeStyleItem(activeSummary)}
        sx={{
          ".MuiAccordionSummary-content": {
            margin: 0,
          },
          ".MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
          ".MuiSvgIcon-root": {
            fill: " #FFFFFF",
          },
        }}
      >
        {renderTitleOrImgTitle()}
      </AccordionSummary>
      <AccordionDetails className={styles.container_details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSideBar;
