import { Alert } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { loanInfoWarningText } from 'pages/home/components/loans/constants';
import { LoanStatusEnum } from 'pages/home/components/loans/types';
import { appRoutes, SettingsRouteSubRoute } from 'routes';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

interface AlertLoansInfoProps {
  status: string;
}
export const AlertLoansInfo = ({ status }: AlertLoansInfoProps) => {
  const navigate = useNavigate();
  const warningText = loanInfoWarningText[status];

  const alertColor =
    status === LoanStatusEnum.DiscountedSettlement ? 'info' : 'error';

  return warningText ? (
    <Alert
      severity={alertColor}
      classes={{
        action: styles.alertAction,
        icon: styles.alertIcon,
        root: styles.alertRoot,
      }}
      action={
        status !== LoanStatusEnum.Defaulted &&
        status !== LoanStatusEnum.DiscountedSettlement && (
          <Button
            color='error'
            variant='text'
            className={styles.btn}
            onClick={() =>
              navigate(appRoutes.settings(SettingsRouteSubRoute.support))
            }
          >
            Сontact us
          </Button>
        )
      }
    >
      {warningText}
    </Alert>
  ) : (
    <></>
  );
};
