import { useEffect } from 'react'
import { TransitionAnimation } from 'shared/ui/animations'
import SimpleContainer from 'shared/components/containers/simpleContainer'
import {
  selectFromFinancialAccount,
  selectTransferType,
  TransferType,
  selectErrorForm,
  StepContent,
  setErrorForm,
  SourceAccount,
  setTotalAmount,
  setTransfers,
  selectTransfers,
} from '../../model'
import { useAppDispatch, useAppSelector } from 'shared/models'
import { Button } from 'shared/ui/buttons'
import styles from './styles.module.scss'
import helper from 'services/helper'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { useExternalPayees, Payee } from 'api/hooksApi/useExternalPayees'
import { useForm, useFieldArray ,Path} from 'react-hook-form'
import { findObjById } from 'entities/transfers'
import { AchTransfer, AchTransferSchema } from '../../model/achTransferSchema'
import { TotalBalance } from '../total-balance'
import { SourceAccountList } from '../source-account-list'
import { useMediaQuery, Divider } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { BatchTransferFormItem } from './batch-transfer-form-item'
import {
  useNeteviaPayeesControllerQuery,
  PayeeType,
} from 'api/endpoints/fundsMovement'
import { formatAccountName } from 'shared/lib/format'

interface BatchTransferFormProps {
  setStepContent: (step: StepContent) => void
}

export const BatchTransferForm = ({
  setStepContent,
}: BatchTransferFormProps) => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const { unclosedFinancialAccounts } = useFinancialAccounts()
  const { payees } = useExternalPayees()
  const { data: neteviaPayees } = useNeteviaPayeesControllerQuery()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const transferType = useAppSelector(selectTransferType)
  const errorFrom = useAppSelector(selectErrorForm)
  const transfers = useAppSelector(selectTransfers)

  const initialFormState = {
    fromFinancialAccount: fromFinancialAccount?.id || '',
    toPayee: '',
    amount: '',
    memo: ''
  }
  const isTransferToNetevia = transferType === TransferType.toNetevia

  const {
    control,
    register,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors, isValid },
    setValue
  } = useForm<AchTransfer>({
    resolver: zodResolver(AchTransferSchema),
    mode: 'all',
    defaultValues: {
      forms: transfers || [initialFormState],
    },
  })

  const { fields, append, remove, update, } = useFieldArray({
    control,
    name: 'forms',
  })

  const currentFormValues = watch(`forms`)

  useEffect(() => {
    validateAmountAcoountFrom()
  }, [currentFormValues])

  const validateAmountAcoountFrom = () => {
    currentFormValues.forEach((form, index) => {
      const account = findObjById(
        form.fromFinancialAccount,
        unclosedFinancialAccounts
      )
      const amount = parseFloat(form.amount)
      if (amount > account?.availableCash?.value) {
        setError(`forms.${index}.amount`, {
          type: `forms.${index}.amount`,
          message: `Not enough funds in the account ${account.name}`,
        })
      } else {
        clearErrors(`forms.${index}.amount`)
      }
    })
  }

  const calculateTotalByAccountForSourceList = () =>
    currentFormValues.reduce((acc: SourceAccount[], curr) => {
      const existingItem = acc.find(
        (item) => item.fromFinancialAccount === curr.fromFinancialAccount
      )
      const amountToAdd = !isNaN(parseFloat(curr.amount))
        ? parseFloat(curr.amount)
        : 0

      if (existingItem) {
        existingItem.totalAmount += amountToAdd
      } else {
        acc.push({
          fromFinancialAccount: curr.fromFinancialAccount,
          totalAmount: amountToAdd,
        })
      }

      return acc
    }, [])

  const sourceList = calculateTotalByAccountForSourceList()

  const errorListTotalBalance = sourceList.some((item) => {
    const account = findObjById(
      item.fromFinancialAccount,
      unclosedFinancialAccounts
    )
    return item.totalAmount > account?.availableCash?.value
  })

  const errorsValidationForm =
    Object.keys(errors).some((key) => errors[key]) || errorListTotalBalance

  useEffect(() => {
    dispatch(
      setErrorForm({
        value: errorsValidationForm,
      })
    )
  }, [sourceList])

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))

  const optionsToPayee = (payees) => {
    return payees.map((payee: Payee | PayeeType) => ({
      id: payee.id,
      value: payee.id,
      content: (
        <div>
          {payee.name} ({payee.last4})
        </div>
      ),
    }))
  }
  const renderForm = () => {
    return fields.map((field, index) => (
      <BatchTransferFormItem
        key={field.id}
        formIndex={index}
        control={control}
        optionsFromAcc={optionsFromAcc}
        optionsToPayee={optionsToPayee(
          isTransferToNetevia ? neteviaPayees?.payees : payees
        )}
        handleDeleteTransfer={handleDeleteTransfer}
        isDeleteDisabled={isDeleteDisabled}
        applyAllAmount={applyAllAmount}
        registerFromFinancialAccount={register(
          `forms.${index}.fromFinancialAccount`
        )}
        registerPayee={register(`forms.${index}.toPayee`)}
        registerAmount={register(`forms.${index}.amount`)}
        updateForm={update}
        onAccountFromChange={() => {
          validateAmountAcoountFrom()
          calculateTotalByAccountForSourceList()
        }}
        isTransferToNetevia={isTransferToNetevia}
        applyAllMemo={applyAllMemo}
      />
    ))
  }
  const applyAllAmount = (amountValue: string) => { 
    fields.forEach((item, index) => {
      const path: Path<AchTransfer> = `forms.${index}.amount`;
      setValue(path, amountValue);
  })
  }
  
  const applyAllMemo = (memo: string) => {
    fields.forEach((item, index) => {
      const path: Path<AchTransfer> = `forms.${index}.memo`;
      setValue(path, memo)
  })
  }

  const totalAmount = fields.reduce(
    (acc, curr) => acc + (parseInt(curr.amount) || 0),
    0
  )

  const isDeleteDisabled = fields.length <= 1

  const handleDeleteTransfer = (index: number) => {
    remove(index)
  }

  const handleAddAnotherTransfer = () => {
    append(initialFormState)
  }

  const onSubmitForm = () => {
    const { forms } = getValues()
    dispatch(
      setTransfers({
        value: forms,
      })
    )

    dispatch(
      setTotalAmount({
        value: totalAmount,
      })
    )
    setStepContent(StepContent.confirm)
  }
  return (
    <TransitionAnimation>
      <SimpleContainer
        title={`Batch transfer - ${transferType}`}
        onTitleClick={() => setStepContent(StepContent.typeSelect)}
      >
        <div className={styles.container}>
          <div className={styles.boxInfo}>
            <SourceAccountList sourceList={sourceList} />
            {isMobile && <Divider />}
            <TotalBalance
              total={totalAmount}
              errorForTotalBalance={errorListTotalBalance}
            />
            {isMobile && <Divider />}
          </div>
          {renderForm()}
          <Button
            className={styles.btn}
            variant='text'
            onClick={handleAddAnotherTransfer}
          >
            + Add another transfer
          </Button>
          <Button
            className={styles.btnSubmit}
            variant='contained'
            onClick={onSubmitForm}
            disabled={!isValid || errorFrom}
          >
            continue
          </Button>
        </div>
      </SimpleContainer>
    </TransitionAnimation>
  )
}
