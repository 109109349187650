import styles from './style.module.scss';
import { Typography } from '../../shared/ui/typography';
import { Button } from '../../shared/ui/buttons';
import { SvgIcon } from '../../shared/components/svgIcon';
import { useLoginManagement } from '../../shared/hooks/useLoginManagement';
import clsx from 'clsx';

interface BackToMyAccountProps {
  className?: string;
}

export const BackToMyAccount = ({ className }: BackToMyAccountProps) => {
  const loginManagement = useLoginManagement();

  return (
    <div className={clsx(styles.backToMyAccount, className)}>
      <Typography variant='body2'>
        Currently, you are logged in using one of your connected accounts.
        To return to your main account, please click below:
      </Typography>
      <Button
        fullWidth={false}
        variant="contained"
        onClick={loginManagement.backToMyAccount}
        startIcon={<SvgIcon name="LogoutOutlined" sizePx={18}/>}
      >
        back to my account
      </Button>
    </div>
  )
}
