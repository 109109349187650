import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

interface NotificationsItemApi {
  emailEnabled: boolean;
  pushEnabled: boolean;
}
interface SaveNotificationsArg {
  emailEnabled: boolean;
  pushEnabled: boolean;
}

interface SaveOpsConfirmationArg {
  withOneTimePassword: boolean;
}

interface DeleteDeviceControllerArg {
  deviceId: string;
}

interface Device {
  creationDate: string;
  deviceId: string;
  deviceName: string;
}

interface DevicesItemApi {
  Devices: Device[];
}
interface ChangeDefaultTransfersFinAccountArg {
  finAccountId: string;
}
interface OpsConfirmationApi{
  shortMessageCodeEnabled:boolean
  timeBasedCodeEnabled:boolean
}
export const settingsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "settings",
  tagTypes: [
    ApiTags.GetNotifications,
    ApiTags.GetSettingsNotifications,
    ApiTags.GetDevices,
    ApiTags.GetdefaultTransfersFinAccount,
    ApiTags.GetNotificationsTransfers,
    ApiTags.GetNotificationSettingsTypes,
  ],

  endpoints: (builder) => ({
    notificationsController: builder.query<NotificationsItemApi, any>({
      providesTags: [ApiTags.GetSettingsNotifications],
      query: () => ({
        url: "/settings/informing",
        method: "GET",
      }),
    }),
    saveNotificationsController: builder.mutation<any, SaveNotificationsArg>({
      invalidatesTags: [ApiTags.GetSettingsNotifications, ApiTags.GetNotificationSettingsTypes],
      query: (queryArg) => ({
        url: "/settings/informing",
        method: "POST",
        body: queryArg,
      }),
    }),

    opsConfirmation: builder.query<OpsConfirmationApi, void>({
      providesTags: [ApiTags.GetNotificationsTransfers],
      query: () => ({
        url: "settings/opsConfirmation",
        method: "GET",
      }),
    }),

    saveOpsConfirmationController: builder.mutation<
      any,
      SaveOpsConfirmationArg
    >({
      invalidatesTags: [ApiTags.GetNotificationsTransfers],
      query: (queryArg) => ({
        url: "settings/opsConfirmation",
        method: "POST",
        body: queryArg,
      }),
    }),

    devicesController: builder.query<DevicesItemApi, any>({
      providesTags: [ApiTags.GetDevices],
      query: () => ({
        url: "settings/devices",
        method: "GET",
      }),
    }),

    deleteDeviceController: builder.mutation<any, DeleteDeviceControllerArg>({
      invalidatesTags: [ApiTags.GetDevices],
      query: (queryArg) => ({
        url: "settings/devices",
        method: "DELETE",
        body: queryArg,
      }),
    }),

    defaultTransfersFinAccountController: builder.query<any, any>({
      providesTags: [ApiTags.GetdefaultTransfersFinAccount],
      query: () => ({
        url: "settings/defaultTransfersFinAccount",
        method: "GET",
      }),
    }),
    changeDefaultTransfersFinAccountController: builder.mutation<
      any,
      ChangeDefaultTransfersFinAccountArg
    >({
      invalidatesTags: [ApiTags.GetdefaultTransfersFinAccount],
      query: (queryArg) => ({
        url: "settings/changeDefaultTransfersFinAccount",
        method: "POST",
        body: queryArg,
      }),
    }),
    translationController: builder.query<any, any>({
      query: () => ({
        url: "settings/localization",
        method: "GET",
      }),
    }),
    cardProductSettingsController: builder.query<any, any>({
      query: () => ({
        url: "settings/cardProductSettings",
        method: "GET",
      }),
    }),
    plaidApplicationsController: builder.query<any, any>({
      query: () => ({
        url: "/api/plaidApplications",
        method: "GET",
      }),
    }),
    unlinkPlaidApplicationsController: builder.mutation<any, any>({
      query: (applicationId) => ({
        url: "/api/plaidApplications/unlink",
        method: "POST",
        params: {
          applicationId,
        },
      }),
    }),
    usersRewardController: builder.query<any, any>({
      query: () => ({
        url: "api/UsersReward/template",
        method: "GET",
      }),
    }),

  }),
});

export const {
  useNotificationsControllerQuery,
  useSaveNotificationsControllerMutation,
  useOpsConfirmationQuery,
  useSaveOpsConfirmationControllerMutation,
  useDevicesControllerQuery,
  useDeleteDeviceControllerMutation,
  useDefaultTransfersFinAccountControllerQuery,
  useChangeDefaultTransfersFinAccountControllerMutation,
  useTranslationControllerQuery,
  useCardProductSettingsControllerQuery,
  usePlaidApplicationsControllerQuery,
  useUnlinkPlaidApplicationsControllerMutation,
  useUsersRewardControllerQuery,
} = settingsApi;
