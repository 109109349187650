import { Button } from 'shared/ui/buttons'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { useAppSelector, useAppDispatch } from 'shared/models'
import helper from 'services/helper'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ManageScheduleTransferForm } from './manage-schedule-transfer-form'
import {
  ScheduleTransfer,
  ScheduleTransferSchema,
} from '../../model/scheduleTransferModel'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { FREQUENCY_DATA } from 'entities/transfers'
import { setSelectScheduleTransfer } from '../../model'
import { findObjById } from 'entities/transfers'
import { selectScheduleTransfer } from '../../model'
import { Skeleton } from 'components/skeleton'
import { formatAccountName } from 'shared/lib/format'

export const ManageScheduleTransferEdit = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { unclosedFinancialAccounts } = useFinancialAccounts()
  const { plaidPayees, isLoadingPayees } = useExternalPayees()

  const scheduleTransfer = useAppSelector(selectScheduleTransfer)

  const { control, formState, getValues } = useForm<ScheduleTransfer>({
    resolver: zodResolver(ScheduleTransferSchema),
    mode: 'all',
    defaultValues: {
      fromAccount: scheduleTransfer?.fromAccount.id,
      toAccount: scheduleTransfer?.toAccount.id,
      amount: scheduleTransfer?.amount,
      frequency: scheduleTransfer?.frequency,
      dateTransfer: scheduleTransfer?.dateTransfer,
    },
  })
  const isFormDirty = formState.isDirty

  const onSubmit = () => {
    const value = getValues()

    dispatch(
      setSelectScheduleTransfer({
        value: {
          id: scheduleTransfer?.id!,
          fromAccount: findObjById(value.fromAccount, plaidPayees),
          toAccount: findObjById(value.toAccount, unclosedFinancialAccounts),
          amount: value.amount,
          frequency: value.frequency,
          dateTransfer: value.dateTransfer,
        },
      })
    )
    goNext('6')
  }

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****{account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }))

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {isLoadingPayees ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <>
            <ManageScheduleTransferForm
              control={control}
              optionsToAcc={optionsToAcc}
              optionsFrequency={optionsFrequency}
              payees={plaidPayees}
            />
            <Button
              variant='contained'
              disabled={!formState.isValid || !isFormDirty}
              onClick={onSubmit}
            >
              save changes
            </Button>
          </>
        )}
      </div>
    </TransitionAnimation>
  )
}
