import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import { onboardingStateText } from '../../constants';
import { OnboardingStateEnum } from '../../types';
import { useLaonDismissMutation } from 'api/endpoints/loans';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import styles from './style.module.scss';

interface OnboardingStateProps {
  onboardingState: string;
  signUrl: string;
}

export const OnboardingState = (props: OnboardingStateProps) => {
  const { onboardingState, signUrl } = props;
  const { setSnackBar } = useSnackBar();
  const [laonDismis, { isLoading: isLoadingLaonDismis }] =
    useLaonDismissMutation();

  const onboardingObj = onboardingStateText[onboardingState];
  const viewDismissBtn =
    onboardingState === OnboardingStateEnum.offersExpired ||
    onboardingState === OnboardingStateEnum.stage1Rejected ||
    onboardingState === OnboardingStateEnum.ineligible ||
    onboardingState === OnboardingStateEnum.rescinded;

  const dismis = async () => {
    try {
      await laonDismis(true).unwrap();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography className={styles.label}>{onboardingObj.title}</Typography>
        <Typography className={styles.description}>
          {onboardingObj.description}
        </Typography>
        <div className={styles.boxBtn}>
          {viewDismissBtn && (
            <Button
              variant='text'
              onClick={dismis}
              loading={isLoadingLaonDismis}
            >
              Dismiss
            </Button>
          )}
          <Button
            disabled={isLoadingLaonDismis}
            onClick={() => window.open(signUrl, '_blank')}
          >
            View details
          </Button>
        </div>
      </div>
    </TransitionAnimation>
  );
};
