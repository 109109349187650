import * as yup from 'yup';

export const RenameFinAccountSchema = yup.object().shape({
  accountName: yup
    .string()
    .required('Account name is required') 
    .test('not-only-whitespace', 'Account name cannot be only whitespace', (value) => {
      return value ? value.trim().length > 0 : false;
    }),
});


