import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AuthLoginArg, AuthLoginErrorCaptcha, useLogin2FacControllerMutation, useUseAuthControllerLoginMutation, } from 'api/endpoints/auth';
import { DocumentUploadRequirementApi, RequiredDocumentStatus, useLazyDocumentUploadRequirementQuery, useProductApplicationControllerMutation } from 'api/endpoints/account';
import { LocalStorageKeys, setLocalStorageObject, } from 'shared/utils/local-storage';
import { useForLoginPageCurrentUserControllerMutation } from 'api/endpoints/user';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import GroupingInput from './components/groupingInput';
import LoadingButton from '@mui/lab/LoadingButton';
import ForgotNavigate from './components/forgotNavigate';
import { Divider } from '@mui/material';
import { AnimateWrapper } from 'components/animate-wrapper';
import SingUpNavigate from './components/singUpNavigate';
import GroupingStoreButton from './components/groupingStoreButton';
import Consumer from './components/consumer';
import MultiFactorAuth from './components/multiFactorAuth';
import LoginTitle from './components/loginTitle';
import helper from 'services/helper';
import AccessRestrictionDialogs, { ACCESS_BLOCK_TIMES, APP_ACCESS_RESTRICTED, DataObj, } from './components/accessRestrictionDialogs';
import { appRoutes, VerifyDocsSubRoute, VerifyInfoSubRoute } from '../../routes';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  isConsumer?: boolean;
}

const Login = ({ isConsumer }: Props) => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [loginsCount, setLoginsCount] = useState(0);

  const [loginMutation, { isLoading: loginMutationIsLoading }] =
    useUseAuthControllerLoginMutation();
  const [login2FacController, { isLoading: login2FacIsLoading }] =
    useLogin2FacControllerMutation();
  const [
    productApplicationMutation,
    { isError, isLoading: productApplicationIsLOading },
  ] = useProductApplicationControllerMutation();
  const [
    forLoginPageCurrentUserControllerMutation,
    { isLoading: forLoginPageCurrentUserIsLoading },
  ] = useForLoginPageCurrentUserControllerMutation();

  const [getDocumentUploadRequirement] =
    useLazyDocumentUploadRequirementQuery();

  const [error, setLoginError] = useState<any>('');
  const [siteCaptchaKey, setCaptchaSiteKey] = useState('');
  const [captchaToken, setCaptchaToken] = useState();
  const [need2Fac, setNeed2Fac] = useState<boolean>(false);
  const [isShowMultiFactor, setIsShowMultiFactor] = useState<boolean>(false);

  const [codeError, setCodeError] = useState<string>('');
  const [remember, setRemember] = useState<boolean>(false);
  const [isShowAccessDialogs, setIsShowAccessDialogs] =
    useState<boolean>(false);
  const [pathPhone, setPathPhone] = useState<string>('');

  const [errorAccessDialogs, setErrorAccessDialogs] = useState<
    string | DataObj
  >('');

  const isLoading = [
    loginMutationIsLoading,
    login2FacIsLoading,
    productApplicationIsLOading,
    forLoginPageCurrentUserIsLoading,
  ].some(Boolean);

  const [ticket, setTicket] = useState<string>('');

  const checkDocStatusSubmitted = (data: DocumentUploadRequirementApi) => {
    const businessProfileStatus = data.businessProfile?.requiredDocuments.every(
      (doc) => doc.status === RequiredDocumentStatus.SUBMITTED
    );

    const primaryAuthorizedPersonStatus =
      data.primaryAuthorizedPerson?.requiredDocuments.every(
        (doc) => doc.status === RequiredDocumentStatus.SUBMITTED
      );

    const ultimateBeneficialOwnersStatus =
      data.ultimateBeneficialOwners?.requiredDocuments.every(
        (doc) => doc.status === RequiredDocumentStatus.SUBMITTED
      );

    return (
      businessProfileStatus &&
      primaryAuthorizedPersonStatus &&
      ultimateBeneficialOwnersStatus
    );
  };
  const navigateToVerification = (
    route: string,
    token: string,
    userId: number,
    statusHN?: boolean
  ) => {
    navigate(route, {
      state: {
        token,
        userId,
        statusHN,
      },
    });
  };

  const processApplicationStatus = async (token: string, userId: number) => {
    const res = await productApplicationMutation({ token }).unwrap();
    const status: string = res?.node?.applicationState?.status;
    const doc = await getDocumentUploadRequirement({ token }).unwrap();

    const docsWithUpload = [
      doc?.businessProfile,
      doc?.primaryAuthorizedPerson,
      doc?.ultimateBeneficialOwners,
    ].some(Boolean);

    let canLoginNext = true;

    switch (status) {
      case 'IN_REVIEW':
        if (checkDocStatusSubmitted(doc)) {
          navigate(appRoutes.verifyDocs(VerifyDocsSubRoute.inProcess), {
            state: {
              activeStep: 3,
            },
          });
          canLoginNext = false;
        } else {
          const statusHN = true;
          navigateToVerification(appRoutes.verifyInfo(), token, userId, statusHN);
          canLoginNext = false;
        }
        break;

      case 'PENDING':
        if (checkDocStatusSubmitted(doc) || !docsWithUpload) {
          navigate(appRoutes.verifyDocs(VerifyDocsSubRoute.inProcess), {
            state: {
              activeStep: 3,
            },
          });
          canLoginNext = false;
        } else {
          navigateToVerification(appRoutes.verifyInfo(), token, userId);
          canLoginNext = false;
        }
        break;

      case 'DENIED':
        navigate(appRoutes.verifyInfo(VerifyInfoSubRoute.failure));
        canLoginNext = false;
        break;
    }
    return canLoginNext;
  };

  const handleLogin = async (username, password) => {
    setLoginsCount(count => count + 1);
    const loginBody: AuthLoginArg = {
      password,
      tokenDevice: 'Web',
      nickname: username,
      nameDevice: navigator.userAgent,
      reCaptchaResponse: captchaToken,
    };

    const result = await loginMutation(loginBody).unwrap();
    return {
      token: result.token,
      userId: result.userId,
    };
  };

  const autorized2Fac = async (username, password, code) => {
    if (!code) {
      setIsShowMultiFactor(true);
      return;
    }
    const login2FacRes = await login2FacController({
      username,
      password,
      ticket,
      code: code.replace(/\s/g, ''),
      remember30day: remember,
    }).unwrap();
    clearError();
    return login2FacRes.token;
  };

  const login = async (username, password, code?) => {
    try {
      let token: any = null;
      let userId: any = null;

      if (need2Fac) {
        token = await autorized2Fac(username, password, code);
      } else {
        const autorizedResult = await handleLogin(username, password);
        token = autorizedResult.token;
        userId = autorizedResult.userId;
      }

      const userInfo = await forLoginPageCurrentUserControllerMutation({
        token,
      }).unwrap();

      const isSubUser = userInfo ? userInfo?.type == 4 : false;

      if (!isSubUser && token) {
        const canLoginNext = await processApplicationStatus(token, userId);
        if (!canLoginNext) return;
      }
      if (token) {
        setLocalStorageObject(LocalStorageKeys.AuthToken, token);
        localStorage.setItem('lastRefresh', new Date().toISOString());

        if (!userInfo.isPasswordChangeRequired) {
          localStorage.setItem('isFromLogin', '1');
          window.location.href = '/home';
        } else {
          navigate({
            pathname: '/login/change-password',
            search: createSearchParams({
              NickName: username,
            }).toString(),
          });
        }
      }
    } catch (e: any) {
      const responseDataError = e.status === 400 && e?.data;
      const dataError = e?.data;
      const isAccessBlockError =
        e.originalStatus === 400 && ACCESS_BLOCK_TIMES.includes(e.data);

      if (responseDataError) {
        proccesingResponseDataError(responseDataError);
      } else if (isAccessBlockError) {
        setIsShowAccessDialogs(true);
        setErrorAccessDialogs(e.data);
      } else if (dataError) {
        proccesingDataError(dataError);
      } else {
        setLoginError(helper.formatErrors(e));
      }
    }
  };

  const proccesingResponseDataError = (responseDataError) => {
    if (responseDataError.siteKey) {
      setCaptchaSiteKey(responseDataError.siteKey);
    } else if (responseDataError.needMFA) {
      onMultiFactorAuth(responseDataError);
    } else if (APP_ACCESS_RESTRICTED.includes(responseDataError.message)) {
      setIsShowAccessDialogs(true);
      setErrorAccessDialogs(responseDataError);
    }
  };

  const proccesingDataError = (dataError) => {
    if (dataError === 'Profile was locked.') {
      navigate('/login/account-locked');
    } else if (dataError === 'Invalid code') {
      setCodeError(
        t('common.The entered code is not correct', {
          defaultValue: 'The entered code is not correct',
        })
      );
    } else {
      setLoginError(helper.formatErrors(dataError));
    }
  };

  const onMultiFactorAuth = (data) => {
    setNeed2Fac(data.needMFA);
    setIsShowMultiFactor(true);
    setLoginError('');
    setTicket(data?.tiket);
  };

  const handleRecaptcha = (value) => {
    setCaptchaToken(value);
  };

  const clearError = () => {
    setLoginError('');
    setCodeError('');
    setNeed2Fac(false);
    setIsShowMultiFactor(false);
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <div className={styles.wrapper_content}>
          {!isConsumer ? (
            <>
              <LoginTitle/>
              <GroupingInput
                username={username}
                pass={password}
                setUsername={setUsername}
                setPass={setPassword}
                error={error}
                setError={clearError}
                handleSubmit={login}
              />
              {isShowMultiFactor && (
                <MultiFactorAuth
                  username={username}
                  password={password}
                  onSumbit={login}
                  onClose={() => clearError()}
                  errors={codeError}
                  setErrors={setCodeError}
                />
              )}
              {isShowAccessDialogs && (
                <AccessRestrictionDialogs
                  data={errorAccessDialogs}
                  username={username}
                  password={password}
                  pathPhone={pathPhone}
                  setPathPhone={setPathPhone}
                  onSumbit={login}
                  setIsShowAccessDialogs={setIsShowAccessDialogs}
                />
              )}
              {!!siteCaptchaKey && (
                <div className="mt-[47px]">
                  <ReCAPTCHA key={loginsCount} hl="en" sitekey={siteCaptchaKey} onChange={handleRecaptcha}/>
                </div>
              )}

              <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={!username || !password}
                onClick={() => login(username, password)}
                fullWidth
                sx={{
                  backgroundColor: '#023047',
                }}
              >
                {t('Login.Log In', {
                  defaultValue: 'Log In',
                })}
              </LoadingButton>
              <ForgotNavigate/>
              <SingUpNavigate/>
              <div className={styles.divider}>
                <Divider/>
              </div>
              <GroupingStoreButton/>
            </>
          ) : (
            <Consumer/>
          )}
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default Login;
