export const staticFilterParams = {
  all: {
    displayName: 'Select all',
    value: false,
    items: null,
  },
  pos: {
    displayName: 'Card transactions',
    value: false,
    items: [
      {
        displayName: 'Agricultural Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Airlines, Air Carriers',
        value: false,
        items: null,
      },
      {
        displayName: 'ATM transactions',
        value: false,
        items: null,
      },
      {
        displayName: 'Business Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Car Rental Agencies',
        value: false,
        items: null,
      },
      {
        displayName: 'Clothing Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Contracted Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Government Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Hotels, Motels, and Resorts',
        value: false,
        items: null,
      },
      {
        displayName: 'Miscellaneous Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Professional Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Retail Outlet Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Transportation Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Utility Services',
        value: false,
        items: null,
      },
    ],
  },
};
