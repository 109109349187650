import { useMemo } from "react";
import { useGetAllTicketsControllerQuery, Ticket } from "api/endpoints/tickets";


interface HistoryReturnType {
  history: Ticket[];
  historyIsLoading: boolean;
  historyIsSuccess: boolean;
  historyRefetch: () => void;
}

export const useSupportHistory = (): HistoryReturnType => {
  const { data, isLoading, isFetching, isSuccess, refetch } =
    useGetAllTicketsControllerQuery({});

  const value = useMemo<any>(() => {
    if (!data) {
      return [];
    }

    const temptArr = [...data];

    return temptArr.reverse();
  }, [data]);

  return {
    history: value,
    historyIsLoading: isFetching,
    historyIsSuccess: isSuccess,
    historyRefetch: refetch,
  };
};
