import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { AnimateWrapper } from "components/animate-wrapper"
import { useSearchParams } from "react-router-dom";
import styles from "./style.module.scss";
import { useMediaQuery, useTheme } from "@mui/material";

interface AccountSettingsContainerProps {
  title: string
  children: React.ReactNode
}

const AccountSettingsContainer = ({ title, children }: AccountSettingsContainerProps) => {
  const [_, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img src={ArrowLeft} onClick={() => setSearchParams({})} />
            <span className={styles.container_nav_title}>
              {title}
            </span>
          </div>
        )}

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </AnimateWrapper>
  )
}

export default AccountSettingsContainer;