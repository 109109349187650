import { FC } from "react";
import { replaceNumberWithComma } from "shared/utils/price";
import styles from "./styles.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import cx from "classnames";
import { findRewardCategoryByType } from "pages/reward/lib/findRewardCategoryByType";
import { Typography } from "shared/ui/typography";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";
import infoIcon from "assets/svg/info-blue.svg";
interface EarnderCategoriesItemProps {
  type: number;
  points: number;
  stylesWrapper?: string;
}
const EarnderCategoriesItem: FC<EarnderCategoriesItemProps> = ({
  type,
  points,
  stylesWrapper,
}) => {
  const { t } = useTranslationProvider();
  const item = findRewardCategoryByType(type);

  return (
    <div className={cx(styles.wrapper, stylesWrapper)}>
      <div className={styles.box}>
        <img className={styles.boxImg} src={item?.img} />
        <div className={styles.titleBox}>
        <Typography className={styles.titleBoxText}>
          {t(item?.title!, {
            defaultValue: item?.defaultTitle!,
          })}
        </Typography>
        {item?.toolTip && (
          <TooltipChildren description={item.toolTip}>
            <img src={infoIcon} className={styles.titleBoxTootltipIcon}/>
          </TooltipChildren>
        )}
        </div>
      </div>

      <span className={styles.title_text}>
        {replaceNumberWithComma(points)}
      </span>
    </div>
  );
};
export default EarnderCategoriesItem;
